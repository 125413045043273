<template>
  <div id="scrollContainer">
    <v-container class="pa-0">
      <v-row no-gutters  class="request-info">
        <v-col cols="4" :class="`${centeredClass} pa-0`">
          <v-container class="pa-1">
            <v-row no-gutters
              ><v-col :class="`${centeredClass} pa-0 small-font`">
                Term
              </v-col>
            </v-row>
            <v-row no-gutters
              ><v-col :class="`${centeredClass} pa-0`">
                {{ term.title }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="4" :class="`${centeredClass} pa-0`">
          <v-container class="pa-1">
            <v-row no-gutters
              ><v-col :class="`${centeredClass} pa-0 small-font`">
                Student ID
              </v-col>
            </v-row>
            <v-row no-gutters
              ><v-col :class="`${centeredClass} pa-0`">
                {{ studentId }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="4" :class="`${centeredClass} pa-0`">
          <v-container class="pa-1">
            <v-row no-gutters
              ><v-col :class="`${centeredClass} pa-0 small-font`">
                Confirmation
              </v-col>
            </v-row>
            <v-row no-gutters
              ><v-col :class="`${centeredClass} pa-0`">
                {{ confirmation }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" :class="`${centeredClass} pa-3`">
          <qrcode-vue :value="url" size="90" level="H"></qrcode-vue>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
.small-font{
  font-size: 10px;
}
.request-info{
  background: #828282;
  color: white;
}
</style>
<script>
import QrcodeVue from "qrcode.vue";
import logdown from "logdown";
// import _ from "lodash";
const logger = logdown("QuickRequestInfo");

// const centeredClass = "d-flex align-content-space-around flex-wrap justify-center"

export default {
  name: "QuickRequestInfo",
  components: { QrcodeVue },
  props: ["term", "studentId", "confirmation","url"],
  data: function() {
    return {
      centeredClass:
        "d-flex align-content-space-around flex-wrap justify-center",
    };
  },
  created: function() {
    //init defaults
    logger.log(`created`);
  },
};
</script>
