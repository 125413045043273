<template>
  <div>
    <v-snackbar v-model="snackbar" app centered>
      {{ snackbarText }}
    </v-snackbar>

    <v-dialog
      v-model="menuDialog"
      max-width="500"
      persistent
      style="overflow-x: hidden;"
    >
      <v-card class="pa-0" style="overflow-x: hidden;" flat>
        <v-card-title class="menu-headline pa-0">
          <v-container class="pa-1">
            <v-row no-gutters>
              <v-col cols="6" class="d-flex justify-start">
                <v-icon color="white" class="px-2">mdi-account-outline</v-icon
                >{{ userHumanName }}
              </v-col>
              <v-col cols="4"> </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <v-icon
                  color="white"
                  class="px-2"
                  @click="
                    menuDialog = false;
                    $emit('close');
                  "
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-container class="page-text px-0">
          <v-row>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-0"
            >
              <div class="promo-message-1 ma-0 pa-0">
                <p class="ma-0 pa-0">Refer 3 friends for a chance to win a</p>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-0"
            >
              <div class="promo-message-1 ma-0 pa-0">
                <p class="ma-0 pa-0"><b>FREE Nintendo Switch Lite!</b></p>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-0"
            >
              <v-container id='promo-logo'>
                <v-row no-gutters>
                  <v-col cols="2"> </v-col>
                  <v-col cols="8"> <v-img :src="nintendoSwitch" /> </v-col>
                  <v-col cols="2"> </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-0"
            >
              <div class="promo-link-prompt ma-1 pa-0">
                Share your personal referral link:
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-0"
            >
              <div class="promo-link ma-1 pa-0">{{ promoLink }}</div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-1"
            >
              <div class="promo-click ma-1 pa-0" @click="copyLink">
                Copy link
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-1"
            >
              <div
                id="code-canvas"
                class="promo-click ma-1 pa-0"
                @click="copyLink"
              >
                <qrcode-vue
                  :value="promoLink"
                  size="120"
                  level="H"
                ></qrcode-vue>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-0"
            >
              <div class="promo-link-prompt ma-1 pa-0">
                You have {{refCount}} referrals so far.
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-1"
            >
              <div id='terms-show' class="promo-click ma-1 pa-0" @click="showTerms">
                show terms
              </div>
              <div id='terms-hide' class="promo-click ma-1 pa-0 hidden-terms" @click="hideTerms">
                hide terms
              </div>
            </v-col>
          </v-row>
          <v-row id='promo-terms' class="hidden-terms">
            <v-col
              cols="12"
              class="d-flex align-content-space-around flex-wrap justify-center pa-1"
            >
              <div class="promo-text px-2 py-0 mx-3 my-0">
                You will be entered for a chance to win a Nintendo Switch Lite
                when at least 3 different students use your personal referral
                link to access EdReserve at least once before January 31, 2021.
              </div>
              <div class="promo-text px-2 py-0 mx-3 my-0">
                The winner will be determined by random drawing of all eligible
                entries and will be notified in February 2021.
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-card-text class="py-0 my-0"> 
          <p class="py-1 my-1">
            <Button
              look="btnClose"
              @click="
                menuDialog = false;
                $emit('close');
              "
              height="39"
              width="147"
            />
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.hidden-terms{
  display: none;
}
.promo-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #333333;
}
.promo-link-prompt {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #828282;
}
.menu-headline {
  color: white;
  background: #4f4f4f;
}
.human-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}
.page-text p {
  color: #333333;
}
.promo-message-1 p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #9b51e0;
}
.promo-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #828282;
}
.promo-link {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #4f4f4f;
}
.promo-click {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #9b51e0;
  cursor: pointer;
}
</style>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/Button";
import QrcodeVue from "qrcode.vue";
import logdown from "logdown";
import _ from 'lodash';
import { downloadUserPromoData } from "@/api/user";
import { appRoot } from '@/api/config'
const logger = logdown("NintendoPromoDialog");

export default {
  name: "NintendoPromoDialog",
  components: { Button, QrcodeVue },
  data() {
    return {
      menuDialog: true,
      promoLink: '',
      nintendoSwitch: require("../assets/nintendo-switch.png"),
      refCount: 0,
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapGetters({
      userHumanName: "getUserHumanName",
    }),
  },
  methods: {
    copyLink() {
      navigator.clipboard
        .writeText(this.promoLink)
        .then(() => {
          logger.debug("link successfully copied");
          this.snackbar = true;
          this.snackbarText = "The link was copied to the clipboard.";
        })
        .catch((e) => {
          logger.debug(`could not copy link: ${e}`);
          this.snackbar = true;
          this.snackbarText = "The link could not be copied.";
        });
    },
    showTerms(){
      let terms = document.getElementById('promo-terms')
      let logo = document.getElementById('promo-logo')
      let show = document.getElementById('terms-show')
      let hide = document.getElementById('terms-hide')
      if(terms){terms.classList.remove('hidden-terms')}
      if(logo){logo.classList.add('hidden-terms')}
      if(show){show.classList.add('hidden-terms')}
      if(hide){hide.classList.remove('hidden-terms')}
    },
    hideTerms(){
      let terms = document.getElementById('promo-terms')
      let logo = document.getElementById('promo-logo')
      let show = document.getElementById('terms-show')
      let hide = document.getElementById('terms-hide')
      if(terms){terms.classList.add('hidden-terms')}
      if(logo){logo.classList.remove('hidden-terms')}
      if(show){show.classList.remove('hidden-terms')}
      if(hide){hide.classList.add('hidden-terms')}
    },
  },
  created() {
    logger.debug("created");
    downloadUserPromoData()
      .then(({data}) => {
        logger.debug(data);
        this.refCount = _.get(data, ['count']) || 0
        this.promoLink = appRoot() + "/ref/" + _.get(data, ['code'])
      })
      .catch((e) => {
        logger.error(`could not download user promo data: ${e}`);
      });
  },
  mounted() {
    logger.debug("mounted");
  },
};
</script>
