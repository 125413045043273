/**
 * Mocking the Instructors
*/

const _instructors = [
  { id: "00000090", title: "Staff / TBD" },
  { id: "00000091", title: "Ed Jones" },
  { id: "00000092", title: "Takeshi Tanaka" },
]

function fetchInstructors(){
  return new Promise((resolve)=>{
    setTimeout(()=>{resolve(_instructors)}, 10)
  })
}
export {
  fetchInstructors,
}