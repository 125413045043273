<template>
  <div @click="onChange">
    <v-icon size="30">{{
      selected ? (readOnly ? "$timeSlotSelected" : "$timeSlotChecked") : "$timeSlotUnchecked"
    }}</v-icon>
  </div>
</template>

<script>
import logdown from "logdown";
const logger = logdown("TimeSlot");
// import { mapMutations, mapGetters } from "vuex";
export default {
  name: "TimeSlot",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data: function() {
    return {
      selected: this.value,
    };
  },
  methods: {
    onChange() {
      logger.debug("onChange, readOnly:", this.readOnly);
      // logger.debug(this.selected);
      if (!this.readOnly) {
        this.selected = !this.selected;
        if (this.selected) {
          logger.debug("time-slot-selected");
          this.$emit("time-slot-selected", this.selected);
        } else {
          logger.debug("time-slot-unselected");
          this.$emit("time-slot-unselected", this.selected);
        }
      }
    },
  },
};
</script>

<style scoped></style>
