import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import BoxBlank from '@/components/icons/BoxBlank.vue'
import BoxBlankWhite from '@/components/icons/BoxBlankWhite.vue'
import BoxChecked from '@/components/icons/BoxChecked.vue'
import BoxCheckedWhite from '@/components/icons/BoxCheckedWhite.vue'
import LeafBullet from '@/components/icons/LeafBullet.vue'
import SunBullet from '@/components/icons/SunBullet.vue'
import FlowerBullet from '@/components/icons/FlowerBullet.vue'
import CheckBullet from '@/components/icons/CheckBullet.vue'
import Number1Bullet from '@/components/icons/Number1Bullet.vue'
import Number2Bullet from '@/components/icons/Number2Bullet.vue'
import Number3Bullet from '@/components/icons/Number3Bullet.vue'
import Number4Bullet from '@/components/icons/Number4Bullet.vue'
import SnowflakeBullet from '@/components/icons/SnowflakeBullet.vue'
import BigMagnify from '@/components/icons/BigMagnify.vue'
import Magnify from '@/components/icons/Magnify.vue'
import PageAndPen from '@/components/icons/PageAndPen.vue'
import TimeSlotChecked from '@/components/icons/TimeSlotChecked.vue'
import TimeSlotSelected from '@/components/icons/TimeSlotSelected.vue'
import TimeSlotUnchecked from '@/components/icons/TimeSlotUnchecked.vue'
import TrashBin from '@/components/icons/TrashBin.vue'
import Turkey from '@/components/icons/Turkey.vue'
import DropDown from '@/components/icons/DropDown.vue'
import PullUp from '@/components/icons/PullUp.vue'
//buttons
import BtnAddWP from '@/components/icons/BtnAddWP.vue'
import BtnClose from '@/components/icons/BtnClose.vue'
import BtnLogout from '@/components/icons/BtnLogout.vue'
import BtnGetStartedWG from '@/components/icons/BtnGetStartedWG.vue'
import BtnGetStartedWP from '@/components/icons/BtnGetStartedWP.vue'
import BtnFinishWP from '@/components/icons/BtnFinishWP.vue'
import BtnNextWG from '@/components/icons/BtnNextWG.vue'
import BtnNextWP from '@/components/icons/BtnNextWP.vue'
import BtnNextShortWG from '@/components/icons/BtnNextShortWG.vue'
import BtnNextShortWP from '@/components/icons/BtnNextShortWP.vue'
import BtnBackShortWG from '@/components/icons/BtnBackShortWG.vue'
import BtnConfirmShortWP from '@/components/icons/BtnConfirmShortWP.vue'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#9B51E0',
                secondary: '#4f4f4f',
                accent: '#3f51b5',
                error: '#f44336',
                warning: '#e91e63',
                info: '#00bcd4',
                success: '#4caf50',
                disabled: '#999999'
            },
        },
    },
    icons: {
        values: {
            boxBlank: {
                component: BoxBlank,
            },
            boxChecked: {
                component: BoxChecked,
            },
            boxBlankWhite: {
                component: BoxBlankWhite,
            },
            boxCheckedWhite: {
                component: BoxCheckedWhite,
            },
            timeSlotChecked: {
                component: TimeSlotChecked,
            },
            timeSlotSelected: {
                component: TimeSlotSelected,
            },
            timeSlotUnchecked: {
                component: TimeSlotUnchecked,
            },
            trashBin: {
                component: TrashBin,
            },
            turkey: {
                component: Turkey,
            },
            pageAndPen: {
                component: PageAndPen,
            },
            magnify: {
                component: Magnify,
            },
            bigMagnify: {
                component: BigMagnify,
            },
            leafBullet: {
                component: LeafBullet,
            },
            sunBullet: {
                component: SunBullet,
            },
            flowerBullet: {
                component: FlowerBullet,
            },
            checkBullet: {
                component: CheckBullet,
            },
            number1Bullet: {
                component: Number1Bullet,
            },
            number2Bullet: {
                component: Number2Bullet,
            },
            number3Bullet: {
                component: Number3Bullet,
            },
            number4Bullet: {
                component: Number4Bullet,
            },
            snowflakeBullet: {
                component: SnowflakeBullet,
            },
            dropDown: {
                component: DropDown,
            },
            pullUp: {
                component: PullUp,
            },
            btnAddWP: {
                component: BtnAddWP,
            },
            btnClose: {
                component: BtnClose,
            },
            btnLogout: {
                component: BtnLogout,
            },
            btnGetStartedWG: {
                component: BtnGetStartedWG,
            },
            btnGetStartedWP: {
                component: BtnGetStartedWP,
            },
            btnFinishWP: {
                component: BtnFinishWP,
            },
            btnNextWG: {
                component: BtnNextWG,
            },
            btnNextWP: {
                component: BtnNextWP,
            },
            btnNextShortWG: {
                component: BtnNextShortWG,
            },
            btnNextShortWP: {
                component: BtnNextShortWP,
            },
            btnBackShortWG: {
                component: BtnBackShortWG,
            },
            btnConfirmShortWP: {
                component: BtnConfirmShortWP,
            },
        },
    },
});
