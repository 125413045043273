<template>
  <v-container fluid style="margin: 0px; padding: 0px; width: 100%">
    <div
      @click.passive="testClick"
      class="pa-0 ma-0 d-flex align-center justify-center"
    >
      <BtnClose v-if="look == 'btnClose'" />
      <BtnLogout v-if="look == 'btnLogout'" />
      <BtnAddWP v-if="look == 'btnAddWP'" />
      <BtnGetStartedWG v-if="look == 'btnGetStartedWG'" />
      <BtnGetStartedWP v-if="look == 'btnGetStartedWP'" />
      <BtnFinishWP v-if="look == 'btnFinishWP'" />
      <BtnNextWG v-if="look == 'btnNextWG'" />
      <BtnNextWP v-if="look == 'btnNextWP'" />
      <BtnNextShortWG v-if="look == 'btnNextShortWG'" />
      <BtnNextShortWP v-if="look == 'btnNextShortWP'" />
      <BtnBackShortWG v-if="look == 'btnBackShortWG'" />
      <BtnConfirmShortWP v-if="look == 'btnConfirmShortWP'" />
    </div>
    <!-- <v-icon
      :style="'height:' + height + 'px; width:' + width + 'px'"
      @click.passive="testClick"
      >{{ "" != look ? "$" + look : "" }}</v-icon
    > -->
  </v-container>
</template>

<script>
import BtnClose from "@/components/icons/BtnClose.vue";
import BtnLogout from "@/components/icons/BtnLogout.vue";
import BtnAddWP from "@/components/icons/BtnAddWP.vue";
import BtnGetStartedWG from "@/components/icons/BtnGetStartedWG.vue";
import BtnGetStartedWP from "@/components/icons/BtnGetStartedWP.vue";
import BtnFinishWP from "@/components/icons/BtnFinishWP.vue";
import BtnNextWG from "@/components/icons/BtnNextWG.vue";
import BtnNextWP from "@/components/icons/BtnNextWP.vue";
import BtnNextShortWG from "@/components/icons/BtnNextShortWG.vue";
import BtnNextShortWP from "@/components/icons/BtnNextShortWP.vue";
import BtnBackShortWG from "@/components/icons/BtnBackShortWG.vue";
import BtnConfirmShortWP from "@/components/icons/BtnConfirmShortWP.vue";

import logdown from "logdown";

const logger = logdown("Button");

export default {
  components: {
    BtnClose,
    BtnLogout,
    BtnAddWP,
    BtnGetStartedWG,
    BtnGetStartedWP,
    BtnFinishWP,
    BtnNextWG,
    BtnNextWP,
    BtnNextShortWG,
    BtnNextShortWP,
    BtnBackShortWG,
    BtnConfirmShortWP,
  },
  name: "Button",
  props: {
    look: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "50",
    },
    height: {
      type: String,
      default: "39",
    },
  },
  data: () => {
    return {};
  },
  methods: {
    testClick(event) {
      logger.debug("testClick", event);
      this.$emit("click", event);
    },
  },
};
</script>
