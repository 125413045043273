<template>
  <div>
    <v-snackbar v-model="snackbar" app centered>
      {{ snackbarText }}
    </v-snackbar>
    <v-container class="page-text pa-0">
      <v-row>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center px-0 py-2 rating"
          style="cursor: default;"
        >
          <p class="rating-text ma-1 pa-0">How did we do?</p>
        </v-col>

        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-1"
        >
          <RatingStars @rating-click="onRating" :rating="rating" />
        </v-col>
      </v-row>
      <v-row no-gutters v-if="ratingForm">
        <v-col cols="12" class="pa-3">
          <v-textarea
            outlined
            rows="3"
            v-model="feedbackText"
            dense
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="ratingForm && sendFeedbackButton">
        <v-col cols="12" class="pa-3">
          <CustomButton
            look="longSecondary"
            text="Submit"
            textColor="#9B51E0"
            @click="onSubmit"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="page-text px-0">
      <v-row>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-3 rating"
        >
          <div class="share-message ma-1 pa-0">
            Let your friends know about EdReserve
          </div>
        </v-col>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-1"
        >
          <div class="share-link ma-1 pa-0">{{ getShareLink() }}</div>
        </v-col>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-1"
        >
          <div class="share-click ma-1 pa-0" @click="copyLink">Copy link</div>
        </v-col>
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center pa-1"
        >
          <div id="code-canvas" class="share-click ma-1 pa-0" @click="copyLink">
            <qrcode-vue :value="getShareLink()" size="120" level="H"></qrcode-vue>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import RatingStars from "@/components/RatingStars";
import CustomButton from "@/components/CustomButton";
import { postFeedback } from "@/api/feedback";
import logdown from "logdown";
const logger = logdown("ATermConfirmation");

export default {
  name: "UserRating",
  components: { RatingStars, QrcodeVue, CustomButton },
  props: {
    sendFeedbackButton: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      ratingForm: false,
      rating: 0,
      feedbackText: "",
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
    }),
    ...mapState({
      termsToSelect: (state) => state.allTerms,
    }),
  },
  watch: {
    feedbackText: function(newVal, oldVal) {
      logger.debug(
        `watching feedbackText: ${(newVal || "").length}, ${
          (oldVal || "").length
        }`
      );
      this.setUserFeedback(newVal);
    },
  },
  methods: {
    ...mapMutations({
      setUserFeedback: "setUserFeedback",
      setUserRating: "setUserRating",
    }),
    getShareLink(){
      return `https://${this.config.appDomain}.edreserve.app/`
    },
    onSubmit() {
      this.sendFeedback("send");
      this.ratingForm = false;
    },
    onRating(event) {
      logger.debug(`rating: ${event.rating}`);
      if (!this.ratingForm) {
        this.$emit("show-input");
      }
      this.rating = event.rating;
      this.setUserRating(this.rating);
      this.ratingForm = true;
      this.sendFeedback("auto");
    },
    sendFeedback(mode) {
      postFeedback({
        text: this.feedbackText,
        rating: this.rating,
        mode: mode,
      })
        .then(() => {
          logger.debug("postFeedback, ok");
        })
        .catch((err) => logger.error(err));
    },
    copyLink() {
      navigator.clipboard
        .writeText(this.getShareLink())
        .then(() => {
          logger.debug("link successfully copied");
          this.snackbar = true;
          this.snackbarText = "The link was copied to the clipboard.";
        })
        .catch((e) => {
          logger.debug(`could not copy link: ${e}`);
          this.snackbar = true;
          this.snackbarText = "The link could not be copied.";
        });
    },
  },
};
</script>

<style scoped>
.hidden-form {
  background: orange;
  display: none !important;
}
.rating {
  border-top: solid 1px #333333;
}
.rating-text {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
.page-text p {
  color: #333333;
}
.confirmation-section {
  border-bottom: solid 1px gray;
}
.text-banner {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  /* Gray 1 */
  color: #333333;
}
.text-fineprint {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #828282;
}
.share-message {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #828282;
}
.share-link {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #4f4f4f;
}
.share-click {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #9b51e0;
  cursor: pointer;
}
</style>
