import Vue from 'vue'
import Vuex from 'vuex'
import { fetchTimes } from '@/api/times'
import { getUserStore } from "@/utils"
import { fetchLocations } from '@/api/locations'
import { fetchModalities } from '@/api/modalities'
import { fetchInstructors } from '@/api/instructors'
import { fetchTerms } from '@/api/terms'
import { fetchCourses } from '@/api/courses'
import {
  getReservationStorage, saveReservationStorage,
  saveUserCourse, deleteUserCourse, saveUserTimes, saveUserConfirmations
} from '@/api/reservations'
import _ from 'lodash'
import logdown from 'logdown'

const logger = logdown('store')
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

const _devUserStore = getUserStore()

// initial state
const state = () => ({
  userClient: `${(new Date()).getTime()}`,
  termAndConditions: false,
  showHelpMessages: true,
  appMode: 'student',
  appEnv: 'page',
  bookmark: undefined,
  // reservations
  userHumanName: '-',
  userCourses: {},
  userConfirmations: {},
  userLocations: {
    '2021SPR': {
      main: { id: "main", title: "Main campus" },
      east: { id: "east", title: "East campus" },
      downtown: { id: "downtown", title: "Downtown campus" },
      riverton: { id: "riverton", title: "Riverton campus" },
    }
  },
  userTimes: {},
  userFeedback: '',
  userRating: 0,
  allTerms: [],
  allLocations: [],
  allTimes: [],
  allModalities: [],
  allInstructors: [],
  termCourses: [],
  reservations: [],
  selectedPage: '',
  selectedTerm: undefined,
  selectedSlot: undefined,
  selectedCourse: undefined,
  appWindowHeight: 0,
  debug: debug,
  debugOverlay: false,
  reservationCounts: { 'course': 99 },
  config: {},
  runtime: { os: undefined, browser: undefined, browserVersion: undefined },
  statusMessage: '',
  error: undefined,
  errorLink: undefined,
})

// getters
const getters = {
  getUserClient: state => {
    return state.userClient
  },
  getError: state => {
    return state.error
  },
  getErrorLink: state => {
    return state.errorLink
  },
  getStatusMessage: state => {
    return state.statusMessage
  },
  getRuntimeOS: state => {
    return state.runtime.os
  },
  getRuntimeBrowser: state => {
    return state.runtime.browser
  },
  getRuntimeBrowserVersion: state => {
    return state.runtime.browserVersion
  },
  getConfig: state => {
    return state.config
  },
  getTermsAndConditions: state => {
    return state.termAndConditions
  },
  getShowHelpMessages: state => {
    return state.showHelpMessages
  },
  getUserFeedback: state => {
    return state.userFeedback
  },
  getUserRating: state => {
    return state.userRating
  },
  getUserHumanName: state => {
    return state.userHumanName
  },
  getAppMode: state => {
    return state.appMode
  },
  getAppEnv: state => {
    return state.appEnv
  },
  getBookmark: state => {
    return state.bookmark
  },
  getUserConfirmations: state => {
    return state.userConfirmations
  },
  // reservations
  getUserCourses: state => {
    return state.userCourses
  },
  getUserLocations: state => {
    return state.userLocations
  },
  getUserTimes: state => {
    return state.userTimes
  },
  //
  getDebugOverlay: state => {
    return state.debugOverlay
  },
  getSelectedPage: state => {
    return state.selectedPage
  },
  getSelectedTerm: state => {
    return state.selectedTerm
  },
  getSelectedSlot: state => {
    return state.selectedSlot
  },
  getSelectedCourse: state => {
    return state.selectedCourse
  },
  getCourseReservations: state => {
    return state.reservations
  },
  getAppWindowHeight: state => {
    return state.appWindowHeight
  },
  getAllTerms: state => {
    return state.allTerms
  },
  getAllLocations: state => {
    return state.allLocations
  },
  getAllTimes: state => {
    return state.allTimes
  },
  getAllModalities: state => {
    return state.allModalities
  },
  getAllInstructors: state => {
    return state.allInstructors
  },
}

// actions
const actions = {
  fetchAllTimes({ commit }) {
    logger.debug('fetchAllTimes')
    return fetchTimes().then(times => {
      commit('setAllTimes', times)
    })
  },
  fetchAllLocations({ commit }) {
    logger.debug('fetchAllLocations')
    return fetchLocations().then(locations => {
      commit('setAllLocations', locations)
    })
  },
  fetchAllModalities({ commit }) {
    logger.debug('fetchAllModalities')
    return fetchModalities().then(modalities => {
      commit('setAllModalities', modalities)
    })
  },
  fetchAllInstructors({ commit }) {
    logger.debug('fetchAllInstructors')
    return fetchInstructors().then(instructors => {
      commit('setAllInstructors', instructors)
    })
  },
  fetchAllTerms({ commit }) {
    logger.debug('fetchAllTerms')
    return fetchTerms().then((terms) => {
      commit('setAllTerms', terms)
    })
  },
  fetchTermCourses({ commit }, payload) {
    logger.debug('fetchTermCourses')
    fetchCourses(payload.term).then(courses => {
      commit('setCourses', courses)
    })
  },
  clearUserData({ commit }) {
    logger.debug('clearUserData')
    getReservationStorage(_devUserStore)
      .then(storage => {
        storage.removeAll().then(() => {
          saveReservationStorage(storage).then(() => {
            commit('setUserCourses', {})
          })
        })
      })
      .catch(error => { logger.error(error) })
  },
  fetchAllUserData({ commit }) {
    logger.debug('fetchAllUserData')
    return getReservationStorage(_devUserStore)
      .then(storage => {
        Promise.all([
          storage.getAllCourses().then(courses => { commit('setUserCourses', courses) }),
          storage.getAllTimes().then(times => { commit('setUserTimes', times) }),
          storage.getConfirmations().then(confirmations => {
            commit('setUserConfirmations', confirmations)
          }),
        ])
      })
      .catch(error => logger.error(error))
  },
}

// mutations
const mutations = {
  setError(state, newError) {
    state.error = newError
    logger.debug(`error: ${newError}`)
  },
  setErrorLink(state, newErrorLink) {
    state.errorLink = newErrorLink
    logger.debug(`error: ${newErrorLink}`)
  },
  setStatusMessage(state, newMessage) {
    state.statusMessage = newMessage
    logger.debug(`status message: ${newMessage}`)
  },
  setRuntimeOS(state, newOs) {
    state.runtime.os = newOs
  },
  setRuntimeBrowser(state, newBrowser) {
    state.runtime.browser = newBrowser
  },
  setRuntimeBrowserVersion(state, version) {
    state.runtime.browserVersion = version
  },
  setConfig(state, newConfig) {
    logger.debug('setConfig, old', state.config)
    logger.debug('setConfig, new', newConfig)
    state.config = newConfig
  },
  setTermsAndConditions(state, TAC) {
    logger.debug('setTermsAndConditions', TAC)
    state.termAndConditions = TAC
  },
  setShowHelpMessages(state, SHM) {
    logger.debug('setShowHelpMessages', SHM)
    state.showHelpMessages = SHM
  },
  setUserFeedback(state, feedback) {
    logger.debug('setUserFeedback', feedback)
    state.userFeedback = feedback
  },
  setUserRating(state, rating) {
    logger.debug('setUserRating', rating)
    state.userRating = rating
  },
  setUserHumanName(state, name) {
    logger.debug('setUserHumanName', name)
    state.userHumanName = name
  },
  setAppMode(state, mode) {
    logger.debug('setAppMode', mode)
    state.appMode = mode
  },
  setAppEnv(state, env) {
    logger.debug('setAppEnv', env)
    state.appEnv = env
  },
  setBookmark(state, bookmark) {
    logger.debug('setBookmark', bookmark)
    state.bookmark = bookmark
  },
  setUserConfirmations(state, confirmations) {
    logger.debug('setUserConfirmations', confirmations)
    state.userConfirmations = confirmations
    saveUserConfirmations(_devUserStore, state.userConfirmations)
  },
  // reservations
  setUserCourses(state, courses) {
    logger.debug('setUserCourses', courses)
    state.userCourses = courses
  },
  setUserCourse(state, payload) {
    logger.debug('setUserCourse', payload)
    _.set(state.userCourses, [payload.term, payload.slot], payload.course)
    //sync the localStorage
    saveUserCourse(_devUserStore, payload.term, payload.slot, payload.course)
  },
  setUserCourseUnselectedModalities(state, payload) {
    // {term, slot, unselectedModalities}
    const { term, slot, unselectedModalities } = payload
    logger.debug(`updateUserCourseModalities ${term}, ${slot},`, unselectedModalities)
    _.set(state.userCourses,
      [term, slot, 'unselectedModalities'], unselectedModalities)
    saveUserCourse(_devUserStore, term, slot, _.get(state.userCourses, [term, slot]))
  },
  unsetUserCourse(state, payload) {
    logger.debug('unsetUserCourse', payload)
    logger.debug(`unsetUserCourse, ${payload.term}, ${payload.slot}`)
    _.unset(state.userCourses, [payload.term, payload.slot])
    deleteUserCourse(_devUserStore, payload.term, payload.slot)
  },
  setUserLocations(state, payload) {
    logger.debug('setUserLocations', payload)
    _.set(state.userLocations, [payload.term], payload.locations)
  },
  setUserLocation(state, payload) {
    logger.debug('setUserLocations', payload)
    _.set(state.userLocations, [payload.term, payload.id], payload.location)
  },
  unsetUserLocation(state, payload) {
    logger.debug('setUserLocations', payload)
    _.set(state.userLocations, [payload.term, payload.id])
  },
  unsetUserLocations(state, payload) {
    logger.debug('setUserLocations', payload)
    _.set(state.userLocations, [payload.term], {})
  },
  setUserTimes(state, times) {
    state.userTimes = times
  },
  setUserTermTimes(state, payload) {
    const { term, times } = payload
    logger.debug('setUserTimes', term, times)
    _.set(state.userTimes, [term], times)
    saveUserTimes(_devUserStore, term, times)
  },
  unsetUserTermTimes(state, payload) {
    logger.debug('unsetUserTimes', payload)
    _.set(state.userTimes, [payload.term], {})
  },
  //
  setReservationCount(state, payload) {
    state.reservationCounts[payload.id] = payload.count
  },
  setDebugOverlay(state, newValue) {
    state.debugOverlay = newValue
  },
  setAllLocations(state, locations) {
    logger.debug(`setAllLocations: ${locations.length} locations`)
    state.allLocations = locations
  },
  setAllTimes(state, times) {
    logger.debug(`setAllTimes: ${times.length} times`)
    state.allTimes = times
  },
  setAllTerms(state, terms) {
    logger.debug(`setAllTerms: ${terms.length} terms`)
    state.allTerms = terms
  },
  setAllModalities(state, modalities) {
    logger.debug(`setAllModalities: ${modalities.length} modalities`)
    state.allModalities = modalities
  },
  setAllInstructors(state, instructors) {
    logger.debug(`setAllInstructors: ${instructors.length} instructors`)
    state.allInstructors = instructors
  },
  setCourses(state, courses) {
    logger.debug(`setCourses: {courses.length} courses`)
    state.termCourses = courses
  },
  setCourseReservations(state, newCourseReservations) {
    state.reservations = newCourseReservations
  },
  setSelectedPage(state, newSelectedPage) {
    logger.debug('setSelectedPage', newSelectedPage)
    state.selectedPage = newSelectedPage
  },
  setSelectedTerm(state, newSelectedTerm) {
    state.selectedTerm = newSelectedTerm
  },
  setSelectedSlot(state, newSelectedSlot) {
    state.selectedSlot = newSelectedSlot
  },
  setSelectedCourse(state, newSelectedCourse) {
    state.selectedCourse = newSelectedCourse
  },
  setAppWindowHeight(state, newAppWindowHeight) {
    state.appWindowHeight = newAppWindowHeight
  }
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  strict: debug,
})