import _ from 'lodash'
import logdown from 'logdown'
const logger = logdown('admin')

function adminRedirect(localKey) {
    const host = _.get(window.location, 'host')
    const redirectUrl = `https://admin.${host}/sso/login?localKey=${encodeURI(localKey)}`
    logger.log(`adminRedirect, ${redirectUrl}`)
    window.location.replace(redirectUrl);
    // window.location.replace(`https://admin.udev.edreserve.app/sso/login?localKey=${encodeURI(localKey)}`)
}

export {
    adminRedirect,
}