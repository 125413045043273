var data = {
"ACCT201":{"description":"A study of the principles of financial accounting and reporting as they relate to today's business environment. Both the procedures used and the concepts upon which they are based will be studied."},
"ACCT202":{"description":"Business Core requirement for all business majors.  A study of managerial accounting information as it is used by managers in various types of business organizations. The emphasis is on the development, interpretation, and application of managerial accounting for planning activities, controlling operations, and making decisions."},
"ACCT303":{"description":"An intensive study and application of Generally Accepted Accounting Principles (GAAP) for asset valuation, income measurement, and financial statement presentation for business organizations, including the processes through which GAAP has evolved. The emphasis in this course is on the basic financial statements and related footnotes, the time value of money, the valuation and presentation of assets, current liabilities, and related income statement accounts, and other special accounting topics."},
"ACCT304":{"description":"A continuation of the intensive study and application of GAAP begun in ACCT 303. The emphasis in this course is on the valuation and presentation of long-term liabilities, stockholders equity, revenue, and related income statement accounts, footnote disclosures required by GAAP, and other special accounting topics."},
"ACCT311":{"description":"An introduction to federal tax theory and practice as applied to business entities. The emphasis is on the examination of the role of taxation in society (legal, social, economic), procedural rules and their application, and tax planning techniques."},
"ACCT315":{"description":"The theory and practice of cost accounting with emphasis on its use for planning and control. It builds on the concepts of budgeting, standards, and profitability analysis introduced in ACCT 202."},
"ACCT390":{"description":"This course provides students with the opportunity to serve the local community by preparing federal and state individual tax returns for low-income taxpayers, nonresident taxpayers, and other students. Students will gain hands-on experience in the operation of a Volunteer Income Tax Assistant site. Upon successful completion of the course, students will be able to use tax preparation software, interview clients, research tax issues, and perform quality reviews of prepared income tax returns."},
"ACCT401":{"description":"An intensive study of the theory and application of Generally Accepted Accounting Principles (GAAP) for specific advanced financial accounting entities, problems, and issues. Areas of emphasis include consolidated financial statements, accounting for investments in non-consolidated entities, segment and interim reporting, international accounting and reporting issues, not-for-profit accounting (including governmental accounting), and other special accounting problems and topics."},
"ACCT411":{"description":"A study of federal income tax as it relates to business entities, transfer taxes, and wealth planning. A review of Internal Revenue Code provisions and regulations will be made on topics relevant to tax professionals. This course builds on information learned in ACCT 311."},
"ACCT413":{"description":"A study of organizations, their activities and processes, and the information needs of organizational stakeholders. The course emphasizes accounting data flows, the tools of designing accounting information systems, the use of computer technology in processing accounting transactions, and knowledge of internal control structures in effective accounting information systems."},
"ACCT415":{"description":"An overview of auditing as it is practiced in the USA. Emphasis is placed on understanding the functions of an audit, the ethical and professional standards that apply to auditors, legal liability issues, and the concepts of risk analysis, risk-based auditing, and statistical sampling."},
"ACCT416":{"description":"This course builds on the information learned in ACCT 315. It addresses the theory and practice of cost accounting with emphasis on cost allocation, joint products, transfer pricing, segment performance evaluation, accounting decision models and managerial accounting problems."},
"ACCT422":{"description":"Provides an understanding of the regulatory environment in which financial reporting and auditing take place in the USA. The course examines changes in the auditing profession over the past 25 years that contributed to the ethical and professional lapses associated with a number of financial failures (Enron, WorldCom, etc.), ethical challenges facing CPAs, current controversial issues in financial reporting, and an introduction to fraud auditing and forensic accounting."},
"ACCT445":{"description":"Fraud is a growing concern for organizations worldwide.  Estimates suggest that a majority of organizations experience fraud, representing billions of dollars in losses annually.  Additionally, individuals are defrauded at an increasingly frequent rate.  This course provides an overview of various types of fraudulent activity, the impacts on organizations and individuals and techniques to help detect and prevent fraud."},
"ACCT451":{"description":"This course is the first in a two-course sequence and focuses on equity instruments and alternative assets as well as derivative instruments such as options or futures that are linked to equity instruments.  This course also provides students with the technical skills and knowledge to analyze and report emerging new financial instruments for which there may not be a clear regulation."},
"ACCT452":{"description":"This course is the second in this two-course sequence and focuses on accounting for fixed income securities and interest rate derivatives, private equity, managed futures, and some categories of real assets such as real estate.  This course also provides students with the technical skills and knowledge ti analyze and report new financial instruments for which there may not be a clear regulation."},
"ACCT453":{"description":"This course provides an introduction to the use of alternative investments, the accounting recording for alternative investments, and the related disclosures required by generally accepted accounting principles. Types of investments covered include: stocks, options, futures, private equity, fixed income securities, and interest rate derivatives. In addition, students will learn partnership accounting for investments and develop technical skills and knowledge to analyze and report financial instruments for which there may be no clear standard."},
"ACCT461":{"description":"This course will explore accounting disclosure rules, regulatory, and compliance issues associated with alternative investments, such as investments on hedge funds, private equity funds, venture capital funds, real estate, and commodities, either directly or through funds of funds. The course will focus on operational aspects of alternative investments, emphasizing practical applications and providing opportunities for \"hands-on\" experiences."},
"ACCT465":{"description":"Financial statement fraud came clearly into focus as a result of a series of accounting and auditing scandals in the late 1990s and early 2000s.  A number of events increased overall awareness of fraud and fraudulent financial reporting.  This course will use a case study approach and professional standards and codes of ethics to explore basic ethical frameworks as they pertain to business and accounting scenarios, as well as introduce students to fraud prevention theory and guidelines for practice."},
"ACCT499":{"description":"An opportunity to gain work experience in the accounting field.  The internship program is designed to: (1) provide undergraduate accounting students realistic work experience to improve the depth of understanding and application in the accounting field; (2) develop student maturity and confidence to explore specific career opportunities in accounting; and, (3) create a work situation in which advanced accounting courses are made more meaningful as a result of the perspective that comes with professional accounting experience."},
"ADV101":{"description":"This is a foundation course in advertising that covers a general overview of advertising and marketing communications, its role in marketing and the integration of marketing communication from the client's, consumer's, and advertiser's viewpoint."},
"ADV335":{"description":"This course examines the theories underlying the practice of strategic planning, execution and evaluation of advertising. The course covers the fundamentals of advertising and provides the necessary tools used by planners to conduct and transform research into strategic proposals."},
"ADV342":{"description":"This course covers a general understanding and application of design, color, type, and layout styles in the creation of promotional materials.  Students will also learn to use software such as Adobe Creative Suite to create promotional materials to communicate with target audiences/publics."},
"ADV343":{"description":"Students will learn the concepts behind the strategic selection of media to reach target audiences, media measurements and calculations, and industry trends in media planning. The course will culminate in the creation of a media plan to demonstrate understanding of media planning concepts."},
"ADV346":{"description":"Students will learn strategic and creative event management through event planning, event analytics, venue selection, safety protocols, communication and promotion, fundraising, budgeting, and policies and procedures.  Goals and objectives for the client drive the event planning while supporting the brand."},
"ADV347":{"description":"A study of the principles and practices of writing effective commercial messages for media such as magazines, newspapers, billboards, direct mail, directories, and other promotional copy. It includes studies of message elements; the role of research in developing message strategies; the creative process; and clear, effective, and persuasive copywriting."},
"ADV400":{"description":"Designed to provide an opportunity for upper division communications majors and minors to research subject areas in the field of advertising. A maximum of six hours of ADV 400 may be taken; only three may be taken in any one semester. Students wishing to take independent study courses in the advertising curriculum should be aware of the following points: 1) Only six hours of independent study may apply toward a major in communications; 2) No more than three hours of Advertising 400 may be directed by the same instructor. 3) Students wishing to enroll in independent study courses must receive written permission from their instructors prior to registration."},
"ADV440":{"description":"This course is based on knowledge gained in the PRA major and applied to a client-based regional competition. Students taking this course will be actively engaged in the process requiring a team approach."},
"ADV441":{"description":"This course focuses on integrating advertising, public relations and social/interactive/communications. Students learn theories and models associated with IMC concepts used to develop communications strategies and decisions. Students will evaluate IMC and how it aligns with clients, target publics/audiences/markets, and impact on the organization and the brand."},
"ADV444":{"description":"A capstone course that focuses on the creation and implementation of advertising campaigns. Students will evaluate the current advertising for a client and using research techniques, follow the steps in creating individual advertising campaigns for the client as outlined in the course."},
"ADV446":{"description":"This is a course involving the origins and effects of modern advertising and how advertising works on individuals and society. Students will conduct their own inquiries through individual projects designed to make them consider the nature of needs and how to design, advertise, and satisfy teams."},
"ADV489":{"description":"Offers students job experience in the discipline through a minimum of 50 hours of supervised professional work per credit hour."},
"ADV499":{"description":"Topics will vary. Purpose of course is to cover topics that are not specific courses in the curriculum."},
"AFRC111":{"description":"This interdisciplinary course will introduce and explore the past, present and future of Africana Studies and the paradigms and perspective that make up the discipline. The course will introduce students to African, African-American, and African Diaspora studies. Topics will include race constructions and representations of Blackness, slavery and emancipation, colonialism and anti-colonial resistance, Black internationalism, Diaspora, Apartheid, and Jim Crow."},
"AFRC300":{"description":"An examination of a theme or an era in Africana Studies. Substantial reading, class discussion, and a major assignment are required. The focus of each course offering is determined by the instructor and the Africana Studies minor program."},
"AFRC311":{"description":"Interdisciplinary inquiry into the history, challenges, and forms of Africana Studies that engages contemporary and historical approaches. Students will explore academic disciplines, as well as material beyond the academy, including art, folk culture, language, literature, music, and social movements."},
"AFRC321":{"description":"An educational trip to an African country with the goal of providing students with a practical field experience as a way of exposing them to learning through diversity."},
"AFRC331":{"description":"An historical and socio-cultural analysis of some of the major people, places, and events of the Modern Civil Rights Movement, with particular focus on the implications of the movement on the contemporary society."},
"AFRC362":{"description":"This course takes a thematic and chronological approach to understanding basic concepts of the African Diaspora as portrayed through film. Students will learn about theoretical approaches, as well as the diversity and complexity of people and places of the Diaspora through in-depth weekly film analysis. Due to the vastness of the topic, this course centers each week's analysis on a particular issue of \"case study\" which will collectively contribute to a better grasp of the intricacies and complexities of the African Diaspora. Geographically, films have been selected primarily outside of Africa, but sometimes have African directors, producers, actors, etc. The development of the film (writers, directors, filming, etc.) will contribute to our discussions and understandings of contemporary visions of the Diaspora as well. The ultimate goal is for students to understand simultaneously the commonality and the diversity of experiences of those living in the Diaspora."},
"AFRC400":{"description":"An intensive examination of a theme or an era in Africana Studies.  Substantial reading, class discussion, and a major assignment are required.  The focus of each course offering is determined by the instructor and the Africana Studies minor program."},
"AHA201":{"description":"Introduction to basic issues and challenges in the administration of non-profit arts and heritage organizations, including a brief history of arts and heritage management, the structure and challenges of the non-profit sector, and practical concerns in arts and heritage administration, including artistic and administrative leadership, institutional structures, government relations, and the place of arts and heritage institutions in modern society."},
"AHA251":{"description":"Introduction to computer applications including word processing, spreadsheets, presentations, and other software packages used in public and non-profit institutions and organizations."},
"AHA301":{"description":"Students examine the changing role of arts and heritage organizations, and explore issues related to arts and heritage responsibilities, cultural sensitivity, community relationships, and outreach programs.  Emphasis on creative research, strategic planning, collaboration, and oral and written communication."},
"AHA499":{"description":"Work experience in arts or heritage site or organization which may fall outside the traditional disciplinary internships. Intensive study related to a particular project that will be used by an arts or heritage-related organization may substitute. An internship requires a minimum of 150 hours of supervised professional work, two written evaluations by the supervisor, two written self-evaluations and a final review with director of the AHA program. Intensive study should result in a substantial final project or report that will be submitted to an arts or heritage-related organization."},
"ANTH111":{"description":"This course explores the human condition from a cross-cultural perspective and introduces the basic concepts, theories, and methodologies of cultural anthropology."},
"ANTH121":{"description":"An introduction to archaeological methods, concepts, and theories, and their application in the investigation, reconstruction, and interpretation of both prehistoric and historic cultures. Students will learn what archaeologists do and how they discover what life was like in the past."},
"ANTH131":{"description":"This course examines fundamental aspects of a physical nature of humans and human variability. It selectively reviews the long record of human biological adaptations that have existed from the appearance of the earliest Hominids up to the development of anatomically modern forms. Topics include principles of evolution, human variation and adaptability, non-human primate behavior, non-human osteology (study of the skeleton), and the human fossil record."},
"ANTH200":{"description":"A study of selected topics of current interest and importance in anthropology. These topics will vary in accordance with the interests of the students and faculty."},
"ANTH211":{"description":"This course examines the fields of forensic anthropology and human osteology. It places emphasis on field recovery techniques, management of the death scene, estimation of time since death, and cause and manner of death. In addition, students will learn human osteology and its application to human identification in legal contexts."},
"ANTH221":{"description":"This course will investigate popular myths about the past, such as the lost continent of Atlantis and ancient alien astronauts.  Students will learn to distinguish facts from fiction, understand how archaeologists know what they know, and more generally, learn how to check what we hear."},
"ANTH235":{"description":"This course explores both differences and commonalities, and what defines \"American\" despite the nation's diversity.  The United States is made up of diverse peoples, more-or-less bound together by a common government, laws, and economy and family histories reach into many different ethnic groups and traditions."},
"ANTH241":{"description":"Religious belief is a cultural universal -- all peoples have some form of belief in the supernatural.  Though there are very few specific beliefs that all religions share, there are a number of common ways in which humans relate to and interact with the supernatural.  This course will examine religion from a worldwide perspective, drawing upon examples from many areas of the globe."},
"ANTH322":{"description":"The natural environment provides both opportunities and limitations on human culture.  It is a major contributing factor in the way particular cultures develop over time, in terms of subsistence patterns, technology, and socio-political structure.  Students will examine the concept of cultural ecology, tracing its history in anthropology to the present day."},
"ANTH323":{"description":"The ethnography of Native North American cultures through a detailed study of representative tribal units by cultural areas.  The course includes an examination of historic native cultures from first contact with Europeans to the role of North American Indian populations as an important ethnic group in modern American life."},
"ANTH324":{"description":"An introduction to the diverse cultures of Asia through the reading of ethnography and other relevant anthropological literature.  Emphasis will be placed on such topics as belief systems, ethnic identity, marriage, kinship, and social organization."},
"ANTH325":{"description":"This course provides students with an introduction to the ethnography and ethnology of Latin America, examining the cultural diversity present in Latin America while also looking at the commonalities among its diverse populations."},
"ANTH326":{"description":"This course is a survey of sub-Saharan Africa that will provide a look at African culture, emphasizing economic, religious, and political aspects, both traditional and modern."},
"ANTH327":{"description":"This course provides students with an introduction to the ethnology of South America while focusing on the cultural diversity present in South America, as well as the commonalities that exist amongst its diverse populations."},
"ANTH342":{"description":"An introduction to prehistory of the Eastern Woodlands, comprising those areas east of the Mississippi River. The course will cover the entirety of Native American occupation, from the first peopling of the Americas, circa 15,000 years ago, to the point of European contact."},
"ANTH343":{"description":"An introduction to the archaeological record of Mesoamerica, from the first inhabitants of the Americas to the European arrival in the sixteenth century.  Cultures such as the Olmec, Maya, Toltec, and Aztec will be discussed, with particular emphasis on subsistence, architecture, social systems, and the development of social inequality."},
"ANTH344":{"description":"This course is a survey of Greek archaeology, covering the period from the Stone Age to the death of Alexander the Great.  The material culture of ancient Greece will be examined in its sociopolitical, economic, and religious context, exploring how ancient Greeks lived."},
"ANTH345":{"description":"This course will examine the five main periods within the prehistoric past of Europe, from the Ice Age through the end of the Iron Age.  Students will explore the archaeological remains from these periods, focusing on topics such as subsistence strategies, technology, social organization, mortuary practices, the arts, and warfare."},
"ANTH352":{"description":"Since primates are our closest living relatives, they are of great interest in better understanding both the past and present state of humanity.  This course is an exploration of the taxonomic divisions, ecology, social organization, and reproductive behavior of the non-human primates.  Both living and fossil primates will be considered."},
"ANTH353":{"description":"An examination of the biological bases of human society and culture. This course surveys human evolution from pre-human ancestors to the appearance of Homo sapiens."},
"ANTH354":{"description":"This course is devoted to a detailed examination of the descriptive and functional morphology of the human skeleton, with an emphasis on its applications in physical anthropology and archaeology.  Students will explore methods used in determining age, sex, stature, trauma, and paleopathology."},
"ANTH355":{"description":"The range of contemporary human biological variation is explored from an evolutionary and adaptive perspective.  Also considered are the concepts of \"race\" and the history of its use (and abuse) within the discipline of anthropology."},
"ANTH370":{"description":"An intensive, small-group discussion of specific topics of current interest in cultural anthropology.  The seminar format will emphasize critical thinking and discussion.  Students may take the course more than once as the topic changes."},
"ANTH380":{"description":"An intensive, small-group discussion of recent, provocative books on topics of current interest to archaeological anthropology.  The seminar format will emphasize critical thinking and discussion.  Students may take the course more than once as the reading list changes."},
"ANTH390":{"description":"An intensive, small-group discussion of recent, provocative books on topics of current interest to physical anthropology.  The seminar format will emphasize critical thinking and discussion.  Students may take the course more than once as the reading list changes."},
"ANTH397":{"description":"This course is designed to give students experience in archaeological field methods through participation in a site excavation. Students learn basic excavation techniques, mapping, and artifact identification."},
"ANTH401":{"description":"This course will explore the means by which anthropologists analyze the material remains and derive conclusions.  A variety of prehistoric and historic artifacts will be covered, including stone tools and ceramics.  Each student will complete a research project involving the analysis of a collection."},
"ANTH402":{"description":"This course provides a historical perspective on the changes that have taken place in ethnographic inquiry.  Topics covered include the historical foundations of ethnography, the crisis of representation in cultural anthropology, the ethics of ethnography, and methodologies for doing ethnography.  Major themes will be addressed through readings and discussions of various ethnographic texts."},
"ANTH475":{"description":"This course covers the major intellectual trends in cultural anthropology from the nineteenth century to the present.  Topics include evolutionary anthropology, functionalism, materialism, structuralism, cognitive anthropology as well as more recent postmodern approaches to understanding culture."},
"ANTH489":{"description":"An extensive examination of the main anthropological ideas on a specific topic. The major paper is a product of critical reading or advanced research."},
"ANTH499":{"description":"An opportunity for work experience in the area of anthropology. For students interested in graduate school, the internship will involve field research."},
"ARAB101":{"description":"Fundamentals of Arabic, no previous Arabic required. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of Arabic-speaking countries. In addition to the regular class meetings, students are required to complete one hour per week in language lab."},
"ARAB102":{"description":"Fundamentals of Arabic; continuation of ARAB 101. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Arabic-speaking countries. In addition to the regular class meeting, students are required to complete one hour per week in the language lab."},
"ARAB203":{"description":"Continuation of ARAB 102. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Arabic-speaking countries. In addition to the regular class meeting, students are required to complete one hour per week in the language lab."},
"ARAB204":{"description":"Continuation of ARAB 203. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Arabic-speaking countries. In addition to the regular class meeting, students are required to complete one hour per week in the language lab."},
"ARAB325":{"description":"Critical readings and literary analysis of selected Arabic texts from the middle ages to present in the major literary genres."},
"ARAB499":{"description":"Research or advanced study of a specific area of Arabic literature, culture, education or language culminating in an analytical paper or comprehensive exam."},
"ART103":{"description":"An introduction to the two-dimensional arts through projects based on the fundamentals of design and study of color theory. A wide variety of art media and techniques are presented to prepare students for courses in painting, printmaking, and graphic design."},
"ART104":{"description":"An introduction to the process of developing three-dimensional concepts in visual art, using various media such as wood, metal, and clay."},
"ART105":{"description":"Investigation of basic drawing concepts, such as line quality, negative space, perspective, and use of value. Course will concentrate on techniques in black and white drawing media."},
"ART106":{"description":"Continuation of ART 105, concentrating on development of finished compositions in drawing. Course will explore techniques in color media."},
"ART201":{"description":"A basic survey of the media, elements, and organization of painting, graphics, sculpture, and architecture.  ART 201 is not applicable to the Art major."},
"ART205":{"description":"Drawing the human figure, stressing form, structure, and action of the human figure."},
"ART206":{"description":"Drawing the human figure from the posed model with various props."},
"ART255":{"description":"Introduction to African arts through different types of visual arts and practices. Students will gain an understanding of African arts and their relevance in African cultures and history."},
"ART311":{"description":"Focuses on traditional techniques in oil/acrylic painting media."},
"ART312":{"description":"A continuation of ART 311, with emphasis on experimental/non-traditional painting techniques."},
"ART313":{"description":"Introduction to basic methods of working with clay. Throwing, hand building, and a variety of techniques will be covered."},
"ART314":{"description":"A continuation of ART 313. Emphasis on more complex forms and glazes. Introduction to clay and glaze formulation and continued development of firing techniques."},
"ART315":{"description":"The theory and practice of making jewelry and metal objects. Introduction to the fundamentals of fabrication and construction techniques."},
"ART316":{"description":"Continued development in making jewelry and metal objects. Further technical exploration in metal media."},
"ART341":{"description":"Introduction to processes and materials essential to the creation of three-dimensional art, accompanied by concept development to create successful sculpture."},
"ART342":{"description":"Continuing involvement with sculptural processes and materials with emphasis on concept development."},
"ART351":{"description":"Introduction to fundamental woodworking techniques. Emphasis will be placed on developing an understanding of basic wood joinery, machinery operation, and the development of an original design."},
"ART352":{"description":"Continued experience in woodworking. The emphasis will be placed on merging the utilitarian form with the sculptural aesthetic."},
"ART361":{"description":"Introduction to mono-printing, papermaking, black and white lithography, and woodcut techniques."},
"ART362":{"description":"Introduction to black and white intaglio printmaking as well as basic color techniques in lithography, woodcut, and monoprint techniques."},
"ART399":{"description":"An intensive study of specialized topics in the visual arts."},
"ART405":{"description":"Composing with the human figure, using props, and experimenting with various media."},
"ART406":{"description":"An extension of ART 405, drawing the human figure from posed model in composition, using various media."},
"ART411":{"description":"Development of a series of works that relate conceptually, stylistically, and/or technically."},
"ART412":{"description":"Advanced work developing personal aesthetic vision in painting media."},
"ART413":{"description":"Advanced methods of working with clay and glazes. More independent projects will be required. Demonstrated ability to load, fire, and operate kilns is required."},
"ART414":{"description":"A semester of intense ceramic work. Projects must indicate a comprehensive understanding of ceramic techniques."},
"ART415":{"description":"Continuation of ART 316 with emphasis on individual direction within the jewelry media."},
"ART416":{"description":"Continuation of ART 415 with concentrated effort on personal development within the jewelry media."},
"ART441":{"description":"Advanced experience in sculpture processes and materials with an emphasis on conceptualization and development of a personal aesthetic."},
"ART442":{"description":"Continuing advanced experience in sculptural processes and materials with emphasis on concept and personal aesthetic development."},
"ART451":{"description":"Advanced experience in woodworking with emphasis on furniture design."},
"ART452":{"description":"Advanced experience in woodworking with emphasis on structural possibilities of wood."},
"ART461":{"description":"Intermediate methods in intaglio, lithography, woodcut, and monoprint techniques."},
"ART462":{"description":"Advanced methods in intaglio, lithography, woodcut, and monoprint techniques."},
"ART474":{"description":"Advanced work in lighting and technical processes; development of a personal aesthetic vision in the medium."},
"ART489":{"description":"Course by appointment with any of the full-time art faculty. Problems related to specific training within a specific area of art."},
"ART493":{"description":"A capstone course for art and art education majors combining discussion of current aesthetic issues in the visual arts with practical knowledge in career or graduate school preparation, as well as evaluation of students' artistic growth and potential."},
"ART498":{"description":"A course to complement classroom and studio experience by providing professional work situations with an employer. The course requires a minimum of 150 hours of supervised professional work, two written evaluations by the supervisor, two onsite visits by a University official, maintenance of a portfolio of completed work, and periodic interviews with an art faculty member. Open to juniors and seniors."},
"ARTD231":{"description":"An introduction to the creative principles and processes in visual communication. Emphasis is placed on thinking visually, exploring the relationship between words and images, and developing appropriate solutions to the problems assigned. An overview of the history of graphic design also is covered."},
"ARTD232":{"description":"This course examines type as an element of design in visual communications. Type design, the study of letterforms, and the historical and modern development of the alphabet are covered. Students explore the creation of informative, experimental, and expressive typographic forms by using traditional and computer-generated techniques."},
"ARTD275":{"description":"This course explores the computer as a creative tool for designers, artists, and persons in the media arts. It familiarizes students with the concepts used in creative software programs for graphics. The primary emphasis is on the creative uses of computers in the arts, design, and media."},
"ARTD331":{"description":"This course introduces students to illustration principles, methods, and the use of materials. Students develop their perceptual abilities, sensitivity to color, and compositional ideas within the context of illustration. Students also explore the use of various materials and techniques to produce two-dimensional illustrations that challenge their ability to conceive and communicate ideas visually."},
"ARTD332":{"description":"An introduction to computer illustration using both object-oriented drawing software and bit-mapped painting software. This course allows students to explore their creative ideas in depth utilizing the computer. Students explore the synthesis of traditional illustration skills with digital skills and techniques."},
"ARTD376":{"description":"This course investigates the design and production issues of generating computer graphics for print or electronic media. It provides an understanding of the technical process of creating projects incorporating scanned and computer-generated images and text. Students will gain experience integrating various computer applications and input/output hardware."},
"ARTD378":{"description":"This course covers the history of graphic design with an emphasis on developments in design during the 19th and 20th centuries. It also will include an examination of the social, political, and technological factors that have shaped graphic design."},
"ARTD381":{"description":"An introduction to the theory, tools, and techniques for creating interactive web pages. Introduces HTML coding as well as web page layout software. Focuses on designing the look, structuring the information, sequencing the pages, and integrating text and graphics as well as preparing and producing web pages."},
"ARTD382":{"description":"This course provides an introduction to the concepts, tools, and techniques for producing interactive multimedia. Topics covered include: interface design, flow charting, navigational structures, metaphor, props, visual layout, and the integration of audio, video, and animated sequences."},
"ARTD384":{"description":"Students explore the principles of animation with an emphasis in the study of motion.  Using state-of-the industry digital tools, students will learn about timing, sequencing, story, character, camera movements, gravity effects (stretch and squash), and audio synchronization to create animations.  A variety of techniques will be covered including, but not limited to cut-out, frame-by-frame, stop-motion, and rigging."},
"ARTD399":{"description":"An intensive study of specialized topics in graphic design, interactive media, and illustration."},
"ARTD431":{"description":"Introduction to illustrating for narrative, sequence, or series. Students learn the tools and techniques of concept sketching and storyboards as a means of developing and communicating visual ideas. Working with selected texts, students develop a project from concept to completion. Students also explore the historical role of the illustrator as a visual storyteller."},
"ARTD432":{"description":"Students refine their conceptual, aesthetic, and technical skills in a variety of advanced illustration assignments directed toward developing portfolio pieces and fine tuning their personal visual language. Assignments challenge the student to create a range of inventive and effective illustrative solutions in the appropriate media."},
"ARTD468":{"description":"In graphic design field, types of publications include books, magazines, catalogs, annual reports, and in fact, any multiple-page format. This course is a systematic study on publication design. It will help students advance their typographic skills; learn how to identify and organize content; develop creative ideas; design a functional grid; and create a visual system to organize text, images, graphics in a harmonic and logical visual sequence by using a strategic blend of illustration, photography and typography. Students will have the chance to explore publication design in paper books, catalogs, and e-books."},
"ARTD475":{"description":"This course will emphasize the creation of innovative visual layouts by having students analyze communication problems, define the most appropriate approaches, and produce creative and aesthetic solutions using the computer. Various media will be used to implement these solutions including printing, advertising, packaging, exhibitions, and electronic media."},
"ARTD476":{"description":"This course offers advanced study in computer graphics, allowing students to expand their computer knowledge and critically investigate the theoretical and practical issues of generating computer art. Students will propose, design, and produce individual and team projects suitable for their senior portfolios."},
"ARTD481":{"description":"This course covers advanced tools and techniques for designing interactive digital media for network systems. Students are instructed in network delivery methods for sound, video, animation, database, and interactive content. Scripting and vector animation are utilized to bring additional functionality to a network site. Using various software packages, the student will create fully functional sites with interactive multimedia content. Web site management, browser and platform issues, and the limitations involved in designing for networks are covered."},
"ARTD482":{"description":"This course covers advanced issues in designing and producing interactive multimedia. Students are exposed to the complexities of multimedia authoring and production. Students, at times working in teams, will collaborate on developing the concept, layouts, storyboards, production schedule, and the user-interface. They will create and compile the content and produce the digital files for a fully functional multimedia presentation."},
"ARTD494":{"description":"A capstone course for art majors completing the emphasis in graphic design, interactive media design, or illustration. This course combines the discussion of current aesthetic issues in the visual arts with practical knowledge in career or graduate school preparation, as well as evaluation of student's artistic growth and potential."},
"ARTE394":{"description":"Workshop in writing objective statements and lesson plans. Introduction to a variety of art materials and technology, teaching methods, and development of a final student teaching portfolio in art education."},
"ARTE395":{"description":"Part A: Content. The course will involve a basic understanding of historical and philosophical premises of art education; different approaches in the teaching of art; development and practical application of basic art skills in the classroom; and understanding of developmental stages in children's art works. The course examines basic thematic understanding of lesson plans as they apply to the state of Indiana academic and the discipline-based art education (DBAE) standards in teaching art in elementary and middle school. Part B: Supervised Internal Clinical. Use of written lesson plans from Part A in an approved clinical experience."},
"ARTE396":{"description":"Part A: Content. The course will emphasize historical and philosophical premises of art education; different approaches in the teaching of art; development of basic art skills; and understanding of developmental stages in children's art works. The course examines in detail thematic understanding of written lesson plans as they apply to the state of Indiana academic and the discipline-based art education (DBAE) standards in teaching art in elementary and middle schools. Part B: Supervised Internal Clinical. Use of written lesson plans from Part A in an approved clinical experience. You will be teaching on one Saturday for part B."},
"ARTE397":{"description":"Part A: Content. Teaching art at the public high school level with strong emphasis on the use of art materials and methods in art education. The course will involve historical and philosophical premises of art education, different approaches in the teaching of art, understanding of high school children's art works, and the development and use of discipline-based art education (DBAE) written lesson plans as they apply to the state of Indiana academic standards. Part B: Supervised Clinical. Use of written lesson plans from Part A in an approved clinical experience."},
"ARTE495":{"description":"Workshop/review of teaching portfolio, and discussion of trends that are related to teaching art education.  Student presentation of topical research based on assigned reading."},
"ARTH221":{"description":"A survey of the history of primarily Western art and architecture from the prehistoric through Medieval periods.  The course will focus on interpreting individual artworks from different chronological periods and regions, understanding how they are affected by their historical and cultural contexts, and how such primary source material may be used to interpret historical events and eras."},
"ARTH222":{"description":"A survey of the history of primarily Western art and architecture from the Renaissance to the Modern and Contemporary periods.  One focus of this course will be to view and interpret artworks as products of their historical and cultural contexts.  Another focus will be to examine the roots and development of the particularly modern aesthetic impulse of art as self-expression."},
"ARTH223":{"description":"Introduction to Non-Western Art surveys the visual art traditions of selected Non-Western cultures from ancient to contemporary times. \"Non-Western\" refers to cultures that initially developed outside the realm of Western European culture.  Artworks may be selected widely or in a more focused manner from Africa, Asia, the Pacific region, and cultures of the Americas (Indigenous/Native American).  Different types of visual arts will be used to analyze how politics, religion, economics, social organization, artistic influences, and international trade have interacted with the major themes in each culture's artistic traditions.  Differences between Western and non-Western theories of art, aesthetics, values, and use will be discussed and will form the background for understanding how different people react to art from cultures other than their own."},
"ARTH253":{"description":"The art of Mesoamerica and the development of civilization in southern Mexico, Guatemala, Belize, and Honduras; examination of the Olmec, Maya, Aztec, and other groups, as well as the period of conquest and current conditions among the native peoples of the region."},
"ARTH261":{"description":"This course focuses on the types of writing commonly used in the visual arts.  The specific art content of the course may vary by semester, but writing such as (but not limited to) visual analyses, critiques, reviews, and research papers will be covered.  There will also be an introduction to some of the critical approaches employed by art historians.  This course is intended for both art majors and non-majors who wish to deepen their awareness and understanding of the visual arts."},
"ARTH323":{"description":"A survey of major monuments and issues of art from the ancient Western tradition, focusing on archaic and classical Greek art from c. 1000 BCE to c. 50 BCE."},
"ARTH327":{"description":"A survey covering major art movements and monuments in Europe, dating from the 4th to the 14th centuries, but concentrating on the art and architecture of the 8th to the 13th centuries."},
"ARTH335":{"description":"This course surveys the art, architecture, and culture of India, China, Central Asia, and Japan. The approach will be primarily chronological within each of these four geographical units."},
"ARTH343":{"description":"A survey covering major movements, largely in Italy, in the period c. 1300-1600, including the Proto-Renaissance, Early Renaissance, High Renaissance, Mannerism, and Late Renaissance."},
"ARTH344":{"description":"A survey covering major movements in Europe and the Americas in the period 1600-1800, including the art and architecture of the Early Baroque, High Baroque, and Rococo."},
"ARTH353":{"description":"Covers the major movements in the period c. 1780-1880, emphasizing Romanticism, Neo Classicism, Realism, and Impressionism."},
"ARTH354":{"description":"The continuation of ARTH 353 covering the major movements in the period c. 1880-1980, including Post Impressionism, Cubism, Fauvism, Expressionism, DaDa, Surrealism, Abstract Expressionism, Pop, the Muralists, and non-objective art."},
"ARTH355":{"description":"The continuation of ARTH 354 covering the movements and artists in the period c. 1970-the present."},
"ARTH359":{"description":"Art historical studies offered in conjunction with special activities such as seminars, conferences, field work, or travel."},
"ARTH390":{"description":"Studies within the field of art history covering a particular period or movement.  May be used as an Art History Directed Elective.  Approved topics may also be used to fulfill the Non-Western and Contemporary requirements of the Art History emphasis."},
"ARTH490":{"description":"An intensive study of some selected problems, areas, and movements in the field of the visual arts."},
"ARTP211":{"description":"Students will explore black-and-white film photography as they investigate problems of design, content, and expression, with emphasis on fine art applications.  The course is taught in a chemical darkroom and includes film development, printing, mixing chemicals, and scanning negatives into the computer.  Students must provide a manual 35-mm film camera and photo supplies."},
"ARTP221":{"description":"Students will create digital photographs as they learn new methods of using digital cameras and professional editing software for creative purposes.  Students will apply design fundamentals and command basic color management processes as they learn how to apply digital photography skills to the creation of expressive images.  Students must provide a digital camera with some manual features."},
"ARTP311":{"description":"Continuation of ARTP 211, expanding upon basic skills to communicate creatively through visual composition and working in series. This class will also explore different format cameras, larger printing paper, fiber based printing, and advanced darkroom techniques."},
"ARTP321":{"description":"An intermediate level course designed to advance student knowledge incomputer imaging tools such as Adobe Photoshop or like programs. Students are required to produce original photographic imageryfor use in creating digital artwork. Advanced methods of input and output, color calibration between computer and printing,as well as advanced methods of image manipulation, are covered. Emphasis is placed on the aesthetics and ethics of digital photographicart and creating meaningful and effective images."},
"ARTP322":{"description":"An intermediate level course designed to introduce students to controlled lighting.Students receive technical instructionon strobe lighting, lighting techniques and contemporary theory of lighting. Students will work through both conceptual and technical projects."},
"ARTP324":{"description":"In this course students investigate methods of relating narrative content in contemporary photography. Projects span from classic photo-documentary to staged fictions and include the incorporation of text, book-making, collage, and other methods of presentation to effectively communicate the narrative."},
"ARTP331":{"description":"An introduction to the creative principles and processes of video art. Students explore the practical applications and limitations of video and sound production. Basic video editing and visual effects are explored. Elements of storytelling, storyboarding, and other pre-production methods are discussed and implemented."},
"ARTP421":{"description":"This course develops students' conceptual and theoretical processes. Students will focus on how photography is being used in contemporary art and deepen their research skills. Course work will be self-directed and students will be encouraged to take a multi-disciplinary approach to their studio practice."},
"ASTR201":{"description":"An introduction to the basic concepts in astronomy. Use of the telescope and exercises in uranography are stressed."},
"BCOM231":{"description":"A detailed study of business communication. Includes analysis and practice in writing a variety of messages used to communicate in business and industry. Emphasizes the psycho-semantic aspects of effective organizational communication."},
"BCOM331":{"description":"The communication demands placed on modern organizations and individuals are complex and varied in scope.  Students enrolled in BCOM331 will be asked to develop and implement strategies in media relations and crisis communication, compose and present formal proposals, lead organizational change initiatives and prepare for the transition from student to professional."},
"BCOM351":{"description":"The ability to negotiate and resolve conflicts has never been more important than in contemporary organizations, where the allocation of scarce resources is a constant challenge.  Students enrolled in BCOM 351 will be asked to integrate knowledge from fields such as management, marketing, technology, economics, accounting and finance to develop solutions to demands for resources from both the individual and organizational perspective."},
"BCOM401":{"description":"A lecture-discussion-practicum class emphasizing identification of goals and processes of professional development through career planning. Related job-market issues of concern to senior business students also are addressed."},
"BIOL105":{"description":"This course examines general concepts in the life sciences, stressing those fundamental to life processes and of human concern. This course does not count toward requirements for biology majors and minors."},
"BIOL108":{"description":"An integrated inquiry based study of the characteristics of organisms, diversity and evolutionary processes, life cycles, and interdependence of organisms on each other and the environment.  Enrollment will be limited to students majoring and minoring in education.  This course will not earn elective credit for majors in biology, biochemistry, biophysics or minors in biology, environmental biology, biochemistry, or biophysics."},
"BIOL111":{"description":"This is a lecture course that examines the biology of plants and how humans use plants."},
"BIOL112":{"description":"Examination of human uses of plants and the cultural/societal origins of usage. Plants for medicine and health, food, beverage, protection, aesthetics, and recreation will be discussed. Basic concepts in cell biology, genetics, plant taxonomy/ identification, plant anatomy, and plant physiology typical of Introductory Biology for non-majors courses will be covered. Through the lab, students will gain experience that will reinforce concepts from lecture. Students will work through the scientific method and use some of the basic tools used in the study of science. There will be opportunities for students to work through open-ended laboratory experiences. Does not apply toward a major or minor in biology."},
"BIOL114":{"description":"This course is a survey of evolutionary thought from its beginnings as an explanation for diversity to its acceptance and application by biologists. Contemporary controversies will be discussed in their scientific and sociological contexts."},
"BIOL121":{"description":"An introduction to biological and chemical principles as they apply to the human body. Lectures and laboratory work will cover cellular anatomy and physiology, tissues, and the following systems: integumentary, skeletal, muscular, nervous, and endocrine."},
"BIOL122":{"description":"A continuation of BIOL 121 consisting of lectures and laboratory work concerning the following systems: respiratory, cardiovascular, lymphatic, digestive, excretory, and reproductive."},
"BIOL133":{"description":"This lecture/laboratory course will cover biological concepts in the context of current issues in biology. Students will become more familiar with the scientific method as it applies to biology. Topics to be covered include: science and ethics; evolution and biodiversity; ecology; genetics and cellular biology. The lab will reinforce concepts from lecture. The course will not apply toward a major or minor in biology."},
"BIOL141":{"description":"An introductory survey of the fundamental characteristics and processes of living organisms, including cell structure and function, energetics, genetics, development, evolution, and ecology. Laboratories include both didactic and investigative explorations of these processes."},
"BIOL151":{"description":"Fundamentals of plant structure and function are explored in the context of plant diversity and evolution. Consideration is given to variation in plant morphological and physiological strategies for life in a different environment. A course for majors and minors in biology."},
"BIOL152":{"description":"A survey of the adaptations and taxonomic relationships of the major animal phyla with emphasis on evolutionary trends. Primarily for majors and minors in biology."},
"BIOL208":{"description":"The course will address basic principles of wildlife ecology and their application in the management of wildlife populations. Topics include population growth, censusing, food habits, predation, harvesting, disease, exotic and endangered species, captive animal management, and conservation. Laboratories will include indoor and outdoor exercises in sampling, habitat evaluation, censusing, and wildlife observation."},
"BIOL215":{"description":"Introductory survey of the nature and importance of the interactions between organisms and their abiotic and biotic environments. Topics include energy flow, population dynamics, species interactions, community structure and development, and the use of statistics in ecological studies."},
"BIOL221":{"description":"Study of insects, their structure, physiology, taxonomy, development, ecology, economic importance, and their relation to other arthropods."},
"BIOL225":{"description":"This course is an introduction to scientific communication in biology. Topics covered will include the structure of primary literature in biology and outlets for communication of new knowledge in biology, concepts of basic statistical description and inference relevant to biologists, and methods for using biological databases. Hypothesis testing and the hypothetico-deductive method also will be discussed from both the standpoints of statistics and the epistemological foundations of scientific knowledge."},
"BIOL233":{"description":"A study of the general biological aspects of the aging process with emphasis on homeostatic mechanisms and how age related changes relate to the development of disorder and disease at the molecular, cellular, systemic, and organismal levels of organization. Throughout the course special effort will be made to differentiate normal aging processes from age-related deterioration caused by disuse, misuse, and disease."},
"BIOL251":{"description":"This course is designed to introduce students to the basic scientific principles needed to understand the interdisciplinary and multinational (multicultural) nature of environmental issues and concerns. Through a series of lectures, discussions, and videos the student will be introduced to environmental issues from scientific viewpoints as well as political, economic, social, and philosophical realms. Does not apply toward a major or minor in biology."},
"BIOL272":{"description":"Structure, physiology, and general characteristics of medically important microorganisms. Included will be a study of host defense mechanisms, infections of human systems, control of infection, and development of microbiological techniques. Primarily for students in nursing, dental hygiene, and respiratory therapy."},
"BIOL282":{"description":"Introduction to the principles of human heredity, nature, and expression of genetic material. The medical and social implications of genetics are explored. Biology majors may not enroll."},
"BIOL285":{"description":"Using biological studies augmented with video clips, the diversity of animal behavior will be explored in the contexts of genetics, environment, ecology, and evolution. Throughout the course, the process of science will be emphasized as a way to understand animal behavior."},
"BIOL305":{"description":"Dimensions of the physical, chemical, and biological factors of lakes, streams, and estuaries; including the study of benthos and vertebrates with emphasis on identification, classification, anatomy, physiology, and ecology. Indiana species will be emphasized but other important species will be included. Laboratory will be devoted to exploring techniques for evaluation of various aquatic parameters and the identification of organisms."},
"BIOL306":{"description":"Introduction to the study of fishes including their evolution, ecology, and conservation. The course will place a special emphasis on the North American fresh water fauna."},
"BIOL308":{"description":"Lecture, laboratory, and field work on vertebrates; including identification, morphology, natural history, and evolution."},
"BIOL315":{"description":"This course provides an introduction to the natural history of birds, including their evolution, form and function, and behavior. A special emphasis is placed on birds that regularly occur in southwestern Indiana."},
"BIOL321":{"description":"Study of the natural history, classification, adaptation, life cycles, physiology, and anatomy of invertebrate animals."},
"BIOL333":{"description":"An in-depth systems approach will be used to study physiological processes in vertebrate animals with an emphasis on mammals. The course will focus heavily on cellular and molecular mechanisms. Laboratories will include the use of living tissues for the purpose of demonstrating concepts covered in the lecture portion of the course."},
"BIOL334":{"description":"This course examines the organization, functions, properties, and processes of eukaryotic cells, with selected comparisons to prokaryotic cells. Topics include the biochemistry and protein structure and function necessary for cell function; cellular energetics; membrane structure, function, and transport; cell signaling; cell structure; and cell cycle and apoptosis. Other topics may be discussed if schedule and time permits."},
"BIOL336":{"description":"The course probes the major questions of plant physiologic and biochemical function at the sub cellular, cellular, tissue, and whole-plant levels of organization. Attention also is paid to the role of plant physiological response to the biotic and abiotic environment. Lecture areas include photobiology, carbon balance, transport processes, mineral nutrition, and biochemical defense; laboratory investigations will combine classic demonstrations of plant physiological principles with modern and investigative studies."},
"BIOL342":{"description":"Lecture and laboratory studies of the functional morphology of chordates, with emphasis on the concept of homology."},
"BIOL361":{"description":"This course provides an introduction to the science of plant systematics, including phylogenetics, principles of botanical nomenclature, types of systemic data, mechanisms of evolution, and the resultant diversity of green plants.  An emphasis will be placed on developing skills to collect, preserve, and identify plant specimens.  Additionally, students will gain the ability to recognize common native and introduced species of the Indiana flora as well as characteristics associated with the major flowering plant families."},
"BIOL362":{"description":"This course is a study of human interactions with plants particularly those of economic importance. The cultural and global significance of specific plants used for food, beverage, medicine/health, and other things will be considered. How humans manipulate plants (breeding, propagation) and an overview of agriculture will be explored."},
"BIOL371":{"description":"An extensive course designed to acquaint the student with natural biological interactions in aquatic and terrestrial environments. The course consists of a period of field study in an area remote from the local campus. Orientation prior to and following course."},
"BIOL375":{"description":"The structure, physiology, identification, and significance of bacteria including an introduction to related organisms and immunology."},
"BIOL376":{"description":"Principles and laboratory techniques used in the isolation, cultivation, and identification of bacteria."},
"BIOL378":{"description":"A survey of the structural mechanisms of replication and pathogenic mechanisms of bacterial, plant, and animal viruses."},
"BIOL382":{"description":"The cellular and molecular basis of gene transmission, expression, interaction, mutation, mapping, and regulation. Includes laboratory investigations using molecular and classical techniques."},
"BIOL422":{"description":"An introduction to the concepts and applications of immunology. The course emphasizes the structure, function, regulation, and development of the immune system. Immunopathology is included."},
"BIOL432":{"description":"This course surveys many aspects of cancer biology including, but not limited to, tumor identification, molecular mechanisms of tumorigenesis and tumor progression, history of cancer research, and treatment. Relevant material will be discussed using presentations, discussion groups, writing assignments, case studies, problem solving work, and other methods at the instructor's leisure. This course ties together concepts from cell biology, genetics, and organic chemistry/biochemistry."},
"BIOL434":{"description":"Biological molecules, enzymology, intermediary metabolism, and regulation of biochemical processes from a functional context. The cellular and molecular basis of selected diseases and treatment are included."},
"BIOL436":{"description":"A project-oriented laboratory course focusing on the isolation, manipulation, analysis and expression of nucleic acids using molecular biology techniques. Techniques vary among projects and typically include DNA restriction, subcloning, pcr, primer design, blot analysis, and introductory bioinformatics. Other techniques are used when appropriate."},
"BIOL442":{"description":"This course is an introduction to the study of vertebrate tissues and cellular structures through the critical use of the light microscope."},
"BIOL444":{"description":"This course focuses on the natural history of the amphibians and reptiles: their evolution, classification, anatomy, physiology, ecology, and behavior.  Amphibians and reptiles of southwestern Indiana are a major focus of this class."},
"BIOL452":{"description":"We examine the major processes, causes, correlates, and consequences of anthropogenic global change from a biological perspective.  These include climate change and carbon cycle disruption, nitrogen cycle disruption, and habitat conversion as well as global species extinctions, movements, and introductions.  We also explore alterations of ecosystems that result and consider issues surrounding adaptation, mitigation of global change processes."},
"BIOL459":{"description":"This course will cover advanced topics in ecology as determined by the interest of the instructor. Potential topics include behavioral ecology, microbial ecology, physiological ecology, and population ecology. Laboratories will provide opportunities to collect and analyze data germane to lecture topics."},
"BIOL460":{"description":"A supervised learning and work experience in biology which enhances the student's understanding of and appreciation for the application of biological knowledge and skills.  Students can earn from 1-4 credits."},
"BIOL461":{"description":"This is an introduction to the study of morphogenesis and differentiation of tissues. Lecture and laboratory sessions will include an integration of classical, molecular, and evolutionary perspectives in the development of vertebrate embryos."},
"BIOL465":{"description":"This course examines plant growth and development. Factors that affect development of the plant will be studied. The role of growth regulators, the environment, and genetics in plant growth regulation will be emphasized. The laboratory will have a strong investigative component."},
"BIOL470":{"description":"Readings and/or literature surveys covering selected biological topics not part of the established departmental curriculum."},
"BIOL476":{"description":"Bacterial Pathogenesis is designed to introduce the Biology major to the diverse ways bacterial pathogens cause disease at the cellular and organismal levels, with an emphasis on bacterial pathogens.  This lecture course will lay a strong foundation for the understanding of host-parasite interactions, mechanisms of pathogenicity, and a general knowledge of infectious disease.  Primary literature that addresses the newest developments of bacterial pathogenicity will be integrated into the required reading.  Students will be required, at the end of the course, to give a presentation on an assigned pathogen which will focus on the topics covered in the class."},
"BIOL481":{"description":"A discussion of the science of evolution and how evolutionary theory can explain the diversity of life on earth. Topics include the history of evolutionary thought, adaptation, population genetics, speciation, patterns of macroevolution, and human evolution."},
"BIOL482":{"description":"Advanced topics in gene structure, expression, regulation, maintenance, and modification. Examples selected from eukaryotic, prokaryotic, and viral systems. Topics include the mapping, analysis, and manipulation of genes; the analysis and expression of genomes and proteomes; and molecular genetic applications in forensics, agriculture, industry, and medicine."},
"BIOL490":{"description":"A course designed to give practical experience to biology majors in teaching a laboratory class. Duties involve preparation and set-up prior to class and answering student questions during the laboratory session. Design and administration of laboratory tests as well as grading homework will be a component of preparation. Students will be expected to attend staff meetings as appropriate for the course assignment. Hours do not apply to Core 39, the University Core Curriculum or the biology major."},
"BIOL491":{"description":"A course for biology majors in which special topics are considered in an individual or seminar format."},
"BIOL495":{"description":"A seminar course in selected topics of current biological interest."},
"BIOL499":{"description":"Course consists of individual undergraduate research in an area of common interest to student and instructor."},
"BLAW263":{"description":"An introduction to the legal system within which business organizations operate, as well as ethical considerations and social and political influences that affect such organizations by changing the legal system."},
"BLAW363":{"description":"This course provides an overview of the legal issues involved in starting and running a business. Students become limited owners of an actual on-going business, which they operate. Topics covered include, but are not limited to, legal matters in a commercial setting, as well as continued preparation for legal subjects covered in the CPA/CMA exams."},
"BUAD499":{"description":"A cooperative work-study program designed to: (1) provide undergraduate business students realistic work experience to improve the depth of understanding of the nature of American and international business; (2) develop student maturity and confidence to determine in which areas of business  they should seek their professional careers; and, (3) create a work situation where advanced business courses are made more meaningful as a result of the perspective that comes from such a professional experience."},
"CE221":{"description":"Care and use of tapes, level, transit, electronic distance measuring equipment and data collector; differential leveling, traversing, closure and area computations, reduction and plotting of field notes of topographic surveys."},
"CE301":{"description":"A study of the characteristics, capabilities, and operating costs of equipment and estimation of construction costs. This work will include quantity takeoff, pricing of materials, classifcation of work, labor, overhead, specifications, and bid procedures."},
"CE323":{"description":"Introduction to transportation and the planning of transportation systems. Highway and airfield design criteria. Operational characteristics of transportation systems."},
"CE324":{"description":"Introduction to specification and testing of aggregates, concrete, steel, asphalt, wood and masonry for civil engineering applications. Issues of durability and sustainability of each material will be discussed."},
"CE361":{"description":"This course engages students in applying the fundamentals of engineering science to analyze force distributions in structural applications. Specifically, students will be able to calculate the maximum loads effecting a structure, analyze the distribution of forces in simple structures by hand, analyze the distribution of forces in complex structures with software, and verify software results by simple hand calculations."},
"CE381":{"description":"Physical and index properties of soil, soil classification, soil-water interaction, stresses, settlement, and shear strength will be studied. Laboratory experiments will include Atterberg Limits, grain size analysis, shear strength, consolidation, and Proctor tests."},
"CE441":{"description":"Study of environmental systems, water/air/soil resources, environmental legislation, and design of pollution control equipment. Topics include legislation review, water chemistry, water treatment, wastewater treatment, air pollution abatement, solid waste management, and hazardous waste treatment."},
"CE451":{"description":"Study of the hydrologic cycle. Topics include precipitation analysis, infiltration analysis, groundwater flow, unit hydrograph development, hydrologic probabilities, and flow measurement. This course will consider both design of water use and water excess management facilities."},
"CE461":{"description":"Design of reinforced concrete structures for bending, shear and diagonal tension, axial load and bending, development lengths and splices, including retaining walls and reinforced concrete foundations."},
"CE462":{"description":"Design of steel structures for tension members, beams and columns, bolted and welded connections, including plate girders using the latest LRFD specifications."},
"CE471":{"description":"A course immediately preceding Senior Design in which students learn to do systems engineering effectively, communicate effectively and do team work effectively.  Course includes review of professional ethics, preparing for professional licensure, and preparation of Senior Design proposal."},
"CE481":{"description":"Topics that will be studied include bearing capacity, isolated and combined footing design, lateral earth pressure, retaining wall design, pile and pier design, slope stability, and design of foundations for seismicity. Professional and ethical responsibility and legal ramifications of design problems also will be considered."},
"CHEM103":{"description":"This introductory chemistry class is designed to give knowledge and understanding of selected important facts and principles of chemistry. Topics such as nuclear energy, radiation, global warming, and ozone depletion are investigated."},
"CHEM107":{"description":"An introductory chemistry course that uses selected important facts and principles to explain interesting phenomena such as global warming, ozone depletion, nuclear energy, acid rain, etc. A laboratory experience enhances the understanding of chemistry."},
"CHEM108":{"description":"This course provides an inquiry based approach to learning foundational concepts of chemistry with coverage of chemistry's influence on society where appropriate.  Topics will include scientific method, nature of matter, atomic structure, bonding theories, chemical reactions and thermochemistry.  Issues related to laboratory safety including proper handling, storage, and disposal of chemicals are addressed. This course is intended for teacher candidates only."},
"CHEM141":{"description":"Selected principles and applications of inorganic, physical, organic, and biochemistry with laboratory experiments illustrating the principles covered.  Only 4 credits from CHEM 141, CHEM 142, or CHEM 143 may count toward Core 39."},
"CHEM142":{"description":"Selected principles and applications of inorganic, analytical, and physical chemistry with laboratory experiments illustrating the material covered."},
"CHEM143":{"description":"Selected principles and applications of organic chemistry, and biochemistry with laboratory experiments illustrating the material covered."},
"CHEM175":{"description":"A broad introduction to chemical concepts selected to enhance the background of beginning science majors."},
"CHEM218":{"description":"This course provides an introduction to chemical literature, scientific writing, and science ethics. Students will conduct literature searches and prepare outlines, summaries, and reports and give a poster presentation on various topics."},
"CHEM241":{"description":"The major emphasis is on introductory principles of organic and biochemistry."},
"CHEM261":{"description":"A systematic study of the essential nomenclature, hypotheses, theories, and laws of chemistry necessary for chemistry majors and minors. Some of the topics presented in the course include stoichiometry, atomic structure, thermochemistry, solutions, crystal structure, and gas laws."},
"CHEM262":{"description":"A continuation of CHEM 261 with some laboratory work in qualitative analysis. Some of the topics presented include kinetics, equilibrium, ionic equilibrium, thermodynamics, electrochemistry, and nuclear and organic chemistry."},
"CHEM318":{"description":"Students will use online databases such as Dialog, Scifinder, and STN to research chemistry literature and patents in order to prepare scientific manuscripts and present a seminar on a chemistry literature paper. Students will also participate in mock interviews and learn about graduate school and career options."},
"CHEM321":{"description":"The principles and techniques of gravimetric, volumetric, spectrophotometric, electrochemical analysis, and chromatography are developed in lecture and laboratory."},
"CHEM341":{"description":"A combination field, lecture, demonstration, and laboratory course to study the collection, analysis, and effects of chemicals in the environment."},
"CHEM353":{"description":"A development of the fundamentals of aliphatic and aromatic organic chemistry with special emphasis on structure and mechanism. Structure, nomenclature, and physical properties of all organic functional groups are included."},
"CHEM354":{"description":"A continuation of CHEM 353 including alicyclic and aromatic compounds."},
"CHEM361":{"description":"Coverage includes selected topics from applied chemical thermodynamics, kinetics, statistical mechanics and quantum mechanics. Required expressions are developed from fundamental principles utilizing calculus. A laboratory experience providing exercises in thermodynamics, kinetics, and spectroscopy is included. Biological applications are explored in lecture and laboratory when appropriate."},
"CHEM418":{"description":"Using skills gained from 218 and 318, students will write a comprehensive literature review article on an approved chemistry topic and provide an oral presentation to the class."},
"CHEM421":{"description":"This course introduces students to the instruments and techniques used in modern chemical analysis. The course will emphasize spectrophotometry, electrochemical analysis, chromatography, and mass spectrometry, and will provide an introduction to the electronics of modern chemical instrumentation. Laboratory work provides students with experience using techniques described in this course."},
"CHEM431":{"description":"Biochemistry of amino acids, proteins, nucleic acids, lipids, carbohydrates, and the actions of enzymes. Encompasses chemical properties and techniques involved in the study of these macromolecules and their monomeric units."},
"CHEM432":{"description":"Continuation of 431 including major metabolic pathways with emphasis on energy considerations and interrelationships of the pathways, inorganic metabolism, acid-base balance, and hormones."},
"CHEM441":{"description":"Introduction to the chemistry of all the elements, developed from the principles governing atomic structure and bonding, with special emphasis on transition metal and organometallic chemistry."},
"CHEM451":{"description":"Polymer chemistry studies the chemical synthesis and chemical and physical properties of polymers. It is one of the most relevant of the sub-disciplines of chemistry with a large number employment opportunities. This course is four credit hours with a three hour laboratory, and is designed to familiarize students with the basic structure, classification, synthetic techniques, physical properties and applications of polymer compounds and to show the interrelation of basic polymer concepts with their everyday use. Students will gain current knowledge of polymer concepts and an ability to apply them in career situations."},
"CHEM453":{"description":"This course will examine advanced organic chemistry topics related to drug synthesis and will also study aspects of medicinal chemistry."},
"CHEM461":{"description":"Introduction to kinetic molecular theory, chemical thermodynamics, and kinetics. Students will explore the development of these theories from fundamental principles using multivariable calculus. Selected results from statistical mechanics also will be included. Laboratory work is designed to engage students in practical application of these theories and to expand their understanding of formal scientific communication."},
"CHEM462":{"description":"Introduction to the theory of quantum mechanics including its applications to spectroscopy. As a continuation of 461, this course also will contain selected topics in thermodynamics and statistical mechanics. Students will explore the development of quantum mechanics from fundamental postulates using multivariable calculus. Laboratory work is designed to engage students in the practical application of theoretical principles and to expand their understanding of formal scientific communication."},
"CHEM490":{"description":"This course is designed to provide students with practical exposure to and experience with the college teaching profession. Students will work closely with individual faculty to learn and experience firsthand the range of duties performed by college professors in chemistry. Students will assist faculty in activities such as laboratory instruction, exam preparation, grading, and development of course materials and exercises. This course is intended for academically talented chemistry majors who aspire to careers in the professorate or high school teaching. Tuition waiver provided."},
"CHEM499":{"description":"Original problems in experimental and theoretical chemistry."},
"CHIN101":{"description":"Fundamentals of Chinese; no previous Chinese required. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Chinese-speaking countries. In addition to the regular class meetings, students are required to complete one hour per week in language lab."},
"CHIN102":{"description":"Fundamentals of Chinese; continuation of CHIN 101. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Chinese-speaking countries. In addition to the regular class meeting, students are required to complete one hour per week in the language lab."},
"CHIN203":{"description":"Continuation of CHIN 102. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Chinese-speaking countries. In addition to the regular class meeting, students are required to complete one hour per week in language lab."},
"CHIN204":{"description":"Continuation of CHIN 203. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Chinese-speaking countries. In addition to the regular class meeting, students are required to complete one hour per week in language lab."},
"CHIN499":{"description":"Research or advanced study of a specific area of Chinese literature, culture, education, or language, culminating in an analytical paper or comprehensive exam."},
"CIS111":{"description":"An introduction and overview of the important role computer information systems play in modern businesses and organizations. In addition, the many aspects of computer information systems such as history, hardware, operating systems, applications software, networks, systems integration, programming logic, and digital thinking are discussed. This class will take advantage of laboratory assignments that will give students \"hands-on\" experience and thus allow them to become comfortable with the many topics discussed."},
"CIS141":{"description":"Introduction to Windows and Microsoft Word, Excel, and PowerPoint skills for college survival."},
"CIS151":{"description":"Survey of business computer applications emphasizing microcomputers and applications software packages to business. Included are applications with word processing, spreadsheets, presentation programs, file and database management systems, and other software packages used in business."},
"CIS201":{"description":"An introduction to Computer information systems and their applications in the fulfillment of business needs. Fundamental concepts of information are explained and integrated into organizational structures. Relevant technologies are introduced and basic skills on their use are developed. Techniques for information analysis and the creation of business intelligence are introduced and practiced.  Inforamtion as an organizational resource is studied and practically applied."},
"CIS261":{"description":"A continuation of the development of microcomputer applications and problem-solving skills. Provides a basic review of the Windows interface and file management features; explores advanced spreadsheet and database concepts; and provides an introduction to presentation software, the Internet, and integration across word processing, spreadsheet, and database applications."},
"CIS276":{"description":"This course provides an introduction to the socio-behavioral impact of the Internet and the www, and shows how this drives development of web site solutions for effective business use.  This is accomplished through a multifaceted approach consisting of theory in graphics, technical writing, and information technology solutions."},
"CIS301":{"description":"The broad objectives of this course are to give a foundation in core concepts related to ERP and its core components, critique the implications of ERP for organizational functioning, evaluate the issues related to ERP implementation, and demonstrate teamwork and decision-making skills necessary when integrating business process through the use of information technology."},
"CIS305":{"description":"An introduction to management information systems. Fundamental concepts of information are explained and integrated into organizational structures. Basic system modeling tools and techniques are introduced. The importance of the computer and information are emphasized. The use of information as an organizational resource is studied. The strategic use of information technology in an organization to gain a competitive advantage will be emphasized throughout the course."},
"CIS333":{"description":"This experiential, hands-on course will develop a vocabulary, framework, and tangible software skills for discussing, critiquing, and designing visual displays of quantitative data (tables, charts, graphs, dashboards). This entails a basic understanding of the implications of data, human perception &amp; cognition, and the use of best practices in the visualization of quantitative data, dashboard design, and data exploration &amp; storytelling."},
"CIS335":{"description":"The Seminar in Information Systems enables faculty to offer students in the CIS Management track the opportunity to study contemporary issues in management information systems not covered in depth in other departmental courses.  Specific topic, method of presentation and student requirements will be designated by the seminar leader."},
"CIS344":{"description":"This hands-on course will introduce students to how to deploy, use and interpret automated Machine Learning (ML) to help them solve business and organizational problems and present solutions. Using ML Life Cycle process framework students will be exposed to how to define project objectives, acquire &amp; explore data, model data, interpret and communicate, and implement &amp; maintain the model. Students will reinforce the knowledge of the framework by applying it to real business and personal domains using world's most advanced enterprise automated ML platforms that do not require any programming skills nor deep statistical knowledge."},
"CIS345":{"description":"An intermediate level course providing in-depth coverage of the motivations, architectures, and implementations of security-related policies, technologies, and practices to better protect information systems against possible threat."},
"CIS346":{"description":"An advanced cyber security course, which addresses skills needed to assess an organization's security profile and prescribe solutions for a strong network defense.  Students will study concepts, tools, and techniques used to audit an organization for cyber security threats.  Students will also study responses to these threats through concepts, tools, and techniques associated with defending a data communications network."},
"CIS349":{"description":"An advanced cyber security course covering specialized or emerging topics in cybersecurity.  This course may be repeated for credit given the course topic is not a duplicate."},
"CIS361":{"description":"An introduction to Visual Basic for Applications (VBA), which is the foundation for exploiting the full potential of applications such as Microsoft Word, Excel, and Access. Course topics will include techniques to automate repetitive tasks, principals of design that provide criteria for creating interfaces that effect correct results as well as stimulate user interest, interactive objects, and structured programming techniques."},
"CIS366":{"description":"This hands-on course teaches cutting-edge tools and approaches to data management and preparation for effective decision-making through business intelligence and analytics. The class creates data connoisseurs through hands-on exposure to data extraction, transformation, summarization, integration, reduction &amp; splitting, and loading for effective exploratory and predictive analytics. Students are introduced to common BI, machine learning and text mining techniques and software. This course does not require any programming skills nor deep statistical knowledge but is rather focused on the integration and application of world-class enterprise BI&amp;A tools."},
"CIS367":{"description":"This course is designed to give the student a thorough understanding of the existing use of data communication networks as well as future developments in the area of telecommunications. The course topics will include the basic hardware needed for a functioning network, basic technical concepts of data communications, the various types of network configurations, and circuits. Also needed will be network design techniques, protocols, software, network architecture, local area networks, network management, and security and control issues related to networks."},
"CIS375":{"description":"This course is an in-depth study of the business information system development life cycle, emphasizing the investigation, systems analysis, and logical design phases. Analysis and design techniques are presented which are used to develop computer-based systems. Analysis and design of a variety of business information systems are covered and CASE technology is introduced."},
"CIS376":{"description":"An intermediate course in the web development  curriculum focusing on specific technical skills.  Concentration for this course will be on HTML, XHTML, CSS, and Javascript though supplementary topics on client side web page design will be covered."},
"CIS377":{"description":"Introduction to database theory. Discussion of data structures, indexed and direct file organizations, models of data including hierarchical, network, and relational. Discussion of data analysis, design, implementation, and database administrator functions. Application of database, data definition languages, data manipulation languages, and/or data manipulation through a host language."},
"CIS385":{"description":"The broad objectives of this courses are to give a foundation in core concepts related to ERP and its core components, critique the implications of ERP for organizational functioning, evaluate the issues related to ERP implementation, and demonstrate teamwork and decision-making skills."},
"CIS454":{"description":"An introduction to the financial, technical, and strategic information systems planning process. Emphasis is on the relationship of the information systems planning process to overall business goals, policies, plans, management style, and industry conditions. The selection of large system projects, assessment of a currently installed system, determining approaches to staffing, software, hardware, processing, and financing an information system are studied."},
"CIS476":{"description":"This course is an advanced study of enterprise web development focused on server side programming concepts and applications required to support the typical business environment in the Internet age.  Information needs of the typical business will be examined, along with ways of providing this information through the use of Internet applications.  Selected web development tools will be used to illustrate the necessary programming techniques, database connectivity, and utilization required to provide the desired results.  Lab assignments will be used to master programming language, database systems, and problem solving skills."},
"CIS477":{"description":"Application of computer programming and system development concepts, principles, and practices to a comprehensive system development project. A team approach is used to analyze, design, document, and implement realistic systems of moderate complexity. Use of project management methods, project scheduling and control techniques, formal presentations, and group dynamics in the solution of informal systems problems."},
"CIS487":{"description":"Analysis and study of special topics in computer information systems. Focus is on new developments in information management, hardware and software, ethical issues, and other selected topics."},
"CMST101":{"description":"Principles and practices of oral communications with selected experiences in their use."},
"CMST107":{"description":"Increases understanding of oneself, the many roles one plays in the communication process, and the interpersonal relationships of individuals in society. Course includes nonverbal communication, the role of language in structuring interpersonal situations, and the importance of dialogue in resolving interpersonal conflicts."},
"CMST201":{"description":"This course is designed to introduce students to the academic discipline of Communication Studies. For decades, Communication Studies has been mistaken as a discipline that focuses solely on presentational speaking, speechmaking, and public address. However, these elements within CMST represent only a small fraction of the discipline. This course will be an exploration of all the major areas of the field, the history of CMST as a discipline, the research and writing style associated with CMST, the practical and theoretical tools expected of CMST graduates, and the many careers CMST graduates can explore."},
"CMST203":{"description":"This course will serve two purposes. First, operating on the theory that literature is best understood and appreciated when performed, this course is designed to give students experience performing and analyzing literature. In addition, this course will introduce the many ways we experience performance in our everyday lives."},
"CMST204":{"description":"A study of the theory and principles involved in oral communication situations in the business and professional context. Students will present speeches, participate in interviews, and learn to work in teams. Special attention will be given to the use of computer-mediated communication in each of these units."},
"CMST214":{"description":"Introduces students to the basics of group and team communication. Students learn about the dynamics of group interaction with emphasis on leadership, decision making, and group preparation. Students will be exposed to a variety of group types, including standing committees, ad hoc groups, task forces, special interest groups, and administrative groups."},
"CMST286":{"description":"This course is designed to introduce a variety of concepts, theories, and applications of visual rhetoric. Advances in communication technology have resulted in new and more accessible means for creating and distributing images. This course will explore the role of visual images in U.S. culture, specifically the ways in which images function persuasively. By the end of the course, students are expected to gain a better understanding of how images and audience communicatively co-construct meanings."},
"CMST301":{"description":"The purpose of this course is to familiarize students with basic techniques of planning, conducting, and reporting qualitative human communication research. Focusing on communication criticism as well as ethnographic study, the course will introduce students to methods and theories that can enhance the understanding of most types of communication, including public address, media events, popular culture, organizational interaction, family conversation, cultural artifacts, or social interaction."},
"CMST302":{"description":"This course combines cognitive and experimental approaches to gender and culture as they relate to communication. Students will obtain a critical understanding of gender construction and behavior, as well as learn about gendered conflict, identity, relationships, and differences."},
"CMST303":{"description":"This course focuses on our dual roles as producers and consumers of persuasive messages. Students will examine persuasion in a variety of texts and contexts, as well as examine persuasive elements in human interaction and behavior. In addition to learning about the roles logic, credibility, emotion, and cultural tradition play in persuasion, students will learn strategies for constructing effective and ethical persuasive messages."},
"CMST308":{"description":"Students will produce and deliver effective rhetorical messages, actively applying both traditional and more modern rhetorical strategies. Students will begin by studying traditional (e.g., primarily male and Western) notions of rhetoric, argument, and evidence. The focus will then shift to more recent notions of what \"rhetoric\" can be, particularly by studying rhetorical strategies."},
"CMST313":{"description":"Advanced topics in individual performance as a method of textual study, theory, and practice in individual performance as an aesthetic event and rhetorical and social act."},
"CMST314":{"description":"A review of the research and theoretical approaches to organizational communication. This course includes an examination of the process of selecting appropriate communication channels, a consideration of the ways in which meaning, both verbal and nonverbal, is conveyed, and an exploration of ways in which communication can help to alleviate typical interpersonal problems within organizations."},
"CMST315":{"description":"This course will focus upon a variety of forms of Readers Theatre. Experiences will be provided in adapting selections for Readers Theatre and creating original scripts. Students will study techniques of both directing and performing Readers Theatre productions."},
"CMST317":{"description":"This exploration of cross-cultural communication surveys intercultural theories and problems in communication. It offers a general orientation to intercultural communication, theorizes intercultural transactions, and provides insight into cultural differences."},
"CMST318":{"description":"This course provides an overview of conflict-centered communication issues.  It examines the dynamics underlying conflict situations, explores communication variables embedded in conflict processes, and shows students how to apply conflict communication concepts to numerous settings."},
"CMST321":{"description":"This is an advanced course in communication and relational behavior within the modern organization. The goal of this course is to introduce students to contemporary interpersonal communication theory that can be applied to solve interpersonal problems within the workplace. In order to achieve this goal, the bulk of this course will be focused on the many forms and (dys)function of relationships within the workplace."},
"CMST323":{"description":"This course focuses on the communication theories, skills and strategies needed to manage and lead others. It will cover topics such as understanding the managerial communication process, communicating with managers and peers using verbal and nonverbal communication, managing conflict, disagreement, and groupthink, and identifying one's own interpersonal communication predispositions to enhance their work performance as employees as well as their management/leadership style."},
"CMST327":{"description":"In this course, students will be introduced to the many ways that we, as human beings, communicate about our health. This seminar has been designed to integrate numerous theoretical and methodological perspectives on health communication in order to give students a broader scope of how health messages and health communication processes are enacted, mediated, conceptualized, and studied. In this course, students will read and analyze how we communicate health messages interpersonally, organizationally, rhetorically, and through the mass media. Furthermore, through studying diverse health communication processes, students should become more aware of how these processes influence and impact each other (e.g., television messages about smoking impacts how people discuss tobacco use interpersonally)."},
"CMST331":{"description":"This course will focus upon analyzing significant examples of American public address.  An essential element of the course will be identifying the speaker's rhetorical goals, presentational style, key persuasive strategies, demonstrating understanding of the overall influence of the messages upon their intended audiences."},
"CMST386":{"description":"This is a course intended to introduce students to the history and theory of American film, but more specifically we will examine the reciprocal relationship between American film and American culture.  The course begins with an overview of film history and the major developments in the film industry.  With such an overview the course moves to understand the theoretical foundations that situate American film not just as a popular form of entertainment, but also as an economic, artistic, industrial, and cultural institution."},
"CMST400":{"description":"This course is designed to provide an opportunity for upper division communications majors and minors to research subject areas in the discipline. A maximum of six hours may be taken; only three may be taken in any one semester. Students who wish to take independent study courses in the Communication Studies curriculum should be aware of the following points: 1) Only six hours of independent study may apply toward a major in communications; 2) No more than three hours of CMST 400 may be directed by the same instructor; 3) Students wishing to enroll in independent study courses must receive written permission from their instructors prior to registration."},
"CMST410":{"description":"The special topics course will offer students the opportunity to examine more closely specialized topics in interpersonal communication. Topics may include areas such as family communication, health communication, relational maintenance, or dialogic communication. Students may repeat this course provided the topic is different. Only six hours may apply toward the communication studies major."},
"CMST414":{"description":"This course examines the dynamics of orality, literacy, and tele-literacy to better understand the role oral literacy has played in personal and social expression. Students will examine topics including verbal performance, oral poetry, oral history, folk tales, folk songs, and urban legends."},
"CMST420":{"description":"This course will offer the students the opportunity to examine more closely specialized issues in organizational research, theory, and application. Topics will vary each semester and may include areas such as values and leadership, organizational advocacy, communication training and development, or organizational culture."},
"CMST427":{"description":"This course is designed to enable students to think critically about the communication processes that comprise and define families. In this course, students will be exposed to the numerous methodological and theoretical perspectives taken by contemporary family communication scholars. Moreover, in this course students will learn how to distinguish family communication research from other kinds of scholarship (i.e., family sociology or interpersonal communication) and how to better apply family communication theories and research to their own lives."},
"CMST430":{"description":"This special topics course will offer students the opportunity to examine more closely specialized topics related to the psychological, sociological, or cultural dimensions of persuasion and social influence."},
"CMST433":{"description":"Study of the philosophical theories and models utilized in the human sciences to analyze, describe and interpret human communication as a paradigm of expression and perception.  Emphasis on the nature of persons, consciousness and social exchange as discussed in classical rhetoric, the British analytic school and continental thought, including such topics as structuralism, conceptual analysis, phenomenology, semiotics and hermeneutics."},
"CMST440":{"description":"The purpose of this course is to provide students with a deeper understanding of performance as aesthetic communication, embodied inquiry, site of cultural reproduction, and/or creative mode of expression. Through course readings and assignments, students create and analyze works of performance as guided by the specific topic of the class.  Topics may include performance of literature, environmental performance, performance art, community-based theatre, autoethnographic performance, tourism and cultural performance, and media and performance."},
"CMST489":{"description":"Offers students job experience in the discipline through a minimum of 50 hours of supervised professional work per credit hour."},
"CMST499":{"description":"Topics will vary. Purpose of course is to cover topics that are not specific courses in the curriculum."},
"COMM122":{"description":"This course examines how the public accesses, analyzes, evaluates, and uses media and media messages. It explores, in a global context, how the media works and how it influences a person's beliefs and behaviors. Emphasizes the use of critical thinking skills to identify reliable information."},
"COMM190":{"description":"An introduction to basic computer usage and programs designed around common computer tasks and programs, such as document/page design, photograph manipulation, and graphic design."},
"COMM192":{"description":"Presentation, lectures, and assignments designed to introduce students to the basic concepts of mass communication; to foster an understanding of the roles and functions of communication media in our society; to present concepts and principles of journalism (oral and written); and to prepare students to be intelligent users of media."},
"COMM221":{"description":"Students in this course will explore a diverse range of social media platforms and become familiar with basic social media research and analytics. This course introduces students to social media theory, research, and practice."},
"COMM234":{"description":"This course will inform students about tactics used by organizations to achieve strategic goals.  Students will practice conducting research to select the best strategies for the target audience and organization.  Students will also practice social media monitoring and listening to identify patterns and trends.  Students will learn how to apply these strategies to create, write, and post engaging content to effectively deliver messages on social media platforms.  Students will also be able to identify the legal and ethical foundamentals of writing and implementing strategies in the online environment."},
"COMM299":{"description":"Topics will vary. Purpose of course is to cover topics that are not specific courses in the curriculum."},
"COMM345":{"description":"This course examines the development of social media content creation and the use of current production techniques across social media platforms.  It combines practical, hands-on applications with a broader understanding of social media.  Students will use social media content creation tools and gain an in-depth understanding of the content creation process."},
"COMM445":{"description":"This course will inform students about tactics used by organizations to achieve strategic goals.  Students will practice conducting research to select the best strategies for the target audience and organization.  Students will also practice social media monitoring and listening to identify patterns and trends.  Students will learn how to apply these strategies to create, write, and post engaging content to effectively deliver messages on social media platforms.  Students will also be able to identify the legal and ethical foundamentals of writing and implementing strategies in the online environment."},
"COMM461":{"description":"Social Media Management (SMM) is the use of social media to increase brand awareness, identify key audiences, generate leads and build meaningful relationships with customers.  Social Media Management will focus on the skills and challenges of managing a social media for an organization.  Social media allows companies and organizations to gain a competivitive advantage through the creation and distribution of valuable, relevant and consistent content to attract and retain clearly defined audiences."},
"COMM477":{"description":"This course examines the history and future of international communications and issues related to mass media in a global community, including ethnic and cultural diversity, media systems, the role of media, freedom of the press, parity between distribution of news and shaping the public mind, international stereotyping, and international propaganda."},
"COMM486":{"description":"This course presents issues of diversity in mass media including implications of media stereotyping and other media effects related to portrayals of diversity.  The course also examines diversity representation in positions of management and leadership in media outlets that influence editorial decision-making."},
"COMM487":{"description":"Capstone course for Professional Certification in Crisis Resolution students. Working under the direction of the Scripps Howard Center for Media Studies, students research, design and fulfill a learning contract to pursue their particular interests within the certification field. The contract may include formal training outside the program, independent research or study, and/or experiential learning. SERVICE LEARNING course."},
"COMM494":{"description":"A study of the special laws of libel, First Amendment problems, free press vs. fair trial, copyright, the Internet, ethical issues, and regulatory provisions that pertain to mass media."},
"COMM495":{"description":"Theories and applications of research methods for the mass media including survey design, basic statistical procedures, sampling, and the reporting of data."},
"COMM497":{"description":"This course is designed to prepare the student for transition from college to the job market by examining career options, job search preparation, ethics in professional practice, and leadership skills and development related to decision making."},
"CRIM200":{"description":"This course allows for the study of selected topics of current interest and importance in criminal justice. These topics will vary in accordance with the interests of students and faculty. Students may repeat the course without limit, as the topic changes."},
"CRIM224":{"description":"An introduction to criminology, the scientific study of the causes, control, and consequenceof crime."},
"CRIM234":{"description":"This course will examine the historical, philosophical, and theoretical foundations of the correction systems and organizations. Topics include but are not limited to sentencing options, treatment of prisoners, prisoner subcultures, prison, life, rehabilitative programming for prisoners, prisoner healthcare, prisoners' rights, community-based corrections, prisoner release and reentry, and the nature of working in and managing prisons."},
"CRIM244":{"description":"This course explores how the various law enforcement agencies interact with society. The main topics include the history of law enforcement in the U.S. and other countries, and current issues and controversies in policing."},
"CRIM245":{"description":"This course is an exploration of professionalism and decision making in the criminal justice system through the lens of ethics, professional codes of conduct, and leadership in organizations."},
"CRIM254":{"description":"Students will gain an understanding of how various courts are structured and how these courts operate in the United States. The role, function, and discretion of legal actors will be explored in detail. Students will also be presented with various issues pertaining to processing and sentencing accused offenders within these various court environments."},
"CRIM301":{"description":"Analysis of the public institutions that determine policy and practice in the administration of the criminal justice system. This will include the legislature, the police, the courts, and correctional systems."},
"CRIM302":{"description":"History and development of criminal law as a system of social control; the relationship among criminal laws; and the workings of courts and correctional systems."},
"CRIM304":{"description":"Critical consideration of the criminal justice system's capacity to deal with crime, and its relationship to the citizenry. Emphasis on programs to provide effective community relations."},
"CRIM305":{"description":"A comparative study of criminal justice systems throughout the world, focusing on the effects of historical events, globalization, and political issues on the systems."},
"CRIM311":{"description":"Convict criminology is a developing field in critical criminology that analyzes crime and social control issues using the research and writings of academics who are convicts or ex-convicts."},
"CRIM312":{"description":"The purpose of this courses it to highlight 1) the social perception, nature, and extent of organized crime; 2) theoretical explanations of organized crime; 3) the business of organized crime; 4) measures being taken to combat organized crime in the U.S. and around the world; 5) differing world perspectives on organized crime."},
"CRIM315":{"description":"Examines various crime control strategies and programs. Topics may include the history of crime control methods, crime prevention theories, the evaluation of control policies' effectiveness, and current policy, practices, and programs."},
"CRIM331":{"description":"The focus of this course is on women's experiences as victims of crime, perpetrators of crime, prisoners, and as employees and practitioners working with and within the criminal justice system. It includes a discussion of the feminist criminological perspective as it applies to these aforementioned experiences."},
"CRIM335":{"description":"An in-depth examination of juvenile delinquency and its causes, effects, treatment, and prevention. Includes analysis of how social institutions influence delinquency, theoretical explanations for delinquency, and the creation and current efforts of the juvenile justice system."},
"CRIM336":{"description":"A variety of cultural, structural, economic, and political viewpoints are used to provide an in-depth look at drugs, drug use, and society.  Special focus will be placed on the history, effects, and effectiveness of drug laws and policies."},
"CRIM351":{"description":"The purpose of this class is to acquaint students with the process of social research. The study of research methods is about understanding the art and the science of designing and executing research. It is about blending logic, common sense, creativity, and the scientific method to learn about contemporary issues related to crime, policy, and society."},
"CRIM352":{"description":"Introductory course to statistical reasoning and social science statistics commonly used in criminal justice research. Students will learn how to analyze and interpret quantitative data. Includes introduction to descriptive and inferential statistics."},
"CRIM370":{"description":"This course will examine a special topic in-depth that is timely or not focused on in other courses. Topics will change based on current events and expertise of faculty. Students may repeat this course with different topics."},
"CRIM371":{"description":"This course is a survey of the historical and contemporary theoretical explanations that relate to crime, offenders, victims, and the criminal justice system. The major emphases of the course will be the evaluation, assessment, and critical analysis of criminological theory in general and the use of such theories in criminal justice policies."},
"CRIM401":{"description":"The processes of probation and parole in the United States in terms of its historical development, philosophy, and standards; attention is focused on the utilization of parole and probation as tools of social control with special emphasis on the implications of the philosophical impact of probation and parole on field practices."},
"CRIM403":{"description":"This course will provide an overview of violence directed toward women. Crimes such as intimate partner violence/battering, stalking, rape, human trafficking, sexual assault, and sexual harassment will be analyzed within a broader social context."},
"CRIM405":{"description":"This course is an examination of the historical, political, and cultural facets of the death penalty and the legal and policy controversies surrounding the application of the death penalty in the United States. Topics discussed include a history of the death penalty in the United States, analysis of major U.S. Supreme Court cases addressing the death penalty, and an examination of the major issues surrounding the contemporary death penalty."},
"CRIM411":{"description":"This course is an examination of the common law and federal rules of evidence. Topics include but are not limited to the law of searches and seizures, the exclusionary rule, confessions, types of evidence, chain of custody, examination of witnesses, hearsay, and testimony."},
"CRIM412":{"description":"This course is an in-depth examination of police discretion and the application of coercive power. It includes analyses of the exercise and impact of police discretion on society and the impact that police discretion has on individual police officers, police departments, and societal support for law enforcement."},
"CRIM431":{"description":"Analysis of crime, deviance, and deviants.  Topics covered may include the creation of norms, ways of defining deviance, theoretical explanations for deviance, and the lives and actions of \"deviants.\""},
"CRIM475":{"description":"Students will successfully demonstrate their ability to apply relevant theory and methods to an empirical research problem and/or question appropriate to the field of criminal justice studies. The application will take the form of a research papers and class presentation."},
"CRIM489":{"description":"An extensive examination of the main criminal justice/criminology ideas on a specific topic. The major paper is a product of critical reading or advanced research."},
"CRIM499":{"description":"An opportunity for work experience in the area of criminal justice. For students interested in graduate school, the internship will involve field research."},
"CS101":{"description":"A first course intended for both major and non-major novice computer users that introduces computer programming techniques.  Topics include history of computing, problem-solving, variables, assignment statements, arithmetic statements, if statements, while loops, arrays, algorithms, and data structures.  Students will use a computer lab to produce small programs in a high-level programming language."},
"CS201":{"description":"Introduces the fundamental concepts of programming from an object oriented perspective. Through the study of object design, this course introduces data abstraction, inheritance, overriding, programming flow of control, operator precedence, and simple data structures such as lists and arrays. This course also introduces the basics of human-computer interfaces, graphics, and the social implications of computing, along with significant coverage of software engineering."},
"CS215":{"description":"Offers an intensive introduction to discrete mathematics as it is used in Computer Science. Topics include functions, relations, sets, propositional and predicate logic, simple circuit logic, proof techniques, elementary combinators, and discrete probability."},
"CS258":{"description":"Introduction to C# with a focus on object-oriented programming techniques. In addition, the language syntax, data types, methods, functions, and procedures necessary to write C# programs will be covered. The language will be introduced using console examples and will migrate to Windows examples during the course of the semester. Laboratory exercises will be assigned that represent common business problems for which the language is best suited."},
"CS276":{"description":"An introduction to the COBOL programming language. A structured approach is used to demonstrate the use of COBOL language in managing corporate information. Includes structured design of programs, control break processing, debugging techniques, table processing, and sequential file processing."},
"CS299":{"description":"Computer Science special topics course in which content is deemed to be at the Sophomore-level in content."},
"CS301":{"description":"Continues the introduction to object-oriented programming begun in CS 201, with an emphasis on algorithms, data structures, string processing, internal searching and sorting, software engineering, and the social context of computing."},
"CS311":{"description":"This course introduces students to data structures such as lists, trees (including binary and multi-way trees), heaps, stacks, queues, and graphs. Students will learn concepts of algorithmic complexity, time and space analysis of recursive and non-recursive algorithms, including graph and sorting algorithms, and different algorithm design techniques. The course also introduces concepts of NP-completeness."},
"CS321":{"description":"An introduction to the internal structure of digital computers. The design of gates, flip-flops, registers, and memories to perform operations on numerical and other data represented in binary form."},
"CS358":{"description":"Advanced study of the C# programming language, emphasizing object oriented techniques applied to complex business information needs. In addition, the course will deal extensively with database connectivity and utilization. The course will start with Windows examples and will migrate to Web examples during the course of the semester. Laboratory exercises will be assigned that represent common business problems for which the language is best suited."},
"CS365":{"description":"The course includes the fundamental principles of operating systems, resource allocation and use by applications, device organization, interrupts, concurrency, user/system state, and protection. It develops each of these areas in greater depth: scheduling and dispatch, preemptive and non-preemptive scheduling, processes and treads, file systems, buffering, encryption, virtual memory, paging, swapping, security, protection, and related topics. Both Microsoft and Unix systems will be covered."},
"CS376":{"description":"Sequential and random file processing, table handling, sorting, merging, sub-program, and error handling."},
"CS377":{"description":"Introduction to database theory. Discussion of data structures, indexed and direct file organizations, models of data including hierarchical, network, and relational. Discussion of data analysis, design, implementation, and database administrator functions. Application of database, data definition languages, data manipulation languages, and/or data manipulation through a host language."},
"CS379":{"description":"Introduction to formal study of syntax semantics and the logic of programming languages. Features of prominent languages will be examined."},
"CS399":{"description":"Computer Science special topics course in which content is deemed to be at the Junior-level in content."},
"CS411":{"description":"This course provides an overview of computer networking. It covers topics in communication technologies, communication design, communication protocols, and communication system planning and development. It includes distributed computing and collaboration technology. It will introduce the levels of the OSI and TCP/IP stacks and discuss the protocols at each level. At the highest level applications such as Web services and email as well as potential security issues will be presented. Additional protocols will be presented including HTTPS and SSL. The physical level will also be covered including switches, hubs, routers, gateways, and firewalls. Hands-on assignments with system and firewall configuration."},
"CS421":{"description":"An overview of legacy systems that will include the study of operating systems, user interfaces, basic operator commands, flat file architecture, editors, interactive and batch programming concepts, and programming languages. Laboratory exercises will be assigned that emulate common problems encountered in businesses utilizing legacy systems."},
"CS458":{"description":"This course is an advanced study of the C# programming language, emphasizing the development of dynamic web pages.  The course will cover server side controls, how to access and update data from a data source, how to bind controls to a data source and how to encapsulate visual and programmatic behavior in reusable ASP components.  Laboratory exercises will be assigned that represent common business problems for which the language is best suited."},
"CS461":{"description":"Introduces students to the fundamental concepts and techniques of artificial intelligence (AI)."},
"CS478":{"description":"Provides an intensive, implementation-oriented introduction to the software-development techniques used to create medium-scale interactive applications."},
"CS483":{"description":"This course applies computer programming and software engineering concepts, principles, and practices to a comprehensive system development project. A team approach is used to analyze the problem, and then specify, design, implement, test, validate, and deliver a software package that solves the problem. Teams will use software engineering techniques and project management techniques, including milestones and formal presentations, to create and test the package solution to the system problem."},
"CS499":{"description":"Advanced project in mathematics, business, or other area, done under supervision of instructor in area."},
"DMS301":{"description":"This course provides concepts of basic patient care procedures in the field of imaging sciences. The topics of the course include the healthcare team; attitudes and communication in patient care; patient interactions; psychological considerations; interactions with the patient; patient safety; transferring techniques; trauma and reactions to contrast agents; patient tubes, lines, and catheters; care of patients during and following special procedures; and patient care during mobile or surgical procedures. The role of the radiologic and imaging science professional in patient education will be identified."},
"DMS312":{"description":"This course provides the beginning sonography student with a thorough understanding of the ultrasound department, key terms and definitions pertaining to the field of diagnostic sonography, important laboratory values, and surgical procedures. Students will gain a beginning understanding of the value of diagnostic medical sonography in a healthcare setting, survey the role of sonographers in a variety of settings, recognize national and state credentialing requirements, identify sonography association functions at all levels, and recognize ARDMS standards, ethics, and policies and their effects on practicing sonographers' conduct and patient treatment."},
"DMS313":{"description":"This course provides the student with a basic knowledge of neck, chest, abdominal and pelvic cross-sectional anatomy. Students will gain a beginning understanding of sonographic sectional anatomy in the transverse, longitudinal, and coronal planes. Emphasis is placed on the sonographic appearance of neck, chest, abdominal and pelvic organs and vessels and their anatomical relationship to each other."},
"DMS315":{"description":"This course provides the student with a study of the clinical applications within the specialty of abdominal sonography. Emphasis is placed on the correct use of all technical and medical terms that are necessary for a complete discussion of sonographic procedures within the abdomen, related clinical symptoms and laboratory tests, normal vs. abnormal sonographic patterns of the anatomy studied, related disease processes, and sonographic protocol for each of the exams covered in the course."},
"DMS316":{"description":"This course provides the student with an introduction to the sonography clinical environment through campus laboratory practice. Students will gain an understanding of the daily activities within an ultrasound department, necessary skills to properly operate diagnostic ultrasound equipment, and proper patient care for sonography exams. Emphasis is placed on the application of skills studied in DMS 315 - Abdominal Procedures. The student will achieve an understanding of clinical and practical experience in support of classroom studies."},
"DMS321":{"description":"This course provides the student with an introductory study of sonographic physics and instrumentation within the practice of general diagnostic medical sonography. Emphasis is placed on the principles of ultrasound and its propagation through tissues, construction of the ultrasound transducer, and the various types of continuous wave and pulse echo imaging modalities."},
"DMS323":{"description":"This course provides the student with a study of the clinical applications within the specialty of obstetric sonography. Emphasis is placed on the correct use of all technical and medical terms that are necessary for a complete discussion of sonographic procedures within the field of obstetrics. Topics include the related clinical symptoms and laboratory tests used in obstetrics, fetal embryology, normal vs. abnormal sonographic patterns of fetal anatomy, related maternal and fetal disease processes, fetal anomalies and their sonographic appearance, and sonographic protocol for each of the registry exams covered in the course."},
"DMS324":{"description":"This course provides the student with a study of the clinical applications within the specialty of gynecologic sonography. Emphasis is placed on the correct use of all technical and medical terms that are necessary for a complete discussion of sonographic procedures within the field of gynecology. Topics will include the related clinical symptoms and laboratory tests, embryology of the female reproductive and genitourinary systems, female reproductive anomalies and their sonographic appearance, normal vs. abnormal sonographic patterns of female anatomy, related disease processes and their sonographic appearance, and sonographic protocol for each of the registry exams covered in the course."},
"DMS326":{"description":"This course provides the student with continued preparation in the sonography clinical environment through campus laboratory practice. Students will continue to build on skills introduced in DMS 316 by gaining an understanding of the daily activities within an ultrasound department, necessary skills needed to properly operate diagnostic ultrasound equipment, and proper patient care for sonography exams. Emphasis is placed on the application of skills studied in DMS 323 = Obsstetric Procedures and DMS 324 - Gynecology Procedures. The student will achieve an understanding of clinical and practical experience in support of classroom studies."},
"DMS327":{"description":"This course provides supervised clinical education allowing students to apply knowledge and essential skills of diagnostic medical sonography. Emphasis is placed on the application of skills studied in DMS 316 - Sonographic Lab Practice I."},
"DMS331":{"description":"This course provides the student with an advanced study of sonographic physics and instrumentation within the practice of general diagnostic medical sonography. Emphasis is placed on the principles of continuous wave and Doppler ultrasound, image artifacts, system operation and display, and biological effects of medical sonography."},
"DMS332":{"description":"This course provides the student with a study of the clinical applications within the areas of small parts, pediatric, and specialty exams performed in diagnostic medical sonography. Emphasis is placed on the correct use of all technical and medical terms that are necessary for a complete discussion of sonographic procedures, related clinical symptoms and laboratory tests, normal vs. abnormal sonographic patterns of the anatomy studied, related disease processes, and sonographic protocol for each of the exams covered in the course."},
"DMS333":{"description":"This course provides the student with advanced discussions and critical reviews of abdominal, obstetric, and gynecologic ultrasound. The course will culminate with the synthesis of a written draft of a clinical pathology research analysis article. Emphasis is placed on utilizing research to correlate underlying pathophysiology with its sonographic presentation, related diagnostic procedures, and the role of ultrasound in the medical diagnostic process."},
"DMS337":{"description":"This course is a continuation of supervised clinical education. Emphasis is placed on the application of skills studied in DMS 323 - Obstetric Procedures, DMS 324 - Gynecology Procedures, and DMS 326 - Sonographic Lab Practice II."},
"DMS347":{"description":"This course requires a final mastery of all sonographic clinical competencies within a supervised clinical education setting. Emphasis will be on general skill achievement with introduction to specialized sonographic examinations and procedures."},
"DMS436":{"description":"This course emphasizes individualized study, analysis and synthesis of concepts related to general, vascular and cardiac sonography concentrations. The student is challenged to pursue diagnostic medical sonography credentialing examinations defined by American Registry for Diagnostic Medical Sonography (ARDMS)."},
"DSCI351":{"description":"This course introduces the student to the discipline of Operations Research/Management Science (OR/MS) so that he/she may appreciate, understand, and utilize the principal techniques of OR/MS in his/her organizational as well as personal decision-making. The course stresses the use of the scientific methodology in decision- making and problem-solving. Specific topics include decision theory, linear programming, the transportation problem, the assignment problem, network models, and queuing theory. Excel and other computer software are used throughout the course."},
"DSCI445":{"description":"This course presents the basic fundamentals of managing operations. It covers issues in selecting, operating, controlling, and updating systems so that goods or services are produced on time, at minimum cost, and according to customer specifications. Topics include forecasting, capacity planning, facility location, inventory management, material requirements planning, Just-in- Time, and total quality management."},
"DTAS111":{"description":"This course focuses on the general pathological conditions of the oral cavity with implications for the dental assistant."},
"DTAS122":{"description":"This course introduces the student to chairside dental assisting procedures with an emphasis on instrument identification and care, tray set-ups, transfer of instruments, and the role of the dental assistant in operative procedures. Clinical asepsis is emphasized and microbiological theories of disease and disease transmission are integrated and applied into course content. Includes student application in extramural settings."},
"DTAS156":{"description":"This course presents the structure and function of body systems, their pathologies and emergencies, along with preventions and treatments."},
"DTAS158":{"description":"This course provides comprehensive consideration of dental ethics, jurisprudence, office procedures, and techniques for seeking employment. The student is prepared to assume the role of the business assistant, including receptionist duties, correspondence, computer usage, and management of financial accounts."},
"DTAS166":{"description":"Structure and function of the remaining body systems, their related pathologies and emergencies, along with preventions and treatments are highlighted in this class. The digestive system with special emphasis on human nutrition and diet therapy is a special content area in human systems II."},
"DTAS175":{"description":"This course surveys dental diseases and provides practical methods of preventing their occurrence, utilizing plaque control, fluoride therapy, dietary control, pit and fissure sealants, and patient education."},
"DTAS181":{"description":"This clinical course prepares the student to assume the role of the dental assistant in the private office. Includes extramural clinical rotations."},
"DTAS183":{"description":"This course introduces the theories and techniques of chairside assisting in the dental specialties. Also includes the manipulation and uses of dental materials and the exposure, processing, and mounting of dental radiographs utilized in the dental specialties."},
"DTAS291":{"description":"This course prepares the student to assume the role of the expanded functions dental assistant in a clinical setting. Restorative dental procedures utilized in dentistry are presented in this course. Significant emphasis of course content will be placed on correct utilization and placement of dental dam in addition to the application of specific types of dental materials such as amalgam and composite restorations."},
"DTHY303":{"description":"This course includes principles of radiation physics, exposing, processing, mounting, evaluating, and interpreting dental radiographs. Biological effects of radiation and protective principles, quality assurance protocols, and infection control are emphasized. Emerging imaging technology is addressed."},
"DTHY308":{"description":"This course focuses on the study of the physical, chemical, and biologic properties and uses of dental materials. Identification and manipulation of common dental materials are incorporated in laboratory experiences."},
"DTHY313":{"description":"This course focuses on anatomical structures of the head and neck, including bones, muscles, glandular tissue, and vascular, nervous, and lymphatic systems. The infectious process and the spread of dental infection are discussed."},
"DTHY314":{"description":"This course provides the student with a detailed study of the anatomy of the oral cavity and underlying structures. The eruption, arrangement, function, developmental disturbances, occlusion, and morphological characteristics of both the permanent and deciduous dentitions is presented."},
"DTHY315":{"description":"This course provides the student with a detailed study of the embryological and histological formation of the oral-facial structures of the head, neck, oral cavity, and underlying structures."},
"DTHY316":{"description":"This course emphasizes prevention of dental and periodontal diseases through mechanical dental plaque control, fluoride therapy, pit and fissure sealants, dietary control, and basic principles of client education. The effects of tobacco use on oral health are included. Tobacco cessation strategies and their use in oral health education are presented."},
"DTHY318":{"description":"This course emphasizes teaching methodology and techniques for individual and group oral health education. Communication, behavior modification, and learning theories are presented. Tobacco use and cessation interventions will also be a focus of instruction.  The course prepares the student to provide individualized oral health instruction and to participate in service learning activities in the community."},
"DTHY322":{"description":"This course focuses on periodontal diseases and their relevance for the dental hygienist. A review of the anatomy and structures of the periodontium is included. A thorough analysis and application of the clinical dental hygiene diagnosis is provided. Emphasis is placed on the etiology, systemic correlation, clinical and radiographic assessment, diagnosis and classification, treatment planning, and surgical and non-surgical treatment procedures of periodontal diseases."},
"DTHY327":{"description":"This course will provide foundational knowledge for the dental hygiene student to select and implement evidence-based decision-making strategies in the provision of patient/client care.  In addition, it proposes to develop critical thinking and problem solving skills necessary for patient centered care.  It includes basic knowledge and skills related to research terminology and design, library and computer-based information retrieval systems, approaches for analysis and evaluation of scientific literature and will provide an introduction to dental indices used in the description of oral health status and disease states."},
"DTHY341":{"description":"This course introduces the theory of the dental hygiene process of care, with emphasis on providing the student with an ethical and scientific foundation needed in performing comprehensive dental hygiene treatment.  Medical emergencies, infection control, comprehensive assessments, care planning, nutritional counseling for the dental professional, and fundamental instrumentation skills are introduced. Current clinic issues are addressed in a one-hour weekly seminar."},
"DTHY342":{"description":"This pre-clinical laboratory experience course presents practicum covering the fundamentals of medical emergencies, infection control procedures, assessment of the dental hygiene client, development of a dental hygiene diagnosis, instrumentation skills, equipment use and maintenance, and basic oral health education."},
"DTHY351":{"description":"This course explores professional writing, cultural competence, and concepts of evidence based clinical practice.  Solutions to oral health care discrepancies in our society are examined.  By engaging in interviews, research, case studies, reflection, and inquiry, students will develop a project addressing culturally competent approaches to oral health care.  Students demonstrate synthesis of course objectives through a sustained writing project.  The use of supportive dental hygiene treatment in providing comprehensive care is introduced.  Weekly seminar addresses current clinic issues and trends connecting theory and clinical practice."},
"DTHY352":{"description":"This clinical course introduces the dental hygiene student to providing comprehensive dental hygiene treatment. Application of knowledge in infection control practices, development of care plans, assessment, oral health education, radiography, and fundamentals of instrumentation are implemented in collaboration with advising faculty. Supportive dental technology, equipment and products related to dental hygiene treatment are introduced."},
"DTHY401":{"description":"This course provides the student with periodontal patient management experiences, focusing on assessment, treatment planning, development of behavior modification strategies, implementation of treatment strategies, maintenance, and evaluation.  Treatment experiences are integrated into clinic management activities and oral presentations.  Evaluation of patient treatment by associate degree students enrolled in the Dental Hygiene Program is incorporated."},
"DTHY402":{"description":"This course will utilize a collaborative approach to patient treatment management.  The baccalaureate degree student will evaluate periodontal treatment strategies developed and implemented by associate degree students.  The student will develop both clinical and classroom instructive skills through individually designed educational experiences."},
"DTHY405":{"description":"This course will combine general and oral pathology found in the human system. Concepts of pathologic physiology included in this course are the inflammatory process, wound healing, and immunology. Emphasis will be placed on the oral/ facial characteristics of systemic disease. Principles of pathology, clinical features, risk factors, etiology, locations, and treatment options are presented."},
"DTHY411":{"description":"This course is designed to provide concepts and principles related to the practice of community oral health. Prevention, access to care, providers of dental services, financing dental care, and quality assurance are discussed. The dental hygienist's role in developing community oral health programs is an integral component. The significance of oral health measures for all members of society is addressed."},
"DTHY412":{"description":"In this course the dental hygiene student will develop oral health education programs for diverse populations utilizing the dental hygiene process of care paradigm of assessment, planning, implementation, and evaluation. Field experiences will take place in community schools, extended care facilities, outpatient treatment facilities, and acute care facilities."},
"DTHY422":{"description":"This clinical course focuses on providing non-surgical periodontal treatment for clients with moderate to advanced periodontal disease. Emphasis is placed on collecting and evaluating assessment data, treatment planning, providing oral health education and nutritional counseling, providing periodontal treatment, and performing post-operative evaluation. Periodontal Case Client presentations are developed from clinical experiences."},
"DTHY423":{"description":"This course addresses coverage of pain and anxiety management for conscious dental clients. The indications, contraindications, and pharmacology of local anesthesia and nitrous oxide and oxygen sedation used in dentistry will be discussed. Local anesthesia techniques and the administration of nitrous oxide and oxygen sedation will be studied. Students will be introduced to general anesthesia, parenteral sedation, and anti-anxiety medications. Administration of local anesthesia techniques will be performed."},
"DTHY441":{"description":"This course addresses the care and clinical management of special needs clients who present with conditions/diseases of significance to dental hygiene care. Included is discussion of the pathophysiology, etiology, psychosocial, physical, and oral characteristics, potential emergencies, and the related dental hygiene process of care associated with these conditions/diseases. Current clinic issues are addressed in a one hour weekly seminar."},
"DTHY442":{"description":"This clinical course focuses on the dental hygiene student building on prior experience in providing comprehensive dental hygiene treatment. Increased opportunity for treating clients with moderate to severe periodontal disease as well as those with complex medical and pharmacological histories is introduced. Students gain experience in applying advanced strategies in assessment, care planning, oral health education, and instrumentation. Student directed judgment and relating theory to the clinical component of dental hygiene education are emphasized."},
"DTHY451":{"description":"The intent of this course is to provide students with a systematic approach to board preparation and dental hygiene curricula review. Information presented in this course will assist senior dental hygiene students in preparing for credentialing examinations and other procedures required for obtaining a dental hygiene license. Specific guidance is offered to students who are preparing for the National Dental Hygiene Board Examination and the Northeast Regional Board Examination. Indiana laws related to dental and dental hygiene practice are studied in detail. Current clinic issues are addressed in a one hour weekly seminar."},
"DTHY452":{"description":"This clinical course challenges the dental hygiene student to work ethically, professionally, and independently in providing comprehensive dental hygiene treatment for clients in all stages of periodontal health with increased concentration on treating clients with moderate to severe periodontal disease. Dental hygiene treatment based on current scientific theories, research, and standard of care is expected. Meeting the individual needs of clients utilizing problem-solving strategies, critical thinking skills, and good communication is emphasized."},
"DTHY457":{"description":"This course presents professional oral healthcare topics from a historical, economic, legal/ethical, global, political, social, interdisciplinary, and multicultural framework. Students will investigate current issues in oral healthcare and examine ethical and legal concerns in the practice of dental hygiene, and explore the accelerating need for access to care for all population groups. Students will develop evidence-based solutions which could be used to influence organizational, institutional, and governmental decisions impacting oral health care.  In addition, discussions and exploration of current dental hygiene practice will be included to prepare the student for transition into the profession."},
"DVT398":{"description":"This course provides the student with an introduction to vascular sonography through campus laboratory practice. The student will gain an understanding of the daily activities within a vascular laboratory, necessary skills to properly operate vascular diagnostic ultrasound equipment, and proper patient care for vascular sonography exams. Emphasis is placed on the application of topics studied in DVT 401 - Introduction to Vascular Anatomy and Procedures."},
"DVT401":{"description":"This course provides the student with an introduction to vascular anatomy and physiology as it pertains to non-invasive diagnostic vascular sonography. Emphasis is placed on methods of sonographic imaging, equipment utilized, and conditions warranting vascular evaluation. Topics include vascular physiology, hemodynamics of the circulatory system, vascular pathophysiology, extracranial cerebrovascular testing, and evaluation of the deep and superficial venous systems of the upper and lower extremities."},
"DVT402":{"description":"This course provides the student with a study of the disease processes pertaining to non-invasive diagnostic vascular sonography. Emphasis is placed on methods of sonographic imaging, equipment utilized, and conditions warranting vascular evaluation. Topics include lower extremity venous insufficiency testing, lower extremity arterial Doppler, visceral vascular sonographic evaluation, and other non-sonographic vascular imaging procedures."},
"DVT421":{"description":"This course provides the student with a continued study of the disease processes pertaining to non-invasive diagnostic vascular sonography. Emphasis is placed on methods of sonographic imaging, equipment utilized, and conditions warranting vascular evaluation. Topics include hemodialysis access imaging, transcranial Doppler, and quality assurance in the vascular lab."},
"DVT423":{"description":"This course provides the student with continued preparation for the vascular sonography clinical environment through campus laboratory practice. Students will continue to build on skills introduced in DVT 398 - Introduction to Sonographic Vascular Lab. Emphasis is placed on the application of skills studied in DVT 402 - Vascular Procedures I."},
"DVT426":{"description":"This course provides supervised clinical education allowing students to apply knowledge and essential skills of vascular sonography. Emphasis is placed on the application of skills studied in DVT 398 - Introduction to Songraphic Vascular Lab and DVT 401 - Introduction to Vascular Anatomy and Procedures."},
"DVT437":{"description":"This course is a continuation of supervised clinical education. Emphasis is placed on the application of skills studied in DVT 402 - Vascular Procedures I and DVT 423 - Songraphic Vascular Lab Practice I."},
"ECE217":{"description":"This course covers the fundamentals of procedural computer programming with an emphasis on C language.  Typical software development methodologies are presented, including common mechanisms for documentation and descriptions of program logic."},
"ECE241":{"description":"This course teaches the basics of digital logic by using computer simulations and circuit construction in laboratory experiments. Binary, octal, and hexadecimal number systems are investigated, and basic logic gates (AND, OR, NAND, NOR, and INVERTER) are discussed. Flip-flops, multiplexers, and various memory devices and their uses in logic circuitry are studied."},
"ECE255":{"description":"Introduction to electric circuit theory and analysis for engineering majors. Topics covered include D.C. Circuits, A.C. Circuits, mesh and nodal analysis, Norton's and Thevenin's equivalent circuits, source conversions, impedance calculations, maximum power transfer, phasor and sinusoidal steady state responses."},
"ECE311":{"description":"This course covers the foundations of electric and magnetic fields. Material for the course consists of vector calculus, electrostatics, magnetic fields, Maxwell's equations, electromagnetic waves, and transmission lines. The course will have three 50 minute lectures each week, with a 50 minute tutorial session. Students will be assessed through weekly homework, exams, and projects."},
"ECE341":{"description":"Semiconductors' introduces students to fundamental properties of semiconductor materials including but not limited to doping, band diagrams, p-n junctions, and semiconductor device theories."},
"ECE343":{"description":"This course introduces the three basic discrete devices: the diode (both pn and zener), the bipolar junction transistor, and the field effect transistor. Device modeling, biasing techniques, frequency response, h parameters and amplifier design are discussed."},
"ECE344":{"description":"Integrated Circuits (IC) is a continuation of ECE 343 - Electronics. Students will analyze and design circuits utilizing the major devices they studied in 343, namely field effect transistors and bipolar junction transistors. Typical devices students will investigate are differential amplifiers, frequency dependent circuits, power amplifiers, and wave-function manipulation circuits."},
"ECE345":{"description":"Advanced electrical circuits elective course for engineering majors. Topics covered include polyphase circuits, complex frequency and Laplace Transform, s-domain circuit analysis, series and parallel resonance, and Fourier frequency analysis. Course stresses network theorems, solution of time and frequency- domain problems. Course coverage includes transient analysis by classical and transform methods as well as basic concepts of steady state AC circuit analysis."},
"ECE347":{"description":"An introduction to the concepts of microcomputers and microcontrollers including system architecture, addressing modes, assembly language programming, fixed point arithmetic, data structures and stacks, subroutines, high-level compilers, and integrated development environments."},
"ECE349":{"description":"This course is a technical elective intended for electrical emphasis students. It is part of the power systems design sequence. The course introduces the students to electromechanical devices such as DC machines, induction motors, and synchronous machines. The course has a lab associated with it in which modeling and applications will be emphasized."},
"ECE377":{"description":"This course will introduce students to printed circuit board (PCB) design and fabrication.  Using foundational skills in circuits, students will transition their knowledge of breadboard circuit design to PCB design.  Students will be introduced to topics that include board design and layout, fabrication, and PCB population.  Students will also be introduced to how different design programs work, PCB fabrication limitations, and training to use PCB fabrication equipment. At the culmination of the course, students will be able to show their gained knowledge by prototyping, building, and testing electronic circuits towards a semester project."},
"ECE443":{"description":"A study of the fundamental concepts of linear automated control of physical systems. The course includes the following areas of study: mathematical modeling, block diagrams, frequency response analysis, root-locus analysis, time-domain analysis, stability analysis, compensation techniques, controller design, and interface transducers."},
"ECE445":{"description":"Classification, analysis, and design of systems in both the time- and frequency-domains. Continuous-time linear systems: Fourier Series, Fourier Transform, bilateral Laplace Transform. Discrete-time linear systems: difference equations, Discrete- Time Fourier Transform, bilateral z-Transform. Sampling, quantization, and discrete-time processing of continuous-time signals."},
"ECE447":{"description":"This course involves the design of firmware and hardware for microprocessor-based systems, including analog and digital interfaces, system architecture, memory system design, IO structure and handshaking protocols, interrupts, timers, parallel and serial subsystems, and analog-to-digital conversion."},
"ECE448":{"description":"An introduction to software engineering principles, with emphasis on the methods, processes, tools, and metrics needed to develop quality software products and systems."},
"ECE449":{"description":"Fundamental concepts of power system analysis, transmission line parameters, basic system models, steady state performance, network calculations, power flow solutions, fault studies, symmetrical components, operating strategies and control. Industry standard software, like ETAP, will be used in the course."},
"ECE452":{"description":"This course covers the fundamental theories involved in digital signal processing and their application to real-world systems.  Topics include the sampling theorem, discrete linear systems, Fourier, Laplace, and z Transforms, digital filters, error correction coding, and data compression.  Students gain real-world experience by implementing DSP algorithms in real-time computer programs and off-line computer simulations.  The course is administered as two 50-minute lectures per week and a three-hour lab.  Student learning is assessed through in-class exams and quizzes, a final exam, homework, and laboratory/programming assignments."},
"ECE453":{"description":"This course introduces various topics of analog and digital communication systems. The course will review the Fourier series and Fourier transform for continuous time signals. The discussion then turns to analog modulation techniques: amplitude and phase modulation. The effectiveness of these techniques in presence of noise will be considered. Analog-to-digital conversion is also addressed, as well as an introduction to digital modulation and data transmission techniques. The course will be administered as two 50-minute lectures per week and a three-hour lab. Student learning will be assessed through two in-class exams, a final exam, homework, and laboratory assignments."},
"ECE471":{"description":"This course is the first step in the senior design sequence. The first third of the course (lecture and lab) will concentrate on system specifications and instrumentation. For the rest of the semester, the lecture will cover various topics of system design including project management, decision making, testing, and economics. To coincide with this material, the lab will be used as time for the students to start the senior design project. The course will be assessed through a project proposal, midterm design review, final design review. Furthermore, the students will need to turn in individual progress reports and various assignments (labs, homework, case studies) throughout the semester. The course consists of 2 hours of lecture and a 2 hour lab."},
"ECHO398":{"description":"This course provides the student with an introduction to the sonographic echocardiography lab clinical environment through campus laboratory practice. The student will gain an understanding of the daily activities within an echocardiography laboratory, necessary skills to properly operate cardiac diagnostic ultrasound equipment, and proper patient care for all cardiac sonography exams. The student will achieve an understanding of clinical and practical experience in support of classroom studies."},
"ECHO401":{"description":"This course provides the student with an introductory study of the clinical applications within the specialty of cardiac sonography. Emphasis is placed on the anatomic and physiological relationships within the thoracic cavity."},
"ECHO402":{"description":"This course provides the student with an advanced study of the clinical applications within the specialty of cardiac sonography. Emphasis is placed on echocardiography evaluation procedures and techniques."},
"ECHO421":{"description":"This course provides the student with a study of the fundamentals of fetal echocardiography, including case study review of normal anatomy, physiology, and pathological conditions of the heart and its visualization with 2D and 3D imaging, Doppler, and M-mode echocardiography."},
"ECHO423":{"description":"This course provides the student with continued preparation for the sonographic echocardiography lab clinical environment through campus laboratory practice. The student will continue to build on skills introduced in ECHO 398 by gaining an understanding of the daily activities within an echocardiography lab, necessary skills needed to properly operate cardiac diagnostic ultrasound equipment, and proper patient care for all cardiac sonography exams. Emphasis is placed on the application of skills studied in ECHO 402 - Advanced Echocardiography. The student will achieve an understanding of clinical and practical experience in support of classroom studies."},
"ECHO426":{"description":"This course provides supervised clinical education allowing students to apply knowledge and essential skills of cardiac sonography. Emphasis is placed on the application of skills studied in ECHO 398 - Introduction to Echocardiography Lab and ECHO 401 - Introduction to Echocardiography."},
"ECHO437":{"description":"This course provides the student with a continuation of supervised clinical education. Emphasis is placed on the application of skills studied in ECHO 402 - Advanced Echocardiography and ECHO 423 - Echocardiography Lab Practice I."},
"ECON175":{"description":"An introduction to basic economic terms and concepts, such as scarcity, choice, resources, opportunity cost, markets, incentive, prices, competition, employment, inflation, growth, output and changes in business activity. Special emphasis is given to understanding how these concepts are applied and influenced by choices which individuals and organizations face every day."},
"ECON208":{"description":"The course will introduce students to the Economist's lens of 'knowing' social phenomena. This lens is formed of the Cost-Benefit approach to individual decision-making, which draws from diverse fields such as ethics, cognitive science and game theory, along with the interactions of these decisions through coordinating mechanisms, such as a market. Social phenomena examined include 'everyday' phenomena such as: why do we find a lot of carts laying around the parking lot of one store in comparison to that of another store? as well as phenomena of a larger scope, such as: why the physical conditions of homes in an area can impact its crime rate."},
"ECON209":{"description":"An introductory analysis of overall economic activity. Income, production, employment, and prices are examined at the national level. Alternative theories of, and policies toward, economic stabilization and growth are examined."},
"ECON241":{"description":"An introduction to the causes and consequences of the growing economic interdependence of nations. Emphasis is placed on how different people, cultures, institutions, and economies are affected by, and respond to, current global issues. Topics may include international trade, immigration, economic development, and capital markets."},
"ECON265":{"description":"An initial presentation of the conceptual framework of statistical thinking. The course then presents design and graphical techniques for describing data and for making inferences from the data. Business applications are utilized for illustration."},
"ECON301":{"description":"This course provides an introductory application of economic concepts to a wide variety of current social issues and problems with an integrative emphasis across the social sciences."},
"ECON306":{"description":"This course examines the nature of power and the origin and expressions of evil from an individual and cultural perspective.  Predictors of evil and specific cultural practices are analyzed.  The course then considers religion (including atheism) and the 'problem of evil' through information-seeking and game-play.  After reviewing the diverse cultural/religious responses, the unique evils due to religion and the future of evil are debated.  This course is designed for students of all majors seeking to understand the issues facing humanity today and for those who want to change the world tomorrow."},
"ECON308":{"description":"An in depth study in microeconomic theory. This framework is fundamental to understanding an enormous range of phenomena in business, public policy, social behavior, and a variety of other fields. Microeconomic analysis relies on a small set of enormously powerful analytical tools: constrained optimization analysis, equilibrium analysis, and comparative statics analysis. This course attempts to help you master these tools by illustrating their use in many different contexts."},
"ECON309":{"description":"An in depth study in macroeconomic theory. The course will cover traditional macroeconomic topics like short-run fluctuations and stabilization policy, as well as explain other important issues like the determinants of long-run economic growth, the trade balance and financial flows, labor markets, and institutional framework of policymaking."},
"ECON327":{"description":"This course presents the analytical framework for a popular decision-making tool used by private and public sector managers for investment analysis and project evaluation.  Emphasis will be placed on understanding fundamental economic theory and practical measurement techniques.  The course will focus on real-life problems and case studies while discussing the limitation and critiques of the method."},
"ECON331":{"description":"Economic rationales for government involvement in the economy including correcting market failures and redistributing income. Public Economics examines government expenditure and taxation policy in achieving these goals."},
"ECON336":{"description":"In this course we will learn the basics of both the art and the science of building economic models.  Specifically, we will gain an appreciation for how insights about the driving forces behind social phenomena can be distilled from simple economic models constructed using the language of mathematics."},
"ECON338":{"description":"Explanation and application of the interplay between the natural environment and economic activity. Environmental economics focuses on the impact of economic activity on the natural environment; whereas, resource economics studies the supply, demand and allocation of natural resources used for economic activity. Issues include water and air pollution, global climate change, and the management of natural resources."},
"ECON341":{"description":"An examination of the causes and consequences of the movement of goods and services across national borders."},
"ECON343":{"description":"The study of foreign exchange markets, exchange rate determination, balance of payments, international flow and management of financial assets and various risk exposures, and the operation and effectiveness of economic policy in an open economy."},
"ECON346":{"description":"The study of development factors and theories with the emphasis on the international context of development. Topics may include the implications of globalization, the rise of China and the continuing crisis of Sub-Saharan Africa."},
"ECON349":{"description":"An examination of the positive and negative facets of important economic phenomena that emerge from countries being financially interlinked and the policy tools that impinge on such phenomena. The course begins with a general model of an open economy and then considers special cases pertaining to particular countries, institutions, and historical time periods."},
"ECON351":{"description":"The course focuses on the decisions of wage earners within modern society and how the role of government, firms and other economic agents influence those decisions. Topics include the decision to work, labor demand, wage theory and policy, discrimination, human capital, the role of unions, and managerial incentives."},
"ECON353":{"description":"An examination of the industry of sports using the tools and concepts of economic analysis. The topics to be addressed in professional and amateur sports include new stadiums and franchises, salary caps, unions, free agency, the NCAA, the existence of amateur status, and the impacts of monopoly and cartel behavior."},
"ECON357":{"description":"Students will learn about the healthcare sector and how to apply the tools of economics to analyze the structure and performance of this sector. The features of the market for health services are described with emphasis placed on the characteristics which make this market different from the markets for other goods and services."},
"ECON361":{"description":"The nature and function of credit in the American economy; operation of commercial banks; purposes and functions of the Federal Reserve System, and the introduction to monetary theory. The course emphasizes the impacts of recent developments in the U.S. monetary system on both individuals and the overall economy."},
"ECON365":{"description":"Continued exploration of statistical techniques and methods at an upper level in areas of business applications. The analysis of large and complex data sets will be emphasized. Topics may include regression, ANOVA, time series analysis, some multi-variate techniques, and non-parametric techniques."},
"ECON433":{"description":"This course is designed to investigate those scenarios where agent's choices are made in the presence of strategic motives, i.e. an agent's optimal decision is dependent on the choices of others within the environment. The participants (players) within these types of strategic scenarios (games) need not be individuals; they can be firms, political parties, tribes, nations, etc."},
"ECON461":{"description":"Where do popular economic ideas and theories originate? The development of economic thought from early Greeks and Romans into the present century is presented. Emphasis on Smith, Malthus, Ricardo, Mill, Marx, Marshall, and Veblen. The literature is examined in relation to the social, political, and economic events of each period with an emphasis on how ideas have changed and developed over time."},
"ECON472":{"description":"How do economists test their ideas and theories? This course presents an overview of the economics devoted to the statistical testing of propositions derived from economic theory. An introduction to econometric methods and their application to economic research is developed."},
"ECON489":{"description":"This course offers students the opportunity to study selected topics of current interest and importance in economics. These topics will vary according to the interests of students and faculty."},
"ECON499":{"description":"A seminar and research course in selected topics of current interest."},
"EDUC173":{"description":"Survey of U.S. schooling, beginning with the earliest Massachusetts laws and schools and continuing to No Child Left Behind in the 21st century. In this course students use primary documents to study a dynamic and contentious aspect of U.S. history."},
"EDUC203":{"description":"A survey course designed to introduce and explore traditional and contemporary children's literature through immersion into a variety of authors, illustrators, and genres. This course is designed to enhance teacher candidates' understanding of children's literature and how these books can be used effectively with children of all ages."},
"EDUC204":{"description":"This course introduces teacher candidates to best practices in teaching writing in the elementary and middle school classroom in all subject areas. Students examine theoretical perspectives on the teaching of writing and the connections between writing and other literacies (reading, speaking, and listening). The course will incorporate strategies for using writing to enhance children's learning in all disciplines."},
"EDUC206":{"description":"This course defines and examines the nature and needs of exceptional children.  Special education foundations and legal mandates will be discussed.   Appropriate accommodations and teaching methods that address each area of exceptionality and the effectiveness of these techniques will be covered.  The role of teachers, parents, and other relevant personnel will be investigated in relation to current practices which include individual education programs (IEP), inclusion, and least restrictive environment (LRE).  This course must be taken as a prerequisite or co-requisite with the Exploration Phase course(s) in Teacher Education."},
"EDUC214":{"description":"Explores the use of the technology to enhance the teaching and learning process. Students will explore current research, issues, and methodology related to the design, development, and use of technology resources. Emphasis will be placed on the evaluation and selection of Internet resources, educational software, productivity tools, and other emerging technologies in the educational environment. Students will develop skills in the production and effective use of print, projected, and displayed communication using technology tools."},
"EDUC221":{"description":"The purpose of this course is to explore the theory and knowledge base that supports multicultural education and an awareness of diversity within American society. Topics may include the critical study of issues as they relate to race and ethnicity, exceptionality, language and dialect, religion, gender, and age."},
"EDUC241":{"description":"Early Childhood Education studies the historical and current perspectives in the education of young children in early childhood settings including curriculum models, health, safety and nutrition needs of children, licensing and accreditation standards."},
"EDUC242":{"description":"Examines the development of the early years and the ways to assess development in the growth domains. Examines the importance of observation and documentation, compares assessment instruments, and examines appropriateness of each tool. Guided field experience required."},
"EDUC243":{"description":"Examines the importance of observation and documentation to the assessment process. Techniques for each area will be considered. Field experience required."},
"EDUC246":{"description":"Examines the process of language and literacy development in young children. The course focuses on ways teachers of young children integrate research-based knowledge with early school and family literacy."},
"EDUC255":{"description":"Examines the growth and development of infants and toddlers with an emphasis on the uniqueness of the earliest years and the implications for care in group settings. Theoretical and philosophical approaches to infant/toddler care will be studied. Programs and activities for infants and toddlers will be discussed and planned, incorporating accommodations for early intervention. Required field work involves experiences with infants and toddlers (B-2 years)."},
"EDUC256":{"description":"Studies various theories and practices related to the guidance of young children. Emphasis on self-awareness for teachers and parents leading to setting program goals and promoting prosocial behavior."},
"EDUC261":{"description":"Studies the various special needs conditions, early identification and intervention approaches, and the subsequent adaptations necessary for a classroom setting."},
"EDUC283":{"description":"This course is designed to integrate the fundamental aspects of adolescent development and teaching adolescents reading strategies in a departmentalized middle or secondary (5-12) school setting. The course includes a field experience."},
"EDUC292":{"description":"This course will provide a broad overview of current issues and practices in elementary schools. Candidates will explore major child development concepts, learning theories, socio-historical contexts, and research related to the growth and development and literacy acquisition. This course requires a field experience involving tutoring in a local school."},
"EDUC313":{"description":"Candidates will synthesize their foundational knowledge of children's trade books, pedagogy, and technology to create a learning environment that fosters and supports P-6 students' motivation and achievement in reading. Print and digital materials will be used to make connections across content areas and with the world beyond school."},
"EDUC314":{"description":"Course provides a broad survey of contemporary literature for adolescents and young adults (grades 6-12).  Participants will read critically acclaimed literature and design plans for incorporating these works across the curriculum in middle school and high school classrooms.  Technology-based response activities will be included."},
"EDUC315":{"description":"This course covers behavioral theory, foundations of positive behavior support, and legal issues related to behavior. It teaches students strategies for managing the classroom to facilitate learning and ensure safety in diverse school settings. Students learn to identify the functions of student behavior and effective behavior interventions for addressing behavior difficulties. Strategies for crisis prevention and intervention are addressed."},
"EDUC340":{"description":"Explores contemporary topics or innovative developments in early childhood education. Subtitle and credit hours determined by faculty at each offering."},
"EDUC344":{"description":"Examines the diversity of families and factors that influence parenting in contemporary society. Focuses on strategies to develop working partnerships with parents. Addresses ways to design parent-teacher conferences, parent education, and parent involvement in P-6 settings. Guided field experience required."},
"EDUC351":{"description":"This course focuses on play as the principal medium of children's learning and development through activities such as exploration of music, movement, and rhythms for young children. Its objectives relate mainly to learning the teacher's role in supporting, facilitating, and guiding children's play with a focus on physical activity through developmentally appropriate activities for birth through age 8 settings."},
"EDUC354":{"description":"Directed field experiences in approved settings involving students in the elementary school setting (Grades K-6). Experiences will involve the identification of specific learning needs within the classroom, development of integrated curriculum, collaborative implementation through differentiated instructional strategies, and assessment of instructional impact on student learning."},
"EDUC355":{"description":"Relates theory to practice through supervised experiences in an educational setting."},
"EDUC356":{"description":"Examines methods and materials in the social studies, sciences, and mathematics that are developmentally appropriate for young children.  Emphasis is on planning active learning opportunities, integrating the curriculum, and professional collaboration."},
"EDUC357":{"description":"Examines the objectives, resources, curriculum development, methods of teaching expressive arts at the elementary level. Emphasis is on dance, music, theatre and visual arts in various cultures."},
"EDUC359":{"description":"Experience is designed to relate theory to practice through supervised experiences in an early childhood setting. Students work under the supervision of preschool and University personnel. Topic is specified for each offering."},
"EDUC373":{"description":"Designed to integrate physical, biological, earth/science concepts, and the teaching of science. Experiences in inquiry-based problem solving using primary and integrated science process skills stressed. Age-appropriate science content and teaching methodologies are studied. The course also explores issues regarding safe science, evaluating student comprehension in science, and addressing state and national science standards. It is strongly recommended that students complete the science content course requirements before enrolling in this course."},
"EDUC378":{"description":"This course introduces candidates to the principles and techniques of scientifically-based reading and English language arts instruction in K-6 classrooms. A guided field experience is required."},
"EDUC381":{"description":"In this course students will study concepts, theories, and research related to the nature and acquisition of second languages so as to construct learning environments that support culturally and linguistically diverse learners' literacy, language development and content-area achievement. Students will learn instructional strategies for teaching English Learners in content areas.  People's attitudes toward linguistic diversity worldwide will also be explored.  A portion of the course will focus on developing global competence in teacher candidates.  The course will cover a plethora of themes including: theories of second language acquisition, assessment and assessment bias, the four traditional language skills, primary language support, language and identity, world migration, bilingualism  and identity, bilingualism and program models, Ebonics and bidialectalism, code-switching, heritage language maintenance, global language policy, language shift, world Englishes, and global interconnectedness."},
"EDUC382":{"description":"Three semester hour course designed to integrate the fundamental aspects of differentiated instruction, integrated curriculum development, lesson planning, and concept assessment while teaching in an elementary (K-6) school setting. The course includes a field experience practicum in a local school."},
"EDUC383":{"description":"Three semester hours designed to integrate the fundamental aspects of differentiated instruction, curriculum development, lesson planning, and concept assessment while teaching in a departmentalized middle or secondary (5-12) school setting."},
"EDUC384":{"description":"Three semester hours of field practicum designed to support candidates' integration of differentiated instruction, curriculum development, lesson planning, and concept assessment while teaching in a departmentalized middle or secondary (5-12) school setting."},
"EDUC386":{"description":"This course is a comprehensive study of early childhood pedagogy, research and curriculum, with a focus on how children acquire, organize, and apply knowledge. Integrated methods and developmentally appropriate activities are emphasized. Reflecting and supporting diversity in addition to utilizing observation and assessment of learning will be stressed."},
"EDUC388":{"description":"This course prepares elementary teacher candidates with the knowledge, skills, and dispositions needed to teach the social studies curriculum in grades K-6, as defined by the Indiana Academic Standards and the Indiana licensure standards for Elementary Generalists."},
"EDUC392":{"description":"The materials, devices, and methods of teaching mathematics in the elementary school. Guided laboratory experiences in local schools may be required."},
"EDUC396":{"description":"This course in teaching science in grades 5-12 provides teacher candidates with the standards-based practices in science planning, teaching and assessing diverse learners.  The course content focuses on current Indiana and national standards with an emphasis on classroom management, teaching strategies, laboratory techniques, and educational technology appropriate for secondary science teaching."},
"EDUC398":{"description":"Examines issues related to scientifically-based reading research.  Reading strategies and assessments for teaching and evaluating phonemic awareness, phonics, fluency, comprehension, and vocabulary are taught and then utilized in a guided field experience."},
"EDUC408":{"description":"Emphasizes the knowledge and skills necessary for teaching reading to students with mild disabilities."},
"EDUC411":{"description":"This course will examine the legal foundations for the provision of special education services. This course will review contemporary legal findings prevalent in the field through class discussion of cases involving mediation, due process, and court decisions. Legal tenets that relate to differing opinions involving school personnel, state educational agencies, and parents will be thoroughly examined."},
"EDUC412":{"description":"This course explores the purpose(s) of assessment, selection of criteria, administration techniques, need for accuracy in scoring, determination of validity of interpretation, and application of data for instructional design and implementation. The development of assessment skills, awareness of legal and ethical issues, and collaboration will be emphasized."},
"EDUC413":{"description":"This course examines teaching methods appropriate for students with exceptional needs in the primary and intermediate categories. Emphasis is placed on all phases of the instructional process based on assessment of student needs, developmental plans, appropriate implementation and evaluation of effective instruction."},
"EDUC418":{"description":"This course examines teaching methods appropriate for students with exceptional needs in the Early Adolescent and Young Adult categories. Emphasis is placed on all phases of the instructional process based on assessment of student needs, development plans, appropriate implementation and evaluation of effective instruction."},
"EDUC419":{"description":"This course examines and analyzes the structure of the English language using encoding, decoding, syllable types, phonemics, spelling rules, and word roots. It focuses on the implementation of knowledge using a multisensory approach to integrated instruction at all grade levels to improve encoding, decoding, grammar and comprehension in language art skills."},
"EDUC421":{"description":"This practicum consists of field experience in the elementary school setting taught concurrently with EDUC 413."},
"EDUC422":{"description":"This practicum consists of field experience in the secondary school setting and taught concurrently with EDUC 418."},
"EDUC423":{"description":"This course defines, studies, and applies the skills necessary for teaching collaboratively. It presents a paradigm that adheres to the belief that close working relationships between teachers serving the same students are an absolute necessity. Emphasis will be placed on the need for close communication between professionals, the challenges of scheduling and instructional coordination, and interpersonal problem solving. This course will also examine student transition as it relates to placement in the elementary school, movement from the elementary setting to a middle school placement, movement from the middle school to a high school placement and from the school setting into community environments. It will promote skill development in working with students and their parents to encourage advocacy and self-determination."},
"EDUC424":{"description":"Teacher candidates in the final semester of their program are assigned to a full-time special education placement for the duration of the semester.  Candidates will student teach using USI's Co-Teaching model under the guidance of a classroom teacher and a university supervisor."},
"EDUC431":{"description":"Teacher candidates in the final semester of their program of their program are assigned to a full-time early childhood placement for the duration of the semester.  Candidates will student teach using USI's Co-Teaching model under the guidance of a classroom teacher and a university supervisor."},
"EDUC437":{"description":"This course examines how assessment data can be used to inform instructional decision-making and classroom management.  This course will provide candidates with the assessment tools necessary to enhance learning for diverse student populations in P-6 classrooms.  Teacher candidates will conduct evidence-based assessments of student learning, analyze data collected from a variety of assessments, communicate results with stakeholders, and use data to improve teaching and classroom management."},
"EDUC440":{"description":"The teaching of reading undergoes a variety of changes based on the social and political climate. This course offers the opportunity for students to explore trends and issues that are currently impacting the teaching of reading. Topics that may be explored: new instructional programs, state mandates in reading instruction, changing state and national standards, and phonics instruction."},
"EDUC455":{"description":"Emphasizes diagnostic teaching in which teachers assess reading during instruction. A model of diagnostic decision-making is presented that encourages the teacher of reading to view assessment and instruction as interacting and continuous processes. A variety of formal and informal assessment measures are studied for their assessment and instructional value."},
"EDUC458":{"description":"Capstone seminar open to all teaching majors and minors.  This course provides a synthesis of interdisciplinary knowledge and pedagogical practices.  Integrates knowledge gained from Core 39, subject area coursework, and professional education core.  The course examines current issues faced by teaching professionals in today's schools."},
"EDUC463":{"description":"This course, taken concurrently with student teaching, prepares secondary and all-grades candidates for Indiana licensure requirements. Candidates also learn how assessment data can be used to inform instructional decision-making, manage the classroom, and enhance learning for diverse student populations."},
"EDUC465":{"description":"Focuses on the integration of technology into the school curriculum and explores the use of technology to enhance the teaching and learning process. Students will examine issues related to the evaluation, selection, and implementation of technology resources including software, hardware, and peripherals, the emerging technologies and trends in technology education, and various pedagogical approaches. Emphasis is placed on the design and implementation of technology-based instruction for K-12 classrooms."},
"EDUC466":{"description":"Focuses on the systematic design and development of educational software and multimedia resources. Students plan instructional sequences, then apply computer programming and courseware design skills to the development of informational, instructional, and constructional resources. Internet, laserdiscs, CD-ROM, CD-audio, digitized audio, digitized still and motion video, and scanned images are incorporated into multimedia projects. Interdisciplinary multimedia thematic units also are developed."},
"EDUC467":{"description":"Focuses on the applications of Internet technology in teaching and education with emphases on resources, publication, and research. Topics include the examination of diverse Internet-based tool and information sources for teachers and learners. Students explore the Internet and create web publications applicable to professional development, student publication, school based network presence, and lesson development."},
"EDUC468":{"description":"Focuses on technical and management skills needed to coordinate the technology program in a school. Technical topics include advanced instructional development, general technical troubleshooting and maintenance; and assistive technology. Management topics include technology planning, implementation, and evaluation; staff development; facilities design; budgeting, and grant writing."},
"EDUC469":{"description":"Explores contemporary topics or innovative developments related to the integration of computers and other technology into educational settings. Credit hours available determined by faculty."},
"EDUC471":{"description":"Teacher candidates in the final semester of their program are assigned to a full-time elementary placement for the duration of the semester.  Candidates will student teach using USI's Co-Teaching model under the guidance of a classroom teacher and a university supervisor."},
"EDUC473":{"description":"Teacher candidates in the final semester of their program are assigned to a full-time placement for the duration of the semester.  Candidates will student teach using USI's Co-Teaching model under the guidance of a classroom teacher and a university supervisor."},
"EDUC480":{"description":"This course examines the representation of diverse populations in literature for children and adolescents. In the course, students have an opportunity to read a variety of multicultural books written for students in grades K-8, research authors who write and illustrate multicultural books, examine the multiple perspectives represented in books advertised as multicultural and discuss using the books in classrooms."},
"EDUC490":{"description":"Course is devoted at each offering to the study of a contemporary topic or innovative development in education. Credit hours available for each offering and course requirements are determined by the education faculty."},
"EDUC493":{"description":"Teaching techniques and materials for using reading and writing across the curriculum."},
"EDUC494":{"description":"An analysis of instructional programs in content areas with emphasis on both developmental reading and remedial procedures."},
"EDUC498":{"description":"A survey of current research in education. Designed to meet individual needs of students."},
"EDUC499":{"description":"A survey of current research in learning. Designed to meet individual needs of students"},
"ENG100":{"description":"A portfolio-based, preparatory course in reading, writing, reflection, and discussion, emphasizing rhetorical analysis and strategies for focusing, developing, and organizing writing. Special attention also is given to strategies for revising and editing writing. Course credits will apply as electives toward graduation."},
"ENG101":{"description":"A course in the critical arts of reading, writing, reflection, and discussion, with an introduction to rhetoric and informal logic."},
"ENG103":{"description":"This course, designed specifically for incoming freshmen with creative writing scholarships, teaches the fundamentals of poetry and fiction writing. Students will read and write fiction and poetry."},
"ENG105":{"description":"An introduction to literature emphasizing the ability to read critically. Topics vary."},
"ENG185":{"description":"An introductory examination of motion pictures as an art form. Students attend a series of selected films and discuss and evaluate them with respect to theme and technique. Critical principles are examined, and basic dramatic, literary, and photographic principles of cinema are investigated."},
"ENG201":{"description":"A second course in the critical arts of reading, writing, reflection, and discussion emphasizing the responsibilities of written inquiry and structured reasoning."},
"ENG205":{"description":"ENG 205 introduces students to the major literary genres (lyric poetry, drama, and prose fiction), to significant theoretical orientations (such as formalism, reader-response, Marxism, and feminism) that establish particular ways of reading any text, and to the range of disciplines included within the study of English (including linguistics, rhetoric, writing, and the teaching of English). This course is intended to help students become more critical and informed readers, more comfortable with the terminology used across the broad spectrum of English courses, and more able to marshal technical information and apply theoretical concepts in their literary interpretations."},
"ENG210":{"description":"The fundamentals of technical communication with emphasis on clear, precise, and orderly exposition."},
"ENG222":{"description":"A study of literary works from different countries and eras that reflect on the causes, nature, and consequences of good and evil. Special focus on development of ethical principles and decision-making."},
"ENG226":{"description":"Through the study of American Folklore in its various forms (legend, food, folk craft and art, superstition, regional dialects, etc.), students will come to a better understanding of how folklore is a part of our individual, cultural, and national identities and, as a result of this knowledge, students will be better able to understand other cultures."},
"ENG227":{"description":"Students will develop an understanding of the uses of creative expression in both the literary arts and popular culture, interpreting diverse works of art through critical reading, thinking, and writing.  Topics vary."},
"ENG231":{"description":"A course which examines the African American literary tradition, covering representative works from the African pre-colonial and modern periods, nineteenth century America, the Harlem Renaissance, to the present. Included are works by African and African American authors from the oral and written traditions of poetry, prose, and drama."},
"ENG255":{"description":"This course examines the historical development of British literature, from the Anglo-Saxon invasions that catalyzed the birth of a British national identity and brought Beowulf into the literature of England, through the contentious development of this national identity into a global power, to Britain's 20th century post-colonial status. By concentrating on representative or \"canonical\" works as well as those conventionally underrepresented, the course will provide students with the background necessary to understand British literary works in their social, political, and cultural contexts."},
"ENG265":{"description":"ENG 265 provides an overview of the main currents in the development of American literature, from its beginnings in pre-colonial times through its reliance on and then struggle against British and European themes and forms, to its 20th-century development into the many voices of an uneasily multi-ethnic world power. By historically situating representative works from within the canon as well as from voices traditionally excluded or under-represented, the course will provide students with the background necessary to understand literature as the product of and often as challenging this background."},
"ENG286":{"description":"A study of the mythologies and legends of the Near Eastern, Cretan, Greek, Roman, and Northern European civilizations."},
"ENG288":{"description":"This course exposes students to a variety of texts by and about women from diverse historical periods and cultures, emphasizing issues of race, class, gender, and sexuality.  Readers will discover the changing cultural norms that have defined women in different times and places.  The readings will challenge students to engage with various perspectives and think critically about cultural assumptions and viewpoints."},
"ENG299":{"description":"Selected works grouped together because they all deal with the same type (e.g., Science Fiction, Satire) author(s) (Hemingway and Fitzgerald, Virginia Woolf), or concept (Alienation in Literature, Psychology and Literature). Each time this course is offered the particular topic is announced in the class schedule. A student may take this course only once for credit as part of the English requirements, a student may take the course more than once for elective hours toward University requirements."},
"ENG301":{"description":"A flexible course in advanced composition emphasizing the analysis of various rhetorical situations and the cultivation of prose styles appropriate to these situations."},
"ENG302":{"description":"Inquiry into the creative process. Students will write poems, short fiction, and dramatic scenes with critical analysis and discussion by the instructor and class."},
"ENG303":{"description":"An intense study of poetry in which students examine poems of others and address in their own poems, issues of character, personality, image, nuance, tone, setting, and concision."},
"ENG304":{"description":"An intense study of fiction in which students examine fiction of others and write fiction of their own."},
"ENG305":{"description":"A course in which students will hone critical skills and polish their prose in the literary essay."},
"ENG307":{"description":"An intense study of screenwriting in which students examine screenplays of others and write a full-length screenplay of their own.  Use only once in ENG majors/minors; for graduation, may be taken three times."},
"ENG310":{"description":"A course for prospective secondary school teachers on issues related to teaching writing in grades 5-12, including writing as a process, assigning and evaluating student writing, portfolio assessment, and research as writing to learn. Students also will practice writing in a variety of modes. Required for Teacher Certification in English."},
"ENG314":{"description":"Survey of the main twentieth century and contemporary approaches to the grammar of the English language. The course will consider both descriptive approaches (such as transformational-generative and phrase-structure) and prescriptive approaches (considering grammar from the perspective of its social roles). Students should acquire a variety of ways to analyze English sentences, including those deemed \"non-standard.\""},
"ENG316":{"description":"A course aimed at developing critical thinking and investigative skill–the latter involving greater sophistication not only in secondary research but also in primary research (e.g., questionnaires, interviews, etc.). These skills are applicable whether the writing is for government agencies, non-profit organizations, research foundations, business, industry, or education."},
"ENG319":{"description":"This course studies the grant-writing process from the basic principles of grant seeker eligibility and donor research, proposal guidelines, required submission components, and follow-up procedures. The course will include the design and completion of an individual grant proposal as well as a narrowly-focused, collaborative mock proposal stressing the ability to submit a grant proposal to a private foundation, corporation, or government institution."},
"ENG322":{"description":"A chronological investigation of the development of the short story, emphasizing the short story in English but considering European, Asian, and Latin American traditions as well."},
"ENG330":{"description":"A survey of significant minority contributions to literature, particularly Black-American, but including Native-American, Asian-American, and Mexican-American."},
"ENG344":{"description":"Students will gain a substantial knowledge of adolescent and young adult literature necessary for the teaching of grades 5-12 in the secondary system."},
"ENG361":{"description":"An intense study of dramaturgy in which students will write dramas of their own."},
"ENG371":{"description":"Readings in the literature and literary theory of the non-Western World. This course engages in detailed analysis of key texts written in English or English translation. Readings may be selected from the literatures of Asia, Africa, the Caribbean, and the Middle East."},
"ENG372":{"description":"Readings in the literature and literary theory of the Western World, excluding Great Britain and the United States. This course engages in detailed analysis of key texts in English translation. Readings may be selected from the literatures of the European Continent, Western Hemisphere, and Post-colonial contexts."},
"ENG373":{"description":"Through the study of a variety of genres this course offers a detailed introduction to the literature of the different countries and peoples that make up the vast continent of Africa.  We will read texts written in English or translated from French, Portuguese, Arabic and African languages. Literary theory, film and history will strengthen and support our understanding of the fiction we read."},
"ENG375":{"description":"A study of major trends and authors in modern Canadian prose fiction from about 1930 to the present, with some consideration of cultural backgrounds."},
"ENG381":{"description":"An introduction to Medieval World Literature in translation, including works from Europe, the Middle East, and Asia from the sixth century CE to 1400 CE. Students will read major works by Boethius, Dante, Christine de Pizan, Petrarch, Abelard and Heloise, Chretien de Troyes, Farid Ud-Din Attar, Kenko, Sei Shonagon, and Ou-Yang Hsui, as well as anonymous Irish and Anglo-Saxon texts."},
"ENG382":{"description":"Literary study of selected writings of the Hebrew Bible, the Apocrypha, and the New Testament, in English translation."},
"ENG386":{"description":"An examination of the major cultural mythologies from around the world for cross-cultural comparisons and contrasts."},
"ENG387":{"description":"A study of significant 19th- and 20th-century works of literature and literary theory by or about women, with special emphasis on the implications of gender for art and culture."},
"ENG399":{"description":"This course is a revolving-content course on the artful use of specific techniques in creative writing through reading, writing, and workshop. Students should have workshop (ENG 303, ENG 304, or ENG 305) experience in the genre being studied, or permission of the instructor."},
"ENG402":{"description":"An advanced course designed for students committed to writing professional poetry, or fiction. Students will be admitted by consent of the instructor and will contract with the instructor to concentrate in one of the above areas."},
"ENG411":{"description":"A course in the kinds of writing made possible by computer-based composing with attention to the impact of technology and computers upon the writer's process and product. The course will cover word processing, document design, desktop publishing, hypertext, email, Internet information access, and multimedia presentation."},
"ENG412":{"description":"Study of practical ways to write the specialized documentation needed for today's industry, science, research, and technical management."},
"ENG413":{"description":"A course that examines the historical development of rhetoric and writing studies, paying special attention to current theory and pedagogy. Course includes reading in major movements from pre-process to post-process; modes of inquiry such as theoretical, historical, and empirical; and areas of special importance to today's teachers and scholars, including feminist rhetoric, culture studies, and social justice."},
"ENG414":{"description":"Intercultural Technical Writing will provide students with the ability to discern and conceptualize the codes, conventions, and discourse structures of differing cultures, thus enabling students to both localize and internationalize technical and professional documents. This course emphasizes that recognizing and analyzing cultural diversity is essential for successful communication within the scientific, technical, and business fields."},
"ENG415":{"description":"An understanding and comparison of various movements in the history of rhetoric, with particular emphasis on the relationship between rhetorical strategy and one's image of human beings and on historical events which influenced rhetoric. The course aims to increase the scope of students' understanding of rhetoric and help them apply this knowledge to their own communication and to their evaluation of the communications of others."},
"ENG416":{"description":"An intensive exploration of the 20th century's re-valuing of rhetoric as an interdisciplinary theory of language and meaning. This course complements ENG 415 - History of Rhetoric."},
"ENG417":{"description":"A chronological study of the development of English, including consideration of the recorded history of the English language beginning in the ninth century as well as the influence of non-English languages on English. The course will cover aspects of phonology, morphology, semantics, and syntax. Students will analyze the development of dialects (including Standard Edited English) in the United States as well as apply theories of linguistics (sociolinguistics and applied linguistics, for example) in the study of the history of American English."},
"ENG419":{"description":"This course applies the fundamentals and theory of grant writing to a client-based project with non-profit or not-for-profit local organizations in a service-learning environment. Students will work with the client to locate sources of funding, prepare the grant proposal, and submit the grant application to the appropriate agency. Classroom instruction will include rhetorical analysis of the grant-writing situation broadly allowing them to apply principles of effective grant writing to a specific client and grant situation. This course also emphasizes the effective management of collaborative writing and client-based projects."},
"ENG424":{"description":"A study of the major works of Geoffrey Chaucer, particularly The Canterbury Tales, with attention to the language and times of Chaucer. Includes lecture and discussion."},
"ENG425":{"description":"John Milton's prose and poetry constitute essential parts of the English tradition in literature, politics, and religion. This course focuses on Milton's prose writings—e.g., Areopagitica and The Tenure of Kings and Magistrates—which have profoundly influenced both the English and American politics and on his lyric, epic, and dramatic poems, including close study of Paradise Lost and Paradise Regained."},
"ENG426":{"description":"Non-Shakespearean drama, poetry, and prose of the Renaissance.  Emphasis on major figures such as Spenser, Donne, and Milton."},
"ENG427":{"description":"From Dryden to the pre-Romantics. Emphasizes poetry and changing social thought in England and America, with continental backgrounds."},
"ENG431":{"description":"The writing of the English Romantics and Victorians and of American writers of the period, studied against the background of nineteenth-century continental writers."},
"ENG432":{"description":"A study of representative twentieth-century poets."},
"ENG435":{"description":"A study of prose fiction to 1900 including the origins of the novel in the seventeenth century, the Enlightenment and Romantic Era, and the great Victorian, American, European, and Russian writers of the nineteenth century in English or in English translation."},
"ENG436":{"description":"A study of prose fiction after 1900, including the great Modernist writers of England and Europe along with great post-Modernist practitioners from throughout the world including Latin America, the Middle East, and Australia in English or in English translation."},
"ENG437":{"description":"A study of prose fiction of the recent past by practitioners from throughout the world in English or English translation."},
"ENG442":{"description":"This course covers a broad range of medieval literary forms including drama, romance, lyrics and ballads, letters, treatises, and devotional literatures of the Middle Ages."},
"ENG444":{"description":"A course on issues related to the teaching of literature in grades 5 through 12, including censorship, literary theory, adolescent literature, multicultural literature, the ethics of reading, and the connections between reading and the other language arts: writing, speaking, and listening."},
"ENG448":{"description":"A study of literary criticism and aesthetic theory. Consists of a survey of literary theories, and an introduction to current critical approaches to literature."},
"ENG449":{"description":"An intensive study of the works of a major author in fiction, poetry, drama, or nonfiction. Course may be repeated for credit as long as the student has not previously studied the author in an ENG 449."},
"ENG451":{"description":"An intensive study of selected histories and tragedies written by William Shakespeare."},
"ENG453":{"description":"An intensive study of selected comedies and romances by William Shakespeare."},
"ENG459":{"description":"English studies in conjunction with special activities and events, such as seminars, conferences, field work, travel projects, and research. Semester hours of credit awarded for each offering will be determined by the instructor and the department chairperson."},
"ENG473":{"description":"Survey of representative plays from the Classics through the English Renaissance as a basis for theatrical production and dramatic criticism."},
"ENG474":{"description":"A broad study of dramatic literature from 1600 to the modern period as a basis for establishing a world view through theatrical and dramatic criticism."},
"ENG480":{"description":"Individualized study under the direction of a faculty member, leading to the production of a substantial written product. May be used to meet a requirement for the English major or minor with prior approval of the department chairperson."},
"ENG490":{"description":"A course that enables a student to gain practical experience in teaching English on a one-to-one basis while staffing the University's Writing Workshop. Tuition waiver possible. Hours do not apply to Core 39 or the English major. Students attend a weekly seminar of approximately one-and-one-half hours and tutor in the workshop."},
"ENG491":{"description":"An advanced seminar for students in the Creative Writing Emphasis, involving all aspects of preparing a manuscript through actual publication. Students will complete a project demonstrating such professional competence."},
"ENG498":{"description":"A course granting credit for work experiences complementing the English major. The course provides for a minimum of 150 hours of supervised professional work, two written evaluations by the supervisor, an on-site visit by a University representative, maintenance of a log or portfolio, and periodic interviews with a department faculty member. Open to juniors or seniors."},
"ENG499":{"description":"An advanced topics course for majors, minors, and other qualified students."},
"ENGR101":{"description":"Presentation and discussion of current professional engineering topics by faculty and guest lecturers. Student club meetings also are held during this class period. Required of all new full-time engineering, manufacturing engineering technology, and industrial supervision students."},
"ENGR103":{"description":"Problem solving method in geometry, trigonometry, force vectors, energy, equation of linear, exponential, and power data, unit conversions, laboratory and report."},
"ENGR104":{"description":"Problem solving using computers, flowcharts, and computer programming."},
"ENGR107":{"description":"This course introduces students to a systematic, engineering problem-solving method. Problems have been selected to preview many of the engineering courses that the students will take as sophomores, including statics and electrical circuits. A large portion of this course is dedicated to formulating problems for computer solutions using methods such as flowcharting that can be applied to any programming language. Additionally, students learn computer programming using VISUAL BASIC and MATLAB."},
"ENGR108":{"description":"This course introduces students to systems engineering and design.  This course uses team-oriented projects to teach students the design process and technical communication.  Students are expected to communicate their designs through written technical reports, oral presentations, and design reviews.  This course includes a laboratory component to allow students in-class time for project work."},
"ENGR121":{"description":"Principles of engineering modeling and documentation in a computer environment.  Specific topics to be covered include visualization, geometric modeling of objects in a computer-aided design environment, and application of documentation standards and conventional engineering representations.  Emphasis is placed on freehand sketching as a means of communication and on the accurate generation of computer models and documentation files.  Recommended for Engineering majors with a Civil, Electrical, or Industrial Engineering emphasis or undecided."},
"ENGR122":{"description":"Geometric Dimensioning and Tolerancing (GD&amp;T) and its application to Computer Aided Design (CAD). Based on ASME Y14.5-2009 design language for uniform practices for stating and interpreting GD&amp;T on engineering drawings. Recommended for majors in Mechanical, Manufacturing Engineering, or Mechatronics Engineering emphasis."},
"ENGR127":{"description":"This course will introduce students to the basic use of Mathematica. This includes plotting, lists, functions, solving systems of equations, matrix algebra and more."},
"ENGR202":{"description":"This course introduces students to topics in engineering.  Students will be exposed to engineering methods, application, design and analysis through projects.  These projects are selected to introduce students to underlying scientific principles typically used in engineering.  Projects will encourage students to explore connections between science and mathematics."},
"ENGR235":{"description":"Fundamentals of engineering mechanics including forces acting on bodies at rest as they apply to equilibrium of coplanar force systems, analysis of frames and trusses, non-coplanar force systems, friction, centroids, and moments of inertia. Emphasis is placed on drawing a free-body diagram, determining the equilibrium equations, and developing a logical scheme for complete analysis of an engineering statics problem."},
"ENGR237":{"description":"This course covers the use of computer methods to solve engineering problems, primarily through MATLAB.  It is intended to expand on concepts from freshman problem solving courses and enhance student preparation for foundational engineering courses and electives.  Topics will include vector and matrix methods, sequential programming, numerical integration, and finite difference methods among others."},
"ENGR238":{"description":"This course covers the fundamentals of programming in VBA with an emphasis on interface with Microsoft Excel.\nCourse topics include common coding conventions, recording macros, data types, structures and arrays, function definition, control objects, and graphic user interfaces."},
"ENGR265":{"description":"ENGR 265 will include a survey of energy systems, energy production, and efficiencies of the various systems that are currently in use and/or are under development.  It will also cover topics of energy usage, such as building systems and sustainable building design.  The production, transportation and consumption of energy are some of the more important topics facing the human race, because energy touches on every facet of human existence.  This course will prepare engineering students to contribute to the complex societal nature of energy production, usage, and conservation in a quantitative way."},
"ENGR275":{"description":"Kinematics and kinetics of particles and rigid bodies using vector analysis. Topics include: force, mass, acceleration, work and energy, impulse, and momentum."},
"ENGR277":{"description":"This course is designed to introduce students to the diverse areas of biomedical engineering.  It will be taught from an interdisciplinary perspective to create synergies between students majoring in engineering and those majoring in the sciences.  Students will use their respective knowledge collectively to research scholarly articles, understand how biomedical engineering is implemented into commercial capabilities, and present their findings.  Students will be immersed into areas of biomedical engineering such as biomechanics, bioelectricity, bioinstrumentation, biological engineering, and tissue engineering.  Students will also learn how many other facets of engineering (mechanical, chemical, electrical, material, and computer) are integrated into the field in order to solve tough biomedical problems."},
"ENGR290":{"description":"Participation in an end-to-end engineering project as part of a team to prepare, launch, track, and recover a high-altitude helium-filled weather balloon which will carry a number of devices and experiments. Each student will be expected to contribute to at least one device or experiment, and to participate in other aspects of the pre-flight and post-flight experience. Oral presentations will be required pre-flight and post-flight."},
"ENGR291":{"description":"This is a 2-credit hour (1 hour lecture + 2 hours laboratory per week) course designed to improve your skills as an experimenter.  You will gain proficiency in designing and executing an experiment and then analyzing and presenting the results (both in written and oral form).  Writing technical reports is a significant component of this course, as is technical reading related to lifelong learning and ethical responsibility."},
"ENGR298":{"description":"This course will allow the engineering student to work with high school students in project Lead the Way. Concepts involved will be team leadership, teaching and mentoring. A report of activities will be required at the end of the semester."},
"ENGR299":{"description":"Approved investigation undertaken coincident with the co-op assignment. Evaluation based on a formal report submitted by the student."},
"ENGR305":{"description":"Calculus-based examination of descriptive and inferential statistics. Topics covered include population and sample data analysis, discrete random variables, continuous random variables, frequency distributions, probability, hypothesis testing, analysis of variance, regression, and correlation. Computer applications for problem solution are required."},
"ENGR324":{"description":"A study of aggregates, concrete, steel, wood, and asphaltic materials, including concrete mix design. Additional subject matter covered will be characteristics, capabilities, and operating costs of equipment; estimation of construction costs; field inspection practices and responsibilities."},
"ENGR335":{"description":"This course is designed to provide information on the scope and application of various numerical techniques and evaluation criteria for making economic decisions. Interest rates, cash flows, depreciation, and tax implications will be covered. Methods such as present worth, annual worth, future worth, and rate- of- return will be used to make comparisons between alternatives."},
"ENGR353":{"description":"This course focuses on the development of analytical skills required to examine the feasibility of a product innovation or business concept by conducting product, market, organizational, and financial research.  Through prototype development, customer interaction, and secondary research, innovative ideas are first analyzed with regard to technical feasibility and commercializability.  This information is then used to examine organizational and financial feasibility (i.e., profit potential), which provides the details of how the idea can be taken to market successfully."},
"ENGR355":{"description":"A study of stress-strain relationship for axial, torsion, shearing, and bending loads; deflection of beams; connections; combined loadings; statically indeterminate members; and plane stress. The laboratory experience will include material testing to determine physical and mechanical properties that will reinforce the principles studied."},
"ENGR362":{"description":"This course is a survey of traditional and non-traditional manufacturing processes including machining, welding, fabrication, plastics processing, assembly, and shop safety.  Laboratory exercises will allow students to learn to program and operate robots, CNC machines, and other automation technologies."},
"ENGR373":{"description":"An introductory course in optics covering wave propagation, interaction of electromagnetic radiation with matter, geometrical optics, polarization, interference, and diffraction. Supplementary topics from modern optics such as lasers, detectors, fiber optics, optical communications, imaging, and storage also included."},
"ENGR375":{"description":"Fundamentals of fluid mechanics including application of Bernoulli's equation for incompressible flow, hydrostatic forces on gates, dynamics of fluid flow, friction loss and drag, sizing of pipes and pumps, and turbo machinery. The laboratory portion requires students to design experiments to evaluate specific fluid principles and concepts with subsequent completion to reinforce the understanding of the material."},
"ENGR382":{"description":"This course covers the analysis and design of modern industrial control systems. Continuous control concepts such as loop stability and error minimization are examined through the study of proportional, integral, derivative, and advanced control algorithms and process loop tuning methods. Discrete control concepts are explored through the application of programmable logic controller situations. PC-based data acquisition and instrumentation systems also are developed."},
"ENGR390":{"description":"An independent study course requiring the student to complete an assigned design project under the guidance of a faculty member. The project will require application of various analytical methods and the use of computer models or laboratory facilities. A written report is required upon completion of the design project."},
"ENGR417":{"description":"This course provides students with a broad overview of the techniques of project management from an engineering perspective. Topics will include a structured approach to project management, project life cycle, project selection and evaluation, organizational concepts in project management, project planning, conflict and negotiation, budgeting and cost estimation, scheduling, resource allocation, monitoring, project control and project termination. The objective is to gain insight into organizational issues and learn quantitative methods that are necessary for successful project management. The course has a strong emphasis on team work and student project presentations. Special purpose software such as Microsoft Project will be utilized."},
"ENGR436":{"description":"This course will include topics such as multiple regression, regressor significance tests, variable selection methods model adequacy and complications caused by multicollinearity. Additional topics will be Analysis of Variance (ANOVA), statistical quality control, and nonparametric statistical methods."},
"ENGR471":{"description":"Application of the professional method to the formulation and design solution for real-world, industry-type problems. Student teams will utilize their knowledge of engineering principles as well as social and economic issues to develop, analyze, and evaluate proposed designs using experimental, computer, and numerical techniques."},
"ENGR477":{"description":"This course is aimed as a precursor to a biomedical related senior design course.  Students will be introduced to engineering design with respect to biomedical applications.  Students will be immersed into the medical field visiting hospitals, clinics, or other medically relevant facilities to engage with doctors, physicians, and healthcare professions to understand the problems they face in their selected fields.  Following the immersion into the medical field; students will determine a need, assess that need, and develop an idea from concept to prototype. The second half of the semester, the students will work toward design requirements regarding their senior design capstone while garnering professional development and ethical considerations faced by engineers in the healthcare industry."},
"ENGR482":{"description":"An examination of the fundamental concepts of management in engineering organizations with emphasis on the relationships among types of engineering work, type of organizational structures, and managerial responsibilities. Includes study of motivation, time management, oral and written communications, engineering ethics, and lifelong learning."},
"ENGR491":{"description":"A course which provides an opportunity for synthesis of technical, professional, and general knowledge for engineering students. Design problems provided by industrial sponsors are studied by small teams of students to develop solutions using engineering design, while considering realistic constraints such as economic factors, safety, reliability, aesthetics, ethics, and social impact. Formal written and oral reports to faculty, industrial sponsors, and invited guests are required."},
"ENGR499":{"description":"Independent study or research into selected Engineering topics conducted under the guidance of faculty mentors."},
"EXSC225":{"description":"This course is designed to provide students with the basic fundamentals of strength training exercise technique. Students will examine both free weight and machine modalities and will be instructed on how to identify and categorize exercises. Emphasis will be placed on the students' ability to teach and demonstrate basic strength training exercises to a novice client/athlete and provide corrective feedback on performance. Students will be exposed to the components of a training session and the manipulation of program variables that dictate the specific adaptations made to the training regimen. Students will also be instructed on weight room etiquette, safety protocols, and spotting procedures to ensure safe and effective practices."},
"EXSC283":{"description":"Provides students with practical experiences in exercise science related fields. Students will develop a resume, cover letter, reference page, portfolio, and learn about networking and interviewing in an exercise science related field.  Students have the opportunity to observe professionals at work and learn about specific skills in personal training, occupational therapy, physical therapy, strength &amp; conditioning, and other exercise related fields."},
"EXSC287":{"description":"This course is designed to explore a variety of personal training related technology. Students will learn a variety of skills related to the design and execution of fitness assessment technology. Students will also learn computer software skills in the health and fitness fields for use of marketing purposes."},
"EXSC376":{"description":"This course introduces students to behavioral strategies of exercise by providing a broad overview of the major topics in the area. The major course content includes examining and applying theoretical models related to exercise science."},
"EXSC381":{"description":"The basic fundamentals used by the athletic trainer. Major emphasis on recognition, initial care, and prevention of athletic injuries."},
"EXSC383":{"description":"The study of mechanics of human body movements primarily as it relates to the interrelationships of bones, joints, and muscles."},
"EXSC390":{"description":"A study of selected topics of current interest and importance in exercise science.  These topics may include opportunities for students to complete research, participate in experiential learning experiences, and a wide variety of learning activities in accordance with the interests and importance of kinesiology and sport."},
"EXSC391":{"description":"An analysis of movement tasks and their relationship to each other. To apply the kinematic (velocity and acceleration) and kinetic (force) concepts for the mechanical analysis of human movement."},
"EXSC392":{"description":"An analysis of movement tasks and their relationship to each other. An understanding of the processes involved in acquiring and refining motor skills including knowledge of the influence of neural and behavioral aspects of learning motor skills.To apply the kinematic (velocity and acceleration) and kinetic (force) concepts for the mechanical analysis of human movement. Students will apply those principles for better understanding of: (a) developmental change; (b) postural control; (c) prehension; (d) neuromuscular power; (e) resistance training; (f) movement efficiency and economy; (g) locomotion; (h) movement skill acquisition; (i) effects of fatigue;(j) effects of injury; (k)retention and (l) transfer of learning."},
"EXSC394":{"description":"This course describes the development and implementation of physical tests in exercise science. Included in the course are fundamental knowledge and techniques for data collection, analysis, and student assessment through the use of current, basic testing and evaluation methods. Each student will participate as a tester in the department's annual fitness assessment."},
"EXSC396":{"description":"This course introduces students to the management and operations involved with programs and facilities in the health fitness field. The course content will include information on human resources, member recruitment, retention, and profitability, operations and facility management, and evaluations and future trends."},
"EXSC397":{"description":"This course is designed to provide students with a comprehensive introduction to the acute and chronic physiological responses and adaptations to physical activity and exercise training, as well as the effects of various factors and conditions on physical performance. Physiological principles are introduced pertaining to appropriate training programs for maximizing physical performance and promoting lifetime physical fitness and wellness."},
"EXSC475":{"description":"This course will cover the techniques utilized in the field of strength and conditioning. This will include: resistance training, speed, plyometric, flexibility, and core/balance exercises and drills. Exercise choice and progression for each area will be covered."},
"EXSC476":{"description":"This comprehensive course will cover the scientific components essential to employment in a strength and conditioning position.  To include the application of exercise science, program design, and organization and administration."},
"EXSC477":{"description":"This course will cover the testing and evaluation of athletes. This will include appropriate protocol selection, administration, interpretation of laboratory and field-based measurements used commonly when testing various athletic populations."},
"EXSC478":{"description":"This certification review course will be a review and further application of the concepts required to be certified by the National Strength and Conditioning Association as a Certified Strength and Conditioning Specialist. The exam content is focused on four areas: exercise technique, program design, organization and administration, and testing and evaluation."},
"EXSC484":{"description":"A study and application of the principles, techniques, and tools used to assess levels of wellness, fitness, prescribe lifestyle modifications, and evaluate individual progress toward goals."},
"EXSC486":{"description":"This course provides the students with fundamental knowledge and skills related to cardiac rehabilitation with concentration on the design, implementation, and administration of the exercise component of cardiac rehabilitation programs."},
"EXSC487":{"description":"This course will focus on the development of sport-specific training plans to optimize athletic performance and reduce the risk of injury.  Students will demonstrate the ability to perform a sport-specific needs analysis and manipulate the program variables of resistance, power, anaerobic capacity, aerobic, agility, speed, and balance training to enhance athletic performance.  Students will integrate the program variables into a periodized program design that focuses on stimulating specific adaptations while balancing the need for proper rest and recovery to prevent overtraining.  Students will administer and participate in the components of a training session, assessments utilized to measure athletic fitness components, and will review the technique of basic strength and power exercises."},
"EXSC488":{"description":"This course provides exercise recommendations for both healthy adults and those living with a wide range of diseases and disabilities.  Students will utilize the American College of Sports Medicine's risk stratification model to classify an individual's risk for a cardiovascular-related event during the performance of exercise.  This course will employ the Exercise is Medicine Model as it relates to the positive impact exercise can have on the prevention, management, and reduction of disease.  Students will utilize the American College of Sports Medicine's recommendations and modifications for the assessment and exercise prescription of a wide range of populations."},
"EXSC495":{"description":"This certification review course is intended to provide students with a review of: the structure and function of body systems; kinesiology terminology and concepts; the initial client consultation and risk classification; exercise program design guidelines and variables; protocols for building aerobic, resistance, and flexibility programs; basic nutrition concepts, exercise program modifications for special populations; the scope of practice of the certified personal trainer including legal and ethical considerations; and building a clientele through sound business practices and marketing. The course is designed to prepare students to sit for the American College of Sports Medicine (ACSM) Certified Personal Trainer (CPT) Exam."},
"EXSC496":{"description":"Provides students with practical experience in strength and conditioning. Students have the opportunity to assist athletic teams within intercollegiate/interscholastic departments and community facilities and/or organizations."},
"EXSC497":{"description":"Provides students with 150 hours (3 credits) of practical experience in strength and conditioning. Students have the opportunity to assist athletic teams within intercollegiate/interscholastic departments and community facilities and/or organizations. Students have the option of completing 200 hours (4 credits); 250 hours (5 credits); and 300 hours (6 credits) for additional experience."},
"FIN101":{"description":"This course is a one hour reality-based personal finance course based on a 34-module curriculum. Major content areas include income, expenses, assets, liabilities, and risk management."},
"FIN201":{"description":"This course is designed to serve as an introduction to the field of business and personal finance. The course is geared to the specific interests of the associate degree candidates and non-business majors. Emphasis upon the source and use of funds in the area of finance. (Not open to College of Business majors in baccalaureate programs.)"},
"FIN208":{"description":"The various business problems which arise in the life of the individual. Includes a study of such topics as money and credit, banking services, insurance, taxes, home ownership, and consumer protective agencies."},
"FIN305":{"description":"The methods utilized by corporations in securing and utilizing capital. Major topics of interest include capital budgeting, cost of capital, capital structure, and characteristics of alternative forms of capital."},
"FIN325":{"description":"The objective of this course is to introduce students to the current practices in financial modeling and data analysis. Students will learn how to manipulate financial data and how to perform analysis using Excel as a primary financial analysis tool. This course provides skills to apply the theories, concepts, and tools to develop spreadsheet-based solutions that are used for effective financial analysis and decision making."},
"FIN335":{"description":"A course designed to provide critical financial knowledge and skills to those students who are interested in starting and running their own businesses. The course deals with such topics as fund-raising, cash management, budgeting, risk management, initial public offerings, and factoring."},
"FIN341":{"description":"An introduction to the basic principles of, and the relationship between, risk and insurance. Emphasis is placed on the fundamentals of insurance as the major formal method for treating non-speculative risks."},
"FIN343":{"description":"The study of foreign exchange markets, exchange rate determination, balance of payments, international flow and management of financial assets and various risk exposures, and the operation and effectiveness of economic policy in an open economy."},
"FIN345":{"description":"Principles of real estate. Emphasis is on location factors, market analysis, and basic problems in buying and selling real estate."},
"FIN361":{"description":"The institutional structure of the financial systems. Surveys both the public and private sectors of our money and credit economy. Emphasis is placed on understanding the impact of the commercial banking system, financial intermediaries, and public finance needs upon the conduct of business finance."},
"FIN433":{"description":"The essential qualities of good investments, the exchanges, and methods of trading stocks and bonds."},
"FIN461":{"description":"A comprehensive course that gives consideration to developing sound financial policies in the firm. The course will use cases to develop concepts in such areas as capital budgeting and capital structure. Class discussion and lectures are designed to introduce decision-making techniques relevant to financial management problem-solving."},
"FIN481":{"description":"Investigation of significant topics in practice and theory in the areas of corporate financial management, money markets, investments, commercial banking, and financial institutions."},
"FREN101":{"description":"Fundamentals of French; no previous French required. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the French-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"FREN102":{"description":"Fundamentals of French; continuation of FREN 101. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the French-speaking countries. In addition to the regular class meeting, students are required to complete one hour per week in the language lab."},
"FREN203":{"description":"Continuation of FREN 102. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the French-speaking countries. In addition to the regular class meetings, students are required to complete one hour per week in the language lab."},
"FREN204":{"description":"Continuation of FREN 203. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the French-speaking countries. In addition to the regular class meetings, students are required to complete one hour per week in the language lab."},
"FREN205":{"description":"Intensive oral practice of French on the intermediate level. Topics include French popular culture, current events, work, and travel."},
"FREN275":{"description":"Weekly showings and discussions of French films. Analysis of thematic and artistic diversity from the early 20th century to present. (Course taught in English)"},
"FREN306":{"description":"Continuation of FREN 203. Emphasis on the four language skills of listening, speaking, reading and writing, in the cultural context of the French-speaking countries. In addition to the regular class meetings, students are required to complete one hour per week in the language lab."},
"FREN307":{"description":"Continuation of FREN 306. Emphasis on the four language skills of listening, speaking, reading and writing, in the cultural context of the French-speaking countries. In addition to the regular class meetings, students are required to complete one hour per week in the language lab."},
"FREN308":{"description":"Study of Francophone cultures, emphasizing developments in language, literature, philosophy, the arts, politics, traditions, and institutions from the beginnings to the present. (Course taught in English)"},
"FREN325":{"description":"Critical readings and literary analysis of selected French texts from the middle ages to the present in the major literary genres."},
"FREN335":{"description":"Study of the working knowledge of the target language, cultural behaviors and values useful in various professional fields. Course may include a service learning component."},
"FREN344":{"description":"Study and analysis of selected Francophone literary masterworks in English translation."},
"FREN415":{"description":"Advanced study of French civilization, emphasizing development in language, literature, philosophy, the arts, politics, and institutions from the early stages to the present."},
"FREN416":{"description":"An examination of the major literary movements from French Classicism to the Enlightenment through the critical reading of representative texts."},
"FREN417":{"description":"An analysis of representative texts from Romanticism, Realism, Naturalism, Existentialism, and the Absurd."},
"FREN460":{"description":"An opportunity for job-related experience in the area of language studies."},
"FREN490":{"description":"Specialized topics in the field of French language study, teacher preparation, culture and literature."},
"FREN499":{"description":"Research or advanced study in a specific area of French literature, culture, education, or language, culminating in an analytical paper or comprehensive exam."},
"GENS097":{"description":"This non-credit course begins with a review of fractions, then moves quickly to the language of algebra including order of operations, exponents, positive and negative numbers, linear equations, and graphing. A computer component called MyMathLab is used as a supplement in this course. Credit in this course will not apply to a degree. Scientific calculators are allowed. Students whose math placement scores meet the University's established readiness level for Gens 097 may enroll. Students whose math scores are below the University's established readiness level for this course may enroll only with the permission of the Academic Skills Math Specialist."},
"GENS098":{"description":"This non-credit course provides students opportunities to write and revise college writing assignments. Students will engage in the writing process by practicing strategies for inventing, focusing, organizing, developing, and revising their work, as well as by learning editing strategies for standard grammar and usage. Emphasis will be placed on the early stages of the writing process (generating ideas and planning) and on considering the audience for and purpose of writing. Credit in this course will not apply to a degree. Students whose high school GPA and SAT or ACT scores meet the University's established readiness level for GENS 098 may enroll in this course."},
"GENS099":{"description":"This non-credit course introduces students to specific reading skills for improving literal and critical reading comprehension in preparation for the demands of college-level reading. In addition, an emphasis will be placed on vocabulary development through knowledge and skill of context clues, word awareness strategies, and through various readings. Credit in this course will not apply to a degree. Students whose SAT Critical Reading or ACT Reading scores meet the University's established level of readiness for GENS 099 may enroll in this course. Students should not enroll in a reading-intensive class while taking this course. Students who begin their reading placement in GENS 099 will be required to enroll in the subsequent reading course, GENS 151, in order to be prepared for college- level reading."},
"GENS105":{"description":"This course helps incoming freshmen, current students in need of better study skills, and adults reentering or contemplating re-entry to formal education assess their current approach to studying. It offers suggestions and practice to improve study methods. The course topics include time management, note taking from lectures and textbooks, exam preparation, listening skills, memory, concentration, test anxiety, and organization. Course is open to all students for elective credit."},
"GENS111":{"description":"Confusion about career planning occurs among undergraduates as well as among individuals who have entered the working world. This course benefits any student who is undecided about a career choice. The course is designed to increase self-awareness, familiarize participants with informational resources available to help in career planning, and provide individualized assistance with career decision-making. Course is open to all students for elective credit."},
"GENS151":{"description":"In order to be successful in college, students must be able to read, comprehend, synthesize, and process large amounts of information. This course emphasizes the before, during, and after active reading strategies necessary for learning information across the academic disciplines. In addition, students will be encouraged to become self-regulated learners and will demonstrate their understanding of the strategies through practice and application on college-level textbook reading. Students should enroll in a reading intensive course (i.e. history, sociology, political science) as a companion for applying the reading strategies. This three-credit hour course counts as elective credit toward graduation."},
"GENS199":{"description":"This course focuses on an intensive study of specialized topics in study strategies such as advancing vocabulary, speed reading, test taking, and strengths finder. Course is open to all students and repeatable with change in course content."},
"GENS399":{"description":"Students who participate in a University sponsored internship or cooperative education rotation are required to register for one hour of academic credit for each semester of participation. This repeatable course is an alternative for students who elect not to enroll in their departmental internship course. The credit will apply to elective hours outside the academic major. Consent of the instructor is required."},
"GENS401":{"description":"A lecture and discussion class emphasizing identification of goals and processes of professional development through career readiness. Course subject matter will include topics as resume development, internship and job search strategy, interview preparation, communication in the workplace, social media and your career, networking and first year on the job success."},
"GEOG112":{"description":"The study of the whole Earth as a system of many interacting parts including the solar system; the Earth's internal systems and landforms; ocean, atmospheric, and climatic systems; and global ecosystems. Applies scientific method to the study of changes within and between these systems."},
"GEOG123":{"description":"An introduction to the mountains, plateaus, plains, and volcanic fields across the United States, the components that form landscape, the processes that control landscape development, the reasoning and methods used to interpret landscape evolution, the impact of climate change, landscape change, and sea level change on human beings, and the landscape history of certain areas of the United States such as the Rocky Mountains and Appalachian Mountains."},
"GEOG214":{"description":"Lecture and laboratory studies designed to give an understanding of the physical processes responsible for daily weather."},
"GEOG215":{"description":"Course covers classification and distribution of the world's climates; factors that control climate; changing climates, and predictions for the future."},
"GEOG330":{"description":"An interpretation of human activities in selected world regions. Designed to assist students in acquiring certain basic ideas and supporting facts about contemporary world geography. The course surveys the importance, geographical characteristics, and basic problems of the major regions of the world."},
"GEOL101":{"description":"Scientific study of the history of life on earth with emphasis on higher vertebrate groups such as reptiles and mammals. Discussion of current theories of dinosaur habits and mass extinction."},
"GEOL108":{"description":"This course is an introductory survey of Earth-Space science, from geologic materials and processes, through oceanography and meteorology, to planetary science.  Lecture is interspersed with hands-on activities intended to reinforce concepts and to provide the students with ideas for teaching their own classes.  This course is for teacher candidates only."},
"GEOL115":{"description":"A study of the geography and geology of North American landscapes with special reference to the United States. Factors in landscape development, including climate, vegetation, erosion, glaciation, tectonics, and human influences, are explored in the context of specific regions such as the Rocky Mountains, Appalachian Mountains, and Colorado Plateau."},
"GEOL121":{"description":"An introduction to major gemstones, their geological origin, the science of their physical properties and identification, and their role in and impact on human civilization. This course is a survey of several major concepts in physical and environmental geology through the lens of gemstones."},
"GEOL131":{"description":"An examination of the controls on human activity by geology, and the impact of humans on natural geologic processes. This course is a survey of fundamental geologic processes and associated hazards (earthquakes, tsunamis, eruptions, flooding, landslides) and topics such as pollution and land use planning. The course provides an opportunity to discuss, from a geologic perspective, the ramifications of and potential solutions to problems associated with Earth's resources (energy, minerals, water)."},
"GEOL132":{"description":"An introduction to volcanoes, their occurrence, different styles of eruption, and the processes that control volcanic activity, emphasizing the impacts of volcanism, both beneficial and destructive, to human beings and the global environment."},
"GEOL151":{"description":"This course examines principles of physical geology through the medium of America's national parks. Parks that were designated because of their unique geologic setting will be studied to understand the full range of geologic materials and internal Earth processes."},
"GEOL161":{"description":"This course will focus on minerals and rocks, volcanoes, Earth's internal structure, plate tectonics, and surface processes.  Aspects of geology that impact society will also be addressed, such as flooding and earthquakes.  The laboratory provides hands-on activities that supplement material presented in lecture."},
"GEOL162":{"description":"Lecture and laboratory studies of the evolution of the earth and its life forms. Techniques used to interpret Earth and life history. The concept of geologic time."},
"GEOL205":{"description":"Prepares students for the next step in becoming an environmental scientist or geologist, including professional licensure, exploring careers in the private sector (environmental consulting, energy resources, geotechnical) or with state and federal agencies (USGS, NPS, USFS, USDA), and/or pursuing an advanced degree. Topics include scientific ethics, resume and cover letter writing, interview techniques, internships, and summer field camp experiences."},
"GEOL217":{"description":"A survey of distribution of natural resources, with special emphasis on solutions to problems of resource scarcity.  Topics include: mineral resources, soils and agriculture, surface and ground water resources, renewable and non-renewable energy and political issues and other selected components of the geosphere, atmosphere and hydrosphere.  Economics, demographic, and political issues are considered as they affect resources."},
"GEOL234":{"description":"Origin and history of the oceans, including evolution of ocean basins and sea water; causes and effects of oceanic circulation; interaction of oceans and climate; the oceans as a habitat for life; and challenges of protecting oceanic environments. Problems in exploiting the oceans for mineral wealth and food. Effects of global warming and rising sea level."},
"GEOL261":{"description":"Application of fundamental physical geology skills for majors. In this course we will focus on the observation, interpretation, and analyses of Earth materials, topographic maps, aerial photographs, geological maps, and other spatial geological data. These skills will be applied to different geological landscapes. The course has a strong field component and emphasis on written and oral communication skills in the geosciences."},
"GEOL301":{"description":"Systematic lecture and laboratory study of minerals, including their chemical composition, atomic structure, physical properties, and conditions of formation. Laboratory emphasizes crystallography, optical properties, and hand specimen identification of common rock-forming and economic minerals."},
"GEOL302":{"description":"Classification, composition, texture, and origin of igneous and metamorphic rocks. Hand specimen and thin section studies."},
"GEOL311":{"description":"Interdisciplinary science course provides students with a synthetic view of the Earth's atmosphere, geosphere, hydrosphere, and biosphere systems.  Topics include interactions of the atmosphere-ocean system, the hydrologic cycle, climate, change, applications of basic environmental principles to human induced problems, and wetland ecology.  Intended for environmental science students, but also open to other students interested in the application of foundational environmental science concepts."},
"GEOL315":{"description":"Study of geologically important fossil groups, including macroscopic invertebrates, micro fossils, and trace fossils, emphasizing classification paleoecology and biostratigraphy. Laboratory stresses identification of fossils in hand specimen and under the microscope."},
"GEOL316":{"description":"Lecture and laboratory study of composition, texture, description lithologic classification, correlation, and paleoenvironmental interpretation of sediments and sedimentary rocks.  Use of subsurface data including cores and geophysical well logs.  Laboratory includes thin sections and field studies."},
"GEOL341":{"description":"Study of the hydrologic cycle with emphasis on the ecosystem implications of hydrological processes, the effects of natural and human activities on the hydrology and water resources of an area, and related problems and solutions."},
"GEOL345":{"description":"Descriptive, kinematic, and dynamic analysis of geologic structures including geologic map interpretation, cross-section construction, structural analysis, stress-strain relationships, graphical methods, and the use of the Brunton compass."},
"GEOL351":{"description":"Practice of important field techniques in field analysis of geologic materials and structures, and construction of geologic maps. Emphasis is on problem recognition, data gathering, and interpretive analysis."},
"GEOL390":{"description":"Integrated application of geological knowledge to specific field examples. Approximately five weeks of literature investigation of a geological terrain will be followed by a field trip to the selected area. Excursions will vary in length from one weekend to two weeks. The course is intended for geology majors, and students will need to cover expenses."},
"GEOL402":{"description":"This course reviews the present state of knowledge about volcanic activity and hazards, with an emphasis on the methods used to study volcanoes and the significance and interpretation of volcanic rocks and deposits."},
"GEOL407":{"description":"Discussion of surface processes and landforms created by those processes. Emphasis on the evolution of landforms and the interpretation of their origins. Major topics include tectonism, volcanism, weathering and pedological applications, fluvial, eolian, coastal, karst, and glacial processes. Course includes semester long research project applying geomorphological concepts to topics in geomorphology."},
"GEOL411":{"description":"Lecture and laboratory studies of the influence that weathering, erosion, landform, topography, climate, flora and fauna, parent material, ground water, and time has on soil development. Soil classification, profiles, composition, chemistry, fertility, and engineering properties and uses."},
"GEOL414":{"description":"Lecture and laboratory exercises on the origin, composition, and occurrence of petroleum in the Earth's crust. Business models and economic analysis in the petroleum industry.  Case history studies.  Laboratory exercises stress procedures and skills used by working petroleum geologists."},
"GEOL441":{"description":"Behavior and properties of water in the geologic environment. Emphasis on subsurface water, including studies of flow patterns, chemistry, behavior in various geologic materials, and pollution problems."},
"GEOL445":{"description":"The nature of continents and oceans, their current tectonic movements, and the record they provide of past tectonic movements. Includes methods to determine relative and absolute plate motion, historical account of plate tectonic theory, and application of plate tectonics to the origin and development of mountain belts."},
"GEOL455":{"description":"This class seeks to describe and understand the changes in global and regional geologic systems and climate that occurred during the Quaternary by investigating the types of data available, the methods of data acquisition, dating techniques and methods, and the interpretations drawn from the acquired data. GEOG 214, GEOG 215, or GEOL 315 is recommended."},
"GEOL460":{"description":"Supervised learning and work experience in geology which enhances the student's understanding of and appreciation for the application of geological knowledge and skills.  A report of activities will be required at the end of the semester.  Students may earn from 1 to 4 credits."},
"GEOL465":{"description":"Survey of computer-based techniques in the storage, retrieval, analysis, and representation of spatially distributed data. Emphasis is on application of GIS technology to problems such as geologic hazard mapping, surface runoff and erosion, contaminant transport, population density, and environmental impact assessment."},
"GEOL470":{"description":"This elective course may be customized for the special interest of the student.  Students may complete this independent study by participating in faculty-approved supervised service delivery or outreach activity related to geology, or a literature-based paper that focuses on a topic within geology.  A report of activities or a research paper will be required at the end of the semester.  Students may earn from 1 to 4 credits."},
"GEOL475":{"description":"Elements of airborne and space borne remote sensing systems, including aerial photography, radar, thermal, and multi Spectral. Emphasis on use of remotely sensed images for geological mapping, environmental monitoring, resource assessment, and land use."},
"GEOL480":{"description":"This course is designed to give practical experience to geology and earth-space science teaching majors in the classroom.  Duties involve preparation and set-up prior to class and answering student questions.  Design and administration of laboratory exercises, as well as grading where appropriate, will be components of this course.  A report of teaching activities will be required at the end of the semester."},
"GEOL481":{"description":"Selected topics dealing with environmental geology, Earth resources, and land use. The course is conducted as group discussions centered on current and classical literature followed by field experiences. Extensive individual investigation is required. GEOL 441 is recommended."},
"GEOL490":{"description":"Selected topics of current interest in the geologic community."},
"GEOL499":{"description":"Individual research problems in an area of common interest to student and instructor. May involve field and laboratory activities, as well as literature surveys. Final report required."},
"GERM101":{"description":"Fundamentals of German; no previous German required. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the German-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"GERM102":{"description":"Fundamentals of German; continuation of GERM 101. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the German-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"GERM203":{"description":"Continuation of GERM 102. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the German-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"GERM204":{"description":"Continuation of GERM 203. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the German-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"GERM205":{"description":"Intensive oral practice of German on the intermediate level. May be taken concurrently with GERM 204 or GERM 306. Topics include German popular culture, current events, work, and travel."},
"GERM275":{"description":"Weekly showings and discussions of German films. Analysis of the thematic and artistic diversity from its beginnings in the early 20th century to the present. (Course taught in English)"},
"GERM306":{"description":"Continuation of GERM 204. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of German-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"GERM307":{"description":"Continuation of GERM 306. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of German-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"GERM308":{"description":"Study of Germanic cultures, emphasizing developments in language, literature, philosophy, the arts, politics, traditions, and institutions from the beginnings to present. (Course taught in English)"},
"GERM325":{"description":"Critical readings and literary analysis of selected German texts from the Middle Ages to present in the major literary genres."},
"GERM334":{"description":"German drama from mid-19th century to the present time, emphasizing major literary movements and recent experiments in the theatre. Application of the acquired skills are demonstrated by the student in a dramatic presentation as well as through short papers and the final exam."},
"GERM335":{"description":"Study of the working knowledge of the target language, cultural behaviors, and values useful in various professional fields. Course may include a service learning component."},
"GERM344":{"description":"Study and analysis of selected Germanic literary masterworks in English translations. (Course taught in English)"},
"GERM412":{"description":"Study of the city of Berlin from the Weimar Republic to Nazi Germany, the Cold War and the fall of the Berlin Wall."},
"GERM460":{"description":"An opportunity for job-related experience in the area of language studies."},
"GERM490":{"description":"Specialized topics in the field of German language study, teacher preparation, culture, and literature."},
"GERM499":{"description":"Research or advanced study in a specific area of German literature, culture, education, or language, culminating in an analytical paper or comprehensive exam."},
"GERO215":{"description":"This course focuses on aging and health. Major health problems are identified, and treatment strategies are studied. The dimensions of aging are explored. Healthcare issues pertinent to the older adult are discussed."},
"GERO312":{"description":"This course emphasizes the relationship between aging and a healthy lifestyle.  Students will apply behavior modification theory to concepts of living well throughout the lifespan with an emphasis on aging well into elderhood.  The student will learn about the role of nutrition, exercise, disease prevention, and social connectedness throughout the lifespan."},
"GERO316":{"description":"This course focuses on aging and health. Major health problems are identified and treatment strategies are studied."},
"GERO317":{"description":"This course examines the conceptual framework for creating an ideal integrated continuum of care, as well as information about the components for the continuum as they exist today. Students will examine the various residential options for seniors and will analyze issues of cost, access, and quality for each."},
"GERO318":{"description":"This course emphasizes the relationship between aging and healthy lifestyle. Theories of aging and development will be reviewed. The student will learn about the role of nutrition and exercise in the older population. This knowledge will be applied by assessing an older adult's nutritional status and exercise behavior for the purpose of developing an individualized strategy for change. NUTR 376 recommended."},
"GERO319":{"description":"This course focuses on assessment and management of Alzheimer's disease from diagnosis until death. Assessment and strategies identify stage of progression and are the basis for planning effective management. Management is by stage, using retrogenesis theory, and includes interventions related to environment, behavior, interaction, and medication. The course is appropriate for both formal and informal care givers."},
"GERO321":{"description":"This course will examine aging in relation to social roles, processes and structures.  Topics include social theories of aging, caregiving, demography, life course, retirement, death and dying, and health care systems and policy."},
"GERO343":{"description":"A multidisciplinary survey of the principles and processes associated with death, dying, and grief. Students will explore the social structures and process as well as the historical, psychological, medical, political, legal, ethical, and therapeutic aspects of death, dying, and grief in order to increase sensitivity and awareness of end of life issues. The course considers different concepts of death, the demography of death, the determination of death, the funeral industry, the social psychology of dying, dying patients, and the survivors."},
"GERO423":{"description":"This course focuses on major clinical problems of older adults in society. Emphasis is on prevention, early recognition, and management of problems. Environmental, behavioral, physical, and educational approaches are included."},
"GERO425":{"description":"This course focuses on the purpose, organization, and management of long-term care facilities and related organizations. The importance of long-term care facilities in the continuum of care for the elderly or developmentally disabled is examined. The typical structure, by functional area, of such a facility is presented, and federal and state regulations by area are reviewed. Contemporary issues and current legislation/regulations impacting the long-term care industry are analyzed. This course assists the student to prepare for successful completion of the federal and state examination leading to licensure as a health facilities administrator."},
"GERO499":{"description":"This course provides an Administrator-in-Training (AIT) experience as required by the Indiana State Department of Health. Students engage in 520 hours of nursing home management under the supervision of an Indiana qualified supervisor. During this period students rotate through the following content area: standards of competent practice, administration, housekeeping/laundry, facility management, nursing, dietary, activities, business office, admissions/marking, and overall facility management.   Additionally, students attend 12 hours of class in preparation for the Indiana licensing exam.  (6 credit course: 5.2 credits in the nursing home and .8 credits of class time)."},
"GLST101":{"description":"An interdisciplinary introduction to Global Studies that focuses on global interconnectedness."},
"GLST185":{"description":"This course is designed to introduce students to various aspects of Africa and its people including, but not limited to: geography, history, language, culture, religion, politics, economics, literature, and current events. An interdisciplinary approach will be utilized to address the diversity and complexity of the study of Africa and its peoples."},
"GLST201":{"description":"This course draws on interdisciplinary tools to understand forms of cultural and cross-cultural identity and to analyze issues of contemporary global society. The course seeks to help students develop skills in intercultural communication and understanding for success in multicultural or study abroad settings and discusses career opportunities in international fields."},
"GLST213":{"description":"A survey of world magic, its origins and influence on literature, architecture, art, music, theatre, film, religion, and medicine from antiquity to present."},
"GLST302":{"description":"An in-depth study of travel writing, culture, and politics of field study, with original travel writing. Students will read essays written by travelers as well as postcolonial theory of travel writing, do directed research in the culture of choice and travel, and write, revise, and complete four essays associated with travel and study abroad. May be taken online during the semester of study abroad. Students taking the course while studying abroad will use email or blackboard for peer review, editing, and exchange of research and original materials."},
"GLST371":{"description":"Readings in the literature and literary theory of the non-Western World. This course engages in detailed analysis of key texts written in English or English translation. Readings may be selected from the literatures of Asia, Africa, the Caribbean and the Middle East."},
"GLST372":{"description":"Readings in the literature and literary theory of the Western World, excluding Great Britain and the United States. This course engages in detailed analysis of key texts in English translation. Readings may be selected from the literatures of the European Continent, Western Hemisphere, and Post-colonial contexts."},
"GLST470":{"description":"A course designed for study of interdisciplinary special topics with a global context."},
"GLST473":{"description":"A systematic study of the origins, objects, sources, and theories of international law. Foremost in importance is the aim to enable students to analyze basic premises and principles of international law and to develop an awareness of the major international legal problems."},
"GLST480":{"description":"This course grants credit for internships related to the Global Studies major."},
"GLST490":{"description":"This course includes research in a specific area of interdisciplinary, global studies culminating in an analytical paper."},
"GLST497":{"description":"An in-depth interdisciplinary study of a variety of topics within the field of Global Studies. Coursework requires submission of a cumulative portfolio exhibiting work from previous courses taken in the major."},
"GLST499":{"description":"Intensive field experience in a contractual activities involving research, education, or applied studies focus."},
"GNDR111":{"description":"This course is designed as an introduction to the field of gender studies and the many ways gender is understood and examined. Lectures will be drawn from the humanities and social sciences as well as other disciplines within and beyond Liberal Arts."},
"GNDR222":{"description":"This course provides students with an interdisciplinary way of looking at power, privilege, and identity, among other ideas, through various cultural, gendered, and racialized constructions of the physical body.  This course will use a variety of readings and films about international body art practices to enhance the student's cultural understanding of the role the body plays in art and culture."},
"GNDR333":{"description":"Drawing from a variety of disciplines, this course is a survey of contemporary and historical theoretical perspectives on gender.  Students will explore how ideas about men and women matter in terms of behavior, relationships, policies, and culture."},
"GNDR449":{"description":"A means of the study of selected topics of current interest and importance in gender studies. These topics will vary in accordance with the interest of the students and the faculty."},
"GNDR499":{"description":"An intensive examination of a specific subject area related to Gender Studies. Students may only take a maximum of six credit hours of GNDR 499.  This course requires consent of the instructor."},
"HA407":{"description":"This course will introduce students to the history of basic leadership theory and the leadership methods that have been found to be most effective in motivating, developing and retaining members of the highly diverse U.S. health care workforce.  In addition, current and future challenges facing the health care system will be identified and evaluated to identify ways to most effectively manage and predict the needs of health care workers during this dynamic time for the industry."},
"HA411":{"description":"This course includes an overview of leadership, administration, decision-making, and organization within the healthcare system. Emphasis is placed upon leadership styles and application of the functions of leadership to situations in the healthcare environment."},
"HA421":{"description":"This course provides an overview of financial systems. Selected healthcare financial management concepts including payment incentives, integrated systems, cost management, pricing and marketing, productivity monitoring, insurance coding, and quality improvement will be studied as they relate to various types of healthcare organizations. Selected computer applications to demonstrate budgeting, forecasting, data base integration, and variance analysis will be used. ACCT 201 recommended."},
"HA431":{"description":"This course will provide the knowledge base and the tools required to understand and analyze the importance of issues of quality in healthcare. Topics to be covered include: dimensions of quality care and performance, including outcomes, overuse, and under use; errors and threats to patient safety; service flaws; and forms of waste in the healthcare environment."},
"HA496":{"description":"This course focuses on healthcare marketing and strategic planning in healthcare. The increasing complexity of the healthcare system dictates a need for an organized approach to strategy, and one that ties key market issues and needs to a definitive market position, quantifiable objectives, and finally to strategies and actions aimed at achieving the position and objectives. This course allows the student to learn such an organized approach to marketing and strategy. A strategic analysis and plan, as a means to introduce the principles and concepts of strategy applicable to healthcare, will be conducted."},
"HI301":{"description":"This course provides the foundation for the study of health informatics and covers topics such as health information management, clinical information systems (inpatient, outpatient, nursing, laboratory, pharmacy, radiology, etc.), decision support systems, clinical research and health-assessment systems, technology assessment, and healthcare accounting. The focus of the class content is on U. S. healthcare and the role of informatics within the U. S. system."},
"HI302":{"description":"Electronic Health Records (EHRs) are application systems that automate the activities of healthcare clinicians including physicians, nurses, physician assistants, and healthcare administrative staff. The increased use of EHRs has many challenges including complex data, high-security requirements, integration to multiple application systems, a distributed user base, and broad impact on how these users work. This course describes regulatory policies and implementation issues concerning Enterprise Systems and Electronic Health Records (EHRs). Students will be exposed to real-world use and deployment of EHRs through readings, discussions, hands-on labs and case studies."},
"HI303":{"description":"There is an urgent need for the exchange of healthcare information and an interoperable health system that empowers individuals to use their electronic health information to the fullest extent, enables providers and communities to deliver safer, and more efficient care, and promotes innovation at all levels. Students will gain an understanding of basic principles in healthcare system interoperability and infrastructure as well as architectural, business and operational models required to implement and manage a health information exchange (HIE). Students will be exposed to real-world examples to demonstrate concepts in practice."},
"HI304":{"description":"This course gives students an overview on how social media and other emerging communication technologies can connect millions of voices to increase the timely dissemination and potential impact of health and safety information, leverage audience networks to facilitate information sharing, facilitate interactive communication, connection and public engagement, and empower people to make safer and healthier decisions. Students will also learn how monitoring social media for real-time event information and mining that information can be used to improve health."},
"HI401":{"description":"This course will provide an introduction to project management principles and tools used for managing the development of healthcare analytic projects and information systems. Application of the concepts will be presented regarding systems theory, hardware requirements, personnel requirements, vendor negotiations, software, database, telecommunications, and use of the internet."},
"HI402":{"description":"With the increasing adoption of electronic health record systems, new forms of data are becoming available that can be used to measure healthcare delivery and improve patient outcomes. Students will explore the role of analytics in supporting a data-driven healthcare system. Key topics include the value-driven healthcare system, measuring health system performance, quality/performance measurement frameworks, key components of healthcare analytics, and the IT infrastructure and human capital needed to leverage analytics for health improvement."},
"HI403":{"description":"This course will introduce students to the International Classification of Diseases coding system and provides the background and skills needed for assigning and sequencing of medical codes in accordance with approved guidelines."},
"HI404":{"description":"This course prepares students for the management of coding, auditing, and revenue cycle. Topics such as benchmarking, documentation requirements, and strategies for success both in quality and quantity of coding/auditing services from multiple viewpoints such as acute care, outpatient services, physician offices, and third-party settings will be addressed."},
"HI405":{"description":"This course is designed to provide students with an introduction to current and emerging issues in health care information security and regulatory compliance. Students will be introduced to the basic principles of the Health Insurance Portability and Accountability Act (HIPAA) concerning Privacy and Security Rules and other key regulations such as the Health Information Technology for Economic and Clinical Health (HITECH) Act. This course will also provide a foundational set of skills and knowledge to understand cybersecurity threats, threat actors, cybersecurity risk management best practices, digital health innovation, laws and regulations applicable to electronic health records, as well as techniques that are being used to build cybersecurity awareness."},
"HI410":{"description":"The HIIM Internship program provides the critical interface between academia and industry for the rapidly changing field of Health Informatics and Information Management. Students have a unique opportunity to help guide the future of health informatics education and to collaborate with healthcare organizations to improve the quality of care and reduce costs. Internships may require students to implement and assess new software, decision support methods, or analyze quality and patient outcomes data.​"},
"HIST101":{"description":"A survey of United States history from colonial times through Reconstruction."},
"HIST102":{"description":"A survey of United States history from the end of Reconstruction to the present."},
"HIST108":{"description":"This course introduces various ways in which scholars and societies in different global contexts have approached the past. It will teach new ways of thinking critically about the world, its past, present, and future."},
"HIST111":{"description":"The development of the major civilizations, Western and Non-Western, from their beginnings to 1500. The cultural achievements of these civilizations are studied in their historical contexts."},
"HIST112":{"description":"The development of the major civilizations, Western and Non-Western, from 1500 to the present. The cultural achievements of these civilizations are studied in their historical contexts."},
"HIST130":{"description":"A selection of issues which focus on understanding aspects of American history within a national and global framework. This course offers first- and second-year students an alternative to HIST 101 or HIST 102."},
"HIST140":{"description":"A selection of issues which focus on understanding particular aspects of world history within a global framework. This course offers first- and second-year students an alternative to HIST 111 or HIST 112."},
"HIST222":{"description":"This course explores the history of North American and European communalism in the last two centuries. Students will read related works and use the Communal Studies Collection to write a paper on a relevant topic."},
"HIST246":{"description":"U.S. History is a Global Context is a thematic course, presenting a study of a broad topic in U.S. history within the greater context of the history of the world as a whole. Topics vary."},
"HIST261":{"description":"A survey of World History to 1300, emphasizing the development and exchange of representative cultural markers between major Western and non-Western civilizations.  Prominence given to understanding the course of historical change by developing awareness of causality, change, continuity and historical relevance.  This writing-intensive course will emphasize primary source analysis and a critical understanding of history."},
"HIST262":{"description":"A survey of World History 1300 - 1700, emphasizing the interaction between major Western and non-Western civilizations.  Prominence given to understanding the course of historical change by developing awareness of causality, change, continuity and historical relevance.  This writing-intensive course will emphasize primary source analysis, and a critical understanding of history."},
"HIST263":{"description":"A survey of World History since 1700, emphasizing the interaction between major Western and non-Western civilizations. Prominence given to understanding the course of historical change by developing awareness of causality, change, continuity and historical relevance.  This writing intensive course will emphasize primary source analysis and a critical understanding of history."},
"HIST266":{"description":"This course is an introduction to the methods of World History by examination of a theme or topic, including analysis of primary sources and historiographic approaches through secondary literature. Topics vary."},
"HIST287":{"description":"This course serves as an introduction to history teaching methods. The primary goal of this course is to familiarize students with the tools, techniques, and methods required in a 21 century social studies classroom. Students will learn a variety of best teaching practices as they relate to the various Social Studies content areas, as well as understanding citizenship education, teaching for social justice, and global diversity, broadly defined. The course is designed so that students will learn how to create effective, innovative, and student-centered lesson plans, which are grounded in the standards, reflect best classroom practices, and incorporate multiple learning styles. Students should come away from this class with a better idea of what it means to teach history as well as a variety of ways to approach teaching it."},
"HIST288":{"description":"Introduction to the professional historical community. Coursework focuses on how historians apply historical methodology effectively to synthesize information, and present conclusions. Includes a module on professional development, including engagement with scholarly communities."},
"HIST298":{"description":"An exploration of the principles of historical research; students will implement these with a particular focus on developing critical and analytical methodologies. Significant writing component required."},
"HIST300":{"description":"An intensive examination of a them or topic in U.S. History. Key readings, critical analysis of primary sources, discussion and writing assignment are required. The focus of each course offering is determined by the instructor and the History department."},
"HIST301":{"description":"Historical survey of Native American polities from c.900 to the 19th century. Emphasis is on cultural, religious, social, governmental, and economic developments. Attention is given to the historical significance of the European intrusion and the interchange of American, European, and African ideas, resources, products, and peoples."},
"HIST302":{"description":"Analysis of European discovery and colonization of the Americas, particularly North America. Attention is given to European exploration, interaction with Native American cultures, and the beginnings of colonial development."},
"HIST303":{"description":"A detailed history of the last decades of the English colonies in America, the forces leading to the American Revolution, and the creation of the United States up to the writing of the Constitution."},
"HIST304":{"description":"History of the United States from the creation of the Constitution to the end of the war with Mexico, emphasizing economic growth, territorial expansion, and social change. Substantial attention is given to slavery and antebellum reform."},
"HIST305":{"description":"A study of the United States from the end of the war with Mexico to the end of Reconstruction with emphasis on the coming and course of the Civil War and the reunion of the nation at the war's end."},
"HIST306":{"description":"An examination of the social, economic, political, religious, and intellectual developments and controversies that helped shape modern America. Special attention is given to issues raised by industrialization, urbanization, immigration, race and ethnicity, science, and technology. The Labor, Populist, and Progressive movements are studied closely."},
"HIST307":{"description":"American history from involvement in World War I to the end of World War II, including the Great Depression. In addition to exploring America's impact on both world wars and their consequences for United States history and the Great Depression, the course analyzes the social, economic, cultural, intellectual, political, and diplomatic history of the interwar period, 1919-1941."},
"HIST308":{"description":"An introduction to American history in the years of the beginning and collapse of the Cold War. The course examines the rise and weakening of the postwar economic and social order, especially during and after the Vietnam War, Watergate, and the Middle East crises of the 1970s."},
"HIST310":{"description":"An intensive examination of a theme or topic in World History. Key readings, critical analysis of primary sources, discussion and writing assignment are required. The focus of each course offering is determined by the instructor and the History department."},
"HIST311":{"description":"Places the experiences of American women in an international context and considers the contribution of women to American history. Particular attention is given to gender relations, the importance of race and class, and the diverse nature of women's experiences from the colonial period to the present."},
"HIST313":{"description":"An exploration of the sources and patterns of immigration in American history from the early 1600s to the present. The course also treats the acculturation and assimilation of immigrants and the contributions of ethnic and racial groups to the course of American history."},
"HIST315":{"description":"This course will explore the environment as an agent in history and the relationship between humans and their environment since 1600. Topics will include the concept of \"wilderness,\" disease, the impact of industrialization and urbanization, expansion into the West, natural and manmade disasters, and constructed human \"environments.\""},
"HIST316":{"description":"Explores the beginnings of U.S. involvement in Southeast Asia at the conclusion of World War II.  Cold War ideologies underlying American involvement in the 1950s, escalation of the conflict in the 1960s and growing opposition to the war at home.  Students will also analyze the continuing cultural impact of the war as expressed through films, literature, and monuments memorializing the war."},
"HIST320":{"description":"An intensive examination of a theme or topic in European  History. Key readings, critical analysis of primary sources, discussion and writing assignment are required. The focus of each course offering is determined by the instructor and the History department."},
"HIST321":{"description":"A survey of built environment history and the historic preservation movement. Structures, sites, and collections are studied in the context of fund and museum development and management. The relationship of historic sites to local, state, and national organizations and agencies is examined. Student projects include on-site experience."},
"HIST322":{"description":"An introduction to the field of Historical Administration presenting both management and historic material. The course provides an overview of the not-for-profit manager and the basic functions of the historian in public service. Includes on-site visitation and emphasis upon work areas with a historical focus."},
"HIST323":{"description":"Introduction to basic principles and practices in managing archives or special collections, surveying various types of archives and repositories and concepts and methods for organization and descriptive systems used in academic, public, private, and museum collections.  Exploration and analysis of new archival technologies and tools used for processing collections and increasing accessibility, and apply these principles to a service learning project based on a local archive."},
"HIST325":{"description":"This course is designed to introduce students to the theory, methods, and practice of history outside the classroom. Students will learn about the storied and controversial history of this field of study, about the relationship between historians, communities, and cultural memory, and about careers in history outside of the academe. Students will also investigate the challenges of historical work in historic sites, museums, archives, online and within other public history settings."},
"HIST330":{"description":"An exploration of a public history topic to develop knowledge and skills in a specific academic or professional field."},
"HIST331":{"description":"Assesses the Spanish and Portuguese conquest and rule of the Americas; focuses on interactions between Europeans, natives, and Africans in creating colonial societies."},
"HIST332":{"description":"The main events in the history of Latin America from independence to the present, including issues of class, race, gender, and ethnicity."},
"HIST336":{"description":"This course explores the history of African-descended people in the United States from their first arrival in the North American colonies through the end of slavery during the U.S. Civil War. Students will investigate the ways African Americans fashioned new worlds and cultures while living under the enormous constraints of slavery and discrimination. Struggles for freedom, full citizenship, and alternative political visions, and the role of such struggles in shaping African Americans' identification with each other as a people, will be a focus throughout. Students will also treat differences of class and gender within African American communities. Course materials will include both primary and secondary sources, and as we examine these sources we will consider various methods for discovering and interpreting the history of people whose voices are not always easily found in the historical record."},
"HIST337":{"description":"In this course, students will examine the African American experience in the United States from 1863 to the present. Major units include: the end of the Civil War and the beginning of Reconstruction; African Americans' urbanization experiences; the development of the Modern Civil Rights Movement and its aftermath; and the thought and leadership of Booker T. Washington, Ida B. Wells-Barnett, W.E.B. Du Bois, Marcus Garvey, Martin Luther King Jr., and Malcolm X. Additional topics include: black culture, Black feminist thought, queer thought, allies and black activism."},
"HIST339":{"description":"A survey of the history of Indiana from early to modern times."},
"HIST341":{"description":"A detailed survey of the origins and flowering of Greek culture against the backdrop of its archaeology and history, 2000-338 B.C."},
"HIST342":{"description":"A political, economic, religious, social, and intellectual history of the Hellenistic world from 338 to 31 B.C. The course explores the massive change brought about by Alexander the Great's unification of the Near East and Greece."},
"HIST343":{"description":"An intensive study of the Roman Republican experiment in orderly representative government by law until the revolution that brought on the Roman Empire in 31 B.C."},
"HIST344":{"description":"This course studies the Roman Revolution under Augustus which transformed the Republic into the Empire, Rome's assimilation of the heterogeneous peoples of Europe and the Near East, the cultural and religious dimensions of Rome's Silver Age, and the fall of the Empire in 476 A.D."},
"HIST345":{"description":"A survey of European history from late antiquity to the early modern period. Emphasizes cultural contact and the development of political and religious institutions. Working with secondary and primary sources, such topics as the rise of Christianity, Germanic migrations, Byzantium and Islam, gender, and social history will be explored."},
"HIST346":{"description":"A study in the cultural changes in pre-modern, Western Europe exhibited by the revival of ancient styles, growth of Humanism, development of nation states and changes in political thought, print culture, and a shift in the role of religion."},
"HIST347":{"description":"An introduction to the major religious, political and social features of the many movements termed the European Reformations. Consideration of significant social and political, in addition to the religious aspects of the reform movements which ultimately disrupted the Catholic unity of Europe and served to help define Christian theology in the early modern period are covered. The course will also explore the popular responses to reform ideas and their eventual impact on European history."},
"HIST348":{"description":"A study of Absolutism, Enlightenment, and Revolution in their political, economic, religious, social, and artistic aspects, from Descartes and Newton through the French Revolution and Napoleon."},
"HIST349":{"description":"An analysis of the impact upon Europe of Conservatism, Liberalism, and Socialism under the impetus of the Industrial Revolution. Emphasis also is upon the relationship of Social Darwinism, Imperialism, and Nationalism to the beginning of the First World War."},
"HIST351":{"description":"A survey of European society, economy, and politics since 1914. Topics include World War I, Russian Revolution, rise of Fascism, World War II, Cold War, and end of the Soviet Union."},
"HIST353":{"description":"English history from the Celtic period to 1600, with accent upon the political and cultural growth of the English nation."},
"HIST354":{"description":"Examines British history beginning with the seventeenth-century origins of the modern Parliamentary state, with special consideration of the political, cultural, social, economic, imperial, and religious issues that shaped the development of the nation's history from that time to the present."},
"HIST362":{"description":"This course surveys the political, cultural and social history of Paris from the mid-eighteenth century through contemporary times. Among the topics to be covered are the roles of the Catholic Church and the French monarchy; the growth in population and expansion of the suburbs; the city's history of revolutions from 1789 through 1871; and long-term developments in architecture and urban planning. Among the political and cultural figures to be examined are Maximilien Robespierre, Victor Hugo, Honoré de Balzac, Louis Michel and Simone de Beauvoir."},
"HIST365":{"description":"An intensive study of the holy wars between Western Europe and Islam that took place in the Holy Land and Europe from the late eleventh century to the late fifteenth century. Special emphasis is placed on an analysis of the crusading ideal, the motivations of the crusaders, the changes in crusade ideology, as well as Muslim response to Christian military attacks. Cross-cultural exchanges between Christians, Muslims, and Jews in the territories affected by the crusades also are examined."},
"HIST372":{"description":"A study of the Russian Revolution, Marxism-Leninism, the creation of the Stalinist system, the Cold War, and the demise of the Soviet Union."},
"HIST375":{"description":"Exploration of the origins and development of Islam in the context of Middle Eastern history with particular focus given to the social aspects of the Islamic community and its relations with non-members, especially Jews and Christians."},
"HIST376":{"description":"Examination of the changing relationships between the Islamic World and other peoples, especially Europeans after 1500 and the continuing evolution of Islamic identities. Includes exploration of the ongoing debate about the role of modernism, Islamic reform, and westernization within the Islamic world and the emergence of pan-Islamic and fundamentalist movements."},
"HIST377":{"description":"Study of the important political, economic and cultural role Iran has played in the development of Middle Eastern and South Asian societies from ancient times to the present, providing an important cornerstone for understanding the contemporary Middle East."},
"HIST384":{"description":"A study of African history and culture from prehistory to 1800. Emphasis is given to Africa as an integral part of world history."},
"HIST385":{"description":"A study of African history and culture since 1800, emphasizing historical and theoretical approaches to colonial and post-colonial history."},
"HIST393":{"description":"A survey of Chinese history from antiquity through early imperial times. Special emphasis is given to the development of Chinese society and China's role in world history."},
"HIST397":{"description":"This methods course is designed to develop the skills required of pre-service secondary social studies teachers to be effective educators in a secondary education setting. Balancing theoretical approaches and practical application, the course will introduce students to various methods of planning, applying, and assessing instructional models in the multidisciplinary field of social studies."},
"HIST398":{"description":"The study of historical thought and writing, which will explore representative examples of the major historiographical schools, their tools, methods, and ideas as developed over time."},
"HIST399":{"description":"Special reading tutorials or independent research projects proposed by upper-division history majors to provide in-depth study with a specific faculty mentor within the history department. The course may be applied to any one of the appropriate history elective categories: U.S., Europe, or world history."},
"HIST400":{"description":"An intensive examination of a theme or an era in American history. Substantial reading, class discussion, and a major writing assignment are required. The focus of each course offering is determined by the instructor and the History department."},
"HIST405":{"description":"Students will explore the radical civil rights movement of the early twentieth century, including Garveyism, the founding of the NAACP, and African-American leaders in radical working-class politics. Students will analyze American foreign policy and race relations during the Cold War, the emergence of a radical student movement and black nationalism in the 1960s and 1970s, and race relations as they stand in contemporary American politics."},
"HIST410":{"description":"An intensive examination of a theme or topic in world history. Substantial reading, class discussion, and a major writing assignment are required. The focus of each course offering is determined by the instructor and the History department."},
"HIST411":{"description":"An examination of the impact of religious values and institutions on American history. Special attention is given to the rise, decline, and legacy of mainstream Protestant culture, religious pluralism in the 20th century, the religious origins of social and cultural change, the continuing importance of civil religion, and the implications of the First Amendment for religious expression in America."},
"HIST419":{"description":"A study of the utopian thought and historical development of religious and social movements that have employed communal methods of organization in America from colonial times to the present. Shakers, Harmonists, Owenites, Fourierists, Mormons, Oneidans, Hutterites, and communal groups since the youth movement of the 1960s are emphasized."},
"HIST420":{"description":"An intensive examination of a theme or an era in European history. Substantial reading, class discussion, and a major writing assignment are required. The focus of each course offering is determined by the instructor and the History department."},
"HIST431":{"description":"A study of the American South from its colonial beginnings to the present. Southern society, economy, culture, and politics are examined to assess their influence upon the growth of the nation from the time of slavery to the era of civil rights."},
"HIST432":{"description":"The American experience as seen through the movement of people westward with major attention given to the trans-Mississippi west."},
"HIST451":{"description":"Seeks to understand how the French Revolution and Napoleon Bonaparte helped to usher in the modern world. Stresses the impact of the Enlightenment, development of political systems, and the military and diplomatic repercussions of the French Revolution and Napoleonic Empire."},
"HIST452":{"description":"A study of Europe's extraordinary revolutionary history, emphasizing the French and Russian Revolutions and the Revolutions of 1848 and 1989. Focuses on a comparison of patterns, along with gaining an understanding of the impact of industrialization and the role of ideas."},
"HIST454":{"description":"Examines film as an important factor in our understanding of the past. Explores the context in which films are produced, the interpretations of the past they offer and how audiences' historical perception is affected by films. Topics will vary."},
"HIST456":{"description":"This course teaches students to critically examine race, racism, and power dynamics in the United States, including their conceptualizations and impacts on society. This course takes the position that race, gender, class and sexuality are socially constructed and applies this constructionist perspective on examining race relations in the US."},
"HIST464":{"description":"Murder is a social act, and the way that society views this has changed dramatically since the eighteenth century. In addition to looking at changes in official responses to murder (detection, forensics, courtroom procedures, punishments) in the U.K. and the U.S., other topics include literary and theatrical representations of murder, urbanization and the rise of the serial killer, gender and ethnicity as defined by murder, the development of forensics, the rise of the literary detective (Sherlock Holmes and others), and the impact of the popularity of modern crime drama."},
"HIST465":{"description":"Beliefs in ghosts and the paranormal are socially constructed through organized religion, political and legal developments, social change and turmoil, and the environment. This course examines the social context of ghosts and ghost-belief as reflected in court cases, first-person accounts, religious doctrines and debates, popular media, and scholarly debate over the past four centuries."},
"HIST490":{"description":"This course offers an internship in public and applied history through an assigned project at a historic site, museum, library, archive, or other approved institution. The course also may be used to gain credit for special studies in history associated with attending and/or participating in events such as seminars, conferences, and field work or doing honors research. Credit hours available for each offering is determined by the instructor and History department."},
"HIST498":{"description":"A research course in selected fields of American history at the senior level. Students conduct historical research with close guidance from the instructor and write extensive, well-documented papers. HIST 498 or HIST 499 is required for History major or minor; selected sections of HIST 498 will be designed and designated for future secondary school teachers."},
"HIST499":{"description":"A research course in selected fields of World History at the senior level. Students conduct historical research with close guidance from the instructor and write extensive, well-documented papers. HIST 498 or HIST 499 is required for History major or minor; selected sections of HIST 499 will be designed and designated for future secondary school teachers."},
"HONS129":{"description":"A small group discussion for Honors students centered on a current topic of interest and faculty expertise. The seminar format will focus on discussion and emphasize critical thinking, reading, and writing with an interdisciplinary approach to the topic. Topics vary."},
"HONS220":{"description":"This course is an introduction to looking at and understanding art.  This course covers the elements and principles of design and how they are employed in the major media areas in two, three, and four dimensions.  Emphasis will be placed on how visual art is a powerful means of communication from artist to viewer.  The course will focus on how art interacts with other disciplines and how artists push the limits of what art is and what it can do."},
"HONS291":{"description":"This is a discussion-based course designed to provide realistic information about the practice of medicine.  The emphasis is on communication with patients and between health care providers."},
"HP115":{"description":"This course focuses on the study of the prefixes, suffixes, and root words commonly found in the field of medicine and healthcare."},
"HP211":{"description":"This course provides an overview of the healthcare delivery system from a historical, economic, legal/ethical, and political framework."},
"HP225":{"description":"This course provides an introduction to modern therapeutic drugs. Dosage forms, sources, prescriptions, and metabolism are covered first, then students study human physiological systems and the most important drugs used to affect these systems. Emergency and new drugs are included. Open to all health professions and biology students."},
"HP234":{"description":"This course utilizes a systems approach to understand underlying mechanisms of the disease process and how these mechanisms relate to and cause overt signs and symptoms. The content emphasizes normal homeostasis controlling mechanisms and how pathophysiological mechanisms disturb homeostasis and cause dysfunction."},
"HP236":{"description":"This is a course designed to study Eastern medicine and its influence on complementary healthcare therapies. Emphasis is on historical, cultural, social, research, and consumer interest influences on the evolving model of east-west healing and healthcare. Specific modalities will be studied with emphasis on utilization for self-care and their use in healthcare. Implications for changes in healthcare environments and healthcare provider roles also will be analyzed."},
"HP255":{"description":"This course focuses on interdisciplinary approach to the study of individual and societal determinants in the expression of human sexuality in relationship to health. Changing social values, sexual behaviors, contraception, sexually- contracted diseases, and problems in sexual development in relationship to health are explored."},
"HP265":{"description":"This course focuses on an interdisciplinary approach to the study of alcohol and drug abuse from a health perspective. Etiological theories and the physiological and social consequences of alcoholism and drug abuse are studied. Current approaches to prevention, identification, and treatment of alcoholism and drug abuse are analyzed."},
"HP302":{"description":"The course provides students with an introduction to assessing the magnitude, reliability, validity, and precision of associations (e.g., between exposures and disease) and making inferences about target populations using data from samples in the health professions. Topics include probability and sampling distribution, research design and analysis of variance, regression and correlation, stochastic processes, and frequency distributions."},
"HP305":{"description":"This course provides the students with knowledge about health and wellness concepts and strategies to implement them in the workplace. Theoretical models will be the basis used to explore why individuals choose healthy or unhealthy behaviors and what motivates them to change their choices. Effective assessment techniques for the individual and organization will be presented. Utilization of the assessment data to develop an effective health promotion plan will be studied."},
"HP306":{"description":"This course provides the student with knowledge on the development of a health promotion plan for the worksite. Designing interventions to meet the needs of the workforce and the organization will be explored extensively. Examination of the evaluation process including methods, data gathering, data analysis, and outcome analysis."},
"HP308":{"description":"This course provides a physiological and psychosocial base for health professionals and concerned others to provide care and support to children and adults with HIV disease. Theories and concepts relevant to the disease process and care giving will be examined."},
"HP314":{"description":"This course covers topics in basic audiology and hearing impairment. Information covered will be informative for professionals who come in contact with hearing impaired individuals. Approximately five of every 1,000 babies are born with significant hearing loss. Hearing loss frequently accompanies other disabilities and is a normal development with the process of aging. Topics to be studied include: types and causes of hearing loss, social and emotional factors related to hearing loss, helpful communication strategies to use with hearing impaired persons, different types of amplification and management of such, and other equipment and services available for those who are hearing impaired."},
"HP377":{"description":"This course will present an overview of concepts relating to the emerging field of health promotion and preventative care. A review of current trends in program design and resulting behavior changes will assist students in taking responsibility for their own health as well as moving their worksites and their communities toward optimal wellness."},
"HP378":{"description":"This course focuses on the development of community and systemic strategies to improve health knowledge, attitudes, skills and behavior."},
"HP397":{"description":"This course provides students an opportunity to examine and analyze common occurrences in the management of the healthcare system that are affected by laws, standards, and/or regulations; and which may call into question the ethical decisions leaders in healthcare organizations are frequently required to make. Particular emphasis will be placed on ethical business decisions. The role of ethics committees and institutional review boards will be addressed as they relate to both clinical decisions and the administration of healthcare facilities."},
"HP409":{"description":"This practicum provides the student with practical experience in needs assessment, individual and organizational assessment, program planning, implementation, evaluation, and problem solving of health promotion programs. The student will complete a program negotiated with the instructor and gain additional experience under the supervision of an experienced health promotion professional."},
"HP475":{"description":"This course provides information that is essential to the success of today's health care professional. It will give students the understanding of the importance of professionalism and the need to perform in a professional, ethical, legal and competent manner. The student will develop and strengthen the professional traits and behaviors needed for today's healthcare industry. Knowledge of professionalism enhances the student's ability to successfully secure employment in the healthcare arena and/or to pursue advanced education."},
"HP480":{"description":"This course provides an internship for junior or senior student Health Services majors who meet the perquisites. An opportunity to gain valuable insight and understanding of current topics in the healthcare environment while they assist the healthcare business reach its organizational goals will be provided. Interns will function as valuable, student members of a healthcare-related management team. The experience will lead students to a deeper appreciation of the nature of the healthcare field. Both leadership and team work opportunities will be sought, and the student intern will have a chance to think on his/her feet while participating in practical workplace situations. The University will work closely with the participating healthcare organization to customize an experience for the intern that meets the needs of the healthcare organization without compromising academic goals or the student's academic progress."},
"HP490":{"description":"This is an advanced course for majors in health professions. Special topics are considered. Repeatable to a maximum of six credit hours."},
"HP492":{"description":"This multi-disciplinary course is designed for students interested in learning about transcultural populations of people and the relationship of culture to health and healthcare. Included in the course will be a visit to examine transcultural health in the global community. Service projects included during the visit will provide clinical and community education opportunities in a variety of sites and will promote interdisciplinary responses to healthcare issues in the cultural context of the community."},
"HP498":{"description":"This course examines the issues important to the healthcare professional from a historical, economical, legal/ethical, political, and multicultural framework. Professionalism, negotiation, change agent, leadership, role theory, and role strategies are studied in relation to the healthcare professional. Topics will be selected based upon current trends in healthcare."},
"HP499":{"description":"Provides students with an opportunity to study a specialized area of interest in the Health Services field.  This course allows for independent study under the direction of a faculty member and includes in-depth reading and research on a defined topic."},
"HUM211":{"description":"A world survey of major cultural, artistic, and philosophical movements from prehistory to the 14th century methodologically grounded in literature, the arts, and philosophy."},
"HUM212":{"description":"A world survey of major cultural, artistic, and philosophical movements from the 14th century to the contemporary period methodologically grounded in literature, the arts, and philosophy."},
"HUM241":{"description":"An introduction to world culture through a study of literature from antiquity to the 14th century."},
"HUM242":{"description":"An introduction to world culture through a study of literature from the 14th century to the contemporary period."},
"HUM350":{"description":"A study of the history and development of philosophical ideas from ancient Greece to the close of the Middle Ages, including Plato and Aristotle."},
"HUM351":{"description":"A study of the history of early modern philosophy including such major philosophers as Descartes, Leibniz, Locke, Hume and Kant."},
"IEP092":{"description":"This is a course for international students who do not meet the language requirement to be enrolled in the university. The course focuses on developing non-native speakers' writing skills from basic sentences to well organized essays with emphasis on using accurate grammar. Textbook instruction, supplementary assignments, and guided practice within the classroom help the student understand writing process as well as the essentials of composing sentences to build a unified theme.  While the primary emphasis of the course is on the writing skills, the development of critical and analytical thinking skills necessary for successful writing in academic and professional pursuits are explored."},
"IEP093":{"description":"This is an integrated-skills class which focuses on reading, writing, speaking and listening, and grammar. The focus is on teaching language functions through the four skills. This is the course where grammar is emphasized and integrated across the course so students accurately perform the functions expected in each level. While teaching in Skills classes is communicative in nature, attention to accuracy receives equal emphasis. Students are not extensively taught the process and skills related to reading, writing, or listening (as those skills are taught in other classes), but rather they use these skills to perform the target functions. In terms of vocabulary, this class builds vocabulary related to the specific topics covered in each level that will allow the students to perform the functions for each level."},
"IEP094":{"description":"This is a course for international students who do not meet the language requirement to be enrolled in the university. These reading classes teach students a variety of reading strategies from letters and sounds to reading longer texts of academic nature. The choice on which reading strategies to focus on each level depends on students' level of proficiency. Lower levels focus mainly on building students' vocabulary so as to aid comprehension of texts. As the students move up to higher levels, they are taught more complicated texts and higher-level reading skills, such as skimming, scanning, making inferences, analyzing genre and discourse features of texts. The kinds of texts used also vary depending on proficiency levels, ranging from short personal paragraphs to academic reading texts."},
"IEP095":{"description":"This is a course for international students who do not meet the language requirement to be enrolled in the university. The course focuses on developing non-native speakers' listening and speaking skills from basic sentences to extended discourse with emphasis on using accurate grammar. It develops students' listening, comprehension, pronunciation, and analysis skills from simple dialogues to extended academic lectures. Listening and speaking are given the same emphasis in teaching and evaluation in this class. Teaching time is split in half between the two skills. The two skills are taught in an integrated manner.  Listening and speaking micro skills are taught in context."},
"IEP096":{"description":"This 4-5 week course is created for short-term visitors coming to the U.S. in a funded project to learn about the English language and the United States culture."},
"IME311":{"description":"This course will include linear programming, model formulation, the simplex method, duality, sensitivity analysis and transportation/assignment model application. Other topics of study will be integer programming and the branch-and-bound method, which will lead to dynamic (recursive) programming. The course will conclude with network modeling, and decision analysis under uncertainty and risk."},
"IME315":{"description":"This course will include operations analysis, manual, cognitive, workplace, equipment, tool and environment design, methods of time study, performance ratings, and standards development will be included. The course will also cover safety, ergonomics, and safety management."},
"IME331":{"description":"Topics include statistical process control, capability analysis, and sampling plans.  Lab exercises include the data collection, develop of control charts for both variables and attributes, and capability analysis of processes and equipment.  The use of calculators and various statistical software packages will be required for problem solving and report generation."},
"IME411":{"description":"This course is designed to introduce the fundamentals of simulation and systems modeling. During the course, the student will gain experience in formulating an appropriate simulation model for a system, implementing the model as a computer program, and evaluating the output of the model. Topics covered include Monte Carlo techniques, sampling from and identifying stochastic distributions, methods of estimating performance measures from simulation outputs, practical applications, and procedures for validating and verifying simulation models. Special purpose simulation languages such as Arena and SIMAN will be utilized. Systems models will also be constructed using Microsoft Excel software."},
"IME412":{"description":"This is an introductory course in the area of production and inventory control systems. Topics to be covered include a general introduction to inventory management and production planning decisions, economic order quantities, heuristics and models for probabilistic and time-varying demand patterns, coordinated replenishment systems, aggregate planning and supply chain. The principles of lean, Six-Sigma, Material Requirements Planning (MRP) and Enterprise Resource Planning (ERP) will also be discussed."},
"IME414":{"description":"This course develops an understanding of key strategic issues on facility design (e.g., product flow and waste elimination). Emphasis is on designing lean industrial facilities through the design of value-added manufacturing/assembly, material handling, and storage operations and the implementation of effective manufacturing support systems. Two other topics to be covered are plant layout and facility location. Special attention is given to the plant layout topic as it is critical to ensure continuous product flow. Case studies and practical examples are used to illustrate the application of the theoretical concepts taught in class."},
"IME419":{"description":"This course provides an overview of key concepts in supply chain management directly related to transportation and logistics. Emphasis is on the strategic importance of creating value in those areas (i.e., transportation and logistics) through the systematic study of theoretical principles in transportation management, distribution, and warehousing. The adoption of best practices including the use of third-party logistics providers (3PL) is also covered. The role of information technology to enable improvements in transportation and warehousing is reviewed. Case studies and practical examples are extensively used to illustrate how to address operational issues in transportation, distribution, and warehousing to effectively increase supply chain responsiveness and performance and improve customer satisfaction."},
"IME422":{"description":"This course will serve as an introduction to the theory of automation as related to manufacturing.  Automated production equipment, numerical controls, and computer integrated manufacturing will be used to design and implement automated production systems such as flexible manufacturing, assembly, quality control, material handling, packaging, movement, and storage, and automated storage and retrieval systems."},
"IME431":{"description":"This online course provides an overview of Enterprise Resource Planning Systems (ERP systems), which provide means for the holistic, real-time integration of the key business processes (e.g. materials management, production planning and control, quality management, transportation and logistics, warehouse management, and sales and distribution) of manufacturing companies. Students learn how ERP systems facilitate process integration using the SAP ERP software and production data from a fictitious company that manufacturers bikes. To assure learning, students are required to work, using the SAP ERP software, on a variety of hands-on exercises related to the fictitious bike manufacturer in which they create transactions (e.g. generate a production or purchase order or execute a production plan) that generate an effective and timely flow of information and transactional data across the involved functional areas of the company, which enables the real-time integration of business processes. With the idea of enhancing learning, some of the hands-on exercises involve the company's Material Requirements Planning (MRP) and Master Production Scheduling System (MPS) systems. Instructional materials, including the SAP ERP software, and multiple learning activities allow students to: 1) Learn about the fundamentals, importance, and benefits of the holistic integration of business processes using enterprise software, 2) Understand the principles and logic of operation of ERP systems, and 3) Develop an appreciation of the capabilities and functionality of the application modules of the SAP ERP software."},
"IME471":{"description":"This course is the first step in the senior design sequence. The first half of the course will concentrate on conceptual system design. For the rest of the semester, the lecture will cover the analysis, evaluation and operational feasibility of systems. To coincide with this material, additional time will be allotted for students to start the senior design project. The course will be assessed through a project proposal, midterm design review, final design review. Furthermore, the students will need to turn in individual progress reports and various assignments (homework, case studies) throughout the semester."},
"IPH356":{"description":"The course will provide students with an ethical framework for decision-making in the context of a pluralistic society. Models and principles of ethical justification among a diversity of cultures and belief systems will be analyzed. Specific applications are made to concerns in clinical and healthcare management. Topics include the right to healthcare, community health ethics, end-of-life issues, and organizational ethics."},
"IPH401":{"description":"This course will introduce students to interprofessional perspectives on global health in four broad modules: Globalization, Health, Systems and Policy.  The course will draw from a range of academic disciplines at USI and from the Office of International Programs and International Partner universities.  The applications from each module will situate the concept and practice of global health as a statement that health is a fundamental quality of liberty and equity."},
"JPN101":{"description":"Fundamentals of Japanese; no previous Japanese required. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of Japan. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"JPN102":{"description":"Fundamentals of Japanese; continuation of JPN 101. Emphasis on the language skills of listening, speaking, reading, and writing in the cultural context of Japan. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"JPN203":{"description":"A continuation of JPN 102. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of Japan. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"JPN204":{"description":"A continuation of JPN 203. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of Japan. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"JPN205":{"description":"Intensive oral practice of Japanese on the intermediate level. Topics include Japanese popular culture, current events, work, and travel."},
"JPN306":{"description":"A continuation of JPN 204. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of Japan. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"JPN307":{"description":"A continuation of JPN 306. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of Japan. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"JPN499":{"description":"Research or advanced study in a specific area of Japanese, literature, culture, education, or language, culminating in an analytical paper or comprehensive exam."},
"JRN180":{"description":"Credit for adequate work toward final publication of The Shield newspaper, its Internet site, or for an approved CAP course, doing writing, editing, photography, layout, graphics, sales, and/or management."},
"JRN273":{"description":"Students will learn how to use digital photography to tell stories for the mass media.  Topics include photo composition, selection, editing, news judgment, caption writing, history, ethics, and law."},
"JRN281":{"description":"Basic fundamentals of how to gather and report news events for the mass media in such a way as to be meaningful to the public. Stress is on the need for developing writing skills, learning technical terms, writing feature stories and other materials in a newspaper."},
"JRN374":{"description":"This course provides journalism students with an up-to-date understanding of Web design and publishing processes, including conceptualizatin, production, and tracking. This course further offers an introduction to fundamental and emerging web trends. Students also will learn basic web design, HTML and CSS skills, and by the end of the semester they will be able to build a website."},
"JRN381":{"description":"Students will develop advanced skills for independently identifying, producing and publishing news stories for the mass media. Course activities and assignments will focus on finding credible information, developing source relationships, and telling stories that appeal to audiences across media platforms."},
"JRN384":{"description":"Instruction and practice in writing feature articles for newspapers and magazines. Students will be directed to submit articles for publication in magazines."},
"JRN385":{"description":"This course covers the problems generally faced in supervision of newspapers, magazines, yearbooks, and other publications."},
"JRN388":{"description":"This course focuses on producing data-driven journalism and multimedia stories for news audiences and reaching them primarily through social media platforms and other emerging technologies. Issues include professional branding; news curation; crowdsourcing; journalist-citizen interaction; social media optimization; social media economics, ethics, and law; and writing effectively for social media audiences."},
"JRN400":{"description":"This course is designed to provide an opportunity for upper-division communications majors and minors to research subject areas in the field of journalism. A maximum of six hours may be taken; only three may be taken in any one semester. Students who wish to take independent study courses in the journalism curriculum should be aware of the following points: 1) Only six hours of independent study may apply toward a major in communications; 2) No more than three hours of Journalism 400 may be directed by the same instructor; 3) Students wishing to enroll in independent study courses must receive written permission from their instructors prior to registration."},
"JRN421":{},
"JRN423":{"description":"A capstone course examining the contemporary issues and trends confronting journalists. The course regularly covers journalism theories, research and trends; media industry and economics, journalism law and ethics; news audiences; international journalism; politics and journalism; and news and communications technologies."},
"JRN481":{"description":"Students will produce a semester-long multimedia investigative story that requires the use of public document searches and requests, database skills, and advanced interviewing techniques."},
"JRN488":{"description":"This course is about the expression of news using digital tools, such as web authoring, image processing, drawing, and animation software, to involve audiences in novel ways through interactivity and immersion. Students will be exposed to digital forms and presentations of news that exploit the strengths of the new medium."},
"JRN489":{"description":"Offers students job experience in the discipline through a minimum of 50 hours of supervised professional work per credit hour."},
"JRN496":{"description":"This course focuses on the importance of ethical journalism in a free society and the impact of press coverage on sources, victims, news consumers, and journalists. Students will study the ethical dimensions of responsible journalism; analyze ethical problems; recognize cultural stereotypes; and learn how to ensure that the voices of both mainstream America and traditionally under-reported groups are heard in media reports."},
"JRN499":{"description":"Topics will vary. Purpose of course is to cover topics that are not specific courses in the curriculum."},
"KIN112":{},
"KIN114":{},
"KIN118":{},
"KIN122":{},
"KIN123":{},
"KIN124":{},
"KIN125":{},
"KIN132":{},
"KIN134":{},
"KIN137":{},
"KIN138":{},
"KIN141":{},
"KIN142":{},
"KIN143":{},
"KIN145":{},
"KIN151":{},
"KIN155":{},
"KIN161":{},
"KIN162":{},
"KIN163":{},
"KIN181":{},
"KIN182":{},
"KIN183":{},
"KIN185":{},
"KIN186":{"description":"Students will assess present level of physical fitness and wellness and establish behavior modification strategies to address areas which need improvement. Topics of discussion include nutrition, weight control, components of physical fitness, stress management, tobacco use and addictive behaviors, and sexually transmitted diseases."},
"KIN188":{"description":"Orientation to career opportunities in physical education, exercise science and sport-related fields. Examination of sociological, historical, and philosophical foundations, current issues and trends."},
"KIN192":{"description":"This course will provide students with the opportunity to develop skills to live more wisely through wellness/fitness knowledge, current status, opportunities, and assessments.  The students will learn about the importance of wellness/fitness in their everyday lives.  Students will have the opportunity to customize their own behavioral plans for wellness/fitness.  Areas of study include body composition, cardiorespiratory health, nutrition, weight management, and substance abuse."},
"KIN199":{"description":"Topics include:  Yoga, Boot camp, Pilates, Zumba, Spinning, Taekwondo, Backpacking, Canoeing, and Fitness Walking and Cardio Kickboxing"},
"KIN201":{"description":"As an introductory course in sport, exercise and popular culture, emphasis is placed on the history and development of media, film, and critical analysis of popular culture. Students will gain a deep understanding and appreciation for the cultural impacts of sport in film and will examine how visual representations of sport and exercise in film shape modern society. Course topics will include the examination of various dominant cultural ideologies as seen in film, including race, gender, masculinity/femininity, (dis)ability, and nationalism (among others)."},
"KIN232":{"description":"An in-depth look at the mechanics, rules, and officiating techniques for each sport with emphasis on high school interscholastic participation. The IHSAA or appropriate licensing examination may be taken at the completion of each course. An additional fee will be charged for administration of exam."},
"KIN234":{"description":"An in-depth look at the mechanics, rules, and officiating techniques for each sport with emphasis on high school interscholastic participation. The IHSAA or appropriate licensing examination may be taken at the completion of each course. An additional fee will be charged for administration of exam."},
"KIN237":{"description":"An in-depth look at the mechanics, rules, and officiating techniques for each sport with emphasis on high school interscholastic participation. The IHSAA or appropriate licensing examination may be taken at the completion of each course. An additional fee will be charged for administration of exam."},
"KIN238":{"description":"An in-depth look at the mechanics, rules, and officiating techniques for each sport with emphasis on high school interscholastic participation. The IHSAA or appropriate licensing examination may be taken at the completion of each course. An additional fee will be charged for administration of exam."},
"KIN244":{"description":"ARC certification in lifeguarding."},
"KIN281":{"description":"Examination of attitudes, research facts, and misconceptions pertaining to personal health needs and practices. Topics include but are not limited to nutrition, family living, sexuality, drug use and abuse, disease prevention, safety, first aid, and public health resources. NC1"},
"KIN282":{"description":"This course certifies students through the American Heart Association in  both First Aid and Basic Life Support for the Healthcare Provider.    This class stresses the basic steps to follow in an emergency situation which include prevention of disease transmission, assessment, care of life-threatening emergencies, and basic medical emergency treatments."},
"KIN284":{"description":"A study of the elements which manifest stress, its impact, and strategies to minimize its effect on daily life."},
"KIN285":{"description":"Explores the use of a variety of discipline specific technology related to the fields of Exercise Science, Sport Management, Kinesiology and Physical Education.  Topics will include effective use of Word, PowerPoint, Excel, and Publisher as productivity and promotional tools for health related professions.  Students will also develop skills and an understanding of the appropriate use, design, and development of technology related resources."},
"KIN286":{"description":"This course is designed to examine the hazards present in all aspects of modern life that may produce property damage, injury, and/or illness. The activities of transportation, recreation, education, and home living are assessed for safety. Intervention strategies are presented."},
"KIN291":{"description":"A study of the motor development of children through adolescence, their motor learning capabilities, and the factors which influence skill acquisition, retention, and transfer."},
"KIN304":{"description":"This course will explore the identification and evaluation of research problems within the fields of kinesiology and sport.  Students will engage in the interpretation of research in sport management, exercise science, human movement science, leisure, and athletic training; and will learn basic statistical analysis and interpretation.  Attention will be paid to the research process including research design, data collection methods and dissemination of pertinent results."},
"KIN342":{"description":"Students will receive preparation for certification as an American Red Cross water safety instructor."},
"KIN385":{"description":"Students will learn to teach exercise routines for a group setting. The course will serve to assist students in preparing for group exercise certification."},
"KIN390":{"description":"A study of selected topics of current interest and importance in kinesiology. These topics may include opportunities for students to complete research, participate in experiential learning experiences, and a wide variety of learning activities in accordance with the interests and importance of kinesiology and sport."},
"KIN393":{"description":"Provides students with practical experience in kinesiology-related fields.  Students have the opportunity to observe professionals on campus at the Recreational, Fitness, and Wellness Center, learn about personal training and group exercise programs, and basic operations of a facility."},
"KIN432":{"description":"The components of coaching each sport will be analyzed from the perspective of the coach. Topics will include conditioning, fundamentals, team strategy, tournaments, meets and matches, and rules and their application to game play."},
"KIN434":{"description":"The components of coaching each sport will be analyzed from the perspective of the coach. Topics will include conditioning, fundamentals, team strategy, tournaments, meets and matches, and rules and their application to game play."},
"KIN437":{"description":"The components of coaching each sport will be analyzed from the perspective of the coach. Topics will include conditioning, fundamentals, team strategy, tournaments, meets and matches, and rules and their application to game play."},
"KIN438":{"description":"The components of coaching each sport will be analyzed from the perspective of the coach. Topics will include conditioning, fundamentals, team strategy, tournaments, meets and matches, and rules and their application to game play."},
"KIN485":{"description":"This course introduces students to methods of personal training in helping students learn how to work with clients during the initial consultation process, the design of appropriate cardiovascular, strength, and flexibility training programs, and proper orientation techniques."},
"KIN492":{"description":"Enables students to integrate information from their educational experiences to critically examine and analyze contemporary issues in sport and exercise from an interdisciplinary perspective. Current issues, trends, and challenges are presented with the intent of provoking thorough and stimulating debate so students may identify their role in the resolution of issues."},
"KIN493":{"description":"Practical experience in an area of professional preparation."},
"LATN101":{"description":"Fundamentals of the language will be taught through a multiple approach (comprehension, reading, and composition). The class will be conducted in English with emphasis placed on acquiring the ability to read and comprehend written Latin."},
"LATN102":{"description":"Continuation of LATN 101. Continued development of Latin grammar, syntax, vocabulary, and reading skills. Introduction to major Roman literary figures and themes of Roman culture."},
"LATN203":{"description":"A thorough review of Latin grammar, syntax, vocabulary, and reading skills. Readings in Latin prose and poetry will provide students with an understanding of Roman culture and literature."},
"LATN204":{"description":"Continuation of LATN 203. Review of grammar and syntax will include concepts not covered in LATN 203. An emphasis on reading Latin prose and poetry to develop students' interpretative skills."},
"LATN344":{"description":"Students will study and analyze selected Latin literature in English translations. These will include various writings in both prose and poetry (verse), continuing through the Golden and Silver ages of Latin literature, culminating in discussions of later Latin literature and several Christian writers."},
"LATN390":{"description":"Independent study of directed readings, either of the prose author (e.g., Cicero) or Latin poet (e.g., Virgil)."},
"LIBA290":{"description":"A course designed for introductory-level interdisciplinary special topics in the liberal arts."},
"LIBA490":{"description":"A course designed for advanced-level interdisciplinary special topics in the liberal arts."},
"MATH081":{"description":"Emphasis is on algebraic concepts, including numeric and algebraic expressions, solutions to equations and inequalities, linear equations in two variables, properties of exponents, operations with polynomials, functions, systems of equations, data analysis, and basic geometry."},
"MATH082":{"description":"Emphasis is on operations with polynomials, rational and radical expressions, and solutions to quadratic, rational and radical equations."},
"MATH085":{"description":"Emphasis is on algebraic concepts, including numeric and algebraic expressions, solutions to equations and inequalities, linear equations in two variables, properties of exponents, operations with polynomials, functions, rational and radical expressions, solutions to quadratic, rational and radical equations, and operations involving radicals."},
"MATH100":{"description":"Concepts from elementary algebra, functions, properties of exponents, operations with polynomials, rational and algebraic expressions, and solutions to equations and inequalities."},
"MATH101":{"description":"This course is a supplement to MATH 100; topics include concepts from elementary algebra. Concurrent enrollment in the corresponding section of MATH 100 is required. This supplemental course is mandatory for students who have not been successful (C or better) in MATH 100 after having attempted it twice or for students who were placed into GENS 097 and did not pass it on the first attempt."},
"MATH103":{"description":"This course is the first course in a two-course sequence designed to enhance the conceptual understanding and processes of the common content in elementary mathematics curricula. Topics include problem solving, counting, the structure of the decimal system and procedural and conceptual understanding of the four basic operations on whole numbers, decimals and fractions. The use of manipulatives and technology will support learning and teaching in these and other topics studied. Enrollment is only open to students seeking a degree in elementary education or a related degree."},
"MATH107":{"description":"This course focuses on understanding the mathematics required for nursing and health professions. Topics include algebraic concepts and procedures (ratios, proportions, percentage problems, formulas), geometric concepts and procedures (systems of measurements and conversions, area, volume), problem-solving techniques (modeling, dosage calculations, flow-rate calculations) and an introduction to statistical methods and procedures (measures of central tendency, interpreting graphs, range, standard deviation, and normal distributions)."},
"MATH111":{"description":"Topics to be emphasized include polynomials, rational algebraic expressions, graphs, inequalities, theory of equations, and matrices. Special attention is focused on functions and the utilization of appropriate technology."},
"MATH112":{"description":"Emphasis is placed upon trigonometric functions, their properties, applications, and graphs, as well as trigonometric identities, solutions of triangles, trigonometric equations and vectors. This course does not apply toward the major or minor in mathematics."},
"MATH114":{"description":"This course is designed as a foundational experience in quantitative reasoning that will employ real world applications for students to enhance their skills as problem solvers, critical thinkers, informed decision makers and intelligent consumers of quantitative information.  The course addresses a diverse set of mathematical topics including logic, data analysis, probability, linear and exponential models, personal finance, and geometry."},
"MATH115":{"description":"Advanced topics in algebra plus selected topics in trigonometry and elementary functions (polynomials, rational, algebraic, exponential, logarithmic, and trigonometric) are studied with emphasis upon notation, properties, operations, and graphs of functions and their inverses."},
"MATH118":{"description":"This course is designed for students planning to enroll in MATH 230 - Calculus I. The study of functions (polynomial, rational, exponential, logarithmic, algebraic, and trigonometric) forms the foundation for this course. This course will promote the development of algebraic and analytic skills as well as conceptual understanding."},
"MATH122":{"description":"Emphasis is placed on two- and three dimensional coordinate geometry, lines, circles, conic sections, planes, spheres, surfaces, and basic properties of vectors."},
"MATH202":{"description":"This course includes extensions of the fundamental concepts studied in MATH 103 with emphasis on the procedures as they relate to the early-elementary student.  Topics include processes in advanced counting, the four basic operations, elementary fractions, decimals, probability, statistics, angles and other geometric concepts beyond shapes.  The use of manipulatives and technology will support the teaching and learning for this course.  Enrollment is open only to students in the Early Childhood Program."},
"MATH203":{"description":"This course is the second in a two-course sequence designed to enhance the conceptual understanding and processes of the common content in elementary mathematics curricula. Topics include proportional reasoning, algebraic reasoning, geometry, measurement, data analysis, and probability. The use of manipulatives and technology will support learning and teaching of the topics studied. Enrollment is only open to students seeking a degree in elementary education or a related degree."},
"MATH213":{"description":"This course is designed to develop conceptual understandings for topics in algebra and number theory. The study of sequences, the binomial theorem, fundamental theorem of arithmetic, modular arithmetic, systems of linear equations, matrix arithmetic and algebra, and matrices will be covered in this course; the use of manipulatives and technology will support the teaching and learning of these topics."},
"MATH215":{"description":"An introduction to calculus and its applications in business, economics, and the social sciences. Not applicable to the mathematics major or minor; this course is applicable to the elementary and junior/middle school math minor."},
"MATH230":{"description":"The theory of limits, differentiation, successive differentiation, the definite integral, indefinite integral, and applications of both the derivative and integral."},
"MATH235":{"description":"Integrating and differentiating transcendental functions, applications of the integral, numerical integration techniques, and infinite series."},
"MATH236":{"description":"The course will provide opportunities to gain a deeper understanding of geometry as a study of size, shape and properties of space; a tool for problem solving; and a way of modeling physical situations. Topics include the study of logic, polygons, solids, Euclid's postulates, congruent figures, similarity, rigid motion and symmetry, vectors and transformation, and other geometries; the use of manipulatives and technology will support the teaching and learning of these topics."},
"MATH253":{"description":"Introduction to Mathematical Reasoning is a transition to advanced mathematics, with a focus on precise mathematical reasoning and communication. Introductory topics in mathematical logic and proof-writing are discussed, including direct proof, proof by contradiction and contraposition, and mathematical induction. These ideas are applied to problems in elementary number theory, set theory, functions and relations, and cardinality. Further topics may be discussed if time allows."},
"MATH291":{"description":"This course was designed to enhance the conceptual and procedural understandings of the mathematics that is taught at the secondary level—number theory, algebra, geometry, functions, probability and statistics. Concepts and problems will be viewed from an advanced perspective where the students will investigate alternate definitions and approaches to mathematical ideas; consider proofs, extensions and generalizations of familiar theorems; investigate multiple approaches to problem solving, and study connections between topics from different courses. Understanding and communication of mathematical concepts and processes will be emphasized; the use of technology and manipulatives will be used when appropriate. This course will not serve as an upper-level mathematics elective for the major or minor in mathematics."},
"MATH323":{"description":"An introduction to the foundations of geometry. Topics from finite, Euclidean, and non-Euclidean geometries are explored. Properties of polygons, convexity, and various geometric shapes are explored."},
"MATH331":{"description":"Techniques from algebra, calculus, probability, and other areas of mathematics are employed to solve problems arising in the biological, physical, and social sciences."},
"MATH335":{"description":"Vectors in two- and three-dimensions, surfaces and space curves, partial differentiation, multiple integration with applications of each."},
"MATH345":{"description":"This course is an introduction to the complex number system and its applications.  No previous experience with the complex number system is required.  Topics covered include representations and properties of complex numbers, differentiation and integration, the Cauchy-Riemann equations, analytic and harmonic functions, and Laurent series."},
"MATH353":{"description":"Topics in discrete methods, combinations, graph theory, and finite algebraic structures will be studied. The applications of these topics in computer science, physical and life science, as well as in social sciences makes the course valuable for students in many majors."},
"MATH362":{"description":"Systems of linear equations, vector spaces, basic properties of matrices and determinants, linear transformations on a vector space, and canonical representations of a linear transformation."},
"MATH366":{"description":"Differential equations provides an introduction to solutions of ordinary differential equations with applications. Topics include first-order differential equations, second and higher order linear differential equations including the method of Laplace transform and linear systems of differential equations."},
"MATH375":{"description":"This course is an introduction to interest theory and mathematical finance.  Topics include calculation of interest and yield rates; present, future, discounted and accumulated values; and time intervals involved in cash flow transactions.  Both continuous and discrete time models will be considered. This course provides the basic foundation for Exam FM administered jointly by the Society of Actuaries and Casualty Actuarial Society. This course does not satisfy the requirements for a degree in mathematics."},
"MATH391":{"description":"This course is designed to develop the content knowledge and instructional practices necessary to teach mathematics in grades 5-12, including ratios and proportional reasoning, geometric transformations, triangle centers, and rates of change for linear, quadratic, polynomial and exponential functions. Topics will include mathematical process standards, lesson planning, the use of technological and manipulative tools in the teaching and learning of mathematics, assessment methods, and current research in mathematics education. This course will not serve as an upper-level mathematics elective for the major or minor in mathematics."},
"MATH392":{"description":"The materials, devices, and methods of teaching mathematics in the elementary schools."},
"MATH393":{"description":"This course is designed to provide students interactive experiences to design and enact mathematics instruction in grades K-8 and to assess the development of the mathematics learners. Guided field experience required."},
"MATH410":{"description":"This course is an introduction to mathematical analysis on the set of real numbers. Emphasis of the course is placed on the writing and critiquing of proofs concerning basic concepts of analysis. Topics include the completeness properties of the real number system, sequential limits and convergence theorems, basic topology of real numbers, continuity of functions, and uniform convergence of sequences of functions."},
"MATH411":{"description":"Math 411, Theory of Numbers is an introduction to classical number theory.  This course studies the fundamental properties of the integers and the solution of linear and quadratic equations over the integers.  Topics include prime factorization, congruences, Diophantine equations, the theorems of Fermat and Euler, and quadratic reciprocity.  The course is rich in material useful for the secondary teacher of mathematics."},
"MATH412":{"description":"Abstract Algebra offers an introduction to the main ideas of abstract mathematics and algebraic structures.  The primary focus of this course will be group theory with an introduction to ring and field theory.  Emphasis of the course is placed on the writing and critiquing of mathematical proofs concerning algebraic structures."},
"MATH414":{"description":"Rings, integral domains, fields, quaternions, including discussions of characterizations of integers, embedding theorems, polynomials, and extension fields."},
"MATH426":{"description":"Definition and properties of a topological space; continuous functions, and homeomorphisms, various topological properties such as compactness, separation, and connectedness; metric spaces."},
"MATH432":{"description":"Functions of several variables, transformations, multiple integrals, line and surface integrals, infinite series."},
"MATH434":{"description":"Advanced Differential Equations continues the study of differential equations.  Topics include systems of ordinary differential equations, boundary value problems, non-linear and partial differential equations.  An emphasis is placed on theoretical understanding of differential equations and their solution using numerical and approximation techniques."},
"MATH437":{"description":"A survey of current methods in numerical analysis. Topics will include polynomial approximation and interpolation, numerical differentiation and integration, numerical solutions of differential equations, functional approximation, solution of linear and nonlinear equations, and matrix methods for inverse and eigenvalue problems."},
"MATH438":{"description":"This course is an introduction to calculus-based probability.  Topics include sample spaces, discrete and continuous distributions, multivariate and conditional densities, and random variable transformation methods."},
"MATH492":{"description":"An examination of the historical development and perspectives of number, algebra, geometry, trigonometry, statistics, probability, discrete mathematics, and calculus including contributions of significant figures and diverse cultures."},
"MATH499":{"description":"By consent of instructor and approval of the chair of the mathematics department."},
"ME121":{"description":"Principles of engineering modeling and documentation in a computer environment. Specific topics to be covered include visualization, geometric modeling of objects in a computer-aided design environment, and application of documentation standards and conventional engineering representations. Emphasis is placed on freehand sketching as a means of communication and on the accurate generation of three-dimensional computer models and documentation files."},
"ME225":{"description":"Introduction and application to the laws of thermodynamics; analysis of closed and open systems; introduction to heat transfer, carnot principle, engine power plants, and refrigeration applications."},
"ME363":{"description":"An introduction to vibration theory, including the modeling and analysis of oscillatory phenomena found in linear discrete and continuous mechanical systems. This course will introduce noise and vibration control as an application of vibrations theory. A hands-on laboratory will greatly enhance the learning experience and bridge the gap between theory and practice."},
"ME364":{"description":"The design and analysis of mechanical systems considering theories of static failure, fatigue, impact loading, and fracture mechanics; with a special emphasis placed on material selection."},
"ME365":{"description":"Introduction to unified approach to lumped-element modeling and analysis of mechanical, electrical, hydraulic, and mechatronic systems. Topics include graphical modeling using band graphs; formulation of stale-space equations; analysis of linear systems; determination of time and frequency domain response of such systems to transient and periodic inputs, block diagram representation of dynamic system using LaPlace Transforms; and integration into feedback control systems."},
"ME366":{"description":"The synthesis and analysis of mechanical linkages and cams."},
"ME367":{"description":"This course provides students with an overview of biomechanics and modeling of biomechanical systems. Topics include hard and soft tissue mechanics, animal and human movement, biofluid processes. In addition, students will build mathematical models of these systems for design and analysis."},
"ME395":{"description":"An introductory course to the theory and application of the finite element method.  Students will learn techniques necessary to apply the finite element method properly.  In addition, students will use finite element programs, including commercial software, to design, analyze and solve problems in structural and solid mechanics.  Topics covered in the course include design and analysis of structures using rod, truss, beam, frame, shell, and solid elements."},
"ME463":{"description":"An investigation into heat transfer and the formulation of the fundamental principles and laws that govern conduction, convection, and radiation for both steady state and transient conditions with their application in the analysis and design of actual processes and heat exchanges."},
"ME465":{"description":"Application of the fundamental principles of heat transfer and fluid flow in the engineering analysis and design of fluid thermal systems (piping layouts, air conditioners, heat exchangers, power plants, solar and wind power, compressors, etc.)."},
"ME466":{"description":"Engineering design and analysis of fundamental mechanical machine elements; including fasteners, gears, bearings, brakes, shafts, couplings, and flywheels. Synthesis of a machine containing two or more fundamental components."},
"ME473":{"description":"An introduction to the fundamentals of feedback control system design and analysis with emphasis given to frequency domain methods. Modeling, transfer functions, block diagrams, stability, and system specification will be reviewed. The Bode Ideal Cutoff design method will be thoroughly examined and compared to other techniques including root locus and PID control. Includes computer simulations using MATLAB."},
"ME475":{"description":"This course defines fluid power, its advantages and limitations, the prevailing standards, and graphic symbols. It ties in the many applications of fluid mechanics. Various components are examined and used in designing complete circuits. Mathematical and physics based modeling will be introduced which opens the capability to simulate any fluid power system."},
"MKTG201":{"description":"An introductory course designed to apply the marketing process to the specific interests of associate degree candidates and non-business majors. Emphasis upon retailing, personal selling, advertising, and marketing strategy. (Not open to College of Business majors in baccalaureate programs.)"},
"MKTG305":{"description":"A general survey of the entire field of marketing. Attention is given to the management of the marketing efforts of the individual firm. Emphasis is on the role of the marketing environment, understanding markets and customers, and decision making regarding products, pricing, distribution, and promotion."},
"MKTG313":{"description":"Emphasis is placed on the role of services in today's economy. The differences in marketing goods and the marketing of services is stressed. Strategy development for a wide variety of services including financial, medical, and entertainment is highlighted."},
"MKTG332":{"description":"An interdisciplinary approach to the analysis and interpretation of consumer buying habits and motives, and the resultant purchase of goods and services. The purchaser's psychological, economic, and sociocultural actions and reactions are stressed."},
"MKTG334":{"description":"An examination of the promotional techniques available to marketing management. Emphasis is given to market analysis and to the communication process providing the means by which products can be effectively promoted. The specific tools of personal selling, advertising, sales promotion, and publicity are examined as components of overall marketing strategy."},
"MKTG342":{"description":"An examination of the marketing function within organizations marketing goods and/or services to other organizations (rather than final consumers). Major emphasis on business buyer behavior and the business marketing environment as key factors shaping business marketing strategy development, planning, implementation, and control."},
"MKTG344":{"description":"Study of the sales function in a marketing organization, development of techniques for making an effective sales presentation, and developing and maintaining account relationships. The course also will review major sales management functions including organization, allocation, recruitment, selection, training, motivation, compensation, and sales force evaluation and control."},
"MKTG355":{"description":"This course focuses on the integration of state-of-the-art interactive technologies into the design and implementation of marketing programs for the new millennium. The functions of market identification through customer analysis, and the planning and implementation of conception, pricing, promotion and distribution of ideas, goods, and services to satisfy the market benefit immensely from the capabilities of the rapidly developing information technology (IT) infrastructure. The course also includes introduction into different techniques for differentiating products and services and developing systems to focus on the market niche, establishing interactive links to customers, retrieving online information from customers, and using data mining for market research."},
"MKTG438":{"description":"The systematic, objective, and exhaustive search for and analysis of data relevant to problems in the field of marketing. Attention is given to marketing research procedures such as project design, sampling, data collection, data analysis, and reporting. Management's treatment of and reliance upon this important marketing tool are considered."},
"MKTG444":{"description":"The course is designed to help students prepare to face the changing sales environment of many organizations, brought by fierce global competition. Students are introduced to sales management concepts, terms, and the management of field sales forces. The course will highlight the broad domain of sales management, skills to handle increasing demands of buyers, and career opportunities in sales management. The course will provide students with an appreciation of the ethical issues involved with sales management."},
"MKTG447":{"description":"Policies, practices, and problems of efficiently operated retail stores, including the study of such areas as store location, layout, organizations, merchandise planning and control, pricing, buying, and sales promotion."},
"MKTG448":{"description":"Application of problem solving in the area of marketing management, emphasizing planning the marketing effort, management of the marketing organization, and control of marketing operations."},
"MKTG461":{"description":"Approaching organizations' communications issues through the integration of advertising, marketing, and public relations strategies and techniques.  Team project required."},
"MKTG471":{"description":"The whole of enterprise, comparative marketing, and transport institutions and systems in selected foreign countries and the United States. The managerial and operational problems of world enterprise, with emphasis on the role of ethnic and cultural differences in influencing marketing strategy. Case studies will be used."},
"MKTG475":{"description":"Individual investigation of selected marketing problems of contemporary significance will be analyzed and related to assigned readings and current periodical literature. Case studies and field trips also will be used to acquaint the student with actual practices currently employed in marketing."},
"MKTG490":{"description":"Research and reading in the student's major area of interest. May be taken on an arranged basis upon approval of the College of Business dean and the instructor."},
"MNGT141":{"description":"A survey course to acquaint students with functions performed by business and the part business activities play in our economy as a whole. Designed to give familiarity with common business practices and terminology. This course is not open to students who have completed or are currently enrolled in three or more courses in business or economics."},
"MNGT201":{"description":"A survey of the management process; emphasis upon management techniques and actors basic to various fields of business, industrial, and public organizations. This course is designed to apply the management process to the specific interests of all associate degree candidates and four-year non-business majors. (Not open to College of Business majors in baccalaureate programs.)"},
"MNGT305":{"description":"A study of the principles of management theory and practice in organizations, with emphasis placed on the challenges of management in a diverse and complex environment."},
"MNGT315":{"description":"This course is the first of the two courses that helps students to obtain required skills for project management in accordance with nationwide standards and the Project Management Institute (PMI). Students are taught proficiency to prepare to take the nationwide project management certification exam. During the course, students work on short projects after each chapter to help them understand and apply the chapter's material and step-by-step advance toward the real-life applications of project management."},
"MNGT316":{"description":"This course is the second of the two courses that helps students to secure the required skills for project management in accordance with nationwide standards and the Project Management Institute (PMI). Students will finish developing the proficiency needed to take the project management certification exam. During the course, students work on short projects after each chapter to help them comprehend and apply the chapter's material and gradually advance toward the real-life applications of project management."},
"MNGT341":{"description":"The staff work required in planning and controlling the personnel functions in businesses and the personnel responsibilities of line executives. Emphasis is placed upon principles and procedures relative to selection, placement, training of employees, employees' services, morale, wages, and hours."},
"MNGT352":{"description":"This course focuses on the generation, testing, evaluation, and refinement of ideas. Innovation processes that focus on idea development, idea selection, and commercialization of ideas will also be examined. Exploration of the process and stages of creating an innovative new venture will provide the context for discussing the interrelationships among ideation, innovation, commercialization, and entrepreneurial thinking."},
"MNGT353":{"description":"Students in this course will evaluate the viability of a business venture and develop a business plan. The feasibility component of this course assesses the market opportunity, strategic position operations, risks, and the ability of the product or service to generate payback for the prospective firm.  The business plan component of this course addresses the organizational plan, product/service plan, marketing plan, and financial projections for the prospective business."},
"MNGT354":{"description":"Students in this course will bridge the gap between entrepreneurial thought and practice by focusing on activities that must be carried out by a company within the framework of strategic entrepreneurship.  This framework highlights the activities that create, deliver, and capture value for an enterprise.  The course is designed to immerse students actively in the strategic entrepreneurial process with the objectives to reinforce and develop divergent thinking, analytical thinking, and communication skills.  This course will develop skills associated with opportunity recognition (convergent and divergent thinking) and feasibility analysis (market and financial analysis), and extend these to the development and communication of a business model in an atmosphere that is based on team collaboration and coaching."},
"MNGT361":{"description":"An inquiry into the purposes, methods, institutions, results, and philosophy of business and its relationship to the environment in which it functions."},
"MNGT408":{"description":"An overview of the theoretical and practical aspects of negotiation. Students will be engaged with practical experience through role play in addition to group discussion. Emphasis on the entire process of negotiating including preparation, negotiation, and post-negotiation evaluation."},
"MNGT441":{"description":"A comprehensive study of wage and salary policies and techniques, including wage and salary level determination, job evaluation, employee evaluation, merit rating, methods of wage payments, fringe benefits, and controls."},
"MNGT442":{"description":"This course is designed to provide students with an in-depth study of the theories and skills associated with training and developing human resources.  Specific attention will be given to planning, designing, implementing, and evaluating training and development programs.  Broader issues concerning employee development and training for specific needs will also be addressed."},
"MNGT443":{"description":"This course involves the management challenge of designing organizational structure to facilitate effective performance and achieve competitive advantage given the evolving nature of organizational environments. Issues will include organizational innovation and change; technological change and organizational restructuring; global competition; organizational culture; employee involvement; participative management and team systems; total quality management; organizational control; communication; and conflict."},
"MNGT444":{"description":"A study of cultural perspectives and processes reflecting individual, work group, and organizational diversity in the workplace."},
"MNGT445":{"description":"International and cross-cultural study of business decisions, enterprises, markets, and institutions. Examines globalization of industries and firms' competitiveness. Discusses international business transactions and entry strategies."},
"MNGT452":{"description":"This capstone course requires students to develop interdisciplinary solutions for addressing contemporary business problems. In addition to integrating the major fields of business (accounting, finance, marketing, management, computer information systems), this course requires students to address a variety of different topics, such as social issues, diversity, environmental concerns, global/cultural differences, ethics, economics, critical thinking, problem identification, problem solving, communication skills, quantitative analysis, technology, current events, and politics. Through the use of cases, presentations, written projects, class discussions, lectures, and current periodicals, students are exposed to the complexities of conducting business in a changing world."},
"MNGT455":{"description":"Application of business principles to the operation of small business firms; includes emphasis on management functions, elements of the marketing mix, business practices, and problem solving. Students in teams of two to five are assigned as a counseling unit to a small business firm requesting management counseling."},
"MS101":{"description":"The course introduces students to the basic officer competencies, establishes a firm foundation for continued study in higher ROTC courses, and instructs basic life skills pertaining to personal fitness and interpersonal communication skills. Students will be introduced to the U.S. Army values, national values, and expected ethical behavior. Students will be exposed to the unique duties and responsibilities of officers and the expectations of selfless service, dedication, and duty to the nation. Designed to introduce basic soldier skills and squad-level tactical operations. Attention is devoted to development of leadership potential through practical exercises both in and out of the classroom."},
"MS102":{"description":"Examines the leadership process as affected by individual differences and styles, group dynamics, and personality behavior of leaders. Will introduce a generic model of problem solving. Teaches the basic skills that underlie effective problem solving in different work environments. Instructs how to relate the problem-solving model and basic problem-solving skills to the resolution of military problems. Students will experience an introduction of fundamental leadership concepts and examine factors that influence leader and group effectiveness. Designed to teach basic soldier skills and squad level tactical operations. Student involvement in briefings and hands on practical exercises. Attention is devoted to development of leadership potential through practical exercises both in and out of the classroom."},
"MS201":{"description":"Develops basic leadership abilities and management skills through instruction and hands on practical exercises. Introduces principles and techniques of effective written and oral communication. Teaches practical leader skills and examines the principle of subordinate motivation and organizational change. Students will apply leadership and problem solving to a complex case study/simulation. Class is designed to develop individual team skills, decision-making abilities, and test basic tactical proficiency skills as well as improve planning and organizational skills both in and out of the classroom environment. Teaches hands-on soldier skills and squad-level tactical operations. Attention is devoted to development of leadership potential through practical exercises both in and out of the classroom."},
"MS202":{"description":"Further develops leadership skills by focusing on conventional basic squad and small unit tactics and introduces students to the basic tactical principles of maneuver. Examines the roots of national and Army values and better citizenship. Allows students to apply principles of ethical decision-making and resolve ethical issues in case studies. Examines the legal and historical foundations and duties and function of the Army officer. Students will analyze the roles officers played in the transition of the Army from the Vietnam Conflict to the 21st Century. Teaches basic soldier skills and squad-level tactical operations. Special attention is devoted to development of leadership potential through practical exercises both in and out of the classroom."},
"MS204":{"description":"Conducted at Fort Knox, Kentucky, home of the United States Armor Branch, during the summer months, covering a training period of approximately 30 days of paid training and excitement. The Department of Military Science ROTC battalion provides travel to and from Fort Knox. While at camp you will meet students from all over the nation while earning approximately $800 in pay and receive free room and board while at camp. While at camp, you may apply for a two-year ROTC scholarship to cover up to $16,000 at selected high-cost universities to pay for your remaining two years of college, $510 annually for books, and earn a monthly stipend of over $200 for 10 months per year. The Basic Camp is a way to catch up on missed Military Science courses in order to qualify the student to contract into the Advanced ROTC Course at USI."},
"MS241":{"description":"Focuses on U.S. military activity from the French and Indian War through the Spanish American War. Emphasis is on issues in command, impacts of technology, and the evolution of strategy and tactics in modern warfare. Students will conduct a battle analysis using a variety of techniques."},
"MS242":{"description":"Focuses on U.S. military activity from before WWI through the Persian Gulf War as well as highlighted concepts in contemporary peacekeeping operations. Emphasis is on issues in command, impacts of technology, and the evolution of strategy and tactics in modern warfare. Students will conduct a battle analysis using a variety of techniques."},
"MS301":{"description":"Designed for those students who contract with Army ROTC to continue their military studies in pursuit of a commission as on officer into the Army following graduation from college. Course focus is to build cadet leadership competencies in preparation for attendance and successful completion of ROTC Leader Development and Assessment Course, Ft. Lewis, Washington. Provides an in-depth review of the features and execution of the Leadership Development Program, providing the cadet with periodic assessment of performance in leadership positions. Students will study squad and platoon-level tactics, troop-leading procedures, mission analysis, land navigation skills training, military operations plans and orders development, execution of squad battle drills, and basic briefing techniques."},
"MS302":{"description":"Course is a follow-on module to the MS 301 class, preparing cadets for attendance and successful completion of ROTC Leader Development and Assessment Course, Ft. Lewis, Washington. Focus will center on advanced self-development through the Leadership Development Program and an advanced-learning environment of doctrinal leadership and tactical operations at the small unit level. Cadets will plan and conduct individual and collective skill training for offensive operations and a Field Training Exercise during the spring semester. Cadets will be exposed to the developmental counseling program throughout the course period."},
"MS401":{"description":"Designed to develop, train, and transition the advanced course graduate from cadet to lieutenant for service as an officer. Cadet will study Army staff organizations, how they function, and the processes of the army's hierarchy organizational structure. Students will learn in-depth counseling responsibilities/methods, officer and non-commissioned officer evaluation report development, officer evaluation report support form development, and training plan development. Course analyzes the legal aspects of decision-making and leadership in action. Course will expose cadets to the foundations of leadership, operational law, and the key aspects of the Uniformed Code of Military Justice. Cadets will receive training on basic leader responsibilities to foster an ethical command climate and how to meet moral obligations, as well as leader responsibilities to accommodate subordinate spiritual needs."},
"MS402":{"description":"Continued advanced development and transition of the advanced camp graduate from cadet to lieutenant for service as an officer. Course is a follow-on module to the MS 401 class. Course will expose cadets to the in-depth study of leadership, operational law, and the key aspects of the Uniformed Code of Military Justice with a hands-on approach with interactive scenarios being utilized in class. Students will undergo hands-on training and instruction in Joint Ethics regulations, joint strategic level operations, study of army administrative and logistics management, in-depth counseling techniques, and duty at first military assignment. Course will cover the Army's training philosophy, METL development, equipment readiness, and rules of engagement during deployment operations. Students also will receive training in personal awareness financial planning."},
"MUS161":{"description":"The fundamentals of music theory through part-writing and analysis. Course content includes key signatures, scales, intervals, triads, and an introduction to figured bass."},
"MUS162":{"description":"A continuation of MUS 161 emphasizing inversions of triads, the dominant seventh chord, non-harmonic tones, and elementary modulations through part-writing, composition, and analysis with and without figured bass."},
"MUS171":{"description":"This course offers a practical application of the materials studied in MUS 161 and provides the necessary drill in the skills of sight-singing, aural perception, and the dictation of music."},
"MUS172":{"description":"This course offers a practical application of the materials studied in MUS 162 and provides the necessary drill in the skills of sight-singing, aural perception, and dictation of music."},
"MUS181":{"description":"This course provides a comprehensive introduction to piano performance, functional keyboard skills, and the keyboard literature of beginning piano students. Focus will be given to major scales, primary chord progressions, melodic harmonization, and performance of recital repertory relevant to the course objectives."},
"MUS182":{"description":"The second course in the Class Piano sequence, MUS 182 provides a comprehensive introduction to piano performance, functional keyboard skills, and keyboard literature of beginning piano students. Focus will be given to major and minor scales and their inversions, primary chord progressions in root and inverted positions, melodic harmonization, and performance of repertory relevant to the course objectives."},
"MUS200":{"description":"A select touring choir devoted to performance of an eclectic repertory of choral literature for mixed voices (S.A.T.B.). This course will focus on the development of vocal technique, performance practices, and will culminate with several performances throughout the year."},
"MUS202":{"description":"A basic survey of the media elements and organizational patterns of the art of music."},
"MUS210":{"description":"One thirty-minute weekly private instruction in the area of applied voice with an emphasis on vocal performance, technique, vocal health, and the performance of Classical art song, sacred song, folk song, and Broadway musical standards."},
"MUS219":{"description":"A select group devoted to the performance of standard choral repertory for women. This ensemble will focus on the development of vocal technique beginning with the simplest part-songs and progressing through more difficult choral works. This course will culminate in a choral performance near the end of the semester."},
"MUS251":{"description":"Develops appreciation and skills in music as applied by the middle-level education major in voice, keyboard, and recorded musical materials. Comparative study will be pursued in the areas of integrated curriculum and methodology presently used in the instruction of music and movement in grades three through six. Guided field experience."},
"MUS290":{"description":"A select ensemble consisting of any combination of jazz players from a small combo up to a full jazz \"big band.\" The purpose of this course is to educate players in the area of jazz realization and improvisation through the study of standard repertory, improvisation texts, listening assignments, and rehearsal. This ensemble will perform regularly throughout the year."},
"MUS381":{"description":"A study of the development of Western music from antiquity through the end of its Baroque period (1600-1750). Emphasis will be placed on developing a thorough knowledge of music literature, cultural history, compositional styles, and the composers who shaped the music of this time."},
"MUS382":{"description":"A study of the development of Western Music from its Classical Period (1750-1800) through the Modern Age with some examination of contemporary trends. Emphasis will be placed on developing a thorough knowledge of music literature, compositions, cultural history, and the composers who shaped the music of this time."},
"NURS246":{"description":"This course introduces students to essential characteristics of professional nursing practice in today's healthcare system and the interconnected global community. Concepts to be explored include the history and theoretical basis of the nursing profession, the profession as part of a wider healthcare community and delivery system, professional communication, self-understanding and self-care as means to promote health and wellness of self and others. An introduction to the responsibility of self-directed, life-long learning and evidence- based practice is included. An emphasis will be placed on the complexity and holism of culturally sensitive nursing care in relation to the diversity of patients."},
"NURS247":{"description":"This course focuses on beginning skill development in the psychosocial and physical assessment of the health status of individuals across the lifespan. Emphasis is on the collection of subjective and objective data, physical examinations and documentation. The importance of culturally sensitive care and evidence-based practice are integrated into all aspects of patient assessment."},
"NURS251":{"description":"This course provides an introduction to population focused health. Emphasis is placed on the foundations of population health, clinical preventive services and health promotion, clinical practice and population health, and health systems and health policies.  Students focus on individual and population-oriented prevention and health promotion efforts through quantitative and analytical skills using the nursing process across the lifespan."},
"NURS267":{"description":"This course focuses on developing the practicing nurse's skill in psychosocial and physical assessment.  Emphasis on the collection of subjective and objective data, physical examinations, documentation, and clinical implications of findings.  The importance of culturally sensitive care and evidence-based practice are integrated into all aspects of patient assessment."},
"NURS327":{"description":"Preparation of associate or diploma nurse graduates for professional practice in diverse health care settings. Students explore theories, concepts, and assumptions foundational to contemporary nursing practice, patient centered care, and baccalaureate nursing education. Students develop the knowledge and skills necessary for success in distance education and scholarly work including an understanding of the BSN program, the ability to identify and locate scholarly resources, and professional communication and writing skills."},
"NURS331":{"description":"Students engage in the exploration of care coordination and the healthcare delivery system. Key concepts include collaborative care models, applying systems thinking, and policy implications in healthcare delivery. The course will enable students to identify, assess, and evaluate healthcare delivery models in relation to economic, organizational, and political perspectives."},
"NURS333":{},
"NURS337":{},
"NURS350":{"description":"This course provides the opportunity for non-degree seeking students to explore nursing concepts as related to Pharmacology."},
"NURS353":{"description":"This course provides nursing students the knowledge and skills to translate current evidence into practice.  Students learn the interrelationships among theory, practice, and research; the research process and how evidence is developed; protection of human subjects in the conduct of research, and application of evidence to clinical practice.  Skill development to facilitate retrieval, appraisal, and synthesis of evidence will support student use of quality evidence to guide nursing practice.  Development of writing and oral presentation skills will enable the student to collaborate with the interprofessional healthcare team to improve patient outcomes"},
"NURS356":{"description":"This course provides the student with an introduction to pharmacotherapeutics, safe administration of medications, and medications related to the care of patients with specific disorders. Pharmacokinetic, pharmacodynamics, pharmacogenetics, principles of medication administration, medication terminology, systems of measurement and conversion, and medication calculations are discussed. Drug classifications, actions, side effects, adverse effects, and nursing implications of medication administration are presented. Through class and seminar experiences the student will learn to apply the nursing process to safely admnister medications and provide patient centered care related to pharmacotherapy."},
"NURS357":{"description":"This course explores concepts essential to nursing practice with a focus on nursing interventions. An emphasis is placed on the development of knowledge, competence, communication, and caring with the nurse's professional role. Through class and clinical laboratory experiences, students learn to apply principles of safe and effective nursing care."},
"NURS358":{"description":"This course focuses on the application of the nursing process and further develops cognitive, affective, and psychomotor skills necessary to provide nursing care for adults within a legal, ethical, and culturally sensitive framework. Students will integrate concepts from the sciences, liberal arts and nursing theory as a basis for their nursing practice. Evidence-based practice will be the foundation for providing care to patients on the continuum of illness and wellness."},
"NURS360":{"description":"This course provides the opportunity for non-degree seeking students to explore nursing concepts as related to Mental Health."},
"NURS362":{"description":"Students engage in the exploration of care coordination and the healthcare delivery system. Key concepts include collaborative care models, applying systems thinking, and policy implications in healthcare delivery. The course will enable students to identify, assess, and evaluate healthcare delivery models in relation to economic, organizational, and political perspectives."},
"NURS363":{"description":"This course focuses on therapeutic interaction, emphasizing the communication process and the concept of professional caring. Patterns of human responses associated with mental health and mental illness are examined across the lifespan. Within this framework, human responses to mental health and illness are identified. Students practice the therapeutic use of self, and implement culturally sensitive mental health nursing interventions in multiple clinical settings while utilizing methods of evidenced-based practice."},
"NURS364":{"description":"This course focuses on the care of the child and adolescent specific to developmental levels. Age-specific culturally sensitive health promotion issues and health problems through theoretical and clinical application are addressed. The course emphasizes the interrelationship of pathophysiology, clinical symptoms and complications, disease-specific assessment, risk factors, nursing process, collaborative management, and expected outcomes. Emphasis will be placed on evidenced-based practice."},
"NURS367":{"description":"This course provides Registered Nurses with knowledge and skills to translate current evidence into practice.  Students learn the interrelationships among theory, practice, and research, the research process, and the protection of human subjects in the conduct of research.  Students learn how to integrate clinical expertise with evidence to provide high quality patient centered care.  Skill development to facilitate retrieval, appraisal, and synthesis of evidence will support student use of high quality evidence to guide nursing practice.  Development writing and presentation skills will enable the student to better collaborate with the interprofessional healthcare team to improve patient outcomes."},
"NURS368":{"description":"This course focuses on the utilization of the nursing process as a basis for providing nursing care to adult patients in acute and community-based settings. The role of the nurse as a collaborator in the healthcare team will be emphasized. Students will apply concepts from the sciences, liberal arts, and nursing theory as a basis for their nursing practice. Students will examine culturally sensitive human responses along the continuum of illness and wellness utilizing evidenced-based practice."},
"NURS370":{"description":"This course provides the opportunity for non-degree seeking students to explore nursing concepts as related to the Care of Children."},
"NURS380":{"description":"This course provides the opportunity for non-degree seeking students to explore nursing concepts as related to patient care."},
"NURS455":{"description":"This course emphasizes population- focused nursing practice which promotes healthy environmental conditions, lifestyle, and behaviors that improve the health of a community or population.  Students focus on community assessment, including use of epidemiologic data to determine lifestyle, environmental, and genetic factors that are major determinants of population health.  Emphasis is placed on primary prevention, health promotion, disease and injury prevention across the lifespan.  Students collaborate with other professionals and stakeholders to identify and reach out to populations who might benefit from service, and apply evidence-based practice, including immunization, screening, and counseling to prevent and manage health conditions, prevent disability, and mortality."},
"NURS456":{"description":"This course explores reproduction, pregnancy, selected women's health issues and newborn care through theoretical and clinical application. Individual family and cultural influences are explored as they relate to nursing care of the family unit. Specialized assessment techniques are integrated through clinical practice. Delegation and prioritization of nursing care are emphasized and integrated throughout the course."},
"NURS457":{"description":"This course emphasizes population-focused nursing practice which promotes healthy environmental conditions, lifestyle, and behaviors that improve the health of a community or population.  Registered nurses build on previous clinical experience with a focus on community assessment, including use of epidemiologic data to determine lifestyle, environmental, and genetic factors that are major determinants of population health.  Emphasis is placed on primary prevention, health promotion, disease and injury prevention across the lifespan.  Students collaborate with other professionals and stakeholders to identify and reach out to populations who might benefit from service, and apply evidence-based practice, including immunization, screening, and counseling to prevent and manage health conditions, prevent disability, and mortality."},
"NURS460":{"description":"This course provides the opportunity for non-degree seeking students to explore nursing concepts as related to Women and Families."},
"NURS461":{"description":"This course focuses on human responses to complex, multidimensional health problems of adults.  The student will study care of adults across the continuum of acute illness, chronic illness, rehabilitation, and long term health care needs facilitating holistic wellness.  The course integrates concepts of aging, chronicity, standards of practice, cultural influences, role of caregivers, and pertinent public policy into the assessment, intervention, and evaluation of adult patients."},
"NURS468":{"description":"This course focuses on human responses to complex, multidimensional health problems of adults experiencing critical illness.The course examines the interrelationships of pathophysiology, clinical symptoms and complications, risk factors, nursing process, collaborative management, and expected outcomes. The role of the nurse as coordinator of care will be emphasized utilizing evidence-based guidelines and integration of interdisciplinary care."},
"NURS470":{"description":"This course provides the opportunity for analysis and synthesis of concepts and research related to special topics in nursing and healthcare."},
"NURS480":{"description":"This course provides students with an opportunity to increase their understanding of the knowledge, skills, and competencies needed for successful practice in a community-based nursing role.  Students will be paired with a community-based preceptor in a clinical setting.  Individualized experiential learning opportunities for the student will be identified in collaboration with faculty and preceptor.  Current issues related to community-based nursing practice will be addressed."},
"NURS487":{"description":"The course provides registered nurses the knowledge and skills for the application and synthesis of leadership theories, principles, and practices within the context of the role of the Baccalaureate prepared nurse.  Introduction to the role of nurse manager and leader is emphasized and integrated within the clinical experience.  Students will analyze and apply quality improvement and safety principles and practices to discern the impact on error reduction, patient outcomes, and quality of care."},
"NURS488":{"description":"This course provides prelicensure nursing students knowledge and skills for the application and synthesis of leadership theories, principles and practices within the context of care delivery.  Management of care for groups of patients with multidimensional health problems is emphasized and integrated within the clinical experience.  Students will analyze and apply quality improvement and safety principles and practices to discern the impact on error reduction, patient outcomes, and quality of care."},
"NURS490":{"description":"This course provides the opportunity for non-degree seeking students to explore nursing concepts in medical surgical nursing."},
"NURS492":{"description":"This course provides the RN-BSN student with a concentrated clinical experience designed to expand and synthesize nursing knowledge and evidence-based practice. Students will refine their clinical skills as a critical thinker possessing knowledge and communication skills to problem solve. Students will enhance their clinical skills while effectively using resources and integrating evidence-based practice to provide quality and cost-effective healthcare in varying environment."},
"NURS498":{"description":"This course provides the students with a concentrated clinical experience designed to synthesize nursing knowledge and evidence-based practice. Students will refine leadership and management skills as they function as integral members of the healthcare team. Students provide and coordinate direct care for groups of patients in an approved clinical setting. Seminar discussion topics will be related to current clinical practice issues."},
"NUTR203":{"description":"This course provides students with a general overview of practice in food, nutrition, and dietetics. Students gain a beginning understanding of state and national credentialing requirements, certification standards, ethics, and life-long learning needs."},
"NUTR205":{"description":"This course provides students with a general overview of the history, philosophy, and practice of dietetics and how dietitians collaborate with other disciplines. Students gain a beginning understanding of the Accreditation Council for Education in Nutrition and Dietetics (ACEND) standards, the Academy of Nutrition and Dietetics (AND) scope of practice, the code of ethics, and advocacy methods. Students will recognize state and national credentialing requirements and life-long learning needs. Current issues in dietetics and healthcare will be discussed, such as evidence-based practice, the nutrition care process, and informatics."},
"NUTR285":{"description":"This course focuses on defining and applying management theories and functions in food and nutrition settings. Human, material, and facility management will be discussed. Students gain an understanding of the tools available for managing effective and efficient food and nutrition organizations."},
"NUTR376":{"description":"Principles and applications in nutrition emphasize the relationships among the nutrients and how homeostasis relationships are maintained in the healthy person. Students will learn more about themselves and their health in an effort to use this knowledge to improve their health. This knowledge of nutrition will allow students to personalize information to fit their lifestyle. Special attention to nutrition for the developing human and lectures focusing on nutrition counseling will address the needs of dental hygiene students and other health professionals seeking concepts in applied nutrition."},
"NUTR378":{"description":"An overview of the relationship of basic nutrition and energy systems to physical activity and body composition. This course examines popular myths and misconceptions associated with nutrition and performance. Incorporates application of introductory biochemistry."},
"NUTR381":{"description":"This course provides application of the principles of nutrition and food production in meal service from both a family social unit and the broad spectrum of food service. This course will focus on different stages of food service from menu planning and recipe conversion through the end result of serving the food. Special consideration will be given to meeting nutritional needs, food safety, cultural diversification, and consumer satisfaction. Purchasing and inventory techniques will be examined. In conjunction with the University of Southern Indiana Food Service and other food service establishments, this course will include a practicum in management. Includes a laboratory."},
"NUTR383":{"description":"This course will focus on the study of practical applications of food science for food preparation and nutrition.  Students will study the functions of specific ingredients in food, their sources in the food supply, and their effects on health.  They will explore methods to maintain or improve the nutritional value of food while maintaining taste appeal.  Students will apply information from food labels and modify recipes to address health concerns, prepare foods using various cooking methods, and discuss the influence of vegetarianism, culture, socio-economics, and psychological factors on food choices."},
"NUTR384":{"description":"This course will focus on the studies of foods in relation to their composition of physical and chemical properties. This course will include the experimental approach to the study of foods. Throughout this course, sensory evaluation techniques will be utilized. Recipe development and modification will be explored. Includes a laboratory."},
"NUTR396":{"description":"This course focuses on understanding the normal nutritional requirements and needs of special populations throughout the lifecycle. Growth and development theories will be discussed and nutrition concerns will be examined for pregnant women, breastfeeding mothers, and the growing infant, child and adolescent. Students will investigate nutrition-related concerns during the lifecycle and offer appropriate suggestions for management of these concerns. Recommendations for preventing disease and promoting healthy aging will be discussed."},
"NUTR397":{"description":"This course focuses on understanding the role of nutrition in health promotion and disease prevention. Students will have a better understanding of health and food consumption behaviors of the United States population by evaluating population-based studies. Students will locate, interpret and analyze National nutrition surveys and epidemiological studies to determine their use in health promotion. An enhanced understanding of the role of genetics on disease development will be provided. Current food consumption and lifestyle practices of people from diverse cultures and religions will be examined."},
"NUTR412":{"description":"This course focuses on understanding the relationship between food and nutrients, the science of chemistry of the living organism, and health. The course stresses the importance of nutrient interactions and regulation of metabolism. The methods used to assess both nutrient requirements and deficiencies will be studied."},
"NUTR415":{"description":"This course focuses on understanding the role of supplements from a nutrition standpoint in maintaining and promoting health. Common supplements used such as vitamins, minerals, herbs, and other substances will be discussed. Emphases are placed on understanding the history of their use, their proposed mechanisms of action, and safety concerns using the process of a critical review of the literature. Students will analyze the role of these supplements when making nutritional recommendations to individuals and groups."},
"NUTR420":{"description":"This course provides junior- and senior-level students who meet eligibility criteria with the opportunity to gain practical insight into the food and nutrition environments while applying their management knowledge skills. The experience will lead to deeper appreciation and valuable understanding of practical workplace situations. The students will be able to apply their leadership and teamwork skills."},
"NUTR427":{"description":"This course will provide application of the principles of food production and nutrition by designing a menu and serving a meal. Students will focus on assessing needs, planning, implementing, and evaluating a food service meal. Students will utilize their knowledge of food service management and apply food and nutrition principles in a group setting."},
"NUTR452":{"description":"This course will focus on the comprehensive principles and methods of scientific nutrition assessment. Students will examine the methods and tools of the assessment process. Students will demonstrate assessment skills and proficiency in choosing appropriate tools and methods in order to utilize the nutrition care process."},
"NUTR453":{"description":"This course will focus on the study of practical applications of nutrition and health screening, assessment and disease management. Students will apply nutrition and health assessment knowledge and skills. Students will perform nutrition focused physical assessment and subjective global assessment using validated techniques."},
"NUTR465":{"description":"This course focuses on understanding the role of nutrition in public health. It will allow for an understanding of the tools used to design and implement community nutrition programs that promote and protect the public's health. Emphases are placed on community nutrition assessment, program planning, monitoring, and evaluation. Methods to impact the health of the community including nutrition policy making, advocacy, and the legislative process will be discussed."},
"NUTR481":{"description":"This course focuses on the acquisition of nutrition counseling knowledge and the development of interpersonal skills that would enhance the translation of nutrition knowledge into healthy food choices. Employing a problem- solving model, the course covers counseling strategies and techniques, interviewing methods, psychological theories, life span and cross-cultural considerations for counseling, emotional factors of nutrition, eating disorders, ethics, and professional aspects of practice. Students will be challenged to apply and think critically about different counseling techniques and situations. Appropriate educational materials and documentation methods will be evaluated and developed as needed."},
"NUTR485":{"description":"This course focuses on understanding the diseases that are modifiable by medical nutrition therapy (MNT). The nutrition care process and MNT of hyperlipidemia, diabetes, hypertension, malnutrition including under- and over-nutrition and renal diseases will be covered. The pathophysiology, dietary treatments, and evidence supporting MNT will be discussed."},
"NUTR486":{"description":"This course focuses on understanding the diseases that are modifiable by medical nutrition therapy (MNT). The nutrition care process and MNT of liver, respiratory, gastrointestinal, cancer, and other metabolic diseases across the lifespan will be covered. The role of genetics in nutrition will be covered. The pathophysiology, dietary treatments, and evidence supporting MNT will be discussed."},
"NUTR492":{"description":"This course will be a systems approach to food service management. Program planning, forecasting, program evaluation, and marketing will be discussed. This course will cover cost control methods that are specific to managing food service operations, including food waste and theft."},
"NUTR496":{"description":"This course will explore current political, regulatory, ethical, training, quality improvement, management, and other important related issues facing food and nutrition professionals. Students will use their educational foundation to investigate, identify, and suggest alternative methods of resolving these problems. The course will examine the leadership roles of food and nutrition professionals. Students will apply this knowledge by investigating current controversial issues in food and nutrition and will develop solutions to these problems."},
"OTA213":{"description":"This course provides an overview of the etiology, clinical course, management, and prognosis of congenital and developmental disabilities, acute and chronic disease processes, and traumatic injuries; and examines the effects of such conditions on functional performance throughout the lifespan as well as explores the effects of wellness on the individual, family, culture, and society."},
"OTA214":{"description":"Providing a focus in the areas of neurology and orthopedics, this course continues the overview of the etiology, clinical course, management, and prognosis of congenital and developmental disabilities, acute and chronic disease processes, and traumatic injuries by examining the effects of such conditions on functional performance throughout the lifespan and by exploring the effects of wellness on the individual, family, culture and society."},
"OTA221":{"description":"The emphasis of this course is the development of skills in the areas of listening, speaking, reading, and writing. These skills include: interviewing; self, dyadic, and group interaction; documentation to ensure accountability and reimbursement of services; critical reading of research; public speaking; and participation in meetings. This course introduces the student to medical terminology used in many of the treatment settings."},
"OTA231":{"description":"This course emphasizes the meaning and dynamics of occupation and activity related to individuals, groups and populations.  Students are introduced to the history and philosophical base of occupational therapy (OT) as well as the evolution of OT theory and practice.  Social issues are discussed, including occupational justice, health literacy, and ethical considerations that affect health and wellness.  Students demonstrate activity analysis related to occupational performance."},
"OTA232":{"description":"The student will be introduced to theory and clinical competencies needed to become proficient in basic splint fabrication skills and the medical conditions associated with each type of splint constructed. The course also will explore the use of assistive devices, adaptive equipment, and an overview of prosthetics, orthotics, and the use of physical agent modalities in treatment."},
"OTA241":{"description":"This course examines the occupational therapy process with an emphasis on optimal occupational performance which enhances lifespan role functioning across occupational performance contexts. This course examines the interplay of performance areas and concomitant performance components with a focus on psychosocial skills."},
"OTA242":{"description":"This course examines the occupational therapy process with an emphasis on optimal occupational performance which enhances lifespan role functioning across occupational performance contexts. This course examines the interplay of performance areas and concomitant performance components with a focus on sensorimotor and cognitive skills."},
"OTA297":{"description":"Along with a 40-clock-hour Level I practicum experience, this first practicum course provides students opportunities to discuss fieldwork matters and integrate fieldwork with occupational therapy process and practice issues."},
"OTA298":{"description":"Along with a 40-clock-hour Level I practicum experience, this second practicum course provides students additional opportunities to discuss field work matters and integrate field work with occupational therapy process and practice issues."},
"OTA343":{"description":"This course examines the occupational therapy process with an emphasis on optimal occupational performance which enhances lifespan role functioning across occupational performance contexts. This course examines the interplay of performance components and their effects on subsequent performance areas, particularly activities of daily living and play or leisure."},
"OTA344":{"description":"This course examines the occupational therapy process with an emphasis on optimal occupational performance which enhances lifespan role functioning across occupational performance contexts. This course examines the interplay of performance components and their effects on subsequent performance areas, particularly work and productive activities."},
"OTA345":{"description":"The emphasis of this course is the development of skills in use for the pediatric population including evaluation and treatment planning using developmental, cognitive, motor and psychosocial therapies. The use of experiential learning techniques will reinforce presented concepts."},
"OTA350":{"description":"This elective course may be customized for the special interest of the student. Students may complete this independent study by participating in faculty-approved supervised service delivery, research projects under the direction of faculty, or another faculty-approved project."},
"OTA372":{"description":"General management principles such as planning, organizing, staffing, coordinating/directing, controlling, budgeting, marketing, strategic planning are applied to the management of activities service.  There is an emphasis on the development of supervisory skills for occupational therapy students, certified occupational therapy assistants, and other personnel."},
"OTA397":{"description":"While working with persons having various levels of psychosocial, sensorimotor, and cognitive performance components, students have opportunities for synthesis, the integration and application of knowledge gained throughout their educational experiences which include general education/liberal arts courses as well as the sequence of occupational therapy coursework. Students will examine the interplay among occupational performance areas, components, and contexts; develop and expand a repertoire of occupational therapy treatment interventions; and employ clinical reasoning and reflective practice skills. Fieldwork A, a Level II internship of at least eight weeks (full-time) in duration, must vary from Fieldwork B to reflect a difference in ages across the lifespan of persons requiring occupational therapy services, in the setting with regard to chronic conditions (long-term versus short-term), and in facility type (institutional versus community-based)."},
"OTA398":{"description":"While working with persons having various levels of psychosocial, sensorimotor, and cognitive performance components, students have opportunities for synthesis, the integration and application of knowledge gained throughout their educational experiences which include general education/liberal arts courses as well as the sequence of occupational therapy coursework. Students will examine the interplay among occupational performance areas, components, and contexts, develop and expand a repertoire of occupational therapy treatment interventions, and employ clinical reasoning and reflective practice skills. Fieldwork B, a Level II internship of at least eight weeks (full-time) in duration, must vary from Fieldwork A to reflect a difference in ages across the lifespan of persons requiring occupational therapy services, in the setting with regard to chronicity (long-term versus short-term disability/disease process), and in facility type (institutional versus community-based)."},
"PET287":{"description":"Provides students with the basic information about teaching physical education to children in elementary school. Develop, participate in, and teach developmentally appropriate physical activities for children in grades 1-6 including classroom, recess/playground, wellness, fitness, and integrated activities."},
"PET371":{"description":"This course is designed to provide students with a comprehensive introduction to the physiological responses and adaptions to physical activity and exercise training in school age children.  In addition, it will examine human movement and physical activity from anatomic, kinematic, and kinetic perspectives."},
"PET392":{"description":"A study of dysfunctions and characteristics of people with atypical performances. Basic concepts and techniques of modifying physical activity for people with special needs."},
"PET395":{"description":"Planning programs, units, and lessons in physical education to achieve long- and short-term curriculum goals."},
"PET397":{"description":"Students will learn how to teach individual and dual sports. Students will learn proper skill progressions, transition techniques that are used to link fundamental movement with sports skills, and how to evolve simple strategies into more complex offenses and defenses."},
"PET402":{"description":"This course is designed to investigate current controversial topics in health education, which includes sexuality, obesity, death and dying, and other issues in health education.  Students may be required to fulfill field experience."},
"PET493":{"description":"The study of the physiological, psychological, and sociological variables that influence children's sport participation."},
"PH284":{"description":"This course introduces the services and measures that are used to safeguard public health. Content included in the course is: history, development and organization of public health; an introduction to the scientific concepts and analytical tools used in public health; and overviews of the major areas of public health intervention."},
"PH385":{"description":"This course provides systematic coverage of the determinants of disease and control methods by major disease groupings, such as respiratory diseases, and animal-associated diseases."},
"PH483":{"description":"This course provides an overview of the environment in which we live and the way that our health is affected.  Changes in our ecosystems, environmental diseases, food safety, water, air, noise, energy, hazardous and solid waste/pollution will all be discussed in relation to the human population."},
"PH484":{"description":"This course introduces various environmental risks to health status, and the role of public health controls in safeguarding at-risk populations. These areas include: food production, water purity, wastewater and solid waste handling, arthropod and pest control, air quality, and occupational and industrial hazards to health."},
"PH485":{"description":"This course introduces the principles and methods of epidemiology used to study health-related issues in populations. Covers models of disease causation and the association of health-risk factors. Includes: common epidemiological measures, epidemiologic study designs, disease surveillance, and outbreak investigation. Applies epidemiological principles and methods to health service organizations and clinical settings by developing critical literature review and study design skills."},
"PH486":{"description":"This course describes the setting for public health practice; the structure of public health policies and regulations; and the organizations responsible for public health services. Public health funding and the administration of public health services at the local, state, national, and international levels are reviewed and analyzed."},
"PHIL101":{"description":"The nature of philosophy and its problems."},
"PHIL201":{"description":"An in-depth study of issues in practical or applied ethics."},
"PHIL205":{"description":"Forms of inference in terms of traditional logic."},
"PHIL251":{"description":"An introductory survey course designed to expose students to the scholarly methods, disciplines, and topics involved in the academic study of religions."},
"PHIL304":{"description":"Representative theories of the nature and evaluation of art."},
"PHIL305":{"description":"An examination of the principles and methods of the several branches of symbolic logic."},
"PHIL312":{"description":"This course explores the major problems and questions in Business Ethics from the nature of the corporation and workers' rights to product liability and marketing ethics."},
"PHIL344":{"description":"A study of the philosophical thought of important 20th century figures, such as Russell, Wittgenstein, Quine, and Heidegger."},
"PHIL350":{"description":"A study of the history and development of philosophical ideas from ancient Greece to the close of the Middle Ages, including Plato and Aristotle."},
"PHIL351":{"description":"A study of the history of early modern philosophy including such major philosophers as Descartes, Leibniz, Locke, Hume and Kant."},
"PHIL361":{"description":"Repeatable with a change in course content. A study of some selected problem, movement, or philosopher in the field of philosophy."},
"PHIL362":{"description":"Repeatable with a change in course content. An interdisciplinary study of some problem or concept in which the perspective of philosophy is involved."},
"PHIL363":{"description":"A critical survey of the ethical issues generated by research and practice in the fields of biology and medicine."},
"PHIL364":{"description":"A survey of concepts essential to feminist social and political philosophy, including sexism, oppression, discrimination, patriarchy, and equality, with critical application of these concepts to current gender-related issues and to students' own experiences as men and as women."},
"PHIL365":{"description":"An examination of various philosophical issues in sexual ethics, including the role of sexuality in human life, its relation to love and to marriage, the meaning and value of sexual freedom, the moral status of homosexuality, the meaning of sexual consent, and sexual objectification and co-modification."},
"PHIL366":{"description":"This course examines the history and nature of how human relationships to ecosystems create moral obligations to act responsibly with respect to future generations of humans, non-human animals, and the ecosystems of the planet itself. It analyzes the historical roots of current environmental problems as well as more recent theories of environmental ethics such as land ethics, ecofeminism, and deep ecology. Topics may include, but are not limited to: global warming, overpopulation, endangered species, sustainable agricultural practices, genetic engineering, and corporate accountability."},
"PHIL377":{"description":"Cognitive Science is the interdisciplinary science of the human mind and cognition. Students will explore elementary neuroscience, perception and learning, robotics/artificial intelligence, neural networks, and other efforts to model human intelligence using machines. Insights are drawn from several fields, including artificial intelligence, linguistics, neuroscience, philosophy, psychology and robotics."},
"PHIL413":{"description":"Basic problems and philosophically significant theories of religion, including such problems as the relation of faith and reason, and the existence of God."},
"PHIL415":{"description":"An understanding and comparison of various movements in the history of rhetoric, with particular emphasis on the relationship between rhetorical strategy and one's image of men and on historical events which have influenced rhetoric. The course aims to increase the scope of students' understanding of rhetoric and help them apply this knowledge to their own writing and their evaluation of the writing of others."},
"PHIL418":{"description":"Alternative theories of the nature of ultimate reality and related questions about the nature, function, and possibility of such theories."},
"PHIL420":{"description":"Alternative theories of the origin, nature, and possibility of knowledge."},
"PHIL425":{"description":"Covers topics in the traditional philosophy of mind, e.g., the mind/body problem and more contemporary issues such as intentionality, mental representation, and folk psychology."},
"PHIL435":{"description":"An examination of the nature of scientific knowledge and the relation of science to culture."},
"PHIL441":{"description":"An examination of some central issues in political and legal philosophy, e.g., free speech, racial and sexual equality, constitutional interpretation and the relationship between law and morality."},
"PHIL499":{"description":"A course for individual research in a specific area of philosophy. Topics arranged to meet the specific interests of the student and faculty member involved. Course topic should not be covered in a regularly scheduled course. Course will be offered at the discretion and approval of a full-time faculty member who supervises the research."},
"PHYS101":{"description":"Lectures and demonstrations of physics at a primarily conceptual level. Basic problem solving and the scientific method are introduced. Topics covered include Newton's Laws, energy, momentum, light, electricity, magnetism, thermodynamics, fluids, and selected topics from modern physics."},
"PHYS107":{"description":"A course for physics majors to introduce them to current topics in physics, resources and methods for research in physics, and the planning necessary to pursue a career in physics or related fields. Students will engage in discussions from assigned readings, present a poster on a selected topic, and construct their personalized 4-year plan of success as a physics major."},
"PHYS108":{"description":"An introductory survey of Physics principles that govern our everyday lives. It covers the topics of energy, motion, electricity and magnetism, light and optics, sound and waves, and thermodynamics. Lectures are interspersed with hands-on activities intended to reinforce concepts and to provide the students with ideas for teaching their own classes. This course is for teacher candidates only."},
"PHYS175":{"description":"Review of metric system and vectors, the laws of motion and applications, work and energy, states of matter and molecular properties, heat and thermodynamics. Calculus is not employed in the course."},
"PHYS176":{"description":"Wave motion and acoustics, electrostatic fields and potentials, electric current and circuit theory, magnetic fields, optics and the wave properties of light, atomic and nuclear physics. A continuation of PHYS 175."},
"PHYS205":{"description":"An initial study of the phenomena and concepts of classical and modern physics in the areas of mechanics including energy and momentum principles, and heat and thermodynamics, with applications involving elementary calculus. An integrated laboratory serves to develop associated skills in measurement. Required of biophysics majors/minors, physics majors/minors, and engineering majors."},
"PHYS206":{"description":"A continuation of PHYS 205 in the areas of wave motion, acoustics, optics, electrostatics, circuit electricity, magnetism, and modern physics. Required of biophysics majors/minors, physics majors/minors, and engineering majors."},
"PHYS207":{"description":"This course is identical with PHYS 205 except the laboratory is excluded. This is intended for students who have previously acquired credit for the general physics laboratory by having completed course PHYS 175 or who have the equivalent in laboratory experience."},
"PHYS208":{"description":"This course is identical to PHYS 206 except the laboratory is excluded. This is intended for students who have previously acquired credit for the general physics laboratory by having completed course PHYS 176 or who have the equivalent laboratory experience."},
"PHYS311":{"description":"Direct current circuit theory. Alternating current circuit theory utilizing both methods of phasers and complex numbers. Problem solving is emphasized."},
"PHYS352":{"description":"The application of computer programming to scientific problems. Students will learn how to formulate a scientific problem into a form relevant for a computer solution, and create a program that will solve the problem."},
"PHYS355":{"description":"The use of computers in physics has grown enormously over the past few decades, to the point where computers play a central role in virtually every new physics discovery. From particle physics and astrophysics to quantum computing and biophysics, calculations on computers have become one of the most indispensable tools of scientists today. This course provides an introduction to the solution of physics problems using computers. Assuming no previous programming experience, the course will introduce the basic indeas and programming skills of computational physics and help develop skills to solve problems in all areas of physics."},
"PHYS361":{"description":"Physics of the 20th century. Atomic and nuclear phenomena relativity and other fundamental theories. An introduction to quantum mechanics is included."},
"PHYS365":{"description":"This course focuses on the understanding and application of modern techniques of measurement, data acquisition and data analysis used in experimental physics, and the presentation of experimental results in journal format for publication."},
"PHYS371":{"description":"Theory and application of Newtonian mechanics using vector calculus methods. Topics include particle dynamics, energy and momentum principles, multiparticle systems. Problem solving is emphasized."},
"PHYS375":{"description":"This course introduces students to advanced mathematics and its applications to problem-solving in physics and engineering. Possible topics include: linear algebra; complex analysis with emphasis on countour integrals and approximations, Fourier series and Fourier transforms; differential equations with emphasis on typical special functions, asymptotic and perturbative methods and Green's functions; calculus of variations."},
"PHYS381":{"description":"Formulation of the basic theory of electric and magnetic vector fields. Electrostatic and magneto static fields in free space and in material media, and the formulation of Maxwell's equations are included."},
"PHYS391":{"description":"This course improves and develops understanding of physics concepts, and applies them to molecular and cellular biological systems. Concepts and principles from thermodynamics, statistical mechanics, and electricity will be applied to systems such as bacteria, cell membranes, vascular networks, and biological molecules (RNA, DNA, and proteins including enzymes). This course is for students who seek to learn more about the application of physics concepts and principles in biological systems, as well as for physics/biophysics students interested in thinking more about cells and biological molecules."},
"PHYS401":{"description":"The course will cover some of the important ideas in physics and their relationship to other areas of human thought, e.g. philosophy, literature, and art. These relationships will be made through readings from works that have a direct connection to physics. The emphasis will be on learning the physical principles and their significance. The physical theories that will be covered include Newton's laws, energy conservation, the second law of thermodynamics, special relativity, and quantum mechanics. These theories will be framed in the context of the times in which they developed. The course will involve both the solving of \"traditional physics problems\" and the writing of essays relating the ideas developed in the problems to a wider context."},
"PHYS409":{"description":"Advanced methods in mechanics and higher theoretical formulations. Matrix and tensor methods are developed. Topics include rigid body mechanics, relativistic mechanics, Lagrangian and Hamiltonian formulations."},
"PHYS429":{"description":"Fundamental physical theory pertaining to the microstate of matter. Topics include quantum mechanical solutions for the harmonic oscillators, the hydrogen atom, and the angular momentum states."},
"PHYS439":{"description":"The first and second laws of thermodynamics; formulations, interpretations, and applications. The kinetic theory of gases and an introduction to the statistical mechanics."},
"PHYS471":{"description":"Lectures and practicum in the observation and understanding of the mechanics of the celestial sphere. Identification of the visible constellations, location, and observation of the planets, Messier objects, and solar and lunar studies. The orientation and use of the equatorial telescope will be emphasized. Two hours lecture and two hours laboratory."},
"PHYS481":{"description":"This course is a continuation of PHYS 381, which is used to introduce the student to further concepts of electromagnetism. Conservation laws, electromagnetic waves, potentials and field, radiation, and electrodynamics and relativity are included."},
"PHYS490":{"description":"Advanced course for physics students."},
"PHYS491":{"description":"Physics majors in this course will work under the direction of a faculty member on a research topic of their own choosing. Students will plan and carry out independent research that will culminate in both a written thesis and a formal presentation of their work (either a talk or poster at an approved meeting or a local/departmental colloquium).  This course will focus on experimental, theoretical, or computational techniques particular to the chosen problem/subject of the research as well as effective science communication (both written and oral). This course is for senior level physics majors."},
"PHYS499":{"description":"Advanced project in physics, done under instructor supervision."},
"PHYS175L":{"description":"Required laboratory accompanying PHYS 175.  Includes experiments studying the laws of motion and applications, work and energy, states of matter and molecular properties, heat and thermodynamics."},
"PHYS176L":{"description":"Required laboratory accompanying PHYS 176.  Includes experiments studying wave motion and acoustics, electrostatic fields and potentials, electric current and circuit theory, magnetic fields, optics and the wave properties of light."},
"PHYS205L":{"description":"Required laboratory accompanying PHYS 205.  Includes experiments studying the laws of motion and applications, work and energy, states of matter and molecular properties, heat and thermodynamics."},
"PHYS206L":{"description":"Required laboratory accompanying PHYS 206.  Includes experiments studying wave motion and acoustics, electrostatic fields and potentials and potentials, electric current and circuit theory, magnetic fields, optics and the wave properties of light."},
"POLS102":{"description":"Explores the basic elements of the American political process and the institutions of American national government. Provides a foundation for the study of American government and politics."},
"POLS104":{"description":"Examination of the organizational dynamics and external pressures that affect the administration of government institutions in the United States. Theories and concepts are discussed that pertain to a number of the administrative practices in national, state, and local government organizations: planning, budgeting, staffing, decision making, communicating, implementation, controlling, and evaluation."},
"POLS203":{"description":"Examination of the major theoretical approaches to the study of public policy and policymaking at the federal, state and local levels in the United States. The course focuses on critical elements of the policy process such as the definition of public problems, agenda setting, policy formulation, policy implementation, oversight, and policy change."},
"POLS208":{"description":"Sources, types, purposes, and sanctions of American law; functions of courts; problems of legal justice in the United States."},
"POLS232":{"description":"An examination of how the scientific analyses to conducting focus groups and collecting data. Students will become better consumers of the political science literature, will become well versed in several data collection methods, and will become adept at writing research designs."},
"POLS271":{"description":"An introduction to the study of interstate political relations, power, nationalism, and international organizations; discussion of current trends in international affairs."},
"POLS281":{"description":"Introduction to the comparative study of governments and politics. Examines case studies of the political systems of selected nations, including non-democratic regimes such as communism, military rule, and theocracy."},
"POLS299":{"description":"Provides intensive examination of current political problems and issues as selected by the instructor. Topic will vary each time the course is offered."},
"POLS302":{},
"POLS305":{"description":"The framework of states and their local governments in the United States. Indiana government is compared with that of other states. The course stresses the most important contributions of local governments to the federal system."},
"POLS306":{"description":"The structure and function of American political parties and their relationship to democratic government. Emphasis is placed upon party organization and strategy in the political process. Historical as well as current sources are utilized."},
"POLS309":{"description":"Political thought from Puritan America to the present. Particular emphasis upon the great figures in the evolution of American political ideas."},
"POLS314":{"description":"Office, role, and powers of the President in the American political system."},
"POLS322":{"description":"This course is intended to introduce students to the topic of program evaluation, an essential aspect of most publicly and privately funded programs today.  This course examines methods and practices to assess and evaluate programs in the public and nonprofit sectors."},
"POLS323":{"description":"The purpose of this course is to examine public and nonprofit personnel issues, specifically issues related to personnel/human resource management, employee motivation, evaluation, promotion/demotion, training, benefits, wages and salaries, as well as disciplinary policies and various legal aspects relating to public sector and nonprofit employment"},
"POLS332":{"description":"An examination of statistical techniques and data analysis in political science. Specific techniques will include descriptive statistics, model specification, measures of correlation, point estimation, construction of confidence intervals, parametric and non-parametric hypothesis testing, Analysis of Variance (ANOVA), and regression analysis."},
"POLS351":{"description":"An examination of concepts, theories, and processes relating to leading and managing national, state, and local government bureaucracies in the United States. Particular attention is paid to how leadership and management styles are shaped by internal characteristics of government organizations and external political environments."},
"POLS361":{"description":"An examination of the institutional structure of the American federal system, the basic features of contemporary intergovernmental relationships, the changes that national, state, and local governments have undergone in an era of devolution, and the challenges intergovernmental relationships pose for policy makers."},
"POLS363":{"description":"This course provides an examination of theories of organizational behavior in public organizations.  Students will learn about how individuals, groups, and the external environment influence the management of public organizations.  Specific topics of the course include organizational structure, culture, power and politics, leadership, motivation, and performance.  A strong emphasis will be placed on how managers can apply these theories and concepts toward improved decision making and performance for public organizations at the national, state and local level."},
"POLS371":{"description":"An examination of the UN as an organization, UN functions, regional perspectives, and current issues."},
"POLS383":{"description":"Comparative analysis of Latin American political structures, political movements, and political thought with emphasis upon constitutional structures; local, state, and national governments; the military; revolutions; organized labor; and contemporary affairs."},
"POLS384":{"description":"Government and politics of nation-states in northern Africa and western Asia, with special attention to Israel, Iran, and members of the League of Arab States."},
"POLS385":{},
"POLS399":{"description":"An intensive examination of specialized topics in political science. May be taken twice for credit provided the topic is different."},
"POLS404":{"description":"An analysis of leading decisions of the Indiana Supreme Court, with special attention to how particular provisions of the Indiana Constitution have been interpreted. The course also examines issues relating to statutory interpretation and judicial review."},
"POLS405":{"description":"The growth of the American constitutional system by analysis of leading Supreme Court decisions, the role of the Court decisions, the role of the Court in American politics, evolution of the federal system, civil rights, problems of statutory interpretation, and judicial review of administrative action and regulations. Supplementary reading includes judicial biography and commentaries on the court."},
"POLS406":{"description":"Analysis and discussion of leading decisions of the U.S. Supreme Court, with special attention given to civil rights, including the rights of persons accused of crime; freedom of speech, press, assembly, religion, separation of church and state, equal protection of the laws, and property rights."},
"POLS407":{"description":"The lawmaking process in Congress and selected legislative bodies in the United States; organization, powers, and problems of American legislatures."},
"POLS410":{"description":"An intensive analysis of the major American interest groups and their impact upon the formation and implementation of public policy at the local, state, and national levels of government. Major emphasis is given to ethnic, business, labor, agricultural, veterans, and religious associations."},
"POLS427":{"description":"Study and analysis of the major issues, problems, and political processes of urban America, along with topics on urban governance, such as government organization and rescaling."},
"POLS429":{"description":"This course will prepare students to participate in undergraduate moot court competitions, which simulate the experience of arguing a constitutional case before the Supreme Court. Teams from USI may compete in local, regional, and national competitions. In the process, students will become familiar with case law, develop analytical and rhetorical skills, and put them in contact with practicing members of the legal community."},
"POLS441":{"description":"The overall goal for this course is for students to gain a comprehensive introduction to the concepts and practice of budgeting for both public sector and nonprofit organizations. This course is an opportunity for students to combine fiscal and budget theoretical perspectives with a comprehensive understanding of practices, processes, and actors involved in fiscal and budget development and administration. Students will examine cases at the national, state, local, and nonprofit organization level."},
"POLS445":{"description":"Examines the political thinkers and ideas that founded the Western political tradition. Emphasis is upon the nature of virtue and the goals of government."},
"POLS446":{"description":"Political thinkers from Machiavelli to Mill grapple with concepts such as liberty, equality and the proper relationship between the individual and the state."},
"POLS464":{"description":"An examination of environmental politics and policies as related to international, national, state, and local issues.  Primary focus on how environmental politics are created, implemented and enforced.  Topics include air and water pollution, sustainable development and land use issues, and other contemporary environmental problems."},
"POLS465":{"description":"This course is an introduction to cross-national policy research and to the fascinating and challenging issues of comparative research. Students will learn about theories and processes of policy making in fields such as immigration, budgeting, taxes, health care, welfare, education and environment. The course focuses on critical analysis and constructive alternatives of public policies in the cultural, economic, political and institutional contexts of the United States and other countries."},
"POLS471":{"description":"An examination of the development and administration of international organizations, both governmental and non-governmental, such as the United Nations, the European Union, the World Trade Organization, and non-profit organizations. International organizations are evaluated in the context of global issues, such as international and regional security, trade, development, environmental problems, and social and humanitarian issues."},
"POLS473":{"description":"A systematic study of the origins, objects, sources, and theories of international law. Foremost in importance is the aim to enable students to analyze basic premises and principles of international law and to develop an awareness of the major international legal problems."},
"POLS487":{"description":"The course examines the government and politics of Russia and China. Students will learn about the political transformations during post-communism. Specific topics will include political and economic reform, social changes, and foreign relations."},
"POLS490":{"description":"Provides for a minimum of 150 hours supervised experience related to political science, public administration, or a law-related field.  Students are expected to arrange internships with the approval of the instructor.  Assignments are negotiated with the instructor based on the demands of the specific internship experience."},
"POLS497":{"description":"Students serve as interns in the Indiana State Legislature. Students must be selected by one of the party caucuses in the House or Senate. In addition to working for state legislators, students must attend a speaker series and complete various academic requirements. Serving a one-semester internship in the Indiana State Legislature provides direct experience with the realities of practical politics. The internship provides students with concrete illustrations of the political concepts and processes presented in the classroom and thus are an integral part of a student's political education."},
"POLS498":{"description":"Independent investigation of selected political problems under supervision of instructor."},
"POLS499":{"description":"Intensive examination of selected political problems."},
"PRFS301":{"description":"This course expands students' capacity for civic engagement, providing a philosophical basis for service in western and more diverse traditions.  It provides a global context for the issues addressed and affords students the opportunity for real community experiences that build skills necessary for civic involvement."},
"PRFS305":{"description":"Leadership by the Numbers explores the quantitative aspects of organizational leadership in the 21 century. Students will improve their strategic thinking and organizational decision-making ability by increasing their ability to make data driven decisions. Course topics include reading financial statements, making inferential decisions from forecasting and data interpretation, linear programming, and project management."},
"PRFS401":{},
"PRFS490":{"description":"PRFS 490 is designed to recognize the value of experiential learning activities and work experience relevant to the individual's program. Each student, as directed by a faculty member, and with consultation from the BPS director, will develop a career development plan that demonstrates the integration of prior educational and work experience with University coursework into a unified and meaningful whole. The number of hours of credit to be awarded will be determined by the faculty member, but may not exceed six. Credit will be awarded only after the student has been admitted to the BPS program."},
"PRFS498":{"description":"This capstone course satisfies Core 39, Embedded Experience-Writing for students pursuing the Bachelor of Professional Studies. This course is an interdisciplinary study of adult human development and self. Theories of psychosocial, cognitive, moral, and faith development are examined and compared with the student's personal history and career development in the form of a professional portfolio and a thematic autobiography. The assigned Applied Research Project draws from the student's critical thinking and information processing skills in identifying a contemporary problem in an organization, gathering pertinent information for analysis, and choosing an appropriate solution."},
"PRL101":{"description":"Lecture, discussion, and practical application of basic public relations concepts and processes including public relations in corporations, government, and institutions, public opinion, communication media, ethics of public relations, and public relations campaigns."},
"PRL362":{"description":"Techniques of writing news releases, newsletters, brochures, public service announcements, speeches, and other print and broadcast messages that are a part of the public relations profession."},
"PRL364":{"description":"Writing, editing, and designing specialized publications for business and industry. Computer-generated design will include newsletters, brochures, and other publications typically used by organizations in communicating with their target publics."},
"PRL365":{"description":"A course involving case studies and problems regarding the principles and application of effective two-way communications in a variety of situations affecting the practice of public relations."},
"PRL366":{"description":"A rotating seminar that addresses various topics essential to the public relations practitioner."},
"PRL368":{"description":"An applied writing course that focuses on strategically planning and preparing goal-oriented messages, and the selection of appropriate delivery techniques to communicate with an organization's target publics."},
"PRL400":{"description":"This course is designed to provide an opportunity for upper division communications majors and minors to research subject areas in the field of public relations. A maximum of six hours may be taken; only three may be taken in any one semester. Students who wish to take independent study courses in the public relations curriculum should be aware of the following points: 1) Only six hours of independent study may apply toward a major in Communications; 2) No more than three hours of Public Relations 400 may be directed by the same instructor; 3) Students wishing to enroll in independent study courses must receive written permission from their instructors prior to registration."},
"PRL461":{"description":"Approaching organizations' communications issues through the integration of advertising, marketing, and public relations strategies and techniques."},
"PRL465":{"description":"This course explores principles of institutional advancement (public relations) and development (fundraising). Topics covered include marketing, public relations, publications, alumni relations, annual appeal, planned giving, corporate and foundation relations, major gifts, and capital campaigns. The course examines ethical issues involved in all aspects of institutional advancement. This course focuses on institutional advancement in modern universities and non-profit institutions, providing students with a foundation in the institutional advancement field."},
"PRL466":{"description":"A study of the nature of public opinion and its influences on government, the role of mass media, poll-taking, propaganda techniques, relationship to voting habits, and a detailed examination of techniques developed and used by the press in influencing public opinion."},
"PRL467":{"description":"Development of strategic plans and execution of communications for events that have the potential to generate negative news coverage of unfavorable public opinion of an organization."},
"PRL468":{"description":"A capstone course using research techniques to identify problems and/or public relations opportunities, to monitor implementation of communications programs, and to evaluate those programs using a planning process to develop public relations objectives, strategies, and tactics designed to meet client/employer needs. Students will develop a detailed public relations campaign plan for clients."},
"PRL489":{"description":"Offers students job experience in the discipline through a minimum of 50 hours of supervised professional work per credit hour."},
"PRL499":{"description":"Topics will vary. The purpose of the course is to cover topics that are not specific courses in the curriculum."},
"PSY201":{"description":"A survey of basic concepts and methods of psychology as a behavioral science. Seeks to develop an understanding of the individual and social forces that influence and direct behavior.  This course is a prerequisite for all other psychology courses."},
"PSY202":{"description":"This course is designed to provide an orientation to educational issues and career planning and development for students majoring in psychology."},
"PSY261":{"description":"An examination of the theories, methods, and issues of lifespan human development. Biological, intellectual, emotional, linguistic, perceptual, and social aspects of growth and development from birth through old age are explored within the context of current and classical research in the field. Common physiological, familial, and social stresses associated with individual stages of development are reviewed."},
"PSY273":{"description":"A survey of concepts, topics, and research in social psychology. Including research methodology, cognitive dissonance, prejudice, attitudes, persuasion, conformity, attraction, group dynamics, aggression, and altruism."},
"PSY298":{"description":"Considers sexuality from a broad scientific perspective. Areas covered include sex research and methods, the biological basis of sexuality, sexual behavior, sexuality and the life cycle, sexual problems, and social issues."},
"PSY303":{"description":"An introduction to the research methods and statistics used by psychologists, with an emphasis on experimentation. Research designs, principles of methodology, and the statistical analysis of results will be emphasized."},
"PSY304":{"description":"This is an interdisciplinary course that intends to promote scientific literacy through an active exploration of how scientific discoveries are made, how they are subject to forms of manipulation, and how they impact society in the short and longer terms."},
"PSY312":{"description":"Focuses on physical and psychological aspects of human perception. Study of the senses will consider measurement, methodology, and organizational principles with primary focus on vision."},
"PSY322":{"description":"A survey of past and present conceptions of abnormal behavior. Major diagnostic issues, including the role of psychological tests, are discussed. Symptoms, diagnostic criteria, and etiological factors underlying the major disorders are considered, as well as related legal issues. Selected treatments for these disorders are described and evaluated. Disorders considered include anxiety, dissociative, somatoform, psychophysiological, geriatric, affective, schizophrenia, substance abuse/addiction, sexual deviations, disorders of childhood and adolescence, and personality disorders."},
"PSY325":{"description":"Examines concepts of psychological measurement with emphasis on theories and methods of test construction and principles of measures and evaluation in behavioral sciences and education."},
"PSY332":{"description":"Psychological theories and research that address legal issues, and the role psychologists play in the criminal justice system.  Includes an overview of services provided by psychologists, assessment and therapeutic services, and ethical issues."},
"PSY353":{"description":"Advanced topics in the research methods and statistics used by psychologists, with an emphasis on experimentation."},
"PSY357":{"description":"The scientific study of learning over the last one hundred years has produced a sizable body of laws and principles describing how these processes work. This course investigates the ways in which organisms (human and non-human) change their behavior as a result of experience. The course provides the behaviorist perspective on such changes and therefore focuses on respondent and operant conditioning."},
"PSY361":{"description":"This course takes an in-depth look at physical, cognitive, and social-emotional development and the inter-relationships of each from conception to adolescence. Topics such as the effects of heredity as well as pre and post-natal environments on growth will be examined in the context of current and classical research in the field. Emphasis will be placed on recent advances in the understanding of forces affecting adaptive and maladaptive development. This course has a service learning component, requiring that 15-20 hours of the semester's workload be with an outside organization."},
"PSY362":{"description":"A survey of clinical psychology that considers types of problems, diagnostic instruments, clinical orientations, ethics, and the professional role of the clinical psychologist. Students learn to describe and differentiate among psychodynamic, behavioral, cognitive, humanistic, family and group approaches to treatment. Discussions, demonstrations, and supervised laboratory experiences provide opportunities to relate theoretical knowledge to interviewing, establishing rapport, and basic record-keeping."},
"PSY371":{"description":"This course involves an in-depth study of adulthood and aging by examining the major theories, research, and issues of psychological development. Topics covered will include personality, cognitive functioning, interpersonal relationships, mental and physical health, and sociocultural context."},
"PSY376":{"description":"Examination of psychological principles applied to personnel decision-making including job analysis, performance appraisal, training, and engineering psychology. Emphasis on the selection (hiring) process, including criterion development, standardized tests, simulation, interviews, selection battery validation, adverse impact, and test fairness."},
"PSY377":{"description":"Sport psychology focuses on psychological theories and research related to sport and exercise behavior.  Students will learn how various psychological variables influence participation in sport and exercise contexts including the history and development of sport psychology as a field, group and team dynamics, motivation, leadership, communication techniques, and coaching strategies."},
"PSY381":{"description":"Investigation of the psychological and physiological determinates of gender differences and similarities in behavior with emphasis on development of sex-role identity, interpersonal interactions, achievement and affiliation behavior, sex-typed behavior, socialization and personality perspectives."},
"PSY385":{"description":"The study of behavior as influenced by the nervous system. Physiological mechanisms that underlie behaviors such as sensory and motor processes, motivation, emotion, learning, and behavior pathology are emphasized. Biological background is helpful but not required."},
"PSY386":{"description":"Experimental study of language, including sentence comprehension and memory, language acquisition and development, speech perception, and effects of context, perception, reasoning, and linguistic structure on processing of language."},
"PSY400":{"description":"Devoted to the study of psychological topics not currently part of the curricular offerings. Topics change with the interests of students and faculty."},
"PSY422":{"description":"This course is an introduction to the description, classification, and academic study of psychological disorders in children and adolescents.  Each class of disorders is examined with respect to course of development, core and associated characteristics, prevalence data, explanatory theories, causes and current treatment options as well as prevention strategies."},
"PSY425":{"description":"The study of how the human mind processes information. Topics include models of memory, categorization, concept formation, creativity, and imagery with emphasis on language comprehension and problem solving."},
"PSY435":{"description":"This course will cover the basic theories and concepts that define the field of community psychology while utilizing a service learning approach to community action and research. Students will also learn about the principles of research that guide community psychologists, including traditional research methods, qualitative research methods, consulting, program development and evaluation, and participatory action research."},
"PSY462":{"description":"Critical evaluation and comparison of major personality theories. Includes coverage of key issues in personality research such as person perception and cross-situational consistency."},
"PSY497":{"description":"This course provides a rationale and method for studying the history of psychology as a distinct academic and professional discipline. The course also surveys important theoretical systems used to build the science and application of psychology. In addition, the impact of these historical and theoretical precedents upon contemporary research and practice are discussed."},
"PSY498":{"description":"Provides for a minimum of 150 hours supervised experience related to psychology. Students are expected to arrange internships with approval of instructor, maintain a log of their experiences, and meet regularly with instructor. Assignments are negotiated with the instructor based on the demands of the particular internship experience. A maximum of 3 hours may count toward the 41 hours necessary for the psychology major."},
"PSY499":{"description":"A course intended primarily for individual research in a specific area of psychology. Topics arranged to meet the specific interest of the student and faculty member involved. May be elected on an arranged basis upon approval of the faculty member who is to serve as supervisor of the research. Credit does not count toward the hours necessary for the psychology major."},
"RADT196":{"description":"This course provides a general overview of imaging sciences history and practice. Students gain a beginning understanding of the value of imaging sciences in a healthcare setting and survey the role of radiologic technologists and diagnostic medical sonographers in a variety of settings. Students will also recognize national and state credentialing requirements, identify professional association functions at all levels, and recognize ARRT and ARDMS standards, ethics, and policies and their effects on practicing imaging science professionals' conduct and patient treatment."},
"RADT305":{"description":"This course examines the issues important to the radiographers and radiologic technologists practicing in the field of radiography. Human diversity, ethics, health care systems, hospital organizations, radiology organizations, accreditation, professional credentialing, professional organizations, and professional development are studied in relation to the field of radiology."},
"RADT307":{"description":"This course is designed to emphasize the need for critical thinking and to identify factors that hinder critical thinking. This course focuses on identifying the qualities of a critical thinker through the review of numerous case studies, performance case studies for the ionized laboratory, and critical analysis of radiographs."},
"RADT308":{"description":"This course is an introduction to general anatomy, terminology, imaging and positioning principles, and effective communication skills for a diverse patient population including pediatrics, general, and geriatrics. Additionally, an introduction to digital radiography will be included. Image evaluation, positioning methods, manipulation of radiographic equipment, radiation protection, and critical thinking skills required for radiographic examinations of the chest, abdomen, and upper extremity will be incorporated. Students will have required laboratory experiences and assessments to compliment didactic instruction."},
"RADT311":{"description":"This course serves as an introduction for students regarding the fundamentals of the radiologic and imaging sciences profession. Central focuses of this course provide an opportunity for thorough understanding of x-ray production and radiation exposure, x-ray equipment components and functions, brightness and radiographic contrast, spatial resolution, and distortion.  In-depth discussions regarding field size limitations, collimation, and subsequent effects on radiation protection are also provided."},
"RADT312":{"description":"This course is a continuation of RADT 312. Central focuses of this course provide an opportunity for thorough understanding of scatter radiation production and its mitigation through the use of radiographic grids, various generator effects on x-ray production, types and uses for radiographic filtration, and radiographic image quality critique.  In-depth discussions regarding fluoroscopy and mobile radiography are also provided, with specific attention to the purpose and need for exposure indicators, deviation indices, and technique charts in today's digital direct radiography world."},
"RADT313":{"description":"This course provides a detailed study of the anatomy and positioning principles required for radiographic examinations of the urinary system, gastrointestinal system, biliary system, upper extremity, and shoulder girdle.  Additionally, this course covers topics related to surgical and mobile radiography terminology, examinations, and equipment.  Image evaluation, positioning methods, manipulation of radiographic equipment, radiation protection, and critical thinking skills required for radiographic examinations will be incorporated. Students will have required laboratory experiences and assessments to compliment didactic instruction."},
"RADT316":{"description":"This course provides a detailed study of the anatomy and positioning principles required for radiographic examinations of the lower extremity, pelvis, hip, spine, bony thorax, sacrum, coccyx, and sacroiliac joints. Image evaluation, positioning methods, manipulation of radiographic equipment, radiation protection, and critical thinking skills required for radiographic examinations will be incorporated. Students will have required laboratory experiences and assessments to compliment didactic instruction."},
"RADT318":{"description":"This course provides a detailed study of anatomy and positioning principles required for radiographic examinations of the cranium, facial bones, sinuses, orbits, mandible, and temporal mandibular joints. This course will also detail trauma radiographic examinations of the head, chest, abdomen, pelvis, hip, upper extremities, and lower extremities. Patient assessment, medical emergencies, trauma terminology, types of fractures, common injuries for various types of trauma, radiographic exposure factors, grid usage, teamwork, adaptations of standard procedures for trauma patients, and case studies will be presented. Image evaluation, positioning methods, manipulation of radiographic equipment, radiation protection, and critical thinking skills required for radiographic examinations will be incorporated. Students will have required laboratory experiences and assessments to compliment didactic instruction."},
"RADT333":{"description":"This course is an introduction to radiographic/fluoroscopic rooms, image acquisition and manipulation, radiology office and information systems, hospital nursing units and ancillary departments. This course gives student radiographers supervised practical application of previously studied theory of patient care, imaging procedures and professional skills."},
"RADT334":{"description":"This course includes the physical principles underlying radiologic technology, with special attention given to the equipment required to generate x-rays, the nature and behavior of x-radiation, and the devices relevant to the practical applications of x-rays in diagnosis."},
"RADT335":{"description":"This course is designed to study the biological effects of radiation at atomic, molecular, cellular, and systemic levels. In addition, short- and long-term somatic and genetic effect, dose limits, and methods of radiation protection for the patient and the technologist are discussed."},
"RADT337":{"description":"An introduction to invasive procedures, including cardiovascular, neurological, and other specialized radiographic procedures. This course is designed to provide students with basic knowledge of aseptic procedures, sterilization, patient preparation, and instrument manipulation. The course will include an in-depth review of vascular, visceral and neurological anatomy visualized and imaged during invasive procedures. Radiologic and medical pharmacological agents will be discussed in regards to dosage, precautions and usage during invasive procedures."},
"RADT343":{"description":"During this course students will apply knowledge and essential skills of radiographic positioning and exposure. Students will be exposed to clinical rotations in computed tomography and magnetic resonance imaging."},
"RADT345":{"description":"Studies the changes that occur with disease in the various systems of the body and the methods of examination and application to radiologic technology."},
"RADT353":{"description":"This course is a continuation of RADT 343. Students will be exposed to clinical rotations in invasive procedure and start to orient to other clinical affiliates and their established protocols."},
"RADT355":{"description":"This course offers an advanced approach to patient care, patient education, and patient management within imaging sciences. Course content will include advanced patient assessment, emergency and lifesaving care, hemodynamics, pathology, pharmacology and contrast media administration."},
"RADT365":{"description":"This course emphasizes individualized study in two major areas of diagnostic imaging which include radiographic procedures and patient care procedures."},
"RADT411":{"description":"This course provides the students with a detailed study of computed tomography and magnetic resonance imaging physics and instrumentation information. Course topics include principles of operation, system components, image characteristics, image resolution, image quality, nuclear magnetism, precession of nuclei, production of the magnetic resonance signal, magnetic resonance pulses, magnetic resonance decay, production of the magnetic resonance image, magnetic resonance imaging equipment, tissue and image contrast, magnetic resonance safety, primary and secondary magnets and biological hazards of magnetic resonance imaging fields."},
"RADT412":{"description":"This course is a continuation of RADT 312. Central focuses of this course provide an opportunity for thorough understanding of altered exposure due to patient condition, pathology, and contrast agents. In-depth discussions regarding patient alignment and motion, grid types and conversion factors, exposure factor formulation, and quality control measures are also provided."},
"RADT413":{"description":"This course provides the students with a detailed study of the magnetic resonance imaging computed tomography procedures of the head and spine."},
"RADT414":{"description":"This course provides the students with an introduction to the functions and basic procedures of magnetic resonance imaging and computed tomography. This course includes an overview of the field of MRI/CT, ethics, patient care, professional standards, and the clinical application of imaging skills of the following examinations: routine examinations of the head, skull, neck, cervical spine, thoracic spine, and lumbar spine. All MRI/CT procedures will be performed under the direct supervision of a registered MRI/CT technologist."},
"RADT415":{"description":"This course provides the students with a detailed study of the magnetic resonance imaging physics and computed tomography procedures of the thorax, abdomen, urogenital system, pelvis, hip, and knee."},
"RADT416":{"description":"This course provides the students with the introduction to the functions of magnetic resonance imaging and computed tomography. The students will perform the following routine examinations: the thorax, abdomen, pelvis, and knee. All MRI/CT procedures will be performed under the direct supervision of a registered MRI/CT technologist."},
"RADT421":{"description":"This course focuses on clinical education principles and procedures for radiology programs. Lesson plan formation, unit plan organization, teaching strategies for the clinical educator, and evaluation methods are studied."},
"RADT424":{"description":"This course will provide students an opportunity to explore leadership within a diagnostic imaging department. Students will participate in classroom instruction or Internet delivered instruction.  Students will share knowledge gained during clinical education and/or work environment with each other concerning positive experiences and difficulties they may have encountered.  Students will focus on teamwork and problem-solving strategies as applicable in practice."},
"RADT425":{"description":"This course focuses on the general guidelines for radiographic critique. The student will critique radiographs on the following regions: chest and abdomen; upper and lower extremities; shoulder; pelvis and hip; cervical, thoracic, and lumbar vertebrae; ribs; and cranium."},
"RADT426":{"description":"This course provides students with a practicum in clinical applications with general radiography and radiology management.  The student will intern as an assistant clinical instructor or assistant radiologic manager in an accredited radiology clinical site.  Concurrent with the clinical practicum the student will participate in classroom instruction or Internet-delivered instruction.  Students will share knowledge with each other concerning positive experiences and difficulties they may encounter in their clinical practicum.  Students will be supervised by clinical instructors and departmental managers."},
"RADT427":{"description":"This course provides students with a detailed study of digital radiography encompassing image acquisition and storage.  Students will also study current radiology management systems and picture archival medical systems."},
"RADT435":{"description":"This course is a continuation of RADT 353. Students will become oriented to the clinical outpatient radiology environment. In addition, students will utilize critical thinking skills and learn to prioritize duties as required during hours of optimized staffing."},
"RADT445":{"description":"This course is a continuation of RADT 435. Students will expand their critique of images as it relates to pathological processes."},
"RADT455":{"description":"This course is a continuation of RADT 445. This course is a final mastery of all clinical competencies with an introduction to alternative clinical experiences.  By the end of this practicum, students will demonstrate competency to function as an entry-level radiologic technologist."},
"RADT456":{"description":"This course emphasizes individualized study in three major areas of diagnostic imaging which includes radiation protection; radiographic equipment operation and maintenance; and image production and evaluation."},
"RADT465":{"description":"This course emphasizes individualized study in the five major areas of diagnostic imaging which include radiation protection; radiographic equipment operation and maintenance; image production and evaluation; radiographic procedures; and patient care procedures."},
"RADT480":{"description":"This course provides the opportunity for analysis and synthesis of concepts and research related to special topics in imaging sciences and healthcare."},
"RADT486":{"description":"This course provides students with the knowledge, theory, and proficiency in performing the following MRI/CT examinations: skull, head, sinuses, neck, cervical spine, thoracic spine, lumbar spine, pelvis, hip, and knee."},
"RADT491":{"description":"A capstone course focusing on the synthesis of professional knowledge, skills, and attitudes in preparation for professional employment and lifelong learning.  This course will explore the imaging technologist's contribution to the body of knowledge of the radiologic sciences, effectively analyze resources to promote growth within the profession, foster life-long learning skills to stay in step with the current health care environment and promote reflection in practice to meet required professional continued qualifications."},
"REST201":{"description":"This course examines and studies the procedures, techniques, and equipment utilized in the administration of oxygen and other gases, with emphasis on analysis. It includes introductory topics pertinent to entering the respiratory care profession, e. g., and patient assessment, basic modes of care, specific medical terminology, and general patient care."},
"REST211":{"description":"Emphasizes procedures, techniques, and equipment utilized in humidification and aerosol therapy."},
"REST216":{"description":"Introduces the student to the scientific basis of respiratory care, including the study of the anatomy of the normal cardiopulmonary system, gas exchange mechanisms, and interpretation and clinical application of arterial blood gasses."},
"REST223":{"description":"Examines the etiology, pathophysiology, physical diagnosis, and respiratory care of pulmonary problems with emphasis on patient interview and examination of selected patients, and discussion of commonly related clinical problems."},
"REST226":{"description":"This course provides an overview of the basics of cardiopulmonary pharmacology therapeutics, focusing on dosages and solutions, side effects, mechanism of action, and routes of administration."},
"REST303":{"description":"Emphasizes procedures, techniques, and equipment utilized in airway management and supportive therapy."},
"REST312":{"description":"Examines and studies procedures, techniques, and equipment utilized in the practice of lung physiotherapy, with emphasis on the mechanics of respiration, breathing exercises, and respiratory care techniques. The course will also examine procedures, techniques and equipment utilized in the practice of obtaining arterial blood samples."},
"REST317":{"description":"Provides the student with additional information regarding pulmonary physiology. This will include advanced concepts of the cardiopulmonary system."},
"REST322":{"description":"Emphasizes procedures, theory, physiology, techniques, and equipment utilized in mechanical ventilation and life support."},
"REST323":{"description":"Continuation of REST 322 with emphasis on hands-on experience troubleshooting mechanical ventilation, exploiting ventilator graphs, and identifying key determinates of ventilator lung injury. In addition, students will learn evidenced based guidelines for ventilator management."},
"REST325":{"description":"Further explores the structure and function of the disease lung with case studies and discussion of respiratory disorders."},
"REST361":{"description":"Examines the relationship between theoretical concepts from the physician's point to the clinical practice of the respiratory therapist, with emphasis on emergency care treatment."},
"REST362":{"description":"A study of therapeutic procedures to achieve adequate spontaneous and artificial ventilation of the neonatal and pediatric patient. Includes indications, complications, and physiological effects of ventilatory support."},
"REST363":{"description":"Examines testing techniques, performance standards, quality assurance procedures and clinical applications for basic and advanced pulmonary diagnostic testing. Topics to be cover include Pulmonary Function Testing, Bronchoscopies, and Polysomnography."},
"REST381":{"description":"Practical study of theory and practice of respiratory therapy and the treatment of respiratory diseases. Includes seminar discussions of client problems relevant to respiratory care."},
"REST382":{"description":"Continuation of REST 381 with emphasis on techniques used in the critical care setting."},
"REST451":{"description":"Examination of specific theory and practice used in the leadership in a healthcare environment. Emphasis will be placed on specific skill sets used by managers of today's workforce. Includes effective communication skills and professionalism."},
"REST452":{"description":"Examines research in respiratory therapy. This writing-intensive course promotes the use of research findings as a basis for improving clinical practice. Quantitative and qualitative research methodologies are presented. Emphasis is on the critical review of research studies and their applications to clinical practice. An overview of evidence-based practice is provided."},
"REST453":{"description":"Provides the necessary pulmonary disease management information respiratory therapists need to improve long-term pulmonary disease care and improve patient quality of life. This course will focus on the key components of pulmonary disease education for COPD, pulmonary fibrosis, asthma, pulmonary hypertension, and cystic fibrosis.  The course also provides instruction on pulmonary function technology, tobacco cessation, pulmonary rehabilitation, patient education, and many other vital areas of effective pulmonary disease management."},
"REST454":{"description":"Focuses on the advanced medical treatment and respiratory care of the critically ill adult patient. Emphasis is placed on cardiopulmonary assessment and treatment of trauma, post-surgical, cardiac, and respiratory failure patients."},
"REST455":{"description":"This course provides an overview of objectives, methods, and expected results of pulmonary rehabilitation. Patient and family education, bronchial hygiene, breathing re-training, physician re-training, and home care will be described and discussed. Basic concepts of gerontology and geriatrics are presented."},
"REST456":{"description":"This writing-intensive course explores the impact of numerous professional and societal forces on health care policy and practice. Content includes an analysis of current studies; respiratory care policy and position statements; political, environmental, and cultural issues; and changing respiratory roles. The study of these issues examines the impact on health care delivery systems in today's society."},
"REST460":{"description":"Provides students with the opportunity to study a specialized area of interest in respiratory therapy. This course allows for independent study under the direction of a faculty member which includes extensive in-depth reading and research on a narrow topic."},
"REST491":{"description":"Continuation of REST 381 and REST 382 with emphasis on clinical application of respiratory care in the critical care setting. Students also gain experience in other areas of the hospital including the OR, ER, and special care areas. Students will also have the opportunity to receive instruction in advanced life support (ACLS)."},
"REST492":{"description":"Respiratory therapy internship with emphasis on the utilization of the respiratory therapy process to provide safe patient care, including enhanced communication, time management, and organizational skills."},
"REST499":{"description":"This advanced course provides the opportunity for analysis and synthesis of concepts related to special topics in respiratory therapy."},
"RTV150":{"description":"Assignment of electronic media duties with radio station WSWI-AM, the Scripps Howard Video Production Complex, or in an approved CAP course. Students may earn up to eight hours credit for this course, but may enroll for no more than three credit hours per semester. No more than three credit hours may be applied to a major or minor in Communications."},
"RTV151":{"description":"A history and survey of broadcasting. This course traces the development of broadcasting to the present day, with emphasis on contemporary broadcasting with its social, cultural, and economic implications."},
"RTV255":{},
"RTV350":{"description":"A workshop course involving the planning, production, and presentation of radio and/or television program materials."},
"RTV351":{"description":"Specific training in broadcast performance situations applicable to both radio and television, including commercial announcing, news reporting, interviewing, and ad-lib announcing. Attention is given to pronunciation, articulation, and diction."},
"RTV352":{"description":"Study of the problems and techniques of studio-based, multi-camera, real-time television production. Students will prepare, produce, and direct programs for broadcast."},
"RTV353":{"description":"Study of the theory and practice of single camera video production with emphasis on concept and script development, camera technology, shot composition, lighting, computer nonlinear editing, and special effects. Students will prepare, produce, and direct client-based PSAs and channel IDs for broadcast."},
"RTV354":{"description":"Students will learn to write effective copy for radio, television, and the Internet. Assignments will involve writing of advertising and public service copy, and promotional materials for the electronic media. Legal and ethical issues, consumer behavior, and various advertising strategies will also be covered."},
"RTV356":{"description":"A study of radio and television sportscasting, with an emphasis on radio. Emphasis will be placed upon play-by-play sports newscasts, interviewing, and features."},
"RTV357":{"description":"A study of the policies and practices of broadcast news with an emphasis on reporting. Students will produce news packages and learn the decision making processes of broadcast news managers, including agenda setting, gate keeping, and diversity and ethical issues."},
"RTV358":{"description":"In-depth study of the problems and techniques of preparing, producing and designing audio production for radio, video and the internet."},
"RTV366":{"description":"Techniques of gathering, writing, editing, and producing news for radio and television. Topics will include broadcast style, working with wire services, codes of ethics, legal considerations, and news applications of audio and video technology."},
"RTV400":{"description":"This course is designed to provide an opportunity for upper-division Communications majors and minors to research subject areas in the field of radio-television. A maximum of six hours may be taken; only three hours may be taken in any one semester. Students who wish to take independent study courses in the radio-television curriculum should be aware of the following points: 1) Only six hours of independent study may apply toward a major in communications; 2) No more than three hours of Radio-Television 400 may be directed by the same instructor; 3) Students wishing to enroll in independent study courses must receive written permission from their instructors prior to registration."},
"RTV451":{"description":"A study of current theories and practices of radio and television programming. The stimulation of creative thinking regarding media programming and its conversion into tangible and practical form. Special attention is given to important aspects of broadcast law and station management and their relationships to programming."},
"RTV452":{"description":"Techniques for selling time on radio, television, and cable. Includes interpretation of ratings reports, working with advertising agencies, preparation of sales presentations, and marketing."},
"RTV453":{"description":"The special problems encountered by managers of broadcast and cable operations. Emphasis on FCC regulations, managing \"creative\" personnel, serving the public interest, and other issues faced by radio, TV, and cable managers."},
"RTV457":{"description":"A capstone course examining contemporary issues confronting broadcasters, cable operators, and Internet content providers. Economic, regulatory, social, and ethical issues are explored."},
"RTV489":{"description":"Offers students job experience in the discipline through a minimum of 50 hours of supervised professional work per credit hour."},
"RTV499":{"description":"Topics will vary. Purpose of course is to cover topics that are not specific courses in the curriculum."},
"SOC121":{"description":"An examination of social dynamics and consequences of social life. The main topics are culture, social groups, socialization, deviance, social stratification, race relations, gender, and family. It is required of majors and minors."},
"SOC200":{"description":"This course is a means for the study of selected topics of current interest and importance in sociology. These topics will vary in accordance with the interests of students and faculty. Students may repeat the course without limit as the topic changes."},
"SOC225":{"description":"A consideration of criminality, its nature and extent, particularly in the United States. Includes analysis of the etiology of criminal behavior, the sociology of criminal law, and societal reaction to criminals."},
"SOC231":{"description":"Examination of the nature, extent, causes, and effects of selected contemporary social problems, such as gender, sexual behavior, drugs, environment, economic inequality, racial inequality, crime, and education."},
"SOC251":{"description":"A general consideration of human behavior in social situations with particular emphasis on communication processes, socialization, social roles, social self, and social groupings."},
"SOC261":{"description":"The course examines research about the family life cycle. The specific areas investigated are sex roles, a history of the American family, dating, sexual behavior, marriage, childbirth, children, the middle-aged, the elderly, divorce, and step-parenting."},
"SOC311":{},
"SOC321":{"description":"This course will examine aging in relation to social roles, processes, and structures.  Topics include social theories of aging, demography, life course, retirement, and health care systems and policy."},
"SOC341":{"description":"The social organization of medical care. The course considers patterns of morbidity and mortality, social epidemiology of disease, social effects of disease, doctor-patient relationships, cost of medical services, hospitalized patients, modes of medical practice, hospital organization, and programs of medical care."},
"SOC343":{"description":"A multidisciplinary survey of the principles and processes associated with death, dying, and grief. Students will explore the social structures and process as well as the historical, psychological, medical, political, legal, ethical, and therapeutic aspects of death, dying, and grief in order to increase sensitivity and awareness of end of life issues. The course considers different concepts of death, the demography of death, the determination of death, the funeral industry, the social psychology of dying, dying patients, and the survivors."},
"SOC370":{"description":"An intensive, small-group discussion of recent research on topics of current interest to sociologists. The seminar format will emphasize critical thinking and discussion."},
"SOC371":{"description":"An examination of 19th and early 20th century developments in sociological theory. Course examines developments in some of the major theoretical traditions. The context and key ideas of each tradition will be considered. Students will read original texts."},
"SOC372":{"description":"An examination of early 20th century to present developments in sociological theory. Course examines developments in some of the major theoretical traditions. The context and key ideas of each tradition will be considered. Students will read original texts."},
"SOC375":{"description":"An investigation of change in cultural patterns, behavioral relationships, and social structure. Topics comprise social movements, work, urbanization, family, computerization, social organizations, and other aspects of American society."},
"SOC391":{"description":"An examination of the various methods that sociologists use to conduct research. Course work includes concept formation, sampling, questionnaire construction, content analysis, experiments, and field observations. Students learn how to conceptualize and design their own research project."},
"SOC392":{"description":"An introductory course in descriptive and inferential statistics. Course work includes probability, univariate statistics, bivariate statistics, and statistical modeling using the SPSS computer analysis software package. Students learn how to write research reports using statistical evidence."},
"SOC415":{"description":"A sociological approach to understanding the interaction of society and the natural environment. It focuses on social causes and consequences of environmental problems and mitigating actions taken toward them. It also addresses inequality in the distribution of environmental problems. Several specific cases are studied in detail."},
"SOC421":{"description":"A sociological exploration of the origins and influence of race, ethnicity, and cultural/national identity in American and international stratification systems."},
"SOC424":{"description":"The quantitative and qualitative aspects of population and population trends in significant areas of the world; emphasis is on cultural implications with some attention to population research techniques."},
"SOC426":{"description":"An examination of various concepts of deviant behavior, the nature and prevalence of such behavior, and the theories developed to explain deviance. The course emphasizes the relationship of such behavior to social structure and social processes."},
"SOC431":{"description":"An exploration of gender patterns. The course focuses on gender differences. It analyzes the causes and the consequences of these differences for social life, including the various social inequalities between males and females that have become institutionalized in American society."},
"SOC441":{"description":"Consideration of social movements as attempts to establish a new order of life. Analyzes states of development from inception to the achievement of full institutionalization. Specific social movements are examined such as the labor movement, the women's movement, and racial, religious, and political movements."},
"SOC461":{"description":"An examination of current problems and controversies in American education from preschool to college, particularly issues involving quality and equality. The course explores the reciprocal influence between educational institutions and other institutions in American society."},
"SOC463":{"description":"An examination of social stratification in U.S. society with comparisons to other countries. The course explores different indicators of inequality, the social class system, theories of inequality, poverty, social mobility, and legitimation of inequality. It also explores racial and gender stratification."},
"SOC471":{},
"SOC489":{"description":"An extensive examination of the main sociological ideas on a specific topic. The major paper is a product of critical reading or advanced research."},
"SOC499":{"description":"An opportunity for a job-related experience in the area of sociology. Students will be placed in an agency that reflects their future occupational interests."},
"SOCW221":{"description":"This course is an introduction to the field of social work. The class is designed to expose the student to the profession of social work, an overview of systems theory, and the relationship of the social worker to the overall social welfare system. The course emphasizes generalist social work practice, the NASW Code of Ethics, and social work roles needed to impact systems to solve human problems. Special emphasis will be placed on helping the student to develop an understanding and appreciation for diverse cultures, social and economic justice, disabilities, and populations at risk. Open to all students."},
"SOCW222":{"description":"This course explores the philosophical and historical evolution of the American social welfare system. Course content covers public and voluntary efforts to deal with poverty and other social problems. Content addresses theories of political influence on social change. Content on social justice, oppression, discrimination, spirituality, and diversity are included. Open to all students."},
"SOCW223":{"description":"This is an introductory course to the field of gerontology with an emphasis on programs, resources, and services provided to older populations. Open to all students."},
"SOCW224":{"description":"Introduction to Social Justice is a semester-long course designed to engage students in the careful reading and critical analysis of topics related to social justice issues from historical perspectives.  Through readings, guest speakers, field experiences, and independent inquiry, students will deepen their understanding of a variety of social justice movements."},
"SOCW225":{"description":"This introductory course provides a survey of child welfare services in the human services field. Various services are examined including child abuse and neglect, adoption, foster care, and family support services. Open to all students."},
"SOCW238":{"description":"This course explores important issues regarding disabilities in contemporary society. The history of treatment of people with disabilities is explored with an emphasis on the implications of history in current programs and services. Special focus is on a critical analysis of the assumptions that support contemporary thinking about disabilities; as well as legislation and policy issues related to the civil rights of persons with disabilities. A wide range of readily apparent and invisible disabilities will be addressed, including physical, sensory, psychiatric, and developmental/learning disabilities."},
"SOCW239":{"description":"This course is designed as a focal point of integration for human behavior content in life span development, biology of human concern, and social work courses required of pre-majors. The overall goal of this course is to assist the student in understanding the \"person-in-environment\" and systems concepts when working with individuals, families, and groups. This course provides opportunities for the student to understand the biological, psychological, sociological, and spiritual aspects of individuals as well as other cultures and minority groups. As a result, students will be able to integrate these components of human behavior as generalist social workers."},
"SOCW241":{"description":"Basic introduction to generalist social work practice and its various modes of intervention. The course gives the student a frame of reference for analyzing various systems encountered in social work practice. Concepts, skills, value systems, and activities which are the foundation of the practice of social work will be covered."},
"SOCW242":{"description":"The introduction to substance abuse course prepares students to identify and understand the problems of alcohol and other substance abuse. The nature of addiction, addiction across the lifespan, co-morbidity, diversity, and an introduction to strengths-based helping strategies will be presented to students. The student will demonstrate learning by identifying differing perspectives and theoretical models that define addiction, biological and socio-biological explanations of addiction, signs and symptoms of addiction, ethnic, cultural, and gender issues related to addiction and treatment."},
"SOCW323":{"description":"This course is designed to give the student an overview of the variety of social, psychological, physical, economical, and environmental issues that confront our aging population today. Students will be exposed to a number of social work generalist roles, primary prevention strategies, and relevant social work interventions which are based on major theoretical approaches in working with this population. Open to all students."},
"SOCW325":{"description":"This basic statistics course exposes students to descriptive and inferential statistical measurements. This course includes various methods of data collection, analysis, and presentation. Research methodology and related issues will be examined as they pertain to the social work researcher and, more specifically, the social work practitioner."},
"SOCW326":{"description":"This course exposes the student to the fundamentals of social work research methodology. Students will gain basic skills and knowledge related to hypothesis development, sampling procedures, research methodology, measurement processes, writing and dissemination of reports. Focuses on  research methodology used by social work professionals to evaluate macro and micro practice situations and program development."},
"SOCW340":{"description":"A laboratory experience that engages students in experiential learning situations that enhance the essential interpersonal communication and professional interviewing skills essential to the helping professions. Variables including culture, race, gender, age, ethnic background, disability, and social and economic justice will be examined as they impact the professional communications process. Open to social work majors only."},
"SOCW341":{"description":"Social work is a policy-based profession and this course takes the student through the analysis of policy as it affects the practice of social work. Students study the historical, social, political, and economical aspects of social welfare policy and engage in the analysis of a social welfare policy. The course also examines the impact of policy change on both diverse groups and populations at risk. Open to social work majors only."},
"SOCW342":{"description":"This course is designed to build knowledge and develop the skills needed to work with individuals in a variety of settings. Generalist practice intervention is used as a model to help the student in understanding and completing assessments, and in developing intervention plans among diverse populations and populations at risk. Students also explore ethical and value issues that confront the social worker. Open to social work majors only."},
"SOCW343":{"description":"The purpose of this course is to train student social workers in group methods used in generalist social work practice situations. The types of social group work, the history of group work, and skills needed to be an effective group leader are presented. Students also are exposed to group work with both diverse groups and/or populations at risk. Open to social work majors only."},
"SOCW344":{"description":"The macro course prepares social work students to facilitate planned changes in the community and within agencies. Social work macro practice theory and strategies are presented to students in the course. Students demonstrate learning by identifying a problem or need in the community, developing an implementation plan based on solid research, and carrying out the implementation plan and evaluating the results. Open to social work majors only."},
"SOCW353":{"description":"This course will focus on the impact of substance abuse and addictive disorders on the individual and the family. Emphasis will be placed on skill development utilizing family systems models and techniques."},
"SOCW354":{"description":"This course will include content on pharmacology, neurology, assessment, treatment protocols, documentation and on-going treatment interventions for addictions. Additional content on topics related to addictive disorders such as gambling, sexual addictions, and eating disorders will be covered. Information on how public policy and criminal justice systems impact treatment will be explored."},
"SOCW390":{"description":"This course is intended for the examination of special topics in social work on an individual basis. Topics will be developed and examined on an arranged basis in agreement with the student and the supervising faculty member. Research culminates in a scholarly paper submitted by the student."},
"SOCW392":{"description":"This course is designed for students seeking to increase their global awareness by engaging in social service work outside the United States. Included in the course is a visit to another country where students will apply social work skills and values through service learning activities."},
"SOCW400":{"description":"This course exposes students to a variety of selected topics of current interest and importance in human service delivery. These topics change with the interests of faculty and students."},
"SOCW401":{"description":"An experiential, on-site supervised learning opportunity consisting of a minimum of 150 contact hours per semester or approximately 12 hours per week in a local social service agency approved by the director of field in the Social Work department. The student will be exposed to the actual skills and behavior of the professionals in social work and will have the opportunity of testing newly formed values and knowledge. Open to Social Work majors only."},
"SOCW402":{"description":"The focus is on integrating classroom knowledge content as it relates to the issues of the field practicum. Open to social work majors only."},
"SOCW411":{"description":"Course provides supervised experiential learning external to the classroom. A minimum of 300 contact hours per semester or approximately 20 hours per week are spent in a community social service agency or related organization to develop social work skills and gain supervised practice in performing the role of social worker. Open to Social Work majors only."},
"SOCW412":{"description":"Focus is on providing field students the opportunity of resolving issues encountered in the practicum by consulting with their peers. Under the direction of the faculty, the students participate in consultation/training and peer interaction to enhance the learning gained in the field practicum. Open to Social Work majors only."},
"SOCW413":{"description":"Students will choose from one of several \"Project Options.\" With the guidance of the social work faculty, students carefully develop a project that culminates in a \"Senior Level\" project or research term paper. The paper will be developed in the final semester of the student's senior year. The student must have completed all social work requirements, with the exception of SOCW 411 and SOCW 412. Open to Social Work seniors only."},
"SOCW490":{"description":"This course is cross-listed for both undergraduate and graduate students seeking to increase cultural competence and global awareness by engaging in direct human service work in the country of Jamaica. The primary delivery of this course takes place during an extended visit to Mandeville, Jamaica where students will apply social work skills and values through service learning activities in social service settings. Prior to foreign travel, students will study Jamaica's past in-depth in order to understand the historical context of the social issues currently facing this country. The course emphasizes development of knowledge and skills within a cultural and ethnic-sensitive approach to practice."},
"SPAN101":{"description":"Fundamentals of Spanish; no previous Spanish required. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Spanish speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"SPAN102":{"description":"Fundamentals of Spanish; continuation of SPAN 101 . Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Spanish speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"SPAN203":{"description":"Continuation of SPAN 102. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Spanish-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"SPAN204":{"description":"Continuation of SPAN 203. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Spanish-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"SPAN205":{"description":"Intensive oral practice of Spanish on the intermediate level. Topics include Hispanic popular culture, current events, work, and travel."},
"SPAN275":{"description":"Weekly showings and discussions of Hispanic films. Analysis of the thematic and artistic diversity from its beginnings in the early 20th century to the present. (Course taught in English)"},
"SPAN303":{"description":"Expansion of bilingual proficiency of Spanish-heritage speakers. Emphasis on basic language development in reading, writing, and cultural knowledge."},
"SPAN306":{"description":"Continuation of SPAN 204. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Spanish-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"SPAN307":{"description":"Continuation of SPAN 306. Emphasis on the four language skills of listening, speaking, reading, and writing in the cultural context of the Spanish-speaking countries. In addition to regular class meetings, students are required to complete one hour per week in the language lab."},
"SPAN308":{"description":"Study of Hispanic cultures, emphasizing developments in language, literature, philosophy, the arts, politics, traditions and institutions from the beginnings to present.  (Course may be taught in English.)"},
"SPAN325":{"description":"Critical readings and literary and cultural analysis of selected Latin American and Spanish texts from the Middle Ages to present in the major literary genres."},
"SPAN332":{"description":"A study of Latino Voices in the United States."},
"SPAN333":{"description":"Study of social conditions, and literary and cultural products of Latin American minorities of African descent that have contributed to the establishment of modern nation-states."},
"SPAN335":{"description":"Study of the working knowledge of the target language, cultural behaviors, and values useful in various professional fields. Course may include a service learning component."},
"SPAN344":{"description":"Study and analysis of selected Spanish American and Spanish literary masterworks in English translation."},
"SPAN402":{"description":"Intensive practice of original compositions with a review of the difficult points of grammar, directed to promote students to write Spanish correctly and to develop a literary style."},
"SPAN411":{"description":"Fundamentals of Spanish phonetics, phonology, and dialectology."},
"SPAN412":{"description":"Fundamentals of Spanish morphology, syntax, and semantics, including an overview of the history of the Spanish language."},
"SPAN414":{"description":"Study of the development and understanding of traditional poetry forms as manifested in the literature of Spanish America and Spain."},
"SPAN415":{"description":"Literary analysis of ideas of the principle thinkers of Spanish American countries and Spain through their most important works."},
"SPAN417":{"description":"Study of 20th century drama, emphasizing the contributions of dramatic traditions in Latin America and Spain."},
"SPAN454":{"description":"The verse dramas which illustrate the dramatic techniques and themes prevalent in Spain (1600-1700)."},
"SPAN460":{"description":"An opportunity for job-related experience in the area of language studies."},
"SPAN490":{"description":"Specialized topics to study in the fields of Spanish language study, teacher preparation, culture, and literature."},
"SPAN499":{"description":"Research or advanced study in a specific area of Hispanic literature, culture, education, or language, culminating in an analytical paper or comprehensive exam."},
"SPTM223":{"description":"As an introductory course in recreation and leisure services, emphasis is placed on the history and development of concepts, principles, and philosophical rationale of the recreation movement. The development of leisure and recreation is investigated from ancient to modern times.  Future implications for recreation as a profession are analyzed in light of current trends.  Course topics include historical investigations of the philosophic and theoretical bases of public and private recreation service organizations."},
"SPTM225":{"description":"This course provides a comprehensive view of management processes such as the hiring of coaches, generating revenue to cover expenses, recruiting and its mechanics and regulations, ensuring the well-being of student-athletes, and Title IX enforcement."},
"SPTM226":{"description":"Examines the basic philosophies and concepts of coaching. Attention is focused on the background information of coaching in general rather than on specific sports. Topics of study include the background of athletics, program organization, conditioning, motivation, and public relations as they relate to the coaching position."},
"SPTM228":{"description":"Introduces the foundations and principles on which sport management operates. A general overview of the sport industry and the fundamental knowledge and skill sets of the sport manager are discussed. Potential employment and career choices are highlighted."},
"SPTM263":{"description":"This course covers the philosophy of Outdoor Adventure Education by examining the practical organizational and instructional skills needed for outdoor leadership. The student will be presented with definitions of terms related to outdoor education, historical antecedents, environmental and adventure education. This course will also present perspectives on the social and psychological benefits of outdoor education."},
"SPTM283":{"description":"Provides students with practical experiences in sport management related fields. Students will develop a resume, cover letter, reference page, portfolio, and learn about networking and interviewing in a sport management related field.  Students have the opportunity to observe professionals at work and learn about specific skills in intercollegiate athletics, recreational sports, and professional sports."},
"SPTM288":{"description":"The course will provide a comprehensive understanding of the emerging field of sport communication.  Through the examination of key research, current trends, industry  demands, and the organizational value of sport communication, readers will gain a thorough grounding in the diverse areas the field offers for both academic study and professional practice."},
"SPTM302":{"description":"Integrates coursework with planned and supervised recreational sports experiences for a total of 150 hours."},
"SPTM303":{"description":"Integrates coursework with planned and supervised intercollegiate athletic experiences for a total of 150 hours."},
"SPTM304":{"description":"This course will explore the identification and evaluation of research problems within the fields of kinesiology and sport.  Students will engage in the interpretation of research in sport management, exercise science, human movement science, leisure, and athletic training; and will learn basic statistical analysis and interpretation.  Attention will be paid to the research process including research design, data collection methods and dissemination of pertinent results."},
"SPTM305":{"description":"Integrates coursework with planned and supervised sport management experiences for a total of 150 hours."},
"SPTM332":{"description":"Introduces students to skills needed to manage a sport facility including operations, maintenance, finance/budgeting, purchasing, human resources, security, design/ development, and legal issue within diverse settings. Practical application is provided through exercises that are completed by visiting sport facilities in the community during the semester."},
"SPTM333":{"description":"This course introduces students to the marketing approaches and tolls utilized by the sport industry; at the professional, intercollegiate, and interscholastic levels.  Students will learn the theoretical background of sport marketing by focusing on the basic principles and foundational concepts within sport marketing and sport consumer behavior.  Students in this course will gain skills and practical experience by developing SWOT analyses, approaches to market segments, pricing schemes, promotions, and branding strategies.  Students will exhibit the culmination of their knowledge acquired by creating a marketing plan project derived by conducting research and collecting data on market trends as it pertains to a sport organization."},
"SPTM334":{"description":"Introduces planning, funding, operating, and evaluating events within the sport industry. Provides practical application through the involvement of planning and managing events during the semester."},
"SPTM335":{"description":"This course introduces the responsibilities of college sport information directors or sport public relations professionals that includes having students write press releases, create media kits, and develop crisis communication plans pertaining to current events within the sport world. Additional topics of discussion include the integration of public relations as it pertains to concepts of strategic management, interviews and press conferences, internal and external communication, differentiating between marketing and promotional aims, legal aspects of PR, and the symbiotic relationship between sport and the media."},
"SPTM336":{"description":"This course introduces students to the ethical problems, dilemmas, and conflicts that contribute to the power and politics within sport organizations. Moreover, students will learn the basics of managerial activities necessary for governance and policy development that conceptualize the operational structure for various organizations. Students will be encouraged to integrate management and ethical theory into governance and policy development decisions pertaining to current events within the sport industry. Students will exhibit the culmination of their knowledge acquired by creating a case study comprehensively covering a governance topic of their choice."},
"SPTM337":{"description":"As a specialized course in public recreational sport service delivery and management, an overview of programmatic elements and techniques in recreational sports is provided.  Topics include informal, club, and instructional sports programming; values of recreational sports; and terminology and career opportunities in municipal recreational sport settings."},
"SPTM338":{"description":"Management of campus recreational sport facilities has expanded in both scope and complexity in the 21st century. Today's state-of-the-art recreation and sport facilities offer high-end amenities for students, faculty, staff, and the surrounding community. Managing these diverse and complicated programs and facilities requires knowledge of operations, client services, and industry standards. This course will examine the multiple aspects of contemporary collegiate recreation management, including the multiple professional and organizational properties of campus recreation in the United States."},
"SPTM353":{"description":"This course is designed for both inexperienced and experienced social media users.  Throughout the semester, students will be introduced to various social media platforms with an emphasis being placed upon hands-on experience.  The primary focus of this course will be the relationship between sport and social media.  However, students will be expected to understand the larger social implications associated with these various mediums."},
"SPTM390":{"description":"A study of selected topics of current interest and importance in sport management.  These topics may include opportunities for students to complete research, participate in experiential learning experiences, and a wide variety of learning activities in accordance with the interests and importance of kinesiology and sport."},
"SPTM451":{"description":"Examines the basic legal and risk management issues surrounding the sport management profession.  Informs future sport managers of their legal duties and responsibilities, prevention of legal liability and litigation, and the basic foundation of the U.S. legal system."},
"SPTM452":{"description":"Examines the basic financial and managerial accounting procedures encountered within the sport industry including revenue sources for professional and collegiate sport organizations, sponsorship development, and stadium financing.  Additional topics include fundraising, economic impact analysis, and private/public subsidy.  Practical experience is provided in the form of sponsorship package creation."},
"SPTM453":{"description":"This course explores how historical and contemporary forces in society have shaped the opportunities and experiences of various cultural groupings in the realm of sport.  In particular, the course will focus on diversity issues as they relate to race, ethnicity, gender, social class, sexuality and physical ability/disability.  Examples of possible topics include: Native American names and mascots for sport teams, homophobia in sport, social class stratification in sport, gender equity in collegiate sport, and the expansion of sporting opportunities for individuals with disabilities,."},
"SPTM482":{"description":"This course introduces students to the bylaws that govern institutions affiliated with the NCAA including Division I, II, and III athletic programs.  Students will gain detailed knowledge of both governing principles and enforcement procedures.  This course will offer a more specialized look into the inner workings of intercollegiate athletics operations.  Furthermore, this course is necessary for anyone who wants to work within an athletic department whether it be in compliance, public relations, academic affairs, marketing or management."},
"SPTM491":{"description":"Studies the four functions of management: planning, organizing, leading, and evaluating within sport and physical activity organizations. The four functions of management are applied in developing a potential organization."},
"SPTM492":{"description":"This course will enable students to integrate information from their educational experiences to critically examine and analyze contemporary issues in sport and exercise from an interdisciplinary perspective. Current issues, trends, and challenges will be presented with the intent of provoking thorough and stimulating debate, so that students may identify their role in the resolution of issues."},
"SPTM498":{"description":"Students work at least 450 hours under the supervision of a professional within an approved sport related organization."},
"STAT238":{"description":"This course is designed to develop conceptual understanding for topics in data analysis and probability. The study of selecting and using appropriate statistical methods to analyze data, the developing and evaluating of inferences and predictions that are based on data, and the applying of basic concepts of probability will be covered in this class. The use of manipulatives and technology will support learning and teaching of the topics studied."},
"STAT241":{"description":"This course is an introduction to statistics including topics in probability, measures of central tendency and dispersion, estimation, hypothesis testing and regression."},
"STAT251":{"description":"This course is an introduction to applied statistics, including topics in probability, measures of central tendency and dispersion, estimation, hypothesis testing, simple linear regression, goodness-of-fit test, and analysis of variance. Applications to the biological and health sciences will be emphasized."},
"STAT255":{"description":"An introduction to programming for data science with emphasis on statistical computing and graphics. Topics include generic programming language concepts, writing functions, importing, manipulating, and analyzing data, and visualization tools."},
"STAT261":{"description":"An introduction to statistical methods when the functional form of the population is unknown. Applications will be emphasized but some theory will also be considered.  Topics include One-sample, Two-sample and K-sample methods."},
"STAT265":{"description":"This course is an introduction to applied regression analysis including topics in simple linear regression, multiple regression models, principles of model building, variable selection, residual analysis and using statistical software."},
"STAT355":{"description":"This course addresses data processing, basic concepts and techniques in data classification, association analysis, cluster analysis, anomaly detection, and statistical testing."},
"STAT365":{"description":"This course will cover topics in design and analysis of experiments including analysis of variance (ANOVA) for single-factor design, randomized complete block design, Latin square design, balanced incomplete block design, two-factor factorial design and response surface, two-level factorial designs (2), two-stage nested design and split-plot design.\n\n\nIn addition, associated model for each design - fixed effect model, random effect model, and two-factor mixed effect model will be discussed estimating model parameters, checking model adequacy, comparing contrasts using Scheffe's method, comparing pairs of treatment means using both Tukey-Kramer and Fisher least significance difference (LSD) methods, estimating both variance components and intraclass correlation coefficient. A statistical software will be used."},
"STAT381":{"description":"This course is an introduction to categorical data analysis including topics in contingency tables, tests for independence and homogeneity for logistic regression, model selection methods, Poisson regression, loglinear models for contingency tables and use of statistical software."},
"STAT436":{"description":"This course will include topics such as multiple regression, regressor significance tests, variable selection methods model adequacy and complications caused by multicollinearity.  Additional topics will be Analysis of Variance (ANOVA), statistical quality control and nonparametric statistical methods.  This course will not satisfy one of the upper-level math electives."},
"STAT441":{"description":"This course is an introduction to point estimation, confidence intervals, and hypothesis testing.  Topics include the central limit theorem, sufficiency, maximum likelihood estimation, and likelihood ratio testing."},
"STAT448":{"description":"This course is an introduction to the theory of stochastic processes aiming to provide the basic mathematical principles to create, analyze, and interpret stochastic models that appear in various applications. Topics include the theory and applications of discrete-time Markov chains, Poisson processes, renewal processes, continuous-time Markov processes, and Brownian motion."},
"STAT451":{"description":"This course  will cover theoretical aspects of simple random, stratified, cluster and systematic samplings for finite population with applications including ratio estimation, poststratification, Horvitz-Thompson estimator for two-stage sampling, weights in complex sampling surveys, mechanisms for nonresponse. A statistical software will be used."},
"STAT467":{"description":"This course is an introduction to multivariate data analysis including topics: random vectors, Mahalanobis distance, Hotelling's T-square statistic, multivariate normal distribution, inference of the mean vector, analysis of covariance structure by principle component analysis and factor analysis, analysis of grouping techniques by discriminant analysis and cluster analysis."},
"STAT475":{"description":"This is an introductory course in the theory and applications of time series models. Topics include stationarity, autocorrelation, parameter estimation, model building, diagnostic checking and forecasting for exponential smoothing, ARIMA and ARCH models."},
"STAT495":{"description":"This course provides senior statistics majors the opportunity to synthesize knowledge gained from the major. Students will perform a literature review and then select, study and apply a sequence of statistical methods to address a broad research question. Students will present their findings in an open forum at the end of the course."},
"STEM101":{"description":"This course is designed to provide exposure to the different STEM disciplines.  The seminar will provide opportunities for student to strengthen their foundational knowledge and will provide resources to enhance the study of STEM courses.  This course is only open to engineering, mathematics, and science majors."},
"TECH103":{"description":"Survey of materials commonly used in industry and the primary processes and techniques utilized in fabrication. Laboratory experiences will involve techniques of forming, drilling, machining, and welding metals and alloys."},
"TECH121":{"description":"Principles of engineering modeling and documentation in a computer environment. Specific topics to be covered include visualization, geometric modeling of objects in a computer- aided design environment, and application of documentation standards and engineering conventional representations. Emphasis is placed on freehand sketching as a means of communication and on the accurate generation of three-dimensional computer models and documentation files."},
"TECH213":{"description":"Mechanics is the study of bodies under the action of forces.  This course will provide a real world understanding of physical objects and forces, primarily focused on statics.  Topics will include particles, cables, beams, trusses, frames, and machines."},
"TECH261":{"description":"This course covers the fundamental principles and circuitry used in electronic and electromechanical instrumentation. Topics include divider circuits, electronic and pneumatic relays, power supplies, bridge circuits, filters, operational amplifiers, digital logic, transmitters, and actuators. Applications are demonstrated in the laboratory experiments."},
"TECH272":{"description":"This course is an introduction to industrial robots as used in modern industry.  Topics covered will include manipulator types, arm configurations, robotic system safety, system controller operations, and programming of robot paths using a variety of methods.  The student will become familiar with End Of Arm Tooling design concepts, sensors &amp; peripherals used in robotic automation, and systems integration strategies and methods.  Laboratory experiences will provide the student with significant hands-on activities.  Students will engage in Team exercises.  Students will be expected to produce written reports documenting their laboratory exercises."},
"TECH275":{"description":"This course is designed to develop an understanding of the topic of statistics and its application to accident investigation, worker liability and compensation, and hazard control.  Topics include the selection and use of appropriate statistical methods to analyze manufacturing safety data, the development of statistical inferences, and the application of probability."},
"TECH298":{"description":"Technology students will be assigned to middle schools and high schools to work under supervision of the classroom teacher to help deliver the Project Lead the Way curriculum.  Students are assigned classes by invitation of the appropriate middle and/or high school authorities and will assist the classroom teacher as necessary.  Students must keep a journal during the course and write a report at the end of the semester."},
"TECH299":{},
"TECH313":{"description":"This course will examine bodies under the action of forces: both for particles, cables, beams, trusses, frames, and machines subjected to external loading conditions; and the internal forces, stresses, and deformation of objects and structures subjected to axial, shear, torsional, lateral, and thermal loading conditions.  The intent is to provide actual, real world understanding of physical objects."},
"TECH315":{"description":"Strength of materials is the study of internal stresses and external strains that result from the application of mechanical and thermal loads on objects, including those due to axial forces, torsion, and bending."},
"TECH331":{"description":"Topics include probability and probability distributions, sampling methods, statistical process control, hypothesis testing, regression and correlation analysis, and experiment design. Lab exercises include development and implementation of experiments, data collection, and design of experiments. Use of both a calculator and various statistical software packages on a computer will be required for problem solving and report generation."},
"TECH343":{"description":"This course is intended to give the student a broad overview of the principles of fluid power systems and the components that comprise these systems. Lecture and laboratory topics include pumps, linear and rotary actuators, control devices, and fluid conductors."},
"TECH351":{"description":"This course is intended to give the students a broad overview of the principles of thermodynamics and heat transfer and the industrial applications that use these principles."},
"TECH362":{"description":"This course includes an introduction into the design and implementation of contemporary manufacturing systems. Single and multiple station, manual, mixed mode, automated, and flexible manufacturing systems are covered. Numerical analysis of these systems to determine production rates, product cost, defect rates, and efficiency will be performed. During the lab students will learn to program and operate various types of production hardware including CNC turning and milling machines."},
"TECH367":{"description":"This course is designed to enhance the student's knowledge of advanced manufacturing principles and concepts of quality control, work and material requirements planning, automated manufacturing, and mass production methods."},
"TECH385":{"description":"This course covers basic D.C. and A.C. circuit analysis, power distribution, and power machinery applications. The laboratory will provide an opportunity for hands-on experience with electrical machinery."},
"TECH401":{"description":"This course covers practical application of the principles learned in previous courses to the design of various types of work cells and manufacturing systems. Case studies and example problems will be covered during the lectures. Lab exercises include the programming and operation of robots, CNC machines, and automated work cells."},
"TECH411":{"description":"Fundamental structures comprising the principal families of materials – metals, ceramics, and polymers; and how structures influence the properties of strength, hardness, heat treatment, plasticity, fatigue, creep, wear, etc. Experiments in the laboratory will demonstrate structure-property relationships."},
"TECH439":{"description":"An independent study course requiring the student to complete an assigned or self-generated project under the guidance of a faculty member. The project will require application of various analytical methods and/or the use of computer models or laboratory facilities. A written report is required upon completion of the project."},
"TECH468":{"description":"This course will serve as a prerequisite to TECH 471 Senior Project.  The scope of this course is to provide a basic preparation in project management and team work.  In addition, students will be required to select a project, develop scope and milestones in order to be ready to start working on the project itself in TECH 471."},
"TECH471":{"description":"A course which provides an opportunity for synthesis of technical, professional, and general knowledge for industrial supervision students. Problems provided by industrial sponsors are studied by individual students or small teams of students to develop solutions which consider technical, economic, social, environmental, sustainability, ethical and manufacturability aspects of the problems. Formal written and oral reports to faculty, industrial sponsors, and invited guests are required."},
"THTR101":{"description":"Analysis of the nature of theatre, its origin and development from the standpoint of the play, the physical theatre, and its place in culture. Specific emphasis is placed on the study of styles and the various artistic components which \"collaborate\" to create theatre."},
"THTR102":{"description":"Introduction to the major elements and theory of theatrical production including, but not limited to, back-of-house administration and organization, the various design disciplines, basic collaboration, and overview of current trends and technologies. Course will place specific emphasis upon the \"visual\" component and the basic procedures involved with mounting a performing arts production."},
"THTR120":{"description":"Assigned in one-hour units, two hours required for the major. Designed to provide credit for work completed on USI Theatre productions. Students are required to spend each semester working in a different technical area. A minimum of 42 contact hours of work is required."},
"THTR150":{"description":"Provides a practical background in the fundamentals of three styles of dance. The techniques and discipline of dance will be explored and utilized, culminating in an examination that tests the comprehension, comportment, and flexibility of the student."},
"THTR153":{"description":"This course employs improvisational exercise and training as a primary methodology to actor training, helping beginning actors to achieve unified state of mind and body in spontaneous acts of imagination.  Course derives pedagogy from early and contemporary training approaches, from Lecoq and Spolin to Johnstone."},
"THTR224":{"description":"Fundamental study of scenery production and engineering, shop organization, and the various techniques and procedures utilized by production personnel. Course will include technical drawing, budgeting, instructional workshops, safety, task-oriented engineering solutions, and theatre program production lab work."},
"THTR228":{"description":"This is an introductory course in the fundamentals of drafting for theatrical design and production. Utilizing basic hand and computer drafting with programs such as Vectorworks and Lightwright students will gain an essential communication tool. Emphasis is placed on two-dimensional drafting."},
"THTR234":{"description":"This course is the study and practical application of both hand and machine sewing, clothing construction and basic pattern making."},
"THTR235":{"description":"Basic principles of the art and technique of make-up for the use in the design and execution of developing and projecting the stage character. Purchase of make-up kit required."},
"THTR236":{"description":"This course is a study and practical application of fabric modification techniques, beginning millinery, mask making, and jewelry making."},
"THTR243":{"description":"Fundamental practice and theory of electrical applications in lighting a theatrical production and the collaborative process of basic theatrical lighting design. Course includes lighting terminology, electrical theory and practice, script analysis, the elements of design, and the design process. Projects include lighting observations, master electrician packets, and a lighting design project. Students will be required to participate in at least three outside lighting labs correlating to the USI Theatre productions season."},
"THTR251":{"description":"Study and practice in the fundamentals of the acting process. Class emphasizes physical and vocal work in conjunction with exercises geared toward the expansion of personal imaginative skills. Class work focuses on monologues and improvisational games. Open to all University students."},
"THTR252":{"description":"Continued study and development of the fundamental acting processes taught in THTR 251. Class work consists of scene study focusing on the action, objective, and subtext of the actor's characterizations."},
"THTR253":{"description":"Concerned with the anatomy and function of the speech mechanism. Emphasis on articulation, phonation, and resonation. Study of voice characteristics, vocal quality, volume, rate, pitch, and force. This course requires that the students learn the International Phonetic Alphabet. Students evaluate their own speech characteristics."},
"THTR254":{"description":"Students learn safe techniques for stage combat, including hand-to-hand (fisticuffs) and armed (rapier/dagger) combat.  Students will also learn how to apply the techniques in choreographic sequences for dramatic effect."},
"THTR256":{"description":"This course introduces character development, including fundamental  performance approaches and dramatic analysis.  Dramatic analysis shall include both structural concerns of the work, as well as dramaturgical research of ancillary materials.  Emphasis upon the idea of the actor's performance as applied scholarship."},
"THTR260":{"description":"Provides a practical background in the fundamentals of a variety of traditional dance forms. The techniques and discipline of dance will be explored and utilized, culminating in an examination that tests the comprehension, comportment, and the flexibility of the student."},
"THTR307":{"description":"Emphasizing research methods employed by theatre designers, this course examines the styles and aesthetics of art, architecture, fashion and the decorative arts from ancient Egypt through the first half of the 20th century.  Emphasis will be placed on periods and countries that have been most influential on Western theatrical practices and design."},
"THTR320":{"description":"Assigned in one-hour units, four hours are required for the major.  Designed to provide credit for work completed on USI Theatre productions.  Students will be assigned placement in a technical area each semester either in accord with their individualized plan of specialization or to gather the broadest range of experience that is in their best interest as theatre generalist(s).  A minimum of 42 contact hours of work is required per semester."},
"THTR326":{"description":"This course continues training in computer-assisted design and image-editing software programs commonly employed in theatrical design."},
"THTR327":{"description":"Study of the elements and principles of scenic design. Students will explore the application of design concepts based upon an analysis of script and production parameters in order to produce complete packages of speculative work (research, plans, models, drawings, etc.)."},
"THTR337":{"description":"A study of the elements and principles of costume design for the theatre. Students will develop skill in play analysis for costuming, basic rendering skills, and period research. The class will include an introduction to the various media used in communicating a costume design concept."},
"THTR346":{"description":"Students explore conventional, automated, and video lighting through hands-on utilization of control consoles.  They will further develop both their design skills and their understanding of automated lighting techniques through practical application."},
"THTR347":{"description":"Advanced study and application of lighting design for theatre, opera, dance, and other theatrical genres. May also explore basic elements of television and film. Students will complete design projects that improve upon script analysis, research, rendering, color theory, drafting, and presentation skills. Beginning computer drafting and supplemental paperwork software applications to be introduced. Students will be required to participate in at least three outside lighting labs correlating to the USI Theatre productions season."},
"THTR351":{"description":"Study and practice of the acting techniques and methods used to create a character for the stage. Emphasis placed on the application of these theories in the development of monologues and scenes from major American plays."},
"THTR352":{"description":"Provides a background in period styles to the advanced student actor, examining the special considerations inherent in performing classical drama, beginning with Greek tragedies and continuing through Elizabethan, Restoration, and other periods, including contemporary pieces of a special nature, such as epic, expressionist, and absurdist drama."},
"THTR353":{"description":"The student will explore and experience the nature of musical theatre and the unique performance demands required to create it at a proficient level. Emphasis will be placed on accessible vocal material from the standard musical theatre repertoire, including solos, duets, and company numbers. Students will present research on the genre's historical and stylistic eras."},
"THTR354":{"description":"The student will further explore and experience the nature of musical theatre and the unique performance demands required to create it at a proficient level. Emphasis will be placed on more challenging vocal material from the standard musical theatre repertoire, including solos, duets, and company numbers. Students will present research on the genre's historical and stylistic eras."},
"THTR360":{"description":"This class will continue to develop the working techniques of movement within the dance disciplines of modern, classic ballet, tap and modern jazz and to show the application of these disciplines to the theatre.  Students will develop choreography with other dancers, as well as learn rehearsal techniques for solo and group compositions."},
"THTR361":{"description":"Students will participate in an intense study of dramaturgy and will write dramas of their own."},
"THTR371":{"description":"History of the theatre from its origins through Elizabethan times. Concentration on the development of the stage and on conventions of acting, directing, and design as viewed within the context of their historical period."},
"THTR372":{"description":"History of the theatre from the French Renaissance through the present day. Concentration on the physical theatre, conventions in acting, directing, and design."},
"THTR381":{"description":"This course will look at the structure of theatre, the elements of production, the rehearsal and performance process, and the role and responsibilities of the stage manager in all of these aspects. Students learn the preparation of a promptbook, standard record-keeping, management etiquette, and union requirements."},
"THTR400":{"description":"This course is designed to provide an opportunity for upper division communications/theatre majors and minors to research subject areas in the field of theatre arts.  A maximum of six hours may be taken; only three may be taken in any one semester.  NOTE:  Students who wish to take independent study courses in the theatre arts curriculum should be aware of the following points:  1) Only six hours of independent study may apply toward a major in Communications. 2) No more than three offerings of THTR 400 may be directed by the same instructor. 3) Students wishing to enroll in independent study courses must receive written permission from their instructors prior to registration."},
"THTR420":{"description":"Provides experiential learning opportunities working on USI Theatre productions.  A minimum of 42 contact hours of work is required."},
"THTR427":{"description":"Individual development in scene design projects through intensive study of various styles. Detailed representation of design ideas in rendering and models required. Class includes practice and development in scene painting."},
"THTR437":{"description":"Teaches students to further incorporate the principles of design with play analysis, and apply them toward creating a specific production of a play.  This course enables the student to experiment with a variety of styles and rendering techniques, including computer rendering, and also to explore the unique challenges provided by various genres of costume design."},
"THTR447":{"description":"Students are instructed in advanced lighting techniques as part of a comprehensive approach to lighting design. Emphasis is placed on aesthetics, script analysis in visual terms, visual research methods, theatrical style, and concept development. Practical application will include dramatic, musical, and dance pieces."},
"THTR451":{"description":"Acting as a Business teaches students about the various techniques, tools, and standards required to pursue a professional acting career. Through one-on-one work with the instructor, the student will learn the various auditioning techniques for theatre, film and television; the tools required to seek out and submit for audition opportunities; and the strategies used in finding and preparing strong audition material."},
"THTR473":{"description":"A broad study of dramatic literature from the Ancient World through 1600 AD as a basis for establishing a world view through theatrical and dramatic criticism."},
"THTR474":{"description":"A broad study of dramatic literature from 1600 to the modern period as a basis for establishing a world view through theatrical and dramatic criticism."},
"THTR487":{"description":"An introduction to the principles of play directing. Class emphasizes text analysis, interpretation, picturization, and the various aspects involved in the production process. Class will include direction of extended scenes from full-length plays."},
"THTR490":{"description":"This course is an intensive internship within the professional environment of the USI Theatre and New Harmony Theatre Repertory Project. Students will be assigned positions within a professional theatre season based upon their interests and experience. Assignments will be identified and outlined by contract and may be made in more than one area depending upon degree of supervisory responsibility assigned by the instructor. Internship hours assigned will range between 100 and 140, depending upon area and responsibility."},
"THTR498":{"description":"Work experience in an approved professional theatre, shop, or enrichment center. The course provides for a minimum of 150 hours of supervised professional work, two written evaluations by the supervisor, two written self-evaluations, and a final review with a department faculty member."},
"THTR499":{"description":"Topics will vary. Purpose of course is to cover topics that are not specific courses in the curriculum."},
"UNIV091":{"description":"This is a required course for new freshmen who are on academic probation after their first term.  Students will explore the barriers that may be keeping them from reaching their academic goals and acquire tools to overcome those challenges. Success strategies will focus on self-evaluation. This course will assist students in development of goals, attitudes, and study skills needed to achieve academic success. This course is a cross listed course.  For students enrolled in UNIV091, this will be a 5-week, pass/no pass, non-credit bearing course and there will be no tuition charged for this option. For students enrolled in UNIV101, this course will be a 1 credit hour, graded, tuition bearing course and will replace the grade students earned in their Fall UNIV101 course.  While the UNIV101 option is available to all students who want to better their UNIV101 grade, it is mandatory for those who failed the course in the fall semester."},
"UNIV101":{"description":"This course is an introduction to the University of Southern Indiana and the undergraduate experience with a specific focus on academic success, campus involvement and community engagement.  Students will discuss issues of value and behavior in the college setting, discover what resources are available to them, and learn what it means to integrate themselves into the campus and surrounding communities."},
"UNIV102":{"description":"This course continues to provide the integrative component for the identified Freshman Interest Group linked courses.  It provides opportunities for students to connect prior knowledge and experiences with new learning tasks and content in a shared learning experience.  This course will focus on understanding the co-curriculum and self, career exploration, technological literacy, and improving the educational experiences of first-year students."},
"UNIV201":{"description":"This course provides an overview of the theory related to and skills necessary for the practice of effective leadership in teams and organizational settings.  Leadership is explored as an integral component of a student's career and life plan."},
"WLC155":{"description":"Practical study of citizenship and professionalism in the \"global village\" of the 21st century, emphasizing complexities of cultural, linguistic and social systems of nations and people, to promote communication among widely diverse constituencies at home and abroad.  Serves as preparation for study abroad and further work in international disciplines.  (Course taught in English.)"},
"WLC301":{"description":"This course is based on the premise that contemporary African cultures, and ethnic and national identities are shaped by current and past language policies in African countries. This course will explore pre-, inter-, and post-colonial language policies, multilingualism and its diglossic intricacies, including sample of local languages and their phonetic features, pidgin and creole languages, lexification processes and language borrowing/loaning, as well as literacy of mother tongues and colonial languages. The course also emphasizes the relationship between food and language on the African continent. The structure of Africana and African Diaspora cuisines viewed as meta-languages, vocabularies and grammatical structures."},
"WLC337":{"description":"A study of the African Diaspora in Latin America and the Caribbean, with special emphasis on the esthetics and culture of people in the region. (Taught in English)"},
"WLC365":{"description":"Focuses on the development of language in human beings, first and second language acquisition in formal and informal settings, including computer-assisted language learning."},
"WLC422":{"description":"A practical study of citizenship and professionalism in multicultural and multilingual environments. The course focuses on managing the challenges that cultural and linguistic diversity present to both organizations and individuals."},
"WLC423":{"description":"An intensive certificate program that prepares students and professionals to teach English to Speakers of Other Languages."},
"WLC460":{"description":"An opportunity for job-related experience in the area of language studies."},
"WLC490":{"description":"Specialized topics in the field of language study, teacher preparation, culture and literature. (Course taught in English.)"},
}
module.exports={data}