var data = [
{"id":"ACCT201.","title":"Accounting Princ I"},
{"id":"ACCT202.","title":"Accounting Princ II"},
{"id":"ACCT303.","title":"Intermed Accounting I"},
{"id":"ACCT304.","title":"Intermed Accounting II"},
{"id":"ACCT311.","title":"Intr to Fed Income Taxation"},
{"id":"ACCT315.","title":"Cost Accounting"},
{"id":"ACCT401.","title":"Advanced Accounting"},
{"id":"ACCT411.","title":"Taxation of Corps & Partnshps"},
{"id":"ACCT413.","title":"Acct Info Systems"},
{"id":"ACCT415.","title":"Auditing Theory & Pract"},
{"id":"ACCT422.","title":"Financial Reporting and Analys"},
{"id":"ACCT465.","title":"Acct Ethics & Compliance"},
{"id":"ACCT499.","title":"Accounting Internship"},
{"id":"ACCT601.","title":"Acct-Decision Making & Cntrl"},
{"id":"BCOM231.","title":"Business Communication"},
{"id":"BCOM331.","title":"Corp/Managerial Communication"},
{"id":"BCOM401.","title":"Career Plan&Prof Devel"},
{"id":"BLAW263.","title":"Legal Env of Business"},
{"id":"FIN101.","title":"Money Skills"},
{"id":"FIN201.","title":"Fundamentals of Finance"},
{"id":"FIN305.","title":"Business Finance"},
{"id":"FIN325.","title":"Financial Modeling"},
{"id":"FIN335.","title":"Entreprnrial Finance"},
{"id":"FIN361.","title":"Financial Institutions"},
{"id":"FIN433.","title":"Principles Investment"},
{"id":"FIN461.","title":"Financial Management"},
{"id":"FIN481.","title":"Seminar in Finance"},
{"id":"FIN601.","title":"Financial Management & Markets"},
{"id":"BAN602.","title":"Data-Driven Decision Making"},
{"id":"BUAD499.","title":"Professional Practice"},
{"id":"MBA615.","title":"Project Management I"},
{"id":"DSCI351.","title":"Intro to Operatn Resrch"},
{"id":"DSCI445.","title":"Operations Management"},
{"id":"ECON175.","title":"Fundamtls of Econ"},
{"id":"ECON208.","title":"Microeconomics"},
{"id":"ECON209.","title":"Macroeconomics"},
{"id":"ECON241.","title":"Global Econ Issues"},
{"id":"ECON265.","title":"Elem Statistics"},
{"id":"ECON308.","title":"Interm Micro Theory"},
{"id":"ECON331.","title":"Public Finance"},
{"id":"ECON349.","title":"Open Economy Macroeconomics"},
{"id":"ECON472.","title":"Econometrics"},
{"id":"ECON489.","title":"ST-Economics of Identity"},
{"id":"ECON499.","title":"Sr Seminar in Economics"},
{"id":"ECON601.","title":"Managerial Economics"},
{"id":"MKTG201.","title":"Intro to Marketing"},
{"id":"MKTG305.","title":"Principles Marketing"},
{"id":"MKTG313.","title":"Marketing of Services"},
{"id":"MKTG332.","title":"Consumer Behavior"},
{"id":"MKTG334.","title":"Promotional Strategy"},
{"id":"MKTG342.","title":"Bus & Industrial Marketing"},
{"id":"MKTG344.","title":"Personal Selling"},
{"id":"MKTG355.","title":"Interactive Marketing"},
{"id":"MKTG438.","title":"Marketing Research"},
{"id":"MKTG447.","title":"Retailing Policy& Mngt"},
{"id":"MKTG448.","title":"Marketing Management"},
{"id":"MKTG471.","title":"Internationl Marketing"},
{"id":"MKTG601.","title":"Marketing Strategies"},
{"id":"CIS141.","title":"Computer Skills/non-Bus majrs"},
{"id":"CIS151.","title":"Computer Appl. in Bus."},
{"id":"CIS201.","title":"Business Processes and IS"},
{"id":"CIS276.","title":"Intro to Enterprise Web Devel"},
{"id":"CIS301.","title":"Enterprise Processes & IS"},
{"id":"CIS333.","title":"Data Viz & Storytelling"},
{"id":"CIS345.","title":"Info Systems Security/RiskMgmt"},
{"id":"CIS349.","title":"Special Topics Cybersecurity"},
{"id":"CIS366.","title":"Data Mgmt for BI&Analytics"},
{"id":"CIS367.","title":"Data Communications"},
{"id":"CIS375.","title":"System Analysis&Design"},
{"id":"CIS376.","title":"Prog for Enterprise Web Devel"},
{"id":"CIS377.","title":"Database Concepts"},
{"id":"CIS454.","title":"Managing Info Technology"},
{"id":"CIS601.","title":"Information Sys & Technology"},
{"id":"CS215.","title":"Discrete Struct for Comp Sci"},
{"id":"CS258.","title":"Intro to Object-Oriented Progr"},
{"id":"CS311.","title":"Data Structures & Analysis Alg"},
{"id":"CS358.","title":"Intermediate OOP Using C#"},
{"id":"CS365.","title":"Operating Systems"},
{"id":"CS377.","title":"Data Base Concepts"},
{"id":"CS379.","title":"Programming Languages"},
{"id":"CS478.","title":"Software Development"},
{"id":"CS483.","title":"Senior Software Dev Project"},
{"id":"MNGT201.","title":"Survey of Management"},
{"id":"MNGT305.","title":"Principles of Management"},
{"id":"MNGT315.","title":"Project Managment I"},
{"id":"MNGT341.","title":"Human Resource Mngt"},
{"id":"MNGT352.","title":"Entrepreneur Idea/Innovation"},
{"id":"MNGT353.","title":"Entrprnr Feas Anlys/B-Plan Dev"},
{"id":"MNGT354.","title":"Strategic Entrepreneurship"},
{"id":"MNGT361.","title":"Bus Environ Factors"},
{"id":"MNGT408.","title":"Negotiation Skills"},
{"id":"MNGT441.","title":"Seminar in Human Resource Mngt"},
{"id":"MNGT442.","title":"Training and Development"},
{"id":"MNGT444.","title":"Manage Divrsty-Organztns"},
{"id":"MNGT452.","title":"Policy Formulation Adm"},
{"id":"MNGT611.","title":"Leadership Skills & Innovation"},
{"id":"MNGT671.","title":"Topics in Management"},
{"id":"MNGT681.","title":"Strategic & Internatl Mngt"},
{"id":"ART103.","title":"Color & Design"},
{"id":"ART104.","title":"Design in Materials"},
{"id":"ART104.","title":"Design in Materials-CrtvExpLLC"},
{"id":"ART105.","title":"Drawing I"},
{"id":"ART106.","title":"Drawing & Composition"},
{"id":"ART201.","title":"Intro to Visual Arts"},
{"id":"ART205.","title":"Figure Drawing I"},
{"id":"ART206.","title":"Figure Drawing II"},
{"id":"ART311.","title":"Painting I"},
{"id":"ART312.","title":"Painting II"},
{"id":"ART313.","title":"Ceramics I"},
{"id":"ART314.","title":"Ceramics II"},
{"id":"ART351.","title":"Woodworking I"},
{"id":"ART352.","title":"Woodworking II"},
{"id":"ART361.","title":"Printmaking I"},
{"id":"ART362.","title":"Printmaking II"},
{"id":"ART399.","title":"ST-Gallery Fellowship-NewHrmny"},
{"id":"ART399.","title":"ST-Gallery Fellowship-USI"},
{"id":"ART405.","title":"Figure Draw Compos III"},
{"id":"ART406.","title":"Figure Draw Compos IV"},
{"id":"ART411.","title":"Painting III"},
{"id":"ART412.","title":"Painting IV"},
{"id":"ART413.","title":"Ceramics III"},
{"id":"ART414.","title":"Ceramics IV"},
{"id":"ART451.","title":"Woodworking III"},
{"id":"ART452.","title":"Woodworking IV"},
{"id":"ART461.","title":"Printmaking III"},
{"id":"ART462.","title":"Printmaking IV"},
{"id":"ART489.","title":"SP-Art History"},
{"id":"ART489.","title":"SP-Ceramics"},
{"id":"ART489.","title":"SP-Drawing"},
{"id":"ART489.","title":"SP-Illustration"},
{"id":"ART489.","title":"SP-Painting"},
{"id":"ART489.","title":"SP-Photography"},
{"id":"ART489.","title":"SP-Printmaking"},
{"id":"ART489.","title":"SP-Woodworking"},
{"id":"ART493.","title":"Senior Art Seminar"},
{"id":"ART498.","title":"Internship in Art"},
{"id":"ART589.","title":"Advanced Study in Art/Design"},
{"id":"ARTD231.","title":"Intro to Graphic Design"},
{"id":"ARTD232.","title":"Typography"},
{"id":"ARTD275.","title":"Comp Graphic Arts"},
{"id":"ARTD331.","title":"Illustration Techniques"},
{"id":"ARTD376.","title":"Graphic Design II"},
{"id":"ARTD381.","title":"Interactive Web Design"},
{"id":"ARTD399.","title":"ST- Adv. Typography"},
{"id":"ARTD475.","title":"Graphic Design III"},
{"id":"ARTD476.","title":"Graphic Design IV"},
{"id":"ARTD481.","title":"Advanced Web Design"},
{"id":"ARTD494.","title":"Senior Design Seminar"},
{"id":"ARTH221.","title":"Art History I"},
{"id":"ARTH222.","title":"Art History II"},
{"id":"ARTH327.","title":"Medieval Art"},
{"id":"ARTH355.","title":"Contemporary Art"},
{"id":"ARTH490.","title":"SP-Caravaggio"},
{"id":"ARTH490.","title":"SP-Hellenistic Art"},
{"id":"ARTP211.","title":"Intro to Darkroom Photography"},
{"id":"ARTP221.","title":"Intro Digital Photo Imaging"},
{"id":"ARTP321.","title":"Adv Digital Photo Manipulation"},
{"id":"ARTP322.","title":"Photography: Studio Lighting"},
{"id":"GLST101.","title":"Intro to Global Studies"},
{"id":"GLST371.","title":"Non-Western World Literature"},
{"id":"GLST470.","title":"ST:Witches in World Literature"},
{"id":"GLST497.","title":"Global Studies Capstone"},
{"id":"GNDR111.","title":"Intro to Gender Studies"},
{"id":"GNDR222.","title":"The Body in Art and Culture"},
{"id":"GNDR449.","title":"ST:Witches in World Literature"},
{"id":"HUM211.","title":"World Humanities I"},
{"id":"HUM212.","title":"World Humanities II"},
{"id":"HUM241.","title":"Humanistic Trad Literature I"},
{"id":"HUM242.","title":"Humanistic Trad Literature II"},
{"id":"HUM350.","title":"History of Philosophy I"},
{"id":"LBST501.","title":"Information Literacy &Research"},
{"id":"LBST617.","title":"Data Literacy"},
{"id":"LBST690.","title":"Independent Work-LBST"},
{"id":"LBST695.","title":"Capstone: Portfolio"},
{"id":"LBST697.","title":"Capstone Project or Thesis I"},
{"id":"PRFS301.","title":"Ethics of Global Engagement"},
{"id":"PRFS401.","title":"Teams/Diversity/Leadership"},
{"id":"PRFS490.","title":"Professional Studies Intrnship"},
{"id":"PRFS498.","title":"Personal & Professional Dev"},
{"id":"ADV101.","title":"Intro to Advertising"},
{"id":"ADV335.","title":"Advertising Strategy"},
{"id":"ADV343.","title":"Media Planning"},
{"id":"ADV347.","title":"Advertising Copywriting"},
{"id":"ADV400.","title":"Ind St-Advertising"},
{"id":"ADV444.","title":"Strategic Campaigns"},
{"id":"ADV489.","title":"Intern-Advertising"},
{"id":"CMST101.","title":"IntPublicSpkg-ExpMjr/InnEntLLC"},
{"id":"CMST101.","title":"Intro Public Spkng-Educ LLC"},
{"id":"CMST101.","title":"Intro to Public Speaking"},
{"id":"CMST101.","title":"Intro to Public Spkng-N&HP LLC"},
{"id":"CMST107.","title":"Intro Interpersonal Comm"},
{"id":"CMST201.","title":"Intro Communication Studies"},
{"id":"CMST203.","title":"Intro to Peformance Studies"},
{"id":"CMST286.","title":"Visual Rhetoric & Culture"},
{"id":"CMST301.","title":"Comm Criticism & Analysis"},
{"id":"CMST314.","title":"Organizational Communication"},
{"id":"CMST331.","title":"Contemporary Public Address"},
{"id":"CMST400.","title":"Ind St-Communicatn Studies"},
{"id":"CMST410.","title":"Sem:Privacy & Disclosure"},
{"id":"CMST489.","title":"Intern-Communication Studies"},
{"id":"COMM122.","title":"Understanding Media"},
{"id":"COMM221.","title":"Social Media Communication"},
{"id":"COMM494.","title":"Mass Communicatn Law"},
{"id":"COMM495.","title":"Mass Comm Research"},
{"id":"COMM497.","title":"Career Plan/Prof Development"},
{"id":"COMM603.","title":"Quantitative Rsrch Methds Comm"},
{"id":"COMM613.","title":"Political Communication"},
{"id":"COMM617.","title":"Instructional Communication"},
{"id":"COMM690.","title":"Capstone Project"},
{"id":"COMM699.","title":"Thesis"},
{"id":"JRN180.","title":"Journalism Workshop"},
{"id":"JRN281.","title":"News Reporting and Writing"},
{"id":"JRN400.","title":"Ind St-Journalism"},
{"id":"JRN489.","title":"Intern-Journalism"},
{"id":"PRL101.","title":"Intro to Public Relations"},
{"id":"PRL362.","title":"Writing Public-Relatns"},
{"id":"PRL364.","title":"Publications Design"},
{"id":"PRL365.","title":"Public Relatn Administrn"},
{"id":"PRL400.","title":"Ind St-Public Relations"},
{"id":"PRL468.","title":"Public Relatn Campgns"},
{"id":"PRL489.","title":"Intern-Public Relations"},
{"id":"RTV150.","title":"Practicum-Broadcasting"},
{"id":"RTV151.","title":"Surv Contemp Broadcast"},
{"id":"RTV255.","title":"Media Production"},
{"id":"RTV350.","title":"Radio-TV Workshop"},
{"id":"RTV358.","title":"Adv Digital Audio Production"},
{"id":"RTV366.","title":"Broadcast Journalism"},
{"id":"RTV400.","title":"Ind St-Radio-TV"},
{"id":"RTV453.","title":"Broadcast/Cable Mngt"},
{"id":"RTV489.","title":"Intern-Radio-TV"},
{"id":"CRIM224.","title":"Criminology"},
{"id":"CRIM234.","title":"Introduction to Corrections"},
{"id":"CRIM244.","title":"Police & Society"},
{"id":"CRIM254.","title":"Criminal Courts and Law"},
{"id":"CRIM302.","title":"Criminal Law"},
{"id":"CRIM335.","title":"Juvenile Delinquency"},
{"id":"CRIM351.","title":"Research Methods"},
{"id":"CRIM352.","title":"Statistics"},
{"id":"CRIM370.","title":"Sem:Crime Scene Investigation"},
{"id":"CRIM370.","title":"Sem:Mass Incarceration"},
{"id":"CRIM370.","title":"Sem:Policing Issues"},
{"id":"CRIM370.","title":"Sem:Prisons"},
{"id":"CRIM371.","title":"Criminological Theory"},
{"id":"CRIM401.","title":"Community Corrections"},
{"id":"CRIM403.","title":"Violence Against Women"},
{"id":"CRIM475.","title":"Criminal Justice Capstone"},
{"id":"CRIM489.","title":"Ind Study-Criminal Justice"},
{"id":"CRIM499.","title":"Internship-Criminal Justice"},
{"id":"ENG100.","title":"Intro-Rhet &Comp"},
{"id":"ENG101.","title":"Rhet&Comp I:Literacy/Self"},
{"id":"ENG185.","title":"Introduction to Film"},
{"id":"ENG201.","title":"Rhet&Comp II:Literacy/World"},
{"id":"ENG205.","title":"Int Engl Studies Ways Rding"},
{"id":"ENG210.","title":"Technical Writing"},
{"id":"ENG226.","title":"Introduction to Folklore"},
{"id":"ENG227.","title":"Literature and Popular Culture"},
{"id":"ENG255.","title":"Intro to Brit Lit History"},
{"id":"ENG265.","title":"Intro to American Lit Hist"},
{"id":"ENG301.","title":"Advanced Composition"},
{"id":"ENG302.","title":"Creative Writing"},
{"id":"ENG305.","title":"Creative Non-Fiction Workshop"},
{"id":"ENG319.","title":"Fundamentals of Grant Writing"},
{"id":"ENG371.","title":"Non-Western World Literature"},
{"id":"ENG387.","title":"Women's Lit&Gender Issue"},
{"id":"ENG402.","title":"Adv Creative Writing"},
{"id":"ENG411.","title":"Writing in the Digital Age"},
{"id":"ENG417.","title":"Hist English Languages"},
{"id":"ENG444.","title":"Lit in Secndry Schls"},
{"id":"ENG451.","title":"Shakespeare Hist/Tragedies"},
{"id":"ENG480.","title":"Independent Study"},
{"id":"ENG498.","title":"Internship in English"},
{"id":"ENG499.","title":"Sem:American Novel"},
{"id":"ENG599.","title":"Sem:Transgnrtnl Trauma & Race"},
{"id":"ENG679.","title":"Studies in Literary Theory"},
{"id":"ENG697.","title":"Comprehensive Exam/Portfolio"},
{"id":"ENG698.","title":"Comprehensive Exam & Thesis"},
{"id":"AFRC111.","title":"Intro Africana Studies"},
{"id":"HIST101.","title":"The United States to 1877"},
{"id":"HIST102.","title":"The United States since 1877"},
{"id":"HIST102.","title":"U.S. since 1877-Expl Mjrs LLC"},
{"id":"HIST108.","title":"What is History"},
{"id":"HIST108.","title":"What is History - Honors LLC"},
{"id":"HIST111.","title":"World Civ I"},
{"id":"HIST112.","title":"World Civ II"},
{"id":"HIST246.","title":"U.S. Hist Global"},
{"id":"HIST266.","title":"Global History"},
{"id":"HIST288.","title":"The Historians Craft"},
{"id":"HIST298.","title":"Historical Methods"},
{"id":"HIST301.","title":"Indigenous and Settler America"},
{"id":"HIST311.","title":"Women & Gender in Amer"},
{"id":"HIST325.","title":"Introduction to Public History"},
{"id":"HIST339.","title":"History of Indiana"},
{"id":"HIST348.","title":"Eur,1648 Thru Napoleon"},
{"id":"HIST351.","title":"Europe,WWI to Presnt"},
{"id":"HIST372.","title":"The Soviet Union"},
{"id":"HIST393.","title":"Ancient & Early Imperial China"},
{"id":"HIST397.","title":"Teaching Social Studies"},
{"id":"HIST399.","title":"Independent Study in History"},
{"id":"HIST400.","title":"SS-Populism&Progressivism"},
{"id":"HIST410.","title":"Topics:Urban Africa"},
{"id":"HIST420.","title":"Topics:Black Plague"},
{"id":"HIST490.","title":"Intern/Spec St-History"},
{"id":"HIST499.","title":"Sem: Asia Hist & Lit"},
{"id":"HIST500.","title":"SS-Populism&Progressivism"},
{"id":"HIST599.","title":"Sem: Asia Hist & Lit"},
{"id":"HIST599.","title":"Sem:Black Plague"},
{"id":"MUS161.","title":"Music Theory I"},
{"id":"MUS171.","title":"Aural Skills I"},
{"id":"MUS181.","title":"Class Piano I"},
{"id":"MUS200.","title":"USI Chamber Choir"},
{"id":"MUS202.","title":"Intro to Music"},
{"id":"MUS210.","title":"Applied Voice"},
{"id":"MUS219.","title":"USI Women's Choir"},
{"id":"MUS290.","title":"USI Jazz Ensemble"},
{"id":"THTR101.","title":"Intro to Theatre"},
{"id":"THTR102.","title":"Intro to Theatrical Prod"},
{"id":"THTR120.","title":"Theatre Lab I"},
{"id":"THTR150.","title":"Practicum in Dance"},
{"id":"THTR153.","title":"Improvisational Theatre"},
{"id":"THTR235.","title":"Stage Make Up"},
{"id":"THTR236.","title":"Costume Crafts"},
{"id":"THTR256.","title":"Character Development"},
{"id":"THTR320.","title":"Theatre Lab II"},
{"id":"THTR352.","title":"Acting in Period Style"},
{"id":"THTR371.","title":"Theatre History I"},
{"id":"THTR400.","title":"Ind St-Theatre Arts"},
{"id":"THTR420.","title":"Theatre Laboratory III"},
{"id":"THTR451.","title":"Acting as a Business"},
{"id":"THTR487.","title":"Intro-Stage Directing"},
{"id":"THTR499.","title":"ST-Sound Design & Engineering"},
{"id":"PA632.","title":"Intro to Public Administration"},
{"id":"PA684.","title":"Grant Writing"},
{"id":"PA689.","title":"Ind Study In Public Admin"},
{"id":"PA695.","title":"Internship in Public Agency"},
{"id":"PA699.","title":"Thesis in Public Admin"},
{"id":"PHIL201.","title":"Intro to Ethics"},
{"id":"PHIL251.","title":"Intro Study of Religions"},
{"id":"PHIL305.","title":"Intro Symbolic Logic"},
{"id":"PHIL350.","title":"Hist-Philosophy I"},
{"id":"PHIL364.","title":"Gender and Philosophy"},
{"id":"PHIL425.","title":"Philosophy of Mind"},
{"id":"PHIL499.","title":"Independent Study: Philosophy"},
{"id":"POLS102.","title":"Intr American Polit"},
{"id":"POLS104.","title":"Intro to Public Administration"},
{"id":"POLS208.","title":"Law,Courts,&Justice"},
{"id":"POLS232.","title":"Research Design/Data Collectn"},
{"id":"POLS271.","title":"Internatl Politics"},
{"id":"POLS281.","title":"Comparative Politics"},
{"id":"POLS314.","title":"The American Presidenc"},
{"id":"POLS363.","title":"Public Organizational Behavior"},
{"id":"POLS405.","title":"Constitutional Law"},
{"id":"POLS446.","title":"Modern Political Theory"},
{"id":"POLS487.","title":"Politics of Russia and China"},
{"id":"POLS490.","title":"Political Science Internship"},
{"id":"POLS498.","title":"Research Politcl Sci"},
{"id":"POLS499.","title":"Sem:Urban Politics"},
{"id":"PSY201.","title":"Intro to Psychology"},
{"id":"PSY202.","title":"Orient to Major in Psy"},
{"id":"PSY261.","title":"Lifespan Develop Psy"},
{"id":"PSY273.","title":"Social Psychology"},
{"id":"PSY298.","title":"Human Sexuality"},
{"id":"PSY303.","title":"Research Methods & Stats I"},
{"id":"PSY304.","title":"Scientific Literacy"},
{"id":"PSY312.","title":"Sensation & Perception"},
{"id":"PSY322.","title":"Abnormal Psychology"},
{"id":"PSY325.","title":"Tests and Measures"},
{"id":"PSY332.","title":"Forensic Psychology"},
{"id":"PSY353.","title":"Research Methods & Stats II"},
{"id":"PSY357.","title":"Learning Theory"},
{"id":"PSY361.","title":"Child & Adolescent Psychology"},
{"id":"PSY371.","title":"Adulthood & Aging"},
{"id":"PSY377.","title":"Sport Psychology"},
{"id":"PSY381.","title":"Psychology of Gender"},
{"id":"PSY400.","title":"ST-Psychology Of Fear"},
{"id":"PSY400.","title":"ST-Psychopharmacology"},
{"id":"PSY422.","title":"Psychopath in Children/Adolesc"},
{"id":"PSY425.","title":"Cognitive Psychology"},
{"id":"PSY435.","title":"Community Psychology"},
{"id":"PSY462.","title":"Psychol of Personality"},
{"id":"PSY497.","title":"Capstone Psy:Hist and Syst"},
{"id":"PSY498.","title":"Internship"},
{"id":"PSY499.","title":"Individual Study-Psy"},
{"id":"SOC121.","title":"Principles Sociology"},
{"id":"SOC121.","title":"Principles SociologySrvLeadLLC"},
{"id":"SOC225.","title":"Criminology"},
{"id":"SOC251.","title":"Princ of Social Psy"},
{"id":"SOC370.","title":"Sem:Global Climate Change"},
{"id":"SOC370.","title":"Sem:Sociological Imagination"},
{"id":"SOC371.","title":"Classical Sociological Theory"},
{"id":"SOC421.","title":"Race and Ethnicity"},
{"id":"SOC471.","title":"Sociology Capstone"},
{"id":"SOC489.","title":"Indep St-Sociology"},
{"id":"SOC499.","title":"Internship-Sociology"},
{"id":"SOCW221.","title":"Intro to Social Work"},
{"id":"SOCW222.","title":"Social Welfare"},
{"id":"SOCW224.","title":"Intro to Social Justice"},
{"id":"SOCW239.","title":"Hum Behav Soc Environ"},
{"id":"SOCW241.","title":"Soc Work Intervention"},
{"id":"SOCW242.","title":"Intro Substance Abu Addict Dis"},
{"id":"SOCW325.","title":"Social Work Statistics"},
{"id":"SOCW340.","title":"Communicatn Skills"},
{"id":"SOCW342.","title":"Socw Prac Micro Syst I"},
{"id":"SOCW344.","title":"Macro Sys-Community Org"},
{"id":"SOCW353.","title":"Substance Abuse, the Fam & Soc"},
{"id":"SOCW400.","title":"ST-Child Abuse & Neglect II"},
{"id":"SOCW400.","title":"ST-Intro to Sign Language"},
{"id":"SOCW401.","title":"Soc Wrk Practicum I"},
{"id":"SOCW402.","title":"Soc Wrk Practice I"},
{"id":"SOCW503.","title":"Social Welfare Systems"},
{"id":"SOCW504.","title":"Human Beh&Soc Env"},
{"id":"SOCW506.","title":"Foundtn-Soc Wrk Practice"},
{"id":"SOCW510.","title":"Soc Welf Policy&Service"},
{"id":"SOCW511.","title":"Grad Fld Placemnt I"},
{"id":"SOCW512.","title":"Integr Fld Placemt Sem"},
{"id":"SOCW601.","title":"Clinical Assess/Psychopthlg SW"},
{"id":"SOCW608.","title":"Theories of Clinical Practice"},
{"id":"SOCW611.","title":"Grad Fld Placemnt II"},
{"id":"SOCW612.","title":"Grad Fld Seminar II"},
{"id":"SOCW652.","title":"Family & Couples Therapy"},
{"id":"SOCW660.","title":"Clincial Practice with Groups"},
{"id":"SOCW673.","title":"Research Methods in Addiction"},
{"id":"ANTH111.","title":"Intro to Cultural Anthropology"},
{"id":"ANTH121.","title":"Intro to Archaeology"},
{"id":"ANTH131.","title":"Intro to Physical Anthropology"},
{"id":"ANTH342.","title":"Archaeolgy of Eastrn Nrth Amer"},
{"id":"ANTH370.","title":"Sem: The Yanomamo"},
{"id":"ANTH489.","title":"Ind Study in Anthropology"},
{"id":"ANTH499.","title":"Internship in Anthropology"},
{"id":"FREN101.","title":"Beginning French I"},
{"id":"FREN203.","title":"Intermediate French I"},
{"id":"FREN275.","title":"The French Film"},
{"id":"FREN306.","title":"Advanced French I"},
{"id":"FREN325.","title":"Intro Francophone Literature"},
{"id":"FREN460.","title":"Internship in French"},
{"id":"FREN499.","title":"Individual Study in French"},
{"id":"GERM101.","title":"Beginning German I"},
{"id":"GERM102.","title":"Beginning German II"},
{"id":"GERM203.","title":"Intermediate German I"},
{"id":"GERM306.","title":"Advanced German I"},
{"id":"GERM308.","title":"Germanic Cultures"},
{"id":"GERM325.","title":"Intro to German Literature"},
{"id":"GERM499.","title":"Individual Study in German"},
{"id":"JPN101.","title":"Beginning Japanese I"},
{"id":"JPN203.","title":"Intermediate Japanese I"},
{"id":"LATN101.","title":"Beginning Latin I"},
{"id":"SPAN101.","title":"Beginning Spanish I"},
{"id":"SPAN102.","title":"Beginning Spanish II"},
{"id":"SPAN203.","title":"Intermediate Spanish I"},
{"id":"SPAN204.","title":"Intermediate Spanish II"},
{"id":"SPAN205.","title":"Cultural Contexts Conversation"},
{"id":"SPAN306.","title":"Advanced Spanish I"},
{"id":"SPAN307.","title":"Advanced Spanish II"},
{"id":"SPAN308.","title":"Hispanic Cultures"},
{"id":"SPAN325.","title":"Intro to Hispanic Literature"},
{"id":"SPAN333.","title":"Afro-Latin American Studies"},
{"id":"SPAN460.","title":"Internship in Spanish"},
{"id":"SPAN499.","title":"Individual Study in Spanish"},
{"id":"SPAN499.","title":"Translation Theory in Practice"},
{"id":"WLC155.","title":"Cultural Awareness"},
{"id":"WLC490.","title":"ST-Lngst&CultContxts2ndLangTch"},
{"id":"WLC490.","title":"ST: Language Learning Tech"},
{"id":"WLC490.","title":"ST: Second Lang. Acquisition"},
{"id":"WLC611.","title":"Second Language Acquisition"},
{"id":"WLC660.","title":"Practicum and Internship"},
{"id":"WLC690.","title":"ST-Lngst&CultContxts2ndLangTch"},
{"id":"WLC698.","title":"Comp Oral & Written Exams"},
{"id":"WLC699.","title":"Comprehensive Oral Exam&Thesis"},
{"id":"DTAS122.","title":"Clinical Science I"},
{"id":"DTAS156.","title":"Human Systems I"},
{"id":"DTHY303.","title":"Oral Radiology"},
{"id":"DTHY308.","title":"Applied Dental Materials"},
{"id":"DTHY314.","title":"Oral Anatomy"},
{"id":"DTHY316.","title":"Preventive Oral Health I"},
{"id":"DTHY327.","title":"Intro Evdnce-Bsd Dntl Hyg Care"},
{"id":"DTHY341.","title":"Dental Hygiene Theory I"},
{"id":"DTHY342.","title":"Dental Hygiene Clinic I"},
{"id":"DTHY405.","title":"General & Oral Pathology"},
{"id":"DTHY411.","title":"Community Oral Health Theory"},
{"id":"DTHY422.","title":"Clinical App of Periodontology"},
{"id":"DTHY423.","title":"Pain Management in Dentistry"},
{"id":"DTHY441.","title":"Dental Hygiene Theory III"},
{"id":"DTHY442.","title":"Dental Hygiene Clinic III"},
{"id":"NUTR203.","title":"Intro Food, Nutr & Dietetic"},
{"id":"NUTR376.","title":"Princpl/App in Nutrition"},
{"id":"NUTR378.","title":"Nutr-Fitness & Sport"},
{"id":"NUTR381.","title":"Quantity Food Prod/Purchsng"},
{"id":"NUTR396.","title":"Nutrition Through Life Cycle"},
{"id":"NUTR412.","title":"Adv Human Metabolism"},
{"id":"NUTR415.","title":"Dietary Suppl & Herb Use"},
{"id":"NUTR420.","title":"Practicum Food,Nutr,Wellness"},
{"id":"NUTR453.","title":"Prac Appl Nutr & Hlth Assess"},
{"id":"NUTR485.","title":"Medical Nutrition Therapy I"},
{"id":"NUTR492.","title":"Food Srvc Operations Mngt"},
{"id":"HI301.","title":"Health Informatics"},
{"id":"HI302.","title":"Enterprise Systems and EHRs"},
{"id":"HI304.","title":"Social Media in Health Care"},
{"id":"HI404.","title":"Revenue Cycle Management"},
{"id":"HI405.","title":"Health Inf Privacy & Security"},
{"id":"HI410.","title":"Internship in HIIM"},
{"id":"GERO215.","title":"Hlth Care Aspects Gerontology"},
{"id":"GERO312.","title":"Living Well Through Lifespan"},
{"id":"GERO316.","title":"Age Related Health Changes"},
{"id":"GERO317.","title":"Hlth Care Continuum&Comm Srvcs"},
{"id":"GERO318.","title":"Healthy Aging"},
{"id":"HA407.","title":"Issues Hlth Care Ldrship"},
{"id":"HA411.","title":"Health Care Leadership"},
{"id":"HA421.","title":"Health Care Finance"},
{"id":"HA431.","title":"Health Care Quality"},
{"id":"HA496.","title":"Mktg/Competitive Str Hlth Care"},
{"id":"HP115.","title":"Medical Terminology"},
{"id":"HP211.","title":"Health Care System"},
{"id":"HP225.","title":"Pharmacology and Therapeutics"},
{"id":"HP236.","title":"Eastrn Med & Alt/Comp Hlth"},
{"id":"HP255.","title":"Human Sexuality: Health Persp"},
{"id":"HP265.","title":"Alcohol/Drug Abuse: Hlth Persp"},
{"id":"HP302.","title":"Biostatistics"},
{"id":"HP305.","title":"Struc/Theory:Wrksite Wellns"},
{"id":"HP377.","title":"Foundations for Hlth Promotion"},
{"id":"HP475.","title":"Professionalism in Health Care"},
{"id":"HP480.","title":"Internship in Hlth Professions"},
{"id":"HP498.","title":"Curr Cncpts-Hlth Prof"},
{"id":"HP499.","title":"Independent Study in Hlth Serv"},
{"id":"IPH356.","title":"Ethics & Hlth Care Plural Soc"},
{"id":"IPH401.","title":"Intprfssl Persp on Global Hlth"},
{"id":"MHA621.","title":"The Health Service System"},
{"id":"MHA622.","title":"Biostatistics"},
{"id":"MHA628.","title":"Epidemiology"},
{"id":"MHA633.","title":"Human Res& Labor Rel Mngt"},
{"id":"MHA635.","title":"Sem In Health Administration"},
{"id":"MHA636.","title":"Admin Effectivenss-Hlth Care"},
{"id":"MHA637.","title":"Leadership in Health Care"},
{"id":"MHA653.","title":"Capstone III:Dissemination"},
{"id":"MHA655X","title":"InterdiscplnryPerspctive Aging"},
{"id":"MHA656X","title":"Health Policy and Aging"},
{"id":"MHA661X","title":"Admin-in-Training Residence I"},
{"id":"PH284.","title":"Public Health"},
{"id":"PH385.","title":"Disease Control"},
{"id":"PH483.","title":"Hlth Around Us:Cncpts Env Hlth"},
{"id":"PH484.","title":"Environmental Health"},
{"id":"PH485.","title":"Epidemiology"},
{"id":"NURS267.","title":"Health Assessment for RNs"},
{"id":"NURS327.","title":"Transitions to Adv Nursing"},
{"id":"NURS331.","title":"Care Coordination in Prof Nurs"},
{"id":"NURS333.","title":"Nursing Informatics"},
{"id":"NURS337.","title":"Population Health & Prof NURS"},
{"id":"NURS353.","title":"Nurs Resrch& Evdnce Bsed Pract"},
{"id":"NURS356.","title":"Pharmacology for Nurses"},
{"id":"NURS357.","title":"Intro to Patient Care"},
{"id":"NURS358.","title":"Nursing Care of Adults I"},
{"id":"NURS367.","title":"Application Evidnce Bsed Pract"},
{"id":"NURS450.","title":"ST: Pharmacology"},
{"id":"NURS455.","title":"Population-Focused Nurs Pract"},
{"id":"NURS456.","title":"Care of Women and Families"},
{"id":"NURS457.","title":"Population-Focused Care for RN"},
{"id":"NURS461.","title":"Care of Adlts through Lifespan"},
{"id":"NURS468.","title":"Nursing Care of Adults III"},
{"id":"NURS492.","title":"Clinical Synthesis"},
{"id":"NURS601.","title":"Roles for Adv Nursing Practice"},
{"id":"NURS602.","title":"Evidence-based Pract-Adv Nurs"},
{"id":"NURS603.","title":"Theoreticl Fnd-Adv Nurs Pract"},
{"id":"NURS617.","title":"Adv Concpts-Pathophyslgy"},
{"id":"NURS625.","title":"Mktg & Comp Strat-Nurs & Hlt"},
{"id":"NURS635.","title":"Business of Nursing"},
{"id":"NURS645.","title":"Adv Nurs Assmnt & Intrvntion I"},
{"id":"NURS654.","title":"Prmry Care Nurs-Families I"},
{"id":"NURS661.","title":"Psychopharmacology"},
{"id":"NURS662.","title":"Psych Mntl Hlth-Fam/Lifespan"},
{"id":"NURS671.","title":"Curr Dev & Nurse Educator"},
{"id":"NURS674.","title":"Mngt Acutely Ill Adult-Gero I"},
{"id":"NURS684.","title":"Primary Care Adults/Elders I"},
{"id":"NURS717.","title":"Cultural Diversity"},
{"id":"NURS721.","title":"Systems Ldrship/Interp Collab"},
{"id":"NURS727.","title":"Nurs/Hlth Cre Tech Informactcs"},
{"id":"NURS738.","title":"Quality Initiatives in Nursing"},
{"id":"NURS854.","title":"Critical Appraisal of Practice"},
{"id":"NURS866.","title":"DNP Project Proposal"},
{"id":"NURS867.","title":"Project II: DNP Project Design"},
{"id":"NURS868.","title":"Project III: Implementation"},
{"id":"NURS871.","title":"Project IV: Data Analysis"},
{"id":"NURS872.","title":"Project V: Project Report"},
{"id":"OT611.","title":"Disease & Occupation"},
{"id":"OT623.","title":"Psychosocial & Cognitive Strat"},
{"id":"OT624.","title":"Fund of Occup Therpy Practice"},
{"id":"OT631.","title":"OT Theory and Clincl Reasoning"},
{"id":"OT633.","title":"Phys Disabilts/Ortho OT Pract"},
{"id":"OT652.","title":"Applied Neuroscience"},
{"id":"OT662.","title":"Professional Issues"},
{"id":"OT683.","title":"Adv OT Research Strategies"},
{"id":"OT695.","title":"Prof Practicum Seminar A, B, C"},
{"id":"OT696.","title":"Professional Fieldwork I"},
{"id":"OT697.","title":"Professional Fieldwork II"},
{"id":"OT699.","title":"Occupational Therapy Synth"},
{"id":"OTA213.","title":"Pathophyslgy & Conditions I"},
{"id":"OTA221.","title":"Technical Communication"},
{"id":"OTA231.","title":"Therapeutic Media"},
{"id":"OTA232.","title":"Media & Modalities"},
{"id":"OTA241.","title":"Occuptnl Perf Components I"},
{"id":"OTA297.","title":"Practicum Seminar A"},
{"id":"OTA298.","title":"Practicum Seminar B"},
{"id":"OTA350.","title":"Independent Study"},
{"id":"OTA397.","title":"Technical Fieldwork A"},
{"id":"OTA398.","title":"Technical Fieldwork B"},
{"id":"DMS321.","title":"Sonographic Phys &Instrum I"},
{"id":"DMS323.","title":"Obstetric Procedures"},
{"id":"DMS324.","title":"Gynecology Procedures"},
{"id":"DMS326.","title":"Sonographic Lab Practice II"},
{"id":"DMS327.","title":"Clinical Prac I - General"},
{"id":"DVT402.","title":"Vascular Procedures I"},
{"id":"DVT423.","title":"Sonographic Vasc Lab Prac I"},
{"id":"DVT426.","title":"Clinical Prac I - Vascular"},
{"id":"ECHO402.","title":"Advanced Echocardiography"},
{"id":"ECHO423.","title":"Echocardiography Lab Prac I"},
{"id":"ECHO426.","title":"Clinical Practice I Cardiac"},
{"id":"RADT196.","title":"Orient Radiologic/Imag Sci"},
{"id":"RADT316.","title":"Radiographic Procedures III"},
{"id":"RADT334.","title":"Radiographic Physics"},
{"id":"RADT343.","title":"Clinical Practicum II"},
{"id":"RADT345.","title":"Radiographic Pathology"},
{"id":"RADT411.","title":"CT/MRI Physics & Instr"},
{"id":"RADT412.","title":"Adv Radiographic Exp/QltyCntrl"},
{"id":"RADT413.","title":"CT/MRI Procedures I"},
{"id":"RADT414.","title":"Clinical VII-CT/MRI"},
{"id":"RADT424.","title":"Rad & Img Sci Practice & Mgmt"},
{"id":"RADT425.","title":"Radiographic Critique"},
{"id":"RADT445.","title":"Clinical Practicum V"},
{"id":"RADT486.","title":"Clinical IX-CT/MRI"},
{"id":"RADT491.","title":"Prof Devel & Literacy Img Sci"},
{"id":"REST303.","title":"Cardio Spt and Gen Airway Mgt"},
{"id":"REST312.","title":"Respiratory Modalities II"},
{"id":"REST317.","title":"Cardiopulmonary A&P II"},
{"id":"REST322.","title":"Mechanical Ventilation I"},
{"id":"REST325.","title":"Advanced Pulmonary Diseases"},
{"id":"REST381.","title":"Clinical Practice Resp I"},
{"id":"REST451.","title":"Mgt and Ldrshp in Resp Therapy"},
{"id":"REST452.","title":"Intro to Research Resp Therapy"},
{"id":"REST453.","title":"Resp Therapy Disease Mgt"},
{"id":"REST454.","title":"Advanced Critical Care"},
{"id":"REST455.","title":"Pul Rehab and Geriatrics"},
{"id":"REST456.","title":"Prof Issues in Resp Ther"},
{"id":"REST491.","title":"Clinical Practice Resp III"},
{"id":"MS101.","title":"Fundamental Military Concpts"},
{"id":"MS201.","title":"Advanced Leadership & Mngt"},
{"id":"MS241.","title":"U.S. Mltry Hist I (1740-1900)"},
{"id":"MS301.","title":"Small Unit Leadership"},
{"id":"MS401.","title":"Leadershp, Mngt & Ethics"},
{"id":"MS499.","title":"IND ST: Logistics & Support"},
{"id":"BIOL105.","title":"Biol Human Concern"},
{"id":"BIOL108.","title":"Biology for Educators"},
{"id":"BIOL111.","title":"Ethnobotany"},
{"id":"BIOL121.","title":"Human Anat&Phys I"},
{"id":"BIOL121.","title":"Human Anat&Phys I - N&HP LLC"},
{"id":"BIOL122.","title":"Human Anat&Phys II"},
{"id":"BIOL133.","title":"Biological Concepts"},
{"id":"BIOL141.","title":"Principles of Biology"},
{"id":"BIOL151.","title":"Botany"},
{"id":"BIOL152.","title":"Zoology"},
{"id":"BIOL215.","title":"Ecology"},
{"id":"BIOL251.","title":"Environmntl Conserv"},
{"id":"BIOL272.","title":"Medical Microbiology"},
{"id":"BIOL333.","title":"Animal Physiology"},
{"id":"BIOL334.","title":"Cell Biology"},
{"id":"BIOL342.","title":"Compar Chordat Anatomy"},
{"id":"BIOL361.","title":"Plant Systematics"},
{"id":"BIOL382.","title":"Genetics"},
{"id":"BIOL422.","title":"Immunology"},
{"id":"BIOL442.","title":"Histology"},
{"id":"BIOL444.","title":"Herpetology"},
{"id":"BIOL470.","title":"Independent Study"},
{"id":"BIOL481.","title":"Organic Evolution"},
{"id":"BIOL490.","title":"Undergraduate Teaching"},
{"id":"BIOL491.","title":"ST-Curnt Evnts Med/Molclr Biol"},
{"id":"BIOL499.","title":"Independent Research"},
{"id":"CHEM103.","title":"Molecules, Matter and Me"},
{"id":"CHEM107.","title":"Elements in Everyday Chem"},
{"id":"CHEM108.","title":"Chemistry for Educators"},
{"id":"CHEM141.","title":"Principles-Chemistry"},
{"id":"CHEM142.","title":"Survey of General Chemistry"},
{"id":"CHEM143.","title":"Survey Organic & Biochemistry"},
{"id":"CHEM218.","title":"Chemistry Seminar I"},
{"id":"CHEM241.","title":"Organic/Biochem Princpls"},
{"id":"CHEM261.","title":"General Chemistry I"},
{"id":"CHEM262.","title":"General Chemistry II"},
{"id":"CHEM318.","title":"Chemistry Seminar II"},
{"id":"CHEM353.","title":"Organic Chemistry I"},
{"id":"CHEM354.","title":"Organic Chemistry II"},
{"id":"CHEM361.","title":"Survey of Physical Chemistry"},
{"id":"CHEM421.","title":"Instrumental Analysis"},
{"id":"CHEM431.","title":"Biochemistry I"},
{"id":"CHEM461.","title":"Physical Chemistry I"},
{"id":"CHEM490.","title":"Undrgrad Tch Exp-Chemstr"},
{"id":"CHEM499.","title":"Intro Research-Chem"},
{"id":"CE221.","title":"Surveying"},
{"id":"CE222X","title":"Civil 3D Computer Aided Design"},
{"id":"CE301.","title":"Construction Estimating"},
{"id":"CE323.","title":"Transportation Engineering"},
{"id":"CE324.","title":"Construction Materials"},
{"id":"CE361.","title":"Structural Analysis"},
{"id":"CE381.","title":"Soil Mechanics"},
{"id":"CE471.","title":"CE Design and Planning"},
{"id":"ECE217.","title":"Fundamentals Comp Programming"},
{"id":"ECE241.","title":"Digital Logic"},
{"id":"ECE255.","title":"Electric Circuits"},
{"id":"ECE311.","title":"Electromagnetics"},
{"id":"ECE343.","title":"Electronics"},
{"id":"ECE345.","title":"Adv Electrical Circuits"},
{"id":"ECE347.","title":"Microcomputer Engineering"},
{"id":"ECE349.","title":"Electrical Machines"},
{"id":"ECE443.","title":"Linear Control Systems"},
{"id":"ECE453.","title":"Communication Systems"},
{"id":"ECE471.","title":"Electrical Systems Integration"},
{"id":"ENGR103.","title":"Prin of Problem Solving"},
{"id":"ENGR107.","title":"Fundamentals of Engineering"},
{"id":"ENGR108.","title":"Sys Engrng and Freshmn Design"},
{"id":"ENGR121.","title":"Drafting & Annotation"},
{"id":"ENGR235.","title":"Statics"},
{"id":"ENGR265.","title":"Energy Systms&Sustainable Desg"},
{"id":"ENGR275.","title":"Dynamics"},
{"id":"ENGR291.","title":"Exper Design & Tech Writing"},
{"id":"ENGR299.","title":"Co-op Experience"},
{"id":"ENGR305.","title":"Engineering Statistics"},
{"id":"ENGR335.","title":"Engineering Economics"},
{"id":"ENGR353.","title":"Entrprnr Feasibility Anlys"},
{"id":"ENGR355.","title":"Strength of Materials"},
{"id":"ENGR362.","title":"Manufacturing Processes I"},
{"id":"ENGR375.","title":"Fluid Mechanics"},
{"id":"ENGR382.","title":"SCADA Systems Design"},
{"id":"ENGR390.","title":"Special Design Projects"},
{"id":"ENGR471.","title":"Engineering Design & Anlysis"},
{"id":"ENGR491.","title":"Senior Design"},
{"id":"ENGR499.","title":"Independent Study/Research"},
{"id":"IM603.","title":"Survey of Statistics"},
{"id":"IM641.","title":"Prin & Pract of Qual Mngt"},
{"id":"IM671.","title":"Graduate Project"},
{"id":"IM698.","title":"Individual Study"},
{"id":"IME331.","title":"Intro Statistical Quality Cntl"},
{"id":"IME419.","title":"Transportation and Logistics"},
{"id":"ME121.","title":"Solid Modeling"},
{"id":"ME225.","title":"Thermodynamics"},
{"id":"ME363.","title":"Vibrations"},
{"id":"ME365.","title":"Modeling Dynamic Systems"},
{"id":"ME366.","title":"Dynamics of Machinery"},
{"id":"ME463.","title":"Heat Transfer"},
{"id":"ME466.","title":"Machine Design"},
{"id":"ME473.","title":"Intro to Control Systems"},
{"id":"TECH103.","title":"Materials & Processes"},
{"id":"TECH272.","title":"Robotics"},
{"id":"TECH299.","title":"Co-op Experience"},
{"id":"TECH351.","title":"Thermodynamics & Heat Transfer"},
{"id":"TECH362.","title":"Manufacturing"},
{"id":"TECH401.","title":"Workcell/Prod Sys Design"},
{"id":"TECH439.","title":"Spec Problems-Indust Suprvsn"},
{"id":"TECH471.","title":"Senior Project"},
{"id":"ASTR201.","title":"General Astronomy"},
{"id":"GEOG112.","title":"Earth System Science"},
{"id":"GEOG123.","title":"Phys Geog/Landscp Analysis US"},
{"id":"GEOG330.","title":"World Geography"},
{"id":"GEOL108.","title":"Earth-Space Sci for Educators"},
{"id":"GEOL121.","title":"Geology of Gemstones"},
{"id":"GEOL151.","title":"Geology of America's Natl Prks"},
{"id":"GEOL161.","title":"Introduction to Geology"},
{"id":"GEOL234.","title":"Oceans: Past,Present,Future"},
{"id":"GEOL261.","title":"Intermediate Geology"},
{"id":"GEOL315.","title":"Paleontology"},
{"id":"GEOL441.","title":"Hydrogeology"},
{"id":"GEOL470.","title":"Independent Study"},
{"id":"GEOL499.","title":"Independent Research"},
{"id":"PHYS101.","title":"Intro Physical Sci"},
{"id":"PHYS107.","title":"Physics Seminar"},
{"id":"PHYS108.","title":"Physics for Educators"},
{"id":"PHYS175.","title":"General Physics I"},
{"id":"PHYS175L","title":"General Physics I Lab"},
{"id":"PHYS176.","title":"General Physics II"},
{"id":"PHYS176L","title":"General Physics II Lab"},
{"id":"PHYS205.","title":"Intermed Physics I"},
{"id":"PHYS205L","title":"Intermediate Physics I Lab"},
{"id":"PHYS206.","title":"Intermed Physics II"},
{"id":"PHYS206L","title":"Intermediate Physics II Lab"},
{"id":"PHYS361.","title":"Modern Physics"},
{"id":"PHYS381.","title":"Electromagnetic Fields"},
{"id":"PHYS429.","title":"Quantum Mechanics"},
{"id":"PHYS491.","title":"Physics Senior Thesis"},
{"id":"PHYS499.","title":"Research"},
{"id":"EXSC225.","title":"Fund of Strngth Training Techn"},
{"id":"EXSC283.","title":"Career Preparation for EXSC"},
{"id":"EXSC376.","title":"Behavioral Strategies of Exerc"},
{"id":"EXSC383.","title":"Kinesiology"},
{"id":"EXSC392.","title":"Biomechanics & Motor Behavior"},
{"id":"EXSC394.","title":"Measurement & Evaluation"},
{"id":"EXSC396.","title":"Fitness Management"},
{"id":"EXSC397.","title":"Physiology of Exercise"},
{"id":"EXSC477.","title":"Testing/Eval of Athletes"},
{"id":"EXSC484.","title":"Exercise Testing & Prescrptn"},
{"id":"EXSC487.","title":"Prncpls/Appl Fitness Training"},
{"id":"EXSC488.","title":"Prgm Dsgn Diverse Populations"},
{"id":"EXSC496.","title":"Practicum in Exercise Science"},
{"id":"EXSC497.","title":"Practicum Strength & Condition"},
{"id":"KIN118.","title":"Table Tennis"},
{"id":"KIN124.","title":"Introduction to Rock Climbing"},
{"id":"KIN162.","title":"Hiking&Outdoor Educ"},
{"id":"KIN182.","title":"Weight Training"},
{"id":"KIN185.","title":"Step Aerobics"},
{"id":"KIN192.","title":"Concepts of Wellness & Fitness"},
{"id":"KIN199.","title":"Spec Act: Pilates"},
{"id":"KIN199.","title":"Spec Act: Yoga"},
{"id":"KIN199.","title":"Spec Act:Spinning"},
{"id":"KIN281.","title":"Personal Health Sci"},
{"id":"KIN282.","title":"CPR Hlthcr Prv & Spts Inj Prev"},
{"id":"KIN390.","title":"ST: Personal Training"},
{"id":"KIN393.","title":"Practicum in Kinesiology I"},
{"id":"KIN493.","title":"Practicum in Kinesiology II"},
{"id":"PET287.","title":"Phys Ed & Elem Schl Cls Tchr"},
{"id":"PET392.","title":"Adapted Physical Education"},
{"id":"PET397.","title":"Prin Tchng Dual & Team Sports"},
{"id":"PET402.","title":"Controv Issues in Health Educ"},
{"id":"PET493.","title":"Emotional Hlth-Chldrn Sport"},
{"id":"SPTM223.","title":"Princ of Recreation"},
{"id":"SPTM225.","title":"Princ Intercollegiate Ath Adm"},
{"id":"SPTM226.","title":"Princ & Probs of Coaching"},
{"id":"SPTM228.","title":"Princ & Appls of Sport Mgmt"},
{"id":"SPTM283.","title":"Career Preparation for SPTM"},
{"id":"SPTM288.","title":"Intro to Sport Communication"},
{"id":"SPTM305.","title":"Practicum in Sport Management"},
{"id":"SPTM332.","title":"Sport Facility Management"},
{"id":"SPTM333.","title":"Sport Marketing"},
{"id":"SPTM334.","title":"Sport Event Management"},
{"id":"SPTM336.","title":"Governance & Ethics in Sport"},
{"id":"SPTM353.","title":"Sport & Social Media"},
{"id":"SPTM451.","title":"Legal Aspects of Sport"},
{"id":"SPTM452.","title":"Sport Finance & Economics"},
{"id":"SPTM453.","title":"Diversity in Sport"},
{"id":"SPTM492.","title":"Contemp Iss-Sport & Exercise"},
{"id":"SPTM498.","title":"Internship in Sport Management"},
{"id":"SPTM592.","title":"Contemp Issues Sport & Exerc"},
{"id":"SPTM633.","title":"Sport Marketing"},
{"id":"SPTM652.","title":"Sport Budgets/Fiscal Practices"},
{"id":"SPTM653.","title":"Sport Law"},
{"id":"MATH081.","title":"Foundations Quant Reasoning"},
{"id":"MATH082.","title":"Transitions to College Algebra"},
{"id":"MATH085.","title":"Foundations for College Alg"},
{"id":"MATH103.","title":"Math Elementary Teachers I"},
{"id":"MATH107.","title":"Fundmntls Math for Health Prof"},
{"id":"MATH111.","title":"College Algebra"},
{"id":"MATH112.","title":"Trigonometry"},
{"id":"MATH114.","title":"Quantitative Reasoning"},
{"id":"MATH115.","title":"Pre-Calculus Math"},
{"id":"MATH118.","title":"Comprehensive Pre-calculus"},
{"id":"MATH203.","title":"Math Elementary Teachers II"},
{"id":"MATH215.","title":"Survey of Calculus"},
{"id":"MATH230.","title":"Calculus I"},
{"id":"MATH235.","title":"Calculus II"},
{"id":"MATH236.","title":"Geometry and Meas for Teachers"},
{"id":"MATH253.","title":"Intro-Mathematical Reasoning"},
{"id":"MATH323.","title":"College Geometry"},
{"id":"MATH335.","title":"Calculus III"},
{"id":"MATH345.","title":"Complex Variables"},
{"id":"MATH353.","title":"Discrete Math"},
{"id":"MATH362.","title":"Linear Algebra"},
{"id":"MATH366.","title":"Differential Equations"},
{"id":"MATH391.","title":"Teaching Math-Grades 5-12"},
{"id":"MATH392.","title":"Teaching Elem Sch Math"},
{"id":"MATH393.","title":"Pedagogical Knowlg Tch Math"},
{"id":"MATH410.","title":"Intro to Analysis"},
{"id":"MATH411.","title":"Theory of Numbers"},
{"id":"MATH438.","title":"Theory-Probability"},
{"id":"MATH621.","title":"Technology for Teaching Math"},
{"id":"STAT241.","title":"Principles of Statistics"},
{"id":"STAT251.","title":"Principles of Biostatistics"},
{"id":"STAT261.","title":"Applied Nonparametric Stats"},
{"id":"STAT265.","title":"Applied Regression Analysis"},
{"id":"STAT467.","title":"Multivariate Data Analysis"},
{"id":"EDLE705.","title":"Intro to Educational Research"},
{"id":"EDLE706.","title":"Statistical Literacy Educ Ldrs"},
{"id":"EDLE725.","title":"Community-Based Educ Leader"},
{"id":"EDLE726.","title":"Legal/Ethical Issues Educ Ldrs"},
{"id":"EDLE755.","title":"Orch Community-Bsd Educ Vision"},
{"id":"EDUC203.","title":"Intro to Children's Literature"},
{"id":"EDUC206.","title":"Intro to Exceptionalities"},
{"id":"EDUC214.","title":"Instr Tech in Education"},
{"id":"EDUC221.","title":"Diversity and Equity-Education"},
{"id":"EDUC241.","title":"Early Childhood Educ"},
{"id":"EDUC242.","title":"Growth & Dev:Early Childhood"},
{"id":"EDUC255.","title":"Growth & Dev Infnts & Tddlrs"},
{"id":"EDUC261.","title":"Special Needs Early Child Ed"},
{"id":"EDUC283.","title":"Explorations in Secondary Educ"},
{"id":"EDUC292.","title":"Explorations in Elem Educ"},
{"id":"EDUC315.","title":"Mngt for Students w/Excp Needs"},
{"id":"EDUC340.","title":"ST: Aim4Excellence 1-3"},
{"id":"EDUC340.","title":"ST: Aim4Excellence 4-6"},
{"id":"EDUC344.","title":"Family,School& Comm Partner"},
{"id":"EDUC354.","title":"Practicum in Elementary Educ"},
{"id":"EDUC355.","title":"Practicum-Early Child Educ"},
{"id":"EDUC357.","title":"Fine Arts in the Elem School"},
{"id":"EDUC373.","title":"Tching Science Elem School"},
{"id":"EDUC378.","title":"Literacy Methods"},
{"id":"EDUC381.","title":"Language, Literacy, & Culture"},
{"id":"EDUC382.","title":"Elem Analysis-Curr and Instrct"},
{"id":"EDUC383.","title":"Secndry Analysis-Curr/Pedagogy"},
{"id":"EDUC384.","title":"Advncd Clincl Exp-Sec Schls"},
{"id":"EDUC386.","title":"Early Child Curr and Instructn"},
{"id":"EDUC388.","title":"Teach Soc Studies-Elem"},
{"id":"EDUC396.","title":"Tch Science in Grades 5-12"},
{"id":"EDUC398.","title":"Reading Methods"},
{"id":"EDUC408.","title":"Tching Read Stds w/Spec Needs"},
{"id":"EDUC411.","title":"Legal& Contemp Iss-Exc Needs"},
{"id":"EDUC412.","title":"Assessing Stds w/Special Needs"},
{"id":"EDUC413.","title":"Exceptional Needs Instru Strat"},
{"id":"EDUC418.","title":"Exceptional Need Inst Strat II"},
{"id":"EDUC421.","title":"Excpt Needs Practicum I:Elem"},
{"id":"EDUC422.","title":"Excpt NeedsPract II:Secndry"},
{"id":"EDUC423.","title":"Collaborative Partnerships"},
{"id":"EDUC424.","title":"Sprvsd Std Tching in Spec Educ"},
{"id":"EDUC431.","title":"Sprvsd Tchin Early Chldhd Educ"},
{"id":"EDUC437.","title":"Classrm Mngt Data-Based Dec"},
{"id":"EDUC455.","title":"Diagnostic Teachng of Readng"},
{"id":"EDUC458.","title":"Seminar in Professional Educ"},
{"id":"EDUC471.","title":"Supervisd Std Tchng Elem Educ"},
{"id":"EDUC473.","title":"Suprvsd Std Tching Sec or P-12"},
{"id":"EDUC480.","title":"Diversity in Children's Lit"},
{"id":"EDUC490.","title":"ST-Aim4Excellence 7-9"},
{"id":"EDUC493.","title":"Content Area Reading & Writing"},
{"id":"EDUC508.","title":"Tching Rdg to Stds w/ Sp Needs"},
{"id":"EDUC511.","title":"Found & Legal-Exceptnl Needs"},
{"id":"EDUC512.","title":"Assessing Stdnts w/Spec Needs"},
{"id":"EDUC513.","title":"Learng Strat-Studnts w/Excpt"},
{"id":"EDUC515.","title":"Mngt & Exptnlty:Env,Soc,Beh"},
{"id":"EDUC518.","title":"Exceptional Need Inst Strat II"},
{"id":"EDUC521.","title":"Exceptnl Needs: Practicum I"},
{"id":"EDUC522.","title":"Exceptnl Needs:Practicum II"},
{"id":"EDUC523.","title":"Collaborative Partnerships"},
{"id":"EDUC604.","title":"Equity Education"},
{"id":"EDUC680.","title":"Internship for School Leaders"},
{"id":"EDUC681.","title":"Fdns of Community-based Educ"},
{"id":"EDUC687.","title":"Finance&Facility Mngt Principl"},
{"id":"EDUC699.","title":"Supervised Research"},
{"id":"GENS105.","title":"College Study Strategies"},
{"id":"GENS111.","title":"Career Planning"},
{"id":"GENS151.","title":"Academic Reading Strategies"},
{"id":"GENS199.","title":"ST:Speed Reading"},
{"id":"GENS399.","title":"Field Experience"},
{"id":"GENS401.","title":"Career Readiness & Pro Dev"},
{"id":"GENS998.","title":"Full-Time Co-op Semester"},
{"id":"UNIV101.","title":"FYE- Teacher Education"},
{"id":"UNIV101.","title":"FYE-CNHP 1st Gen/21st Scholars"},
{"id":"UNIV101.","title":"FYE-College of Liberal Arts"},
{"id":"UNIV101.","title":"FYE-College of Nur & Hlth Prof"},
{"id":"UNIV101.","title":"FYE-Creative Exp LLC"},
{"id":"UNIV101.","title":"FYE-ESports&Gaming LLC"},
{"id":"UNIV101.","title":"FYE-Education LLC"},
{"id":"UNIV101.","title":"FYE-Engineering"},
{"id":"UNIV101.","title":"FYE-Engineering LLC"},
{"id":"UNIV101.","title":"FYE-Global Communities"},
{"id":"UNIV101.","title":"FYE-Honors LLC"},
{"id":"UNIV101.","title":"FYE-Innovation & Entrep LLC"},
{"id":"UNIV101.","title":"FYE-Kinesiology & Sport"},
{"id":"UNIV101.","title":"FYE-Lib Arts-1stGen/21stSchlrs"},
{"id":"UNIV101.","title":"FYE-MLS Students"},
{"id":"UNIV101.","title":"FYE-Nursing & Health Prof LLC"},
{"id":"UNIV101.","title":"FYE-Outdoor Adventures LLC"},
{"id":"UNIV101.","title":"FYE-Pott College LLC"},
{"id":"UNIV101.","title":"FYE-RCB  1stGen/21st Scholar"},
{"id":"UNIV101.","title":"FYE-Romain College of Business"},
{"id":"UNIV101.","title":"FYE-Sci-1st Gen/21st Scholars"},
{"id":"UNIV101.","title":"FYE-Science & Math"},
{"id":"UNIV101.","title":"FYE-Service & Leadership LLC"},
{"id":"UNIV101.","title":"FYE-Teacher Education"},
{"id":"UNIV101.","title":"FYE-Univ Div Exploring MjrsLLC"},
{"id":"UNIV101.","title":"FYE-Univ Div-1stGen/21stSchlrs"},
{"id":"UNIV101.","title":"FYE-Univ Division/Athletes"},
{"id":"UNIV101.","title":"FYE-University Div/SSS"},
{"id":"UNIV101.","title":"FYE-University Division"},
{"id":"UNIV101.","title":"FYE-Vets & Non Trad"},
{"id":"HONS129.","title":"Sem: Music that Changed World"},
{"id":"HONS129.","title":"Sem:\"Girls\" Who Run the World"},
{"id":"HONS129.","title":"Sem:SciFi and Philosophy"},
{"id":"IEP092.","title":"IEP-Writing"},
{"id":"IEP093.","title":"IEP-Acad Skills with Grammar"},
{"id":"IEP094.","title":"IEP-Reading"},
{"id":"IEP095.","title":"IEP-Listening and Speaking"},
]
module.exports={data}