<template>
  <div>
    <v-snackbar v-if="enableRichUI" v-model="snackbar" app centered>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <PageBanner bannerText="What do you want to reserve?" />
    <v-container class="ma-0 pa-0">
      <v-row no-gutters>
        <v-col
          v-for="(item, index) in thingsToReserve"
          :key="item.id"
          cols="6"
          :class="
            'd-flex align-content-space-around flex-wrap align-center justify-center tile-border' +
              ' ' +
              (index % 2 == 0 ? 'odd-col' : 'even-col')
          "
        >
          <div class="py-10 resource-tile" @click="navigate(item)">
            <span>
              <div v-if="enableRichUI" class="custom-badge-wrapper">
                <span
                  :class="
                    'custom-badge' +
                      (resourceCount(item.id) > 0 ? '' : ' hidden')
                  "
                >
                  {{ resourceCount(item.id) }}
                </span>
              </div>
              <div v-else class="badge-repalacement tile-text">
                {{ resourceCount(item.id) }}
              </div>
              <v-img
                :src="require('../assets/top-resource-' + item.id + '.svg')"
                class="mb-5"
                contain
                height="50"
              >
              </v-img>
            </span>
            <p class=".text-sm-body-2 text-center tile-text">
              {{ item.title }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import { countItems } from "@/api/reservations";
import { mapMutations } from "vuex";
import logdown from "logdown";

const logger = logdown("WhatToReserve");
// logger.state.isEnabled = true;

export default {
  name: "WhatToReserve",

  components: {
    PageBanner,
  },

  data: () => ({
    thingsToReserve: [
      { id: "course", title: "Courses", screen: "SelectATerm" },
      { id: "book", title: "Books" },
      { id: "study-room", title: "Study room" },
      {
        id: "parking-permit",
        title: "Parking permit",
      },
      { id: "dining-hall", title: "Dining hall time" },
      { id: "gym", title: "Gym visit" },
    ],
    snackbar: false,
    snackbarText: "Not implemented",
    enableRichUI: true,
  }),

  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    navigate(resource) {
      this.$gtag && this.$gtag.event("navigate", { // only if Gtag is active
        event_category: "app_development",
        target: "courses",
      });

      if (resource.screen) {
        logger.debug(resource.screen);
        this.selectPage(resource.screen);
        logger.debug(resource.screen);
      } else {
        this.snackbar = true;
        this.snackbarText =
          "Reservation of " + resource.title + " is not available";
      }
    },
    resourceCount(id) {
      if ("course" == id) {
        logger.debug("counting:", this.$store.getters.getUserCourses);
        return countItems(this.$store.getters.getUserCourses);
      } else {
        return 0;
      }
    },
  },

  created() {
    // this.$store.dispatch("fetchUserCourses");
  },

  beforeCreate() {
    logger.debug("beforeCreate");
    this.$store.dispatch("fetchUserCourses");
  },
};
</script>

<style scoped>
.tile-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}

.tile-border.odd-col {
  border-right: 1px solid #333333;
  border-top: 1px solid #333333;
}

.tile-border.even-col {
  /* border-right: 1px solid #333333; */
  border-top: 1px solid #333333;
}

.resource-tile {
  width: 150px;
  height: 150px;
}

.custom-badge-wrapper {
  position: absolute;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: none;
}

.custom-badge {
  top: -10px;
  left: 120px;
  border-radius: 3px;
  border: solid 1px;
  background-color: #9b51e0;
  color: #ffffff;
  font-size: 12px; 
  height: 22px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 20px;
  padding: 4px 6px;
  pointer-events: auto;
  position: absolute;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}
.hidden {
  display: none;
}
</style>
