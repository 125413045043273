var data = [
{"id":"ACCT201.","title":"Accounting Princ I"},
{"id":"ACCT202.","title":"Accounting Princ II"},
{"id":"ACCT303.","title":"Intermed Accounting I"},
{"id":"ACCT304.","title":"Intermed Accounting II"},
{"id":"ACCT311.","title":"Intr to Fed Income Taxation"},
{"id":"ACCT315.","title":"Cost Accounting"},
{"id":"ACCT390.","title":"Individual Taxation Practicum"},
{"id":"ACCT401.","title":"Advanced Accounting"},
{"id":"ACCT413.","title":"Acct Info Systems"},
{"id":"ACCT415.","title":"Auditing Theory & Pract"},
{"id":"ACCT445.","title":"Forensic Accounting"},
{"id":"ACCT465.","title":"Acct Ethics & Compliance"},
{"id":"ACCT499.","title":"Accounting Internship"},
{"id":"ACCT601.","title":"Acct-Decision Making & Cntrl"},
{"id":"ACCT602.","title":"Financial Statement Analysis"},
{"id":"BCOM231.","title":"Business Communication"},
{"id":"BCOM331.","title":"Corp/Managerial Communication"},
{"id":"BCOM401.","title":"Career Plan&Prof Devel"},
{"id":"BLAW263.","title":"Legal Env of Business"},
{"id":"FIN101.","title":"Money Skills"},
{"id":"FIN208.","title":"Personal Financial Mgt"},
{"id":"FIN305.","title":"Business Finance"},
{"id":"FIN335.","title":"Entreprnrial Finance"},
{"id":"FIN343.","title":"International Finance"},
{"id":"FIN361.","title":"Financial Institutions"},
{"id":"FIN433.","title":"Principles Investment"},
{"id":"FIN461.","title":"Financial Management"},
{"id":"FIN481.","title":"Seminar in Finance"},
{"id":"FIN601.","title":"Financial Management & Markets"},
{"id":"BAN602.","title":"Data-Driven Decision Making"},
{"id":"BUAD499.","title":"Professional Practice"},
{"id":"MBA616.","title":"Project Management II"},
{"id":"DSCI351.","title":"Intro to Operatn Resrch"},
{"id":"DSCI445.","title":"Operations Management"},
{"id":"ECON175.","title":"Fundamtls of Econ"},
{"id":"ECON208.","title":"Microeconomics"},
{"id":"ECON209.","title":"Macroeconomics"},
{"id":"ECON241.","title":"Global Econ Issues"},
{"id":"ECON265.","title":"Elem Statistics"},
{"id":"ECON309.","title":"Interm Macro Theory"},
{"id":"ECON327.","title":"Applied Cost-Benefit Analysis"},
{"id":"ECON346.","title":"Development Economics"},
{"id":"ECON353.","title":"Economics of Sports"},
{"id":"ECON361.","title":"Money & Banking"},
{"id":"ECON433.","title":"Games and Strategic Behavior"},
{"id":"ECON499.","title":"Sr Seminar in Economics"},
{"id":"ECON601.","title":"Managerial Economics"},
{"id":"MKTG201.","title":"Intro to Marketing"},
{"id":"MKTG305.","title":"Principles Marketing"},
{"id":"MKTG313.","title":"Marketing of Services"},
{"id":"MKTG332.","title":"Consumer Behavior"},
{"id":"MKTG334.","title":"Promotional Strategy"},
{"id":"MKTG342.","title":"Bus & Industrial Marketing"},
{"id":"MKTG344.","title":"Personal Selling"},
{"id":"MKTG355.","title":"Interactive Marketing"},
{"id":"MKTG438.","title":"Marketing Research"},
{"id":"MKTG447.","title":"Retailing Policy& Mngt"},
{"id":"MKTG448.","title":"Marketing Management"},
{"id":"MKTG471.","title":"Internationl Marketing"},
{"id":"MKTG475.","title":"Sem Contempo Mktg Prob"},
{"id":"MKTG601.","title":"Marketing Strategies"},
{"id":"CIS141.","title":"Computer Skills/non-Bus majrs"},
{"id":"CIS151.","title":"Computer Appl. in Bus."},
{"id":"CIS201.","title":"Business Processes and IS"},
{"id":"CIS276.","title":"Intro to Enterprise Web Devel"},
{"id":"CIS301.","title":"Enterprise Processes & IS"},
{"id":"CIS333.","title":"Data Viz & Storytelling"},
{"id":"CIS344.","title":"Auto Machine Learning for All"},
{"id":"CIS345.","title":"Info Systems Security/RiskMgmt"},
{"id":"CIS346.","title":"Cybersecurity Assess/Ntwrk Dfs"},
{"id":"CIS367.","title":"Data Communications"},
{"id":"CIS375.","title":"System Analysis&Design"},
{"id":"CIS377.","title":"Database Concepts"},
{"id":"CIS454.","title":"Managing Info Technology"},
{"id":"CIS477.","title":"Appl Software Developmnt"},
{"id":"CIS601.","title":"Information Sys & Technology"},
{"id":"CS215.","title":"Discrete Struct for Comp Sci"},
{"id":"CS258.","title":"Intro to Object-Oriented Progr"},
{"id":"CS311.","title":"Data Structures & Analysis Alg"},
{"id":"CS321.","title":"Architec Digitl Comput"},
{"id":"CS351.","title":"Client-Side Web App Dev"},
{"id":"CS358.","title":"Intermediate OOP Using C#"},
{"id":"CS377.","title":"Data Base Concepts"},
{"id":"CS458.","title":"Advanced Programming in C#"},
{"id":"CS461.","title":"Artificial Intelligence"},
{"id":"CS478.","title":"Software Development"},
{"id":"CS483.","title":"Senior Software Dev Project"},
{"id":"MNGT201.","title":"Survey of Management"},
{"id":"MNGT305.","title":"Principles of Management"},
{"id":"MNGT315.","title":"Project Managment I"},
{"id":"MNGT316.","title":"Project Management 2"},
{"id":"MNGT341.","title":"Human Resource Mngt"},
{"id":"MNGT352.","title":"Entrepreneur Idea/Innovation"},
{"id":"MNGT353.","title":"Entrprnr Feas Anlys/B-Plan Dev"},
{"id":"MNGT354.","title":"Strategic Entrepreneurship"},
{"id":"MNGT408.","title":"Negotiation Skills"},
{"id":"MNGT441.","title":"Seminar in Human Resource Mngt"},
{"id":"MNGT442.","title":"Training and Development"},
{"id":"MNGT444.","title":"Manage Divrsty-Organztns"},
{"id":"MNGT452.","title":"Policy Formulation Adm"},
{"id":"MNGT455.","title":"Small Business Consulting"},
{"id":"MNGT611.","title":"Leadership Skills & Innovation"},
{"id":"MNGT681.","title":"Strategic & Internatl Mngt"},
{"id":"ART103.","title":"Color & Design"},
{"id":"ART104.","title":"Design in Materials"},
{"id":"ART105.","title":"Drawing I"},
{"id":"ART106.","title":"Drawing & Composition"},
{"id":"ART201.","title":"Intro to Visual Arts"},
{"id":"ART205.","title":"Figure Drawing I"},
{"id":"ART206.","title":"Figure Drawing II"},
{"id":"ART255.","title":"Introduction to African Arts"},
{"id":"ART311.","title":"Painting I"},
{"id":"ART312.","title":"Painting II"},
{"id":"ART313.","title":"Ceramics I"},
{"id":"ART314.","title":"Ceramics II"},
{"id":"ART341.","title":"Sculpture I"},
{"id":"ART342.","title":"Sculpture II"},
{"id":"ART351.","title":"Woodworking I"},
{"id":"ART352.","title":"Woodworking II"},
{"id":"ART361.","title":"Printmaking I"},
{"id":"ART362.","title":"Printmaking II"},
{"id":"ART399.","title":"ST-Gallery Fellowship-NewHrmny"},
{"id":"ART399.","title":"ST-Gallery Fellowship-USI"},
{"id":"ART405.","title":"Figure Draw Compos III"},
{"id":"ART406.","title":"Figure Draw Compos IV"},
{"id":"ART411.","title":"Painting III"},
{"id":"ART412.","title":"Painting IV"},
{"id":"ART413.","title":"Ceramics III"},
{"id":"ART414.","title":"Ceramics IV"},
{"id":"ART441.","title":"Sculpture III"},
{"id":"ART442.","title":"Sculpture IV"},
{"id":"ART451.","title":"Woodworking III"},
{"id":"ART452.","title":"Woodworking IV"},
{"id":"ART461.","title":"Printmaking III"},
{"id":"ART462.","title":"Printmaking IV"},
{"id":"ART489.","title":"SP-Ceramics"},
{"id":"ART489.","title":"SP-Drawing"},
{"id":"ART489.","title":"SP-Painting"},
{"id":"ART489.","title":"SP-Photography"},
{"id":"ART489.","title":"SP-Printmaking"},
{"id":"ART489.","title":"SP-Sculpture"},
{"id":"ART489.","title":"SP-Woodworking"},
{"id":"ART493.","title":"Senior Art Seminar"},
{"id":"ART498.","title":"Internship in Art"},
{"id":"ART589.","title":"Advanced Study in Art/Design"},
{"id":"ARTD102.","title":"Introduction to Digital Media"},
{"id":"ARTD231.","title":"Intro to Graphic Design"},
{"id":"ARTD232.","title":"Typography"},
{"id":"ARTD331.","title":"Illustration Techniques"},
{"id":"ARTD332.","title":"Digital Illustration"},
{"id":"ARTD376.","title":"Graphic Design II"},
{"id":"ARTD381.","title":"Interactive Web Design"},
{"id":"ARTD468.","title":"Publication Design"},
{"id":"ARTD494.","title":"Senior Design Seminar"},
{"id":"ARTE394.","title":"Workshop in Art Education"},
{"id":"ARTE396.","title":"Mat & Methods Elem/Mid Art Ed"},
{"id":"ARTE397.","title":"Material & Method Sec Art Ed"},
{"id":"ARTH221.","title":"Art History I"},
{"id":"ARTH222.","title":"Art History II"},
{"id":"ARTH343.","title":"Renaissance Art"},
{"id":"ARTH353.","title":"19th Century Art"},
{"id":"ARTH390.","title":"ST:Northern Baroque"},
{"id":"ARTH490.","title":"SP-Postmodern Methods"},
{"id":"ARTP211.","title":"Intro to Darkroom Photography"},
{"id":"ARTP221.","title":"Intro Digital Photo Imaging"},
{"id":"ARTP324.","title":"Photo:Constructed Narratives"},
{"id":"ARTP331.","title":"Video Art"},
{"id":"GLST101.","title":"Intro to Global Studies"},
{"id":"GNDR111.","title":"Intro to Gender Studies"},
{"id":"GNDR222.","title":"The Body in Art and Culture"},
{"id":"HUM211.","title":"World Humanities I"},
{"id":"HUM212.","title":"World Humanities II"},
{"id":"HUM242.","title":"Humanistic Trad Literature II"},
{"id":"HUM351.","title":"History of Philosophy II"},
{"id":"LBST616.","title":"Cultural Literacy"},
{"id":"LBST690.","title":"Independent Work-LBST"},
{"id":"LBST695.","title":"Capstone: Portfolio"},
{"id":"LBST698.","title":"Capstone Project or Thesis II"},
{"id":"PRFS301.","title":"Ethics of Global Engagement"},
{"id":"PRFS490.","title":"Professional Studies Intrnship"},
{"id":"PRFS498.","title":"Personal & Professional Dev"},
{"id":"ADV101.","title":"Intro to Advertising"},
{"id":"ADV342.","title":"Advertising Design"},
{"id":"ADV347.","title":"Advertising Copywriting"},
{"id":"ADV400.","title":"Ind St-Advertising"},
{"id":"ADV489.","title":"Intern-Advertising"},
{"id":"CMST101.","title":"Intro to Public Speaking"},
{"id":"CMST101.","title":"IntroPublicSpkngCreativeExpLLC"},
{"id":"CMST107.","title":"Intro Interpersonal Comm"},
{"id":"CMST201.","title":"Intro Communication Studies"},
{"id":"CMST204.","title":"Business & Prof Communicatn"},
{"id":"CMST214.","title":"Group & Team Communication"},
{"id":"CMST301.","title":"Comm Criticism & Analysis"},
{"id":"CMST303.","title":"Intro to Persuasion"},
{"id":"CMST314.","title":"Organizational Communication"},
{"id":"CMST323.","title":"Comm to Manage & Lead"},
{"id":"CMST400.","title":"Ind St-Communicatn Studies"},
{"id":"CMST414.","title":"Oral Traditions"},
{"id":"CMST430.","title":"Sem:LGBTQ Rhetorics"},
{"id":"CMST489.","title":"Intern-Communication Studies"},
{"id":"COMM122.","title":"Understanding Media"},
{"id":"COMM234.","title":"Strategic Writing Social Media"},
{"id":"COMM494.","title":"Mass Communicatn Law"},
{"id":"COMM495.","title":"Mass Comm Research"},
{"id":"COMM497.","title":"Career Plan/Prof Development"},
{"id":"COMM601.","title":"Foundations of Comm Theory"},
{"id":"COMM625.","title":"History of Mass Media"},
{"id":"COMM637.","title":"Critical Pedagogy and Communic"},
{"id":"COMM690.","title":"Capstone Project"},
{"id":"COMM699.","title":"Thesis"},
{"id":"JRN180.","title":"Journalism Workshop"},
{"id":"JRN273.","title":"Photojournalism"},
{"id":"JRN400.","title":"Ind St-Journalism"},
{"id":"JRN489.","title":"Intern-Journalism"},
{"id":"PRL101.","title":"Intro to Public Relations"},
{"id":"PRL362.","title":"Writing Public-Relatns"},
{"id":"PRL368.","title":"Strategic Writing"},
{"id":"PRL400.","title":"Ind St-Public Relations"},
{"id":"PRL467.","title":"Crisis Communications"},
{"id":"PRL468.","title":"Public Relatn Campgns"},
{"id":"PRL489.","title":"Intern-Public Relations"},
{"id":"RTV150.","title":"Practicum-Broadcasting"},
{"id":"RTV255.","title":"Media Production"},
{"id":"RTV350.","title":"Radio-TV Workshop"},
{"id":"RTV353.","title":"Advanced Video Field Prod"},
{"id":"RTV354.","title":"Write Broadcast Media"},
{"id":"RTV366.","title":"Broadcast Journalism"},
{"id":"RTV400.","title":"Ind St-Radio-TV"},
{"id":"RTV452.","title":"Broadcast/Cable Sales"},
{"id":"RTV457.","title":"Key Issues Telecommunications"},
{"id":"RTV489.","title":"Intern-Radio-TV"},
{"id":"CRIM224.","title":"Criminology"},
{"id":"CRIM234.","title":"Introduction to Corrections"},
{"id":"CRIM244.","title":"Police & Society"},
{"id":"CRIM254.","title":"Criminal Courts and Law"},
{"id":"CRIM301.","title":"Intro-Criminal Justice Admin"},
{"id":"CRIM305.","title":"Comparative CJ Systems"},
{"id":"CRIM315.","title":"Crime Control Strategies"},
{"id":"CRIM336.","title":"Drugs and Society"},
{"id":"CRIM351.","title":"Research Methods"},
{"id":"CRIM352.","title":"Statistics"},
{"id":"CRIM370.","title":"Sem:Incarceration Effects"},
{"id":"CRIM370.","title":"Sem:Issues in Policing"},
{"id":"CRIM370.","title":"Sem:The Holocaust"},
{"id":"CRIM370.","title":"Sem:Urban Crime"},
{"id":"CRIM371.","title":"Criminological Theory"},
{"id":"CRIM411.","title":"Criminal Procedure"},
{"id":"CRIM475.","title":"Criminal Justice Capstone"},
{"id":"CRIM489.","title":"Ind Study-Criminal Justice"},
{"id":"CRIM499.","title":"Internship-Criminal Justice"},
{"id":"ENG100.","title":"Intro-Rhet &Comp"},
{"id":"ENG101.","title":"Rhet&Comp I:Literacy/Self"},
{"id":"ENG185.","title":"Introduction to Film"},
{"id":"ENG201.","title":"Rhet&Comp II:Lit/Wrld-EngrLLC"},
{"id":"ENG201.","title":"Rhet&Comp II:Lit/Wrld-N&HPLLC"},
{"id":"ENG201.","title":"Rhet&Comp II:Literacy/World"},
{"id":"ENG201.","title":"RhetCompII:CrtvExp&OutAdvLLC"},
{"id":"ENG201.","title":"RhetCompII:Lit/Wrld-PottClgLLC"},
{"id":"ENG205.","title":"Int Engl Studies Ways Rding"},
{"id":"ENG210.","title":"Technical Writing"},
{"id":"ENG222.","title":"Concpts of Good & Evil in Lit"},
{"id":"ENG227.","title":"Literature and Popular Culture"},
{"id":"ENG255.","title":"Intro to Brit Lit History"},
{"id":"ENG265.","title":"Intro to American Lit Hist"},
{"id":"ENG301.","title":"Advanced Composition"},
{"id":"ENG302.","title":"Creative Writing"},
{"id":"ENG303.","title":"Poetry Workshop"},
{"id":"ENG310.","title":"Writing in the Sec Schools"},
{"id":"ENG319.","title":"Fundamentals of Grant Writing"},
{"id":"ENG344.","title":"Adolescent/YngAdult Literature"},
{"id":"ENG372.","title":"Western World Literature"},
{"id":"ENG373.","title":"Intro to African Literatures"},
{"id":"ENG412.","title":"Writng for Professions"},
{"id":"ENG426.","title":"Lit of Renaissance"},
{"id":"ENG431.","title":"19th Century Literature"},
{"id":"ENG437.","title":"Contemporary Fiction"},
{"id":"ENG491.","title":"The Writer at Work"},
{"id":"ENG498.","title":"Internship in English"},
{"id":"ENG646.","title":"Teaching Composition"},
{"id":"AFRC111.","title":"Intro Africana Studies"},
{"id":"HIST101.","title":"The United States to 1877"},
{"id":"HIST102.","title":"The United States since 1877"},
{"id":"HIST108.","title":"What is History"},
{"id":"HIST111.","title":"World Civ I"},
{"id":"HIST112.","title":"World Civ II"},
{"id":"HIST246.","title":"U.S. Hist Global"},
{"id":"HIST266.","title":"Global History"},
{"id":"HIST287.","title":"Social Studies Methods"},
{"id":"HIST288.","title":"The Historians Craft"},
{"id":"HIST298.","title":"Historical Methods"},
{"id":"HIST310.","title":"Topics:AfricanDiaspora In Film"},
{"id":"HIST315.","title":"American Environmental HIstory"},
{"id":"HIST320.","title":"Topics:Ancient Rome"},
{"id":"HIST322.","title":"Intro-Historical Admin"},
{"id":"HIST323.","title":"Intro to Archival Practices"},
{"id":"HIST345.","title":"Medieval Europe"},
{"id":"HIST349.","title":"Eur, Napoleon to WWI"},
{"id":"HIST376.","title":"Middle East since 1500"},
{"id":"HIST410.","title":"Topics:History of Japan"},
{"id":"HIST432.","title":"The American West"},
{"id":"HIST456.","title":"Race, Power, and Violence"},
{"id":"HIST490.","title":"Intern/Spec St-History"},
{"id":"HIST498.","title":"Sem-U.S. Childhood & Youth"},
{"id":"HIST510.","title":"Topics:History of Japan"},
{"id":"HIST598.","title":"Sem-U.S. Childhood & Youth"},
{"id":"MUS162.","title":"Music Theory II"},
{"id":"MUS172.","title":"Aural Skills II"},
{"id":"MUS182.","title":"Class Piano II"},
{"id":"MUS200.","title":"USI Chamber Choir"},
{"id":"MUS202.","title":"Intro to Music"},
{"id":"MUS210.","title":"Applied Voice"},
{"id":"MUS219.","title":"USI Women's Choir"},
{"id":"MUS290.","title":"USI Jazz Ensemble"},
{"id":"MUS382.","title":"Music History II"},
{"id":"THTR101.","title":"Intro to Theatre"},
{"id":"THTR120.","title":"Theatre Lab I"},
{"id":"THTR150.","title":"Practicum in Dance"},
{"id":"THTR224.","title":"Production Technology"},
{"id":"THTR243.","title":"Lighting Technology"},
{"id":"THTR251.","title":"Fundamentals of Acting"},
{"id":"THTR252.","title":"Fundamentals of Scene Study"},
{"id":"THTR253.","title":"Voice and Diction"},
{"id":"THTR260.","title":"Intermediate Dance Practicum"},
{"id":"THTR307.","title":"Period Styles for the Theatre"},
{"id":"THTR320.","title":"Theatre Lab II"},
{"id":"THTR337.","title":"Costume Design I"},
{"id":"THTR351.","title":"Acting for the Modern Stage"},
{"id":"THTR360.","title":"Pract in Dance, Advanced Level"},
{"id":"THTR361.","title":"Playwriting I"},
{"id":"THTR372.","title":"Theatre History II"},
{"id":"THTR400.","title":"Ind St-Theatre Arts"},
{"id":"THTR420.","title":"Theatre Laboratory III"},
{"id":"THTR437.","title":"Advanced Costume Design"},
{"id":"THTR499.","title":"ST-Paints & Props"},
{"id":"PA645.","title":"Prg Eval Public/Nonprofit Sect"},
{"id":"PA663.","title":"Strategic Planning"},
{"id":"PA689.","title":"Ind Study In Public Admin"},
{"id":"PA695.","title":"Internship in Public Agency"},
{"id":"PA697.","title":"Capstone Seminar-Public Admin"},
{"id":"PA699.","title":"Thesis in Public Admin"},
{"id":"PHIL101.","title":"Introduction to Philosophy"},
{"id":"PHIL201.","title":"Intro to Ethics"},
{"id":"PHIL201.","title":"IntroEthics-SvcLdr/InnvEntrLLC"},
{"id":"PHIL205.","title":"Intro to Logic"},
{"id":"PHIL251.","title":"Intro Study of Religions"},
{"id":"PHIL351.","title":"Hist-Philosophy II"},
{"id":"PHIL366.","title":"Environmental Ethics"},
{"id":"PHIL377.","title":"Cognitive Science"},
{"id":"PHIL420.","title":"Theory of Knowledge"},
{"id":"PHIL499.","title":"Independent Study: Philosophy"},
{"id":"POLS102.","title":"Intr American Polit"},
{"id":"POLS104.","title":"Intro to Public Administration"},
{"id":"POLS208.","title":"Law,Courts,&Justice"},
{"id":"POLS232.","title":"Research Design/Data Collectn"},
{"id":"POLS271.","title":"Internatl Politics"},
{"id":"POLS281.","title":"Comparative Politics"},
{"id":"POLS309.","title":"Amer Politicl Theory"},
{"id":"POLS316.","title":"Religion and Politics"},
{"id":"POLS332.","title":"Statistics & Data Analysis"},
{"id":"POLS351.","title":"Leadership Bureaucratic Orgs"},
{"id":"POLS371.","title":"The United Nations"},
{"id":"POLS406.","title":"Constitutionl Rights"},
{"id":"POLS490.","title":"Political Science Internship"},
{"id":"POLS497.","title":"Legislative Internship"},
{"id":"POLS498.","title":"Research Politcl Sci"},
{"id":"PSY201.","title":"Intro to Psychology"},
{"id":"PSY202.","title":"Orient to Major in Psy"},
{"id":"PSY261.","title":"Lifespan Develop Psy"},
{"id":"PSY273.","title":"Social Psychology"},
{"id":"PSY298.","title":"Human Sexuality"},
{"id":"PSY303.","title":"Research Methods & Stats I"},
{"id":"PSY304.","title":"Scientific Literacy"},
{"id":"PSY312.","title":"Sensation & Perception"},
{"id":"PSY322.","title":"Abnormal Psychology"},
{"id":"PSY332.","title":"Forensic Psychology"},
{"id":"PSY353.","title":"Research Methods & Stats II"},
{"id":"PSY361.","title":"Child & Adolescent Psychology"},
{"id":"PSY362.","title":"Clinical Psychology"},
{"id":"PSY371.","title":"Adulthood & Aging"},
{"id":"PSY376.","title":"Industrial Psychology"},
{"id":"PSY377.","title":"Sport Psychology"},
{"id":"PSY381.","title":"Psychology of Gender"},
{"id":"PSY385.","title":"Physiological Psych"},
{"id":"PSY386.","title":"Psycholinguistics"},
{"id":"PSY400.","title":"ST-Psychology Of Fear"},
{"id":"PSY400.","title":"ST-Theories of Intelligence"},
{"id":"PSY400.","title":"ST-Theory of Knowledge"},
{"id":"PSY422.","title":"Psychopath in Children/Adolesc"},
{"id":"PSY425.","title":"Cognitive Psychology"},
{"id":"PSY462.","title":"Psychol of Personality"},
{"id":"PSY497.","title":"Capstone Psy:Hist and Syst"},
{"id":"PSY498.","title":"Internship"},
{"id":"PSY499.","title":"Individual Study-Psy"},
{"id":"SOC121.","title":"Principles Sociology"},
{"id":"SOC231.","title":"Social Problems I"},
{"id":"SOC321.","title":"Sociological Aspects of Aging"},
{"id":"SOC343.","title":"Death, Dying, & Bereavement"},
{"id":"SOC372.","title":"Contemp Sociological Theory"},
{"id":"SOC391.","title":"Sociology Research Design"},
{"id":"SOC392.","title":"Statistics for Soc Research"},
{"id":"SOC431.","title":"Gender and Society"},
{"id":"SOC463.","title":"Wealth & Poverty"},
{"id":"SOC489.","title":"Indep St-Sociology"},
{"id":"SOC499.","title":"Internship-Sociology"},
{"id":"SOCW221.","title":"Intro to Social Work"},
{"id":"SOCW222.","title":"Social Welfare"},
{"id":"SOCW239.","title":"Hum Behav Soc Environ"},
{"id":"SOCW241.","title":"Soc Work Intervention"},
{"id":"SOCW323.","title":"Socw Practice&Elderly"},
{"id":"SOCW326.","title":"Social Work Research"},
{"id":"SOCW341.","title":"Soc Welfare Policy"},
{"id":"SOCW343.","title":"Socw Prac Micro Sys II"},
{"id":"SOCW354.","title":"Counseling & Tx Mod in Add/Rec"},
{"id":"SOCW400.","title":"ST-Child Abuse & Neglect"},
{"id":"SOCW400.","title":"ST-Intro to Sign Language"},
{"id":"SOCW400.","title":"ST-Intro to Sign Language II"},
{"id":"SOCW411.","title":"Soc Work Practicum II"},
{"id":"SOCW412.","title":"Soc Work Practice II"},
{"id":"SOCW413.","title":"Capstone Proj:Research&Service"},
{"id":"SOCW505.","title":"Hum Beh&Soc Env II"},
{"id":"SOCW508.","title":"Micro Sys:Ind,Groups&Fam"},
{"id":"SOCW509.","title":"Macro Sys:Inst&Comm Sys"},
{"id":"SOCW602.","title":"Clinical Plan&TrtmntProcess SW"},
{"id":"SOCW605.","title":"Practice Evaluation"},
{"id":"SOCW610.","title":"Human Diversity in SW Practice"},
{"id":"SOCW611.","title":"Grad Fld Placemnt II"},
{"id":"SOCW612.","title":"Grad Fld Seminar II"},
{"id":"SOCW653.","title":"AddictionCounseling Thry/Tech"},
{"id":"SOCW661.","title":"Group Addiction Counseling"},
{"id":"SOCW671.","title":"Social Work Mngt I"},
{"id":"ANTH111.","title":"Intro to Cultural Anthropology"},
{"id":"ANTH121.","title":"Intro to Archaeology"},
{"id":"ANTH131.","title":"Intro to Physical Anthropology"},
{"id":"ANTH221.","title":"Archaeology: Fact and Fiction"},
{"id":"ANTH343.","title":"Archaeology of Mesoamerica"},
{"id":"ANTH354.","title":"Human Osteology"},
{"id":"ANTH475.","title":"Hist Anthropological Thought"},
{"id":"ANTH489.","title":"Ind Study in Anthropology"},
{"id":"ANTH499.","title":"Internship in Anthropology"},
{"id":"FREN102.","title":"Beginning French II"},
{"id":"FREN204.","title":"Intermediate French II"},
{"id":"FREN205.","title":"Cultural Contexts Conversation"},
{"id":"FREN307.","title":"Advanced French II"},
{"id":"FREN308.","title":"Francophone Cultures"},
{"id":"FREN490.","title":"ST:La bande dessinée"},
{"id":"FREN499.","title":"Individual Study in French"},
{"id":"GERM101.","title":"Beginning German I"},
{"id":"GERM102.","title":"Beginning German II"},
{"id":"GERM204.","title":"Intermediate German II"},
{"id":"GERM205.","title":"Cultural Contexts Conversation"},
{"id":"GERM275.","title":"The German Film"},
{"id":"GERM307.","title":"Advanced German II"},
{"id":"GERM335.","title":"German for the Professional"},
{"id":"GERM499.","title":"Individual Study in German"},
{"id":"JPN102.","title":"Beginning Japanese II"},
{"id":"JPN204.","title":"Intermediate Japanese II"},
{"id":"JPN307.","title":"Advanced Japanese II"},
{"id":"LATN102.","title":"Beginning Latin II"},
{"id":"SPAN101.","title":"Beginning Spanish I"},
{"id":"SPAN102.","title":"Beginning Spanish II"},
{"id":"SPAN203.","title":"Intermediate Spanish I"},
{"id":"SPAN204.","title":"Intermediate Spanish II"},
{"id":"SPAN205.","title":"Cultural Contexts Conversation"},
{"id":"SPAN306.","title":"Advanced Spanish I"},
{"id":"SPAN307.","title":"Advanced Spanish II"},
{"id":"SPAN308.","title":"Hispanic Cultures"},
{"id":"SPAN460.","title":"Internship in Spanish"},
{"id":"SPAN490.","title":"ST:Realist Narrative of Spain"},
{"id":"SPAN499.","title":"Individual Study in Spanish"},
{"id":"WLC155.","title":"Cultural Awareness"},
{"id":"WLC422.","title":"Cultural Awareness Training"},
{"id":"WLC522.","title":"Cultural Awareness Training"},
{"id":"WLC612.","title":"Language Learning Technology"},
{"id":"WLC660.","title":"Practicum and Internship"},
{"id":"WLC698.","title":"Comp Oral & Written Exams"},
{"id":"WLC699.","title":"Comprehensive Oral Exam&Thesis"},
{"id":"DTAS111.","title":"Oral Pathology"},
{"id":"DTAS158.","title":"Mngt,Ethics,Jurisprud"},
{"id":"DTAS166.","title":"Human Systems II"},
{"id":"DTAS175.","title":"Preventive Dentistry"},
{"id":"DTAS181.","title":"Clinical Science II"},
{"id":"DTAS183.","title":"Clinical Science App."},
{"id":"DTAS291.","title":"Expanded Func Dental Assisting"},
{"id":"DTHY313.","title":"Anatomy of the Head & Neck"},
{"id":"DTHY315.","title":"Oral Embryology & Histology"},
{"id":"DTHY318.","title":"Preventive Oral Health II"},
{"id":"DTHY322.","title":"Periodontology"},
{"id":"DTHY351.","title":"Dental Hygiene Theory II"},
{"id":"DTHY352.","title":"Dental Hygiene Clinic II"},
{"id":"DTHY402.","title":"Clinical Management II"},
{"id":"DTHY412.","title":"Community Oral Hlth Practicum"},
{"id":"DTHY451.","title":"Dental Hygiene Theory IV"},
{"id":"DTHY452.","title":"Dental Hygiene Clinic IV"},
{"id":"DTHY457.","title":"Prof/Curr Issues Oral Hlth Cre"},
{"id":"NUTR203.","title":"Intro Food, Nutr & Dietetic"},
{"id":"NUTR205.","title":"Profession of Dietetics"},
{"id":"NUTR285.","title":"Mngt Fundamntls-Food & Nutr"},
{"id":"NUTR376.","title":"Princpl/App in Nutrition"},
{"id":"NUTR378.","title":"Nutr-Fitness & Sport"},
{"id":"NUTR383.","title":"PractAppl &Eval Food Prep Nutr"},
{"id":"NUTR384.","title":"Principles & Appl/Food Sci"},
{"id":"NUTR396.","title":"Nutrition Through Life Cycle"},
{"id":"NUTR397.","title":"Nutr/Hlth Promo &Dis Prev"},
{"id":"NUTR420.","title":"Practicum Food,Nutr,Wellness"},
{"id":"NUTR427.","title":"Sr Project-Food & Nutrition"},
{"id":"NUTR452.","title":"Nutrition & Hlth Assessment"},
{"id":"NUTR465.","title":"Community Nutrition"},
{"id":"NUTR481.","title":"Nutrition Ed, Counseling, Thry"},
{"id":"NUTR486.","title":"Medical Nutrition Therapy II"},
{"id":"NUTR496.","title":"Ldrship/Prof Iss-Food &Nutr"},
{"id":"HI301.","title":"Health Informatics"},
{"id":"HI302.","title":"Enterprise Systems and EHRs"},
{"id":"HI303.","title":"HIE and Interoperability"},
{"id":"HI401.","title":"Project Management for HIT"},
{"id":"HI402.","title":"Healthcare Data Mngmt & Analyt"},
{"id":"HI403.","title":"Healthcare Coding"},
{"id":"HI410.","title":"Internship in HIIM"},
{"id":"GERO215.","title":"Hlth Care Aspects Gerontology"},
{"id":"GERO312.","title":"Living Well Through Lifespan"},
{"id":"GERO321.","title":"Sociological Aspects of Aging"},
{"id":"GERO343.","title":"Death, Dying, & Bereavement"},
{"id":"GERO425.","title":"Health Facilites Admin"},
{"id":"HA407.","title":"Issues Hlth Care Ldrship"},
{"id":"HA411.","title":"Health Care Leadership"},
{"id":"HA421.","title":"Health Care Finance"},
{"id":"HA431.","title":"Health Care Quality"},
{"id":"HA496.","title":"Mktg/Competitive Str Hlth Care"},
{"id":"HP115.","title":"Medical Terminology"},
{"id":"HP211.","title":"Health Care System"},
{"id":"HP225.","title":"Pharmacology and Therapeutics"},
{"id":"HP236.","title":"Eastrn Med & Alt/Comp Hlth"},
{"id":"HP255.","title":"Human Sexuality: Health Persp"},
{"id":"HP265.","title":"Alcohol/Drug Abuse: Hlth Persp"},
{"id":"HP302.","title":"Biostatistics"},
{"id":"HP306.","title":"Evaluation/Applicatn Wrkst"},
{"id":"HP377.","title":"Foundations for Hlth Promotion"},
{"id":"HP378.","title":"Comm Hlth Educ Methods"},
{"id":"HP383.","title":"Stress Management"},
{"id":"HP475.","title":"Professionalism in Health Care"},
{"id":"HP480.","title":"Internship in Hlth Professions"},
{"id":"HP490.","title":"ST-Adv. Leadership Research"},
{"id":"HP490.","title":"ST-Human Resource Management"},
{"id":"HP490.","title":"ST-Independent Study"},
{"id":"HP498.","title":"Curr Cncpts-Hlth Prof"},
{"id":"HP499.","title":"Independent Study in Hlth Serv"},
{"id":"HP690.","title":"ST: AIT"},
{"id":"IPH356.","title":"Ethics & Hlth Care Plural Soc"},
{"id":"IPH401.","title":"Intprfssl Persp on Global Hlth"},
{"id":"MHA624.","title":"Economic Policies-Hlth Care"},
{"id":"MHA625.","title":"Mktg & Comp Strat-Hlth Care"},
{"id":"MHA628.","title":"Epidemiology"},
{"id":"MHA633.","title":"Human Res& Labor Rel Mngt"},
{"id":"MHA636.","title":"Admin Effectivenss-Hlth Care"},
{"id":"MHA642.","title":"Health Informatics"},
{"id":"MHA643.","title":"Health Care Finance"},
{"id":"MHA651.","title":"Capstone I: Planning"},
{"id":"MHA657X","title":"Reg Standrds Post-Acute Care"},
{"id":"MHA658X","title":"Finance and Post-Acute Care"},
{"id":"MHA662X","title":"Admin-in-Training Residency II"},
{"id":"PH284.","title":"Public Health"},
{"id":"PH483.","title":"Hlth Around Us:Cncpts Env Hlth"},
{"id":"PH485.","title":"Epidemiology"},
{"id":"PH486.","title":"Public Health Administration"},
{"id":"NURS246.","title":"Intro to Professional Nursing"},
{"id":"NURS247.","title":"Health Assessment"},
{"id":"NURS251.","title":"Intro to Population Health"},
{"id":"NURS307.","title":"Health Assessment for RN's"},
{"id":"NURS327.","title":"Transitions to Adv Nursing"},
{"id":"NURS331.","title":"Care Coordination in Prof Nurs"},
{"id":"NURS333.","title":"Nursing Informatics"},
{"id":"NURS337.","title":"Population Health & Prof NURS"},
{"id":"NURS362.","title":"Care Coord & Hlthcare Delivery"},
{"id":"NURS363.","title":"Mental Health Nursing"},
{"id":"NURS364.","title":"Care of Children and Families"},
{"id":"NURS367.","title":"Application Evidnce Bsed Pract"},
{"id":"NURS368.","title":"Nursing Care of Adults II"},
{"id":"NURS457.","title":"Population-Focused Care for RN"},
{"id":"NURS487.","title":"Ldrshp in Nrsg & Hlthcare Orgs"},
{"id":"NURS488.","title":"Leadership in Care Delivery"},
{"id":"NURS498.","title":"Internship-Prof Nursing"},
{"id":"NURS604.","title":"Populatn Based Care&Hlth Polcy"},
{"id":"NURS605.","title":"Hlthcare Informatics-Adv Nurs"},
{"id":"NURS622.","title":"Clinical Pharmacology"},
{"id":"NURS634.","title":"Fin Mngt in Hlthcare Orgs"},
{"id":"NURS638.","title":"Nurs Leadrshp/Mngt Culm Pract"},
{"id":"NURS646.","title":"Adv Nurs Assmnt& Intrvtn II"},
{"id":"NURS655.","title":"Primary Care Nurs-Fam II"},
{"id":"NURS656.","title":"Family Nurse Pract Practicum"},
{"id":"NURS663.","title":"PsychMental HlthCare-FamLfspII"},
{"id":"NURS664.","title":"Psych Mental Hlth Care-Fam III"},
{"id":"NURS672.","title":"Teaching Strat for Nurs Ed"},
{"id":"NURS675.","title":"Mngt Acutely Ill Adult-Gero II"},
{"id":"NURS685.","title":"Primary Care Adults/Elders II"},
{"id":"NURS713.","title":"Theory and Practice"},
{"id":"NURS725.","title":"Resource Util Nurs Hlth Care"},
{"id":"NURS732.","title":"Wellness Promotion"},
{"id":"NURS733.","title":"EvidenceBased Practice Seminar"},
{"id":"NURS734.","title":"Patient Mngt Clinical Appl Sem"},
{"id":"NURS772.","title":"Clinical Education"},
{"id":"NURS855.","title":"Synthesis of Nursing Practice"},
{"id":"NURS866.","title":"DNP Project Proposal"},
{"id":"NURS868.","title":"Project III: Implementation"},
{"id":"NURS871.","title":"Project IV: Data Analysis"},
{"id":"NURS872.","title":"Project V: Project Report"},
{"id":"NURS873.","title":"Project VI: Dissemination"},
{"id":"NURS874X","title":"DNP Proj 1:Planing and Implemt"},
{"id":"OT641.","title":"Occupational Therapy Research"},
{"id":"OT643.","title":"Occup Consider-Acts Daily Liv"},
{"id":"OT657.","title":"Specialized Eval Strategies"},
{"id":"OT663.","title":"Occupation Centered Practice"},
{"id":"OT671.","title":"Occupational Therapy Leadrship"},
{"id":"OT690.","title":"Specialized Topics"},
{"id":"OT696.","title":"Professional Fieldwork I"},
{"id":"OT697.","title":"Professional Fieldwork II"},
{"id":"OTA214.","title":"Pathophyslgy& Conditions II"},
{"id":"OTA242.","title":"Occup Perf Components II"},
{"id":"OTA297.","title":"Practicum Seminar A"},
{"id":"OTA343.","title":"Occup Performance Areas I"},
{"id":"OTA397.","title":"Technical Fieldwork A"},
{"id":"OTA398.","title":"Technical Fieldwork B"},
{"id":"DMS301.","title":"Patient Care Im Sci Profession"},
{"id":"DMS312.","title":"Intro DMS Princ &Procedures"},
{"id":"DMS313.","title":"Sonographic Sectnl Anatomy"},
{"id":"DMS331.","title":"Sonographic Phys&Instrum II"},
{"id":"DMS332.","title":"Small Parts Peds Spec Exams"},
{"id":"DMS333.","title":"Critique, Cl Corr & Case An"},
{"id":"DMS337.","title":"Clinical Prac II - General"},
{"id":"DMS436.","title":"Sonographic Profesnl Adv Sem"},
{"id":"DVT421.","title":"Vascular Procedures II"},
{"id":"DVT437.","title":"Clinical Prac II - Vascular"},
{"id":"ECHO421.","title":"Fetal Echocardiography"},
{"id":"ECHO437.","title":"Clinical Practice II Cardiac"},
{"id":"RADT196.","title":"Orient Radiologic/Imag Sci"},
{"id":"RADT307.","title":"Crit Thnk Sklls-Radiography"},
{"id":"RADT308.","title":"Radiographic Procedures I"},
{"id":"RADT311.","title":"Intro Radiologic Technology"},
{"id":"RADT318.","title":"Radiographic Procedures IV"},
{"id":"RADT335.","title":"Radiation Biology"},
{"id":"RADT337.","title":"Intro Invasive Imaging Procedr"},
{"id":"RADT353.","title":"Clinical Practicum III"},
{"id":"RADT355.","title":"Adv Patient Care for Imag Prof"},
{"id":"RADT413.","title":"CT/MRI Procedures I"},
{"id":"RADT415.","title":"CT/MRI Procedures II"},
{"id":"RADT416.","title":"Clinical VIII-CT/MRI"},
{"id":"RADT427.","title":"Digital Rad & Img Informatics"},
{"id":"RADT455.","title":"Clinical Practicum VI"},
{"id":"RADT465.","title":"Directed Study Rad Tech"},
{"id":"RADT491.","title":"Prof Devel & Literacy Img Sci"},
{"id":"REST201.","title":"Intro to Respiratory Therapy"},
{"id":"REST211.","title":"Resp Therapy Modalities I"},
{"id":"REST216.","title":"Cardiopulmonary A&P I"},
{"id":"REST223.","title":"Intro to Pulmonary Diseases"},
{"id":"REST226.","title":"Cardiopulmonary Pharmacology"},
{"id":"REST323.","title":"Mechanical Ventilation II"},
{"id":"REST361.","title":"Emrgency Resp Ther Mgt"},
{"id":"REST362.","title":"Ped and Newborn Resp Ther"},
{"id":"REST363.","title":"Pulmonary Diagnostics"},
{"id":"REST382.","title":"Clinical Practice Resp II"},
{"id":"REST451.","title":"Mgt and Ldrshp in Resp Therapy"},
{"id":"REST452.","title":"Intro to Research Resp Therapy"},
{"id":"REST453.","title":"Resp Therapy Disease Mgt"},
{"id":"REST454.","title":"Advanced Critical Care"},
{"id":"REST455.","title":"Pul Rehab and Geriatrics"},
{"id":"REST456.","title":"Prof Issues in Resp Ther"},
{"id":"REST460.","title":"Independ Study Resp Therapy"},
{"id":"REST492.","title":"Clinical Practice Resp IV"},
{"id":"REST499.","title":"Special Topics Resp Therapy"},
{"id":"NURS736.","title":"Clinical Appl Pharma"},
{"id":"MS102.","title":"Basic Leadership"},
{"id":"MS202.","title":"Leadrshp, Tactics & Offcrshp"},
{"id":"MS242.","title":"U.S. Miltry Hist II(1900-Pres)"},
{"id":"MS302.","title":"Small Unit Operation"},
{"id":"MS402.","title":"Transition to Lieutenant"},
{"id":"MS499.","title":"IND ST:Logistics & Support"},
{"id":"BIOL105.","title":"Biol Human Concern"},
{"id":"BIOL108.","title":"Biology for Educators"},
{"id":"BIOL112.","title":"Ethnobotany with Lab"},
{"id":"BIOL121.","title":"Human Anat&Phys I"},
{"id":"BIOL122.","title":"Human Anat&Phys II"},
{"id":"BIOL122.","title":"Human Anat&Phys II-N&HP LLC"},
{"id":"BIOL133.","title":"Biological Concepts"},
{"id":"BIOL141.","title":"Principles of Biology"},
{"id":"BIOL151.","title":"Botany"},
{"id":"BIOL152.","title":"Zoology"},
{"id":"BIOL215.","title":"Ecology"},
{"id":"BIOL251.","title":"Environmntl Conserv"},
{"id":"BIOL272.","title":"Medical Microbiology"},
{"id":"BIOL315.","title":"Ornithology"},
{"id":"BIOL333.","title":"Animal Physiology"},
{"id":"BIOL334.","title":"Cell Biology"},
{"id":"BIOL375.","title":"Microbiology"},
{"id":"BIOL376.","title":"Lab in Microbiology"},
{"id":"BIOL382.","title":"Genetics"},
{"id":"BIOL432.","title":"Cancer Biology"},
{"id":"BIOL434.","title":"Macromolecules & Metabolism"},
{"id":"BIOL452.","title":"Biology/Env Sci of Global Chng"},
{"id":"BIOL461.","title":"Embryology and Development"},
{"id":"BIOL481.","title":"Organic Evolution"},
{"id":"BIOL491.","title":"ST-Special Topics in Microbiol"},
{"id":"CHEM103.","title":"Molecules, Matter and Me"},
{"id":"CHEM107.","title":"Elements in Everyday Chem"},
{"id":"CHEM108.","title":"Chemistry for Educators"},
{"id":"CHEM141.","title":"Principles-Chemistry"},
{"id":"CHEM142.","title":"Survey of General Chemistry"},
{"id":"CHEM143.","title":"Survey Organic & Biochemistry"},
{"id":"CHEM218.","title":"Chemistry Seminar I"},
{"id":"CHEM241.","title":"Organic/Biochem Princpls"},
{"id":"CHEM261.","title":"General Chemistry I"},
{"id":"CHEM262.","title":"General Chemistry II"},
{"id":"CHEM321.","title":"Quant Analysis"},
{"id":"CHEM353.","title":"Organic Chemistry I"},
{"id":"CHEM354.","title":"Organic Chemistry II"},
{"id":"CHEM418.","title":"Chemistry Seminar III"},
{"id":"CHEM432.","title":"Biochemistry II"},
{"id":"CHEM441.","title":"Inorganic Chemistry"},
{"id":"CHEM451.","title":"Polymer Chemistry"},
{"id":"CHEM462.","title":"Physical Chem II"},
{"id":"CHEM490.","title":"Undrgrad Tch Exp-Chemstr"},
{"id":"CHEM499.","title":"Intro Research-Chem"},
{"id":"CHEM541.","title":"Inorganic Chemistry"},
{"id":"CE451.","title":"Water Resrces & Hydro Design"},
{"id":"CE461.","title":"Reinforced Concrete Design"},
{"id":"CE471.","title":"CE Design and Planning"},
{"id":"CE481.","title":"Geotechnical Engr Design"},
{"id":"ECE217.","title":"Fundamentals Comp Programming"},
{"id":"ECE241.","title":"Digital Logic"},
{"id":"ECE255.","title":"Electric Circuits"},
{"id":"ECE311.","title":"Electromagnetics"},
{"id":"ECE343.","title":"Electronics"},
{"id":"ECE344.","title":"Integrated Circuits"},
{"id":"ECE347.","title":"Microcomputer Engineering"},
{"id":"ECE377.","title":"Printed Circuit Board Design"},
{"id":"ECE443.","title":"Linear Control Systems"},
{"id":"ECE445.","title":"Signals and Systems"},
{"id":"ECE447.","title":"Embedded Systems Design"},
{"id":"ECE449.","title":"Electrical Power"},
{"id":"ECE471.","title":"Electrical Systems Integration"},
{"id":"ENGR103.","title":"Prin of Problem Solving"},
{"id":"ENGR104.","title":"Applied Problem Solving"},
{"id":"ENGR107.","title":"Fundamentals of Engineering"},
{"id":"ENGR108.","title":"Sys Engrng and Freshmn Design"},
{"id":"ENGR121.","title":"Drafting & Annotation"},
{"id":"ENGR235.","title":"Statics"},
{"id":"ENGR275.","title":"Dynamics"},
{"id":"ENGR291.","title":"Exper Design & Tech Writing"},
{"id":"ENGR299.","title":"Co-op Experience"},
{"id":"ENGR305.","title":"Engineering Statistics"},
{"id":"ENGR335.","title":"Engineering Economics"},
{"id":"ENGR353.","title":"Entrprnr Feasibility Anlys"},
{"id":"ENGR355.","title":"Strength of Materials"},
{"id":"ENGR362.","title":"Manufacturing Processes I"},
{"id":"ENGR375.","title":"Fluid Mechanics"},
{"id":"ENGR382.","title":"SCADA Systems Design"},
{"id":"ENGR390.","title":"Special Design Projects"},
{"id":"ENGR417.","title":"Project Management"},
{"id":"ENGR471.","title":"Engineering Design & Anlysis"},
{"id":"ENGR491.","title":"Senior Design"},
{"id":"ENGR499.","title":"Independent Study/Research"},
{"id":"IM651.","title":"Analyt Tech for Econ Eval"},
{"id":"IM661.","title":"Supply Chain Management"},
{"id":"IM698.","title":"Individual Study"},
{"id":"IME412.","title":"Production/Inventory Cntrl Sys"},
{"id":"IME414.","title":"Process and Facility Design"},
{"id":"ME121.","title":"Solid Modeling"},
{"id":"ME364.","title":"Materials Science"},
{"id":"ME365.","title":"Modeling Dynamic Systems"},
{"id":"ME366.","title":"Dynamics of Machinery"},
{"id":"ME395.","title":"Intro to Finite Element Method"},
{"id":"ME463.","title":"Heat Transfer"},
{"id":"ME465.","title":"Fluid/Thermal Design"},
{"id":"TECH103.","title":"Materials & Processes"},
{"id":"TECH121.","title":"CAD Fundamentals"},
{"id":"TECH261.","title":"Fund of Instrumentation"},
{"id":"TECH275.","title":"Stats&Safety in Manufacturing"},
{"id":"TECH299.","title":"Co-op Experience"},
{"id":"TECH313.","title":"Mechanics and Strengths"},
{"id":"TECH343.","title":"Fluid Power Technology"},
{"id":"TECH367.","title":"Advanced Manufacturing"},
{"id":"TECH411.","title":"Materials"},
{"id":"TECH439.","title":"Spec Problems-Indust Suprvsn"},
{"id":"TECH471.","title":"Senior Project"},
{"id":"ASTR201.","title":"General Astronomy"},
{"id":"GEOG112.","title":"Earth System Science"},
{"id":"GEOG123.","title":"Phys Geog/Landscp Analysis US"},
{"id":"GEOG330.","title":"World Geography"},
{"id":"GEOL108.","title":"Earth-Space Sci for Educators"},
{"id":"GEOL121.","title":"Geology of Gemstones"},
{"id":"GEOL131.","title":"Geolgy,Environment& Society"},
{"id":"GEOL132.","title":"Volcanoes and Eruptions"},
{"id":"GEOL161.","title":"Introduction to Geology"},
{"id":"GEOL162.","title":"Historical Geology"},
{"id":"GEOL311.","title":"Concepts/Environmental Science"},
{"id":"GEOL316.","title":"Sediment& Stratigr"},
{"id":"GEOL345.","title":"Structural Geology"},
{"id":"GEOL390.","title":"Geological Field Excursion"},
{"id":"GEOL465.","title":"Intro to Geog Info Syst"},
{"id":"GEOL481.","title":"Advanced Environmental Geol"},
{"id":"PHYS101.","title":"Intro Physical Sci"},
{"id":"PHYS108.","title":"Physics for Educators"},
{"id":"PHYS175.","title":"General Physics I"},
{"id":"PHYS175L","title":"General Physics I Lab"},
{"id":"PHYS176.","title":"General Physics II"},
{"id":"PHYS176L","title":"General Physics II Lab"},
{"id":"PHYS205.","title":"Intermed Physics I"},
{"id":"PHYS205L","title":"Intermediate Physics I Lab"},
{"id":"PHYS206.","title":"Intermed Physics II"},
{"id":"PHYS206L","title":"Intermediate Physics II Lab"},
{"id":"PHYS371.","title":"Analytical Mechanics"},
{"id":"PHYS439.","title":"Thermodynamics"},
{"id":"PHYS481.","title":"Electromagnetic Fields II"},
{"id":"PHYS491.","title":"Physics Senior Thesis"},
{"id":"EXSC225.","title":"Fund of Strngth Training Techn"},
{"id":"EXSC283.","title":"Career Preparation for EXSC"},
{"id":"EXSC287.","title":"Technology in Exercise Science"},
{"id":"EXSC376.","title":"Behavioral Strategies of Exerc"},
{"id":"EXSC381.","title":"Care&Prevent Ath Injur"},
{"id":"EXSC383.","title":"Kinesiology"},
{"id":"EXSC392.","title":"Biomechanics & Motor Behavior"},
{"id":"EXSC394.","title":"Measurement & Evaluation"},
{"id":"EXSC396.","title":"Fitness Management"},
{"id":"EXSC397.","title":"Physiology of Exercise"},
{"id":"EXSC450.","title":"ST: Training Science"},
{"id":"EXSC450.","title":"ST:ACSM PT Review"},
{"id":"EXSC484.","title":"Exercise Testing & Prescrptn"},
{"id":"EXSC487.","title":"Prncpls/Appl Fitness Training"},
{"id":"EXSC488.","title":"Prgm Dsgn Diverse Populations"},
{"id":"EXSC496.","title":"Practicum in Exercise Science"},
{"id":"EXSC497.","title":"Practicum Strength & Condition"},
{"id":"KIN112.","title":"Badminton"},
{"id":"KIN118.","title":"Table Tennis"},
{"id":"KIN124.","title":"Introduction to Rock Climbing"},
{"id":"KIN125.","title":"Advanced Rock Climbing"},
{"id":"KIN162.","title":"Hiking&Outdoor Educ"},
{"id":"KIN162.","title":"Hiking&Outdoor Educ OtdrAdvLLC"},
{"id":"KIN182.","title":"Weight Training"},
{"id":"KIN185.","title":"Step Aerobics"},
{"id":"KIN192.","title":"ConceptWellness&Fit OtdrAdvLLC"},
{"id":"KIN192.","title":"Concepts of Wellness & Fitness"},
{"id":"KIN199.","title":"Spec Act: Flag Football"},
{"id":"KIN199.","title":"Spec Act: Yoga"},
{"id":"KIN199.","title":"Spec Act:Spinning"},
{"id":"KIN201.","title":"Sport, Exsc & Culture in Film"},
{"id":"KIN234.","title":"Officiating Volleyball"},
{"id":"KIN281.","title":"Personal Health Sci"},
{"id":"KIN282.","title":"CPR Hlthcr Prv & Spts Inj Prev"},
{"id":"KIN385.","title":"Methods-Group Exercise Instrct"},
{"id":"KIN393.","title":"Practicum in Kinesiology I"},
{"id":"KIN493.","title":"Practicum in Kinesiology II"},
{"id":"PET287.","title":"Phys Ed & Elem Schl Cls Tchr"},
{"id":"PET395.","title":"Curriculum Const in Phys Ed"},
{"id":"PET402.","title":"Controv Issues in Health Educ"},
{"id":"PET493.","title":"Emotional Hlth-Chldrn Sport"},
{"id":"SPTM223.","title":"Princ of Recreation"},
{"id":"SPTM228.","title":"Princ & Appls of Sport Mgmt"},
{"id":"SPTM283.","title":"Career Preparation for SPTM"},
{"id":"SPTM288.","title":"Intro to Sport Communication"},
{"id":"SPTM305.","title":"Practicum in Sport Management"},
{"id":"SPTM333.","title":"Sport Marketing"},
{"id":"SPTM334.","title":"Sport Event Management"},
{"id":"SPTM335.","title":"Sport Publicity and Promotion"},
{"id":"SPTM336.","title":"Governance & Ethics in Sport"},
{"id":"SPTM337.","title":"Publ Recreational Sports Admin"},
{"id":"SPTM353.","title":"Sport & Social Media"},
{"id":"SPTM451.","title":"Legal Aspects of Sport"},
{"id":"SPTM452.","title":"Sport Finance & Economics"},
{"id":"SPTM482.","title":"NCAA Compl & Inst Control"},
{"id":"SPTM491.","title":"Managing Sport Organizations"},
{"id":"SPTM492.","title":"Contemp Iss-Sport & Exercise"},
{"id":"SPTM498.","title":"Internship in Sport Management"},
{"id":"SPTM604.","title":"Sport Facility & Event Mgmt"},
{"id":"SPTM605.","title":"Dmnsns Dvrsty&Society Sprt Org"},
{"id":"SPTM664.","title":"Research Mths & Stats SprtMgmt"},
{"id":"MATH081.","title":"Foundations Quant Reasoning"},
{"id":"MATH082.","title":"Transitions to College Algebra"},
{"id":"MATH085.","title":"Foundations for College Alg"},
{"id":"MATH103.","title":"Math Elementary Teachers I"},
{"id":"MATH107.","title":"Fundmntls Math for Health Prof"},
{"id":"MATH111.","title":"College Algebra"},
{"id":"MATH112.","title":"Trigonometry"},
{"id":"MATH114.","title":"Quantitative Reasoning"},
{"id":"MATH115.","title":"Pre-Calculus Math"},
{"id":"MATH118.","title":"Comprehensive Pre-calculus"},
{"id":"MATH203.","title":"Math Elementary Teachers II"},
{"id":"MATH213.","title":"Algebraic Concpts for Teachers"},
{"id":"MATH215.","title":"Survey of Calculus"},
{"id":"MATH230.","title":"Calculus I"},
{"id":"MATH235.","title":"Calculus II"},
{"id":"MATH253.","title":"Intro-Mathematical Reasoning"},
{"id":"MATH291.","title":"Math for Secondary Teachers"},
{"id":"MATH331.","title":"Math Models w/Applicat"},
{"id":"MATH335.","title":"Calculus III"},
{"id":"MATH362.","title":"Linear Algebra"},
{"id":"MATH366.","title":"Differential Equations"},
{"id":"MATH392.","title":"Teaching Elem Sch Math"},
{"id":"MATH412.","title":"Mod Abstract Algebra I"},
{"id":"MATH434.","title":"Adv Differential Equat"},
{"id":"MATH437.","title":"Numerical Analysis"},
{"id":"MATH492.","title":"History of Mathematics"},
{"id":"MATH611.","title":"Analysis-Sec Teachers"},
{"id":"STAT238.","title":"Data Analysis/Probablty Teachr"},
{"id":"STAT241.","title":"Principles of Statistics"},
{"id":"STAT251.","title":"Principles of Biostatistics"},
{"id":"STAT255.","title":"Intro Statistical Computing"},
{"id":"STAT381.","title":"Categorical Data Analysis"},
{"id":"STAT441.","title":"Mathematicl Statistics"},
{"id":"STAT475.","title":"Time Series"},
{"id":"STAT495.","title":"Capstone Project in Statistics"},
{"id":"EDLE707.","title":"Srvy Qualitative Rsearch Mthds"},
{"id":"EDLE728.","title":"Eval School Eff Community Lens"},
{"id":"EDLE731.","title":"Analyzing Race, Class & Gender"},
{"id":"EDLE732.","title":"Creatng Cmmnty-Bsed Currc Chg"},
{"id":"EDLE758.","title":"School Finance"},
{"id":"EDLE764.","title":"Literacy/Tech in 21st Cent Sch"},
{"id":"EDUC203.","title":"Intro to Children's Lit-LLC"},
{"id":"EDUC203.","title":"Intro to Children's Literature"},
{"id":"EDUC206.","title":"Intro Exceptionalities-EducLLC"},
{"id":"EDUC206.","title":"Intro to Exceptionalities"},
{"id":"EDUC214.","title":"Instr Tech in Education"},
{"id":"EDUC221.","title":"Diversity and Equity-Education"},
{"id":"EDUC241.","title":"Early Childhood Educ"},
{"id":"EDUC242.","title":"Growth & Dev:Early Childhood"},
{"id":"EDUC246.","title":"Language & Lit Early Years"},
{"id":"EDUC256.","title":"Guidance of Young Children"},
{"id":"EDUC261.","title":"Special Needs Early Child Ed"},
{"id":"EDUC283.","title":"Explorations in Secondary Educ"},
{"id":"EDUC292.","title":"Explorations in Elem Educ"},
{"id":"EDUC313.","title":"Adv Studies in Children's Lit"},
{"id":"EDUC315.","title":"Mngt for Students w/Excp Needs"},
{"id":"EDUC340.","title":"ST: Aim4Excellence 1-3"},
{"id":"EDUC340.","title":"ST: Aim4Excellence 4-6"},
{"id":"EDUC351.","title":"Music,Movemt,Play,&Exprsv Arts"},
{"id":"EDUC354.","title":"Practicum in Elementary Educ"},
{"id":"EDUC356.","title":"TchgMthds Int ContAreas(Pre-P)"},
{"id":"EDUC357.","title":"Fine Arts in the Elem School"},
{"id":"EDUC373.","title":"Tching Science Elem School"},
{"id":"EDUC378.","title":"Literacy Methods"},
{"id":"EDUC381.","title":"Language, Literacy, & Culture"},
{"id":"EDUC382.","title":"Elem Analysis-Curr and Instrct"},
{"id":"EDUC383.","title":"Secndry Analysis-Curr/Pedagogy"},
{"id":"EDUC384.","title":"Advncd Clincl Exp-Sec Schls"},
{"id":"EDUC388.","title":"Teach Soc Studies-Elem"},
{"id":"EDUC398.","title":"Reading Methods"},
{"id":"EDUC408.","title":"Tching Read Stds w/Spec Needs"},
{"id":"EDUC411.","title":"Legal& Contemp Iss-Exc Needs"},
{"id":"EDUC412.","title":"Assessing Stds w/Special Needs"},
{"id":"EDUC413.","title":"Exceptional Needs Instru Strat"},
{"id":"EDUC418.","title":"Exceptional Need Inst Strat II"},
{"id":"EDUC421.","title":"Excpt Needs Practicum I:Elem"},
{"id":"EDUC422.","title":"Excpt NeedsPract II:Secndry"},
{"id":"EDUC423.","title":"Collaborative Partnerships"},
{"id":"EDUC424.","title":"Sprvsd Std Tching in Spec Educ"},
{"id":"EDUC431.","title":"Sprvsd Tchin Early Chldhd Educ"},
{"id":"EDUC437.","title":"Classrm Mngt Data-Based Dec"},
{"id":"EDUC455.","title":"Diagnostic Teachng of Readng"},
{"id":"EDUC458.","title":"Seminar in Professional Educ"},
{"id":"EDUC471.","title":"Supervisd Std Tchng Elem Educ"},
{"id":"EDUC473.","title":"Suprvsd Std Tching Sec or P-12"},
{"id":"EDUC490.","title":"ST-Aim4Excellence 7-9"},
{"id":"EDUC490.","title":"ST: Early Childhood Internship"},
{"id":"EDUC508.","title":"Tching Rdg to Stds w/ Sp Needs"},
{"id":"EDUC511.","title":"Found & Legal-Exceptnl Needs"},
{"id":"EDUC512.","title":"Assessing Stdnts w/Spec Needs"},
{"id":"EDUC513.","title":"Learng Strat-Studnts w/Excpt"},
{"id":"EDUC515.","title":"Mngt & Exptnlty:Env,Soc,Beh"},
{"id":"EDUC521.","title":"Exceptnl Needs: Practicum I"},
{"id":"EDUC523.","title":"Collaborative Partnerships"},
{"id":"EDUC680.","title":"Internship for School Leaders"},
{"id":"EDUC682.","title":"Issues in Educatnl Ldrship"},
{"id":"EDUC683.","title":"School Imprvmnt Models/Process"},
{"id":"EDUC684.","title":"Ethical/Legal Issues in Educ"},
{"id":"EDUC686.","title":"Mngt in the Principalship"},
{"id":"EDUC699.","title":"Supervised Research"},
{"id":"GENS105.","title":"College Study Strategies"},
{"id":"GENS111.","title":"Career Planning"},
{"id":"GENS111.","title":"Career Planning-ExpMjrsLLC"},
{"id":"GENS151.","title":"Academic Reading Strategies"},
{"id":"GENS199.","title":"ST:Defining Success"},
{"id":"GENS199.","title":"ST:Speed Reading"},
{"id":"GENS399.","title":"Field Experience"},
{"id":"GENS401.","title":"Career Readiness & Pro Dev"},
{"id":"GENS998.","title":"Full-Time Co-op Semester"},
{"id":"HONS291.","title":"Seminar in Medicine"},
{"id":"UNIV101.","title":"First Year Experience"},
{"id":"HONS129.","title":"Sem:Authentic Leadership"},
{"id":"HONS129.","title":"Sem:Avengers as Role Models"},
{"id":"HONS129.","title":"Sem:Exploring National Parks"},
{"id":"IEP092.","title":"IEP-Writing"},
{"id":"IEP093.","title":"IEP-Acad Skills with Grammar"},
{"id":"IEP094.","title":"IEP-Reading"},
{"id":"IEP095.","title":"IEP-Listening and Speaking"},
]
module.exports={data}