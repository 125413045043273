<template>
  <v-row align="center">
    <v-col class="text-center" cols="6" >
      <Button look="btnBackShortWG" @click="back()" height="39" width="147" />
    </v-col>
    <v-col class="text-center" cols="6" >
      <Button look="btnNextShortWP" @click="next()" height="39" width="147" />
    </v-col>
  </v-row>
</template>

<script>
import { backTermWizardPage } from "@/api/config";
import { mapGetters, mapMutations } from "vuex";
import Button from "@/components/Button";
import { reportEvent } from '@/utils'

export default {
  name: "TermReviewFooter",

  components: { Button },

  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
    }),
  },

  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    back() {
      // this.$vuetify.goTo(0);
      this.selectPage(backTermWizardPage(this.config, 'TermReview'));
      this.$forceUpdate();
    },
    next() {
      reportEvent(this, 'next_review', '---', this.selectedTerm.id)
      // this.$vuetify.goTo(0);
      this.selectPage("TermConfirm");
      this.$forceUpdate();
    },
  },
  created() {},
};
</script>
