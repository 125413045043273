import logo from './logo.svg'
import { terms, introText } from './terms'
import courses from './courses'
import { nick, name, domain, modalities, appDomain } from './static'
import courses2021summer from './courses2021summer'
import courses2021fall from './courses2021fall'
import courses2022spring from './courses2022spring'

const customer = {
    domain: domain,
    name: name,
    nick: nick,
    modalities: modalities,
    appDomain: appDomain,
    logo,
    terms,
    introText,
    courses,
    courses2021summer,
    courses2021fall,
    courses2021winter: [],
    courses2022spring,
    courses2022summer: [],
    courses2022fall: [],
    courses2022winter: [],
}

export {
    customer,
}