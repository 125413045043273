<template>
  <v-row align="center">
    <v-col class="text-center" cols="6" >
      <Button look="btnBackShortWG" @click="back()" height="39" width="147" />
    </v-col>
    <v-col class="text-center" cols="6" >
      <Button look="btnNextShortWP" @click="next()" height="39" width="147" />
    </v-col>
  </v-row>
</template>

<script>
import { nextTermWizardPage, backTermWizardPage } from "@/api/config";
import { mapGetters, mapMutations } from "vuex";
import Button from "@/components/Button";
import { uploadUserData } from '@/api/user'
import { reportEvent } from '@/utils'
// const logger = logdown("TermTimesFooter");
// logger.state.isEnabled = true;

export default {
  name: "TermTimesFooter",

  components: { Button },
  
  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
      userClient: "getUserClient",
    }),
  },

  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    next() {
      reportEvent(this, 'next_times', '---', this.selectedTerm.id)
      uploadUserData(this.userClient)
      // this.$vuetify.goTo(0);
      this.selectPage(nextTermWizardPage(this.config, 'TermTimes'));
      this.$forceUpdate();
    },
    back() {
      // this.$vuetify.goTo(0);
      this.selectPage(backTermWizardPage(this.config, 'TermTimes'));
      this.$forceUpdate();
    },
  },
  created() {},
};
</script>
