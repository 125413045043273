<template>
  <div>
    <div
      @click="onClick"
      :style="'height: ' + appWindowHeight + 'px;'"
      class="d-flex align-content-space-around flex-wrap justify-center"
    >
      <div>
        <div class="status-message">
          <Logo logoColor="brand" :width="234" :lock="false" />
        </div>
        <div class="status-message main">
          <div class='pa-2'>{{ error }}</div>
        </div>
        <div class="status-message hint" v-if="!errorLink">
          <div>
            try waiting a few moments and tap the screen to reload the app
          </div>
        </div>
        <div v-else class='pa-3 d-flex align-content-space-around flex-wrap justify-center'>
          <a target="_blank" rel="noopener" :href="errorLink">click here</a>
        </div>
      </div>
    </div>
    <v-container fluid v-if="unlockPasscode">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field autofocus @input="onChange" type="password">
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import { mapState, mapMutations, mapGetters } from "vuex";
import logdown from "logdown";
import lodash from "lodash";
const logger = logdown("Error");

export default {
  name: "Error",
  components: {
    Logo,
  },

  computed: {
    ...mapState({
      appWindowHeight: (state) => state.appWindowHeight,
    }),
    ...mapGetters({
      error: "getError",
      errorLink: "getErrorLink",
    }),
  },

  methods: {
    ...mapGetters({
      getConfig: "getConfig",
      runtimeOS: "getRuntimeOS",
      browserName: "getRuntimeBrowser",
      browserVersion: "getRuntimeBrowserVersion",
    }),
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    onClick: function() {
      logger.debug("onClick, reloading '/'");
      window.location = "/";
    },
    onChange: function(event) {
      logger.debug(event);
      if (("" + event).endsWith("iaj")) {
        lodash.set(window, ["localStorage", "ignoreLock"], true);
        // this.$vuetify.goTo(0);
        this.selectPage(this.getConfig().startPage);
      }
    },
  },

  data: () => {
    return {
      unlockPasscode: false,
    };
  },

  beforeUpdate() {
    // this.checkFullScreen(this.runtimeOS, window);
  },
};
</script>

<style scoped>
.main {
  font-size: 20px;
}
.hint {
  font-size: 10px;
}
.status-message {
  padding: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  color: #333333;
  display: flex;
  justify-content: center;
}
</style>
