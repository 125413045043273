<template>
  <v-row align="center">
    <v-col class="text-center" cols="12">
      <Button look="btnGetStartedWP" @click="next()" height="39" width="244" />
    </v-col>
  </v-row>
</template>

<script>
import { setFullScreen } from "@/utils";
import { mapGetters, mapMutations } from "vuex";
import Button from "@/components/Button";

// import logdown from "logdown";
// const logger = logdown("SplashFooter");
// logger.state.isEnabled = true;

export default {
  name: "SplashFooter",

  components: {
    Button,
  },

  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
    }),
  },

  methods: {
    ...mapGetters({
      getConfig: "getConfig",
      runtimeOS: "getRuntimeOS",
      browserName: "getRuntimeBrowser",
      browserVersion: "getRuntimeBrowserVersion",
    }),
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    checkFullScreen() {
      const tryFullScreen = this.getConfig().tryFullScreen;
      if (
        tryFullScreen &&
        (this.runtimeOS() == "iOS" || this.runtimeOS() == "Android")
      ) {
        setFullScreen(
          window,
          this.runtimeOS(),
          this.browserName(),
          this.browserVersion()
        );
        // toggleFullScreen();
      }
    },

    next() {
      // this.$vuetify.goTo(0);
      this.checkFullScreen()
      this.selectPage(this.getConfig().startPage);
    },
  },
  created() {},
};
</script>
