// import _ from 'lodash'
import logdown from 'logdown'
import axios from 'axios'
import { apiRoot } from '@/api/config'
import { getAuthKey } from "@/utils";
const logger = logdown('feedback')

function postFeedback(feedback) {
    logger.debug('postFeedback:', feedback)
    let data = {feedback:feedback}
    data['localKey'] = getAuthKey()
    logger.debug('localKey:', data['localKey'])
    const url = apiRoot() + '/feedback'
    logger.debug('postFeedback, api url:', url)
    return axios.post(url, data)
}

export {
    postFeedback,
}