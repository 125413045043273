/**
 * Term Catalog
*/

import {customer} from '@/api/config'

const _terms = customer.terms

function fetchTerms() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(_terms)
    }, 0)
  })
}

export {
  fetchTerms,
}