<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-row no-gutters v-if="subtitle" class="pa-0 banner-section">
      <v-col :cols="section != '' ? '10' : '12'">
        <div
          class="banner-section d-flex align-content-space-around flex-wrap pa-4 justify-left"
        >
          <div class="banner-text subtitle-banner">{{ bannerText }}</div>
        </div>
      </v-col>
      <v-col cols="2" @click="edit()" v-if="section != ''">
        <div
          class="banner-section d-flex align-content-space-around flex-wrap pa-4 justify-right"
        >
          <v-icon>$pageAndPen</v-icon>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters v-else class="banner-top">
      <v-col cols="12" v-if="confirmation == ''">
        <div
          class="banner-top d-flex align-content-space-around flex-wrap pa-4 justify-left"
        >
          <div class="banner-text">{{ bannerText }}</div>
        </div>
      </v-col>
      <v-col cols="9" v-if="confirmation != ''">
        <div
          class="banner-top d-flex align-content-space-around flex-wrap pa-4 justify-left"
        >
          <div class="banner-text">{{ selectedTerm.title }}</div>
        </div>
      </v-col>
      <v-col cols="3" v-if="confirmation != ''">
        <div
          class="banner-top d-flex align-content-space-around flex-wrap pa-4 justify-left"
        >
          <div class="banner-text">{{ confirmation }}</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logdown from "logdown";
const logger = logdown("PageBanner");
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "PageBanner",
  props: {
    bannerText: {
      type: String,
      default: "",
    },
    subtitle: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: "",
    },
    confirmation: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
    }),
  },
  data() {
    return {
      subHeaderColor: "#C4C4C4",
      cardHeight: 50,
    };
  },
  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    edit() {
      this.$emit("edit-click", { section: this.section });
      logger.log(`section: ${this.section}`)
      if ('quickRequestSlots' != this.section) {
        switch (this.section) {
          case "locations":
            this.selectPage("TermLocations");
            break;
          case "times":
            this.selectPage("TermTimes");
            break;
          default:
            this.selectPage("TermCourses");
        }
      }
    },
  },
  created() {
    this.$nextTick(() => {
      logger.debug("created, nextTick");
    });
  },
};
</script>

<style scoped>
.banner-section {
  background: #c4c4c4;
  /* font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px; */
  color: #000;
  height: 50px;
}
.banner-top {
  background: #4f4f4f;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  height: 50px;
}
.banner-text {
  color: white;
  /* color: orange; */
  font-size: 18px;
}
.subtitle-banner {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: black;
}
</style>
