<template>
  <div>
    <PageBanner
      :bannerText="banner.length > 0 ? banner : 'Courses to Reserve'"
      subtitle
      v-if="readOnly"
      :confirmation="getTermConfirmation()"
      :section="!userInfo ? '' : 'slots'"
    />
    <v-container class="py-0">
      <div v-for="(courseSlot, index) in courseSlots" :key="index">
        <v-row
          v-if="courseSlot['id'] || !readOnly"
          :class="
            'd-flex align-content-space-around flex-wrap px-2' +
              (!userInfo ||
              (readOnly && index + 1 == selectedTerm.reservationLimit)
                ? ''
                : ' row-border')
          "
          @click="!readOnly && selectCourse(courseSlot.slot)"
        >
          <v-col
            cols="2"
            class=" d-flex align-content-space-around flex-wrap px-3 align-center justify-center"
          >
            <div
              :class="'slot-badge ' + (!courseSlot['id'] ? 'empty' : 'full')"
            >
              <div>{{ courseSlot.slot }}</div>
            </div>
          </v-col>
          <v-col cols="9" v-if="!courseSlot['id']" class="px-1">
            <v-card
              height="46"
              flat
              class="d-flex align-content-space-around flex-wrap align-center"
            >
              <div style="color: #828282;">
                Select course
              </div>
            </v-card>
          </v-col>
          <v-col cols="3" v-if="!readOnly && courseSlot['id']" class="px-1">
            <v-card
              height="46"
              flat
              class="d-flex align-content-space-around flex-wrap align-center"
            >
              <div class="course-id">
                {{ courseSlot["id"] }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="6" v-if="!readOnly && courseSlot['id']" class="px-1">
            <v-card
              height="46"
              flat
              class="d-flex align-content-space-around flex-wrap align-center px-1"
            >
              <div class="course-title">
                {{ courseSlot["title"] }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="1" v-if="!readOnly" class="px-0">
            <v-card
              height="46"
              flat
              class="d-flex align-content-space-around flex-wrap px-0 mx-0 align-center justify-center"
              @click.stop="handleSelectOrDelete(index, courseSlot)"
            >
              <v-icon v-if="courseSlot['id']">$trashBin</v-icon>
              <v-icon v-else>$magnify</v-icon>
            </v-card>
          </v-col>
          <!-- alternative look for review -->
          <v-col cols="10" v-if="readOnly" class="px-1">
            <v-container class="py-0">
              <v-row no-gutters>
                <v-col cols="12" class="course-id-readonly">
                  {{ courseSlot["id"] }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="course-title">
                  {{ courseSlot["title"] }}
                </v-col>
              </v-row>
              <v-row no-gutters v-if="userInfo">
                <v-col cols="12" class="course-info">
                  {{ formatModalities(courseSlot, modalities) }}
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </div>
      <v-row no-gutters v-if="!readOnly" class="message-text">
        <v-col cols="12" class="pa-4" v-if="!selectedTerm.courseBullets">
          <p>
            {{ selectedTerm.bullets[0] }}
          </p>
          <p>
            {{ selectedTerm.bullets[1] }}
          </p>
          <p>
            {{ selectedTerm.bullets[2] }}
          </p>
        </v-col>
        <v-col cols="12" class="pa-4" v-else>
          <v-container class="pa-0">
            <v-row
              no-gutters
              class="pa-0"
              v-for="(bullet, index) in selectedTerm.courseBullets"
              :key="index"
            >
              <v-col cols="12" class="pt-0 pl-0 pr-0">
                
                <div :class="' ' + bullet.class">
                  {{ bullet.text }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete reservation?</v-card-title>

        <v-card-text>
          You are about to delete
          <b>
            {{
              courseSlotForDeletion
                ? courseSlots[courseSlotForDeletion - 1]["id"]
                  ? courseSlots[courseSlotForDeletion - 1]["id"]
                  : ""
                : ""
            }}</b
          >
          from your reservations.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDeletion(courseSlotForDeletion)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapMutations } from "vuex";
import { formatCourseSlots } from "@/utils.js";
import PageBanner from "@/components/PageBanner";
import logdown from "logdown";
const logger = logdown("TermCoursesSlots");
const _refreshDelayMillis = 200;

export default {
  name: "TermCoursesSlots",
  components: {
    PageBanner,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
    userInfo: {
      type: Boolean,
      default: true,
    },
    banner: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      deleteDialog: false,
      courseSlotForDeletion: undefined,
      componentKey: 0,
      slots: [],
      courseSlots: [
        // { slot: 1, courseId: "MAT 1A", title: "College Algebra I" },
        // { slot: 2, courseId: "ENG 1B", title: "English Composition" },
      ],
      testIndex: 10,
    };
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      modalities: "getAllModalities",
      userConfirmations: "getUserConfirmations",
    }),
  },
  methods: {
    ...mapGetters({
      getUserCourses: "getUserCourses",
    }),
    getTermConfirmation() {
      return _.get(this.userConfirmations, [this.selectedTerm.id]);
    },
    formatModalities(course, modalities) {
      logger.debug("formatModalities", course, modalities);
      const unselectedModalities =
        _.get(course, ["unselectedModalities"]) || [];
      const toFormat = _.filter(
        modalities,
        (modality) => unselectedModalities.indexOf(modality.id) == -1
      );
      return _.join(
        _.map(toFormat, (modality) => modality.id),
        ", "
      );
    },
    handleSelectOrDelete(index, courseSlot) {
      if (!this.readOnly) {
        if (courseSlot["id"]) {
          this.askToConfirmDeletion(index + 1);
        } else {
          this.selectCourse(courseSlot.slot);
        }
      }
    },
    askToConfirmDeletion(courseSlot) {
      this.courseSlotForDeletion = courseSlot;
      this.deleteDialog = true;
    },
    confirmDeletion(courseSlot) {
      let term = this.selectedTerm.id;
      this.unsetUserCourse({ term: term, slot: courseSlot });
      this.deleteDialog = false;
      this.$emit("course-deleted", { term: term, slot: courseSlot });
      setTimeout(() => {
        this.refreshSlots();
      }, _refreshDelayMillis);
      this.$forceUpdate();
    },
    selectCourse(courseSlot) {
      console.log("courseSlot: " + courseSlot);
      this.selectSlot(courseSlot);
      this.selectPage("SelectACourse");
    },
    ...mapMutations({
      selectPage: "setSelectedPage",
      selectSlot: "setSelectedSlot",
      unsetUserCourse: "unsetUserCourse",
    }),
    refreshSlots() {
      formatCourseSlots(
        this.courseSlots,
        this.getUserCourses(),
        this.selectedTerm.id,
        this.selectedTerm.reservationLimit
      );
    },
  },
  created() {
    this.refreshSlots();
  },
};
</script>

<style scoped>
.purple-text {
  color: #9b51e0;
}
.bold-text {
  font-weight: 900;
}
.special-text{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.normal-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #828282;
  font-weight: 400;
}

.message-text p {
  color: #333333;
}
.row-border {
  border-bottom: 1px solid #333333;
}
.slot-badge {
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty {
  background-color: #bdbdbd;
  border-style: solid #bdbdbd;
}
.full {
  background-color: #4f4f4f;
  border-style: solid #4f4f4f;
}
.course-id {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #282828;
}
.course-id-readonly {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #282828;
}
.course-title {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 16px; */
  letter-spacing: 0em;
  text-align: left;
  /* color: red; */
  color: #282828;
  overflow-y: clip;
}
.course-info {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 16px; */
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
}
</style>
