<template>
  <div fill>
    <v-snackbar v-model="snackbar" app centered>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="pa-0 ma-0">
      <v-row no-gutters>
        <v-col cols="12" class="tile-border">
          <v-card
            flat
            class="mx-auto align-center justify-center d-flex"
            height="75"
          >
            <v-container class="pa-3">
              <!-- <v-row align="center">
                <v-col cols="12">
                  <div>Student Id</div>
                </v-col>
              </v-row> -->
              <v-row align="center">
                <v-col cols="12">
                  <div>
                    <v-text-field class="id-text"
                      label="Student Id"
                      dense
                      hide-details
                      outlined
                      @keydown="onKeypress"
                      @blur="onBlur"
                      v-model="id"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
// import PageBanner from "@/components/PageBanner";
import logdown from "logdown";
import _ from "lodash";

const logger = logdown("StudentIdSelector");

export default {
  name: "StudentIdSelector",

  components: {
    // PageBanner,
  },

  props: ["studentId"],
  data: function() {
    return {
      snackbar: false,
      snackbarText: "Not available",
      id: this.studentId,
    };
  },

  computed: mapState({
    termsToSelect: (state) => state.allTerms,
    // selectedTerm: (state) => state.selectedTerm,
    userConfirmations: (state) => state.userConfirmations,
  }),

  methods: {
    ...mapGetters({
      getSelectedTerm: "getSelectedTerm",
    }),
    ...mapMutations({
      selectPage: "setSelectedPage",
      selectTerm: "setSelectedTerm",
    }),
    update(id) {
      this.$emit("student-id-update", { id: id });
    },
    onKeypress(event) {
      if ("Enter" == _.get(event, "key")) {
        logger.log(`Enter pressed,`, event);
        this.update(this.id);
      }
    },
    onBlur(event) {
      logger.log(`blur,`, event);
      this.update(this.id);
    },
  },
};
</script>

<style scoped>
.id-text{
  font-size: 24px;
}
.active-title {
  color: blue;
}
.tile-border {
  border-bottom: 1px solid #939393;
}
.term-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: red;
  /* color: #9B51E0; */
}
.term-info {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Purple 1 */
}

.term-toggle {
  border-radius: 3px;
  border: none; /*solid 1px;*/
  background-color: #ffffff;
  color: #9b51e0;
  font-size: 16px;
  height: 22px;
  width: 42px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 20px;
  /* padding: 4px 6px; */
  pointer-events: auto;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.term-badge {
  border-radius: 3px;
  border: solid 1px;
  background-color: #9b51e0;
  color: #ffffff;
  font-size: 12px;
  height: 22px;
  width: 22px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 20px;
  /* padding: 4px 6px; */
  pointer-events: auto;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
