import Vue from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'
import vuetify from './plugins/vuetify'
import store from './store'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-MVXR5S9FH6" },
  params: {
    send_page_view: false,
  },
});

new Vue({
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
