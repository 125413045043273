<template>
  <v-app v-resize="onResize">
    <v-app-bar
      id="myappbar"
      app
      v-if="selectedPage != 'Splash'"
      :height="appBarHeight"
      class="main-view-port pa-0 ma-0"
      flat
    >
      <DesktopView
        :color="'white'"
        :backgroundColor="'#9B51E0'"
        :backdropColor="'lightgray'"
        :contentWidth="contentWidth"
        :barRole="'top-bar'"
      >
        <v-container class="pa-0 ma-0">
          <v-row no-gutters class="pa-0 ma-0">
            <v-col cols="1">
              <v-btn
                icon
                v-if="
                  selectedPage == 'SelectATerm' &&
                    getConfig().startPage != 'Intro'
                "
                @click="selectPage('WhatToReserve')"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-icon
                v-if="isAndroid() && appMode == 'student'"
                medium
                color="white"
                @click="onFullScreen"
                >mdi-fullscreen</v-icon
              >
            </v-col>
            <v-col cols="3" class="pl-0 d-flex align-center justify-center">
              <NintendoPromo
                v-if="isPromoActive()"
                @nintendo-promo-click="onNintendoPromo"
              />
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-center">
              <Logo :lock="true" />
            </v-col>
            <v-col
              cols="3"
              class="px-0 d-flex align-center justify-center"
              style="cursor: default;"
            >
              <div class="human-name">{{ userHumanName }}</div>
            </v-col>
            <v-col
              cols="1"
              class="pl-0 d-flex align-center justify-center"
              @click="onMenu"
            >
              <v-icon small color="white" v-if="appMode == 'student'"
                >mdi-share-variant-outline</v-icon
              >
              <v-icon small color="white">mdi-dots-vertical</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </DesktopView>
    </v-app-bar>
    <v-main>
      <div style="height: 100%; display: flex; flex-flow: column;">
        <DesktopView
          :color="'#333333'"
          :backgroundColor="'white'"
          :backdropColor="'lightgray'"
          :contentWidth="contentWidth"
          :topBarHeight="appBarHeight"
          :footBarHeight="appMode == 'student' ? appFooterHeight : 0"
          :scrollY="selectedPage == 'SelectACourse' ? 'hidden' : 'auto'"
        >
          <NintendoPromoDialog
            v-if="nintendoPromoDialog"
            @close="onNintendoPromoDialogClose"
          />
          <v-dialog
            v-model="menuDialog"
            max-width="500"
            persistent
            style="overflow-x: hidden;"
          >
            <v-card class="pa-0" style="overflow-x: hidden;">
              <v-card-title class="menu-headline pa-0">
                <v-container class="pa-1">
                  <v-row no-gutters>
                    <v-col cols="6" class="d-flex justify-start">
                      <v-icon color="white" class="px-2"
                        >mdi-account-outline</v-icon
                      >{{ userHumanName }}
                    </v-col>
                    <v-col cols="4"> </v-col>
                    <v-col cols="2" class="d-flex justify-end">
                      <v-icon
                        color="white"
                        class="px-2"
                        @click="menuDialog = false"
                        >mdi-close</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>
              <UserRating
                :sendFeedbackButton="true"
                v-if="appMode == 'student'"
              />
              <!-- student mode -->
              <v-card-text>
                <p class="py-2 my-2">
                  <Button
                    look="btnLogout"
                    @click="onLogout()"
                    height="39"
                    width="147"
                  />
                </p>
                <p class="py-2 my-2">
                  <Button
                    look="btnClose"
                    @click="menuDialog = false"
                    height="39"
                    width="147"
                  />
                </p>
                <p class="py-2 my-2">
                  <CustomButton
                    v-if="true"
                    look="shortSecondary"
                    textColor="#9B51E0"
                    text="Admin"
                    @click="onAdmin"
                    height="39"
                    width="147"
                  />
                </p>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-container fluid class="pa-0 ma-0 main-view-port">
            <v-overlay
              opacity="0.84"
              :value="debugOverlay"
              z-index="5"
              class="ma-0 pa-0"
            >
              <DebugInfo />
            </v-overlay>
            <Splash v-if="selectedPage == 'Splash'" />
            <Intro v-if="selectedPage == 'Intro'" />
            <Error v-if="selectedPage == 'Error'" />
            <WhatToReserve v-if="selectedPage == 'WhatToReserve'" />
            <SelectATerm v-if="selectedPage == 'SelectATerm'" />
            <ATermLanding v-if="selectedPage == 'ATermLanding'" />
            <QuickRequest v-if="selectedPage == 'QuickRequest'" />
            <TermCourses
              v-if="selectedPage == 'TermCourses'"
              v-on:no-courses="onNoCourses"
            />
            <TermTimes v-if="selectedPage == 'TermTimes'" />
            <TermReview v-if="selectedPage == 'TermReview'" />
            <TermConfirm v-if="selectedPage == 'TermConfirm'" />
            <SelectACourse
              v-if="selectedPage == 'SelectACourse'"
              v-on:screen-info="onScreenInfo"
            />
            <TermLocations v-if="selectedPage == 'TermLocations'" />
            <TermModalities v-if="selectedPage == 'TermModalities'" />
            <TermInstructors v-if="selectedPage == 'TermInstructors'" />
            <ATermConfirmation v-if="selectedPage == 'ATermConfirmation'" />
          </v-container>
        </DesktopView>
      </div>
    </v-main>
    <!-- :height="appFooterHeight" -->
    <v-footer
      id="myappfooter"
      app
      color="secondary"
      class="pa-0 ma-0 main-view-port"
      :max-height="appFooterHeight * 2"
    >
      <DesktopView
        :color="'white'"
        :backgroundColor="'#4F4F4F'"
        :backdropColor="'lightgray'"
        :contentWidth="contentWidth"
        :barRole="'foot-bar'"
      >
        <SplashFooter v-if="selectedPage == 'Splash'" />
        <IntroFooter v-if="selectedPage == 'Intro'" />
        <ATermConfirmationFooter v-if="selectedPage == 'ATermConfirmation'" />
        <ATermLandingFooter v-if="selectedPage == 'ATermLanding'" />
        <QuickRequestFooter v-if="selectedPage == 'QuickRequest'" />
        <SelectACourseFooter v-if="selectedPage == 'SelectACourse'" />
        <TermTimesFooter v-if="selectedPage == 'TermTimes'" />
        <TermReviewFooter v-if="selectedPage == 'TermReview'" />
        <TermConfirmFooter v-if="selectedPage == 'TermConfirm'" />
        <TermCoursesFooter
          v-if="selectedPage == 'TermCourses'"
          :key="footerUpdateTime"
          v-on:scroll-to-top="onScrollToTop"
        />
        <TermLocationsFooter v-if="selectedPage == 'TermLocations'" />
        <TermModalitiesFooter v-if="selectedPage == 'TermModalities'" />
        <TermInstructorsFooter v-if="selectedPage == 'TermInstructors'" />
      </DesktopView>
    </v-footer>
  </v-app>
</template>

<script>
import { fetchConfig, startVersionCheck, getStartPage } from "@/api/config.js";
import UserRating from "@/components/UserRating";
import ATermConfirmation from "./components/ATermConfirmation";
import ATermConfirmationFooter from "./components/ATermConfirmationFooter";
import ATermLanding from "./components/ATermLanding";
import ATermLandingFooter from "./components/ATermLandingFooter";
import Button from "./components/Button";
import CustomButton from "./components/CustomButton.vue";
import DebugInfo from "./components/DebugInfo";
import Error from "@/components/Error";
import Logo from "./components/Logo";
import DesktopView from "./components/DesktopView";
import SelectACourse from "./components/SelectACourse";
import SelectACourseFooter from "./components/SelectACourseFooter";
import SelectATerm from "./components/SelectATerm";
import Splash from "@/components/Splash";
import SplashFooter from "./components/SplashFooter";
import Intro from "@/components/Intro";
import IntroFooter from "./components/IntroFooter";
import QuickRequest from "./components/QuickRequest";
import QuickRequestFooter from "./components/QuickRequestFooter";
import TermConfirm from "./components/TermConfirm";
import TermConfirmFooter from "./components/TermConfirmFooter";
import TermCourses from "./components/TermCourses";
import TermCoursesFooter from "./components/TermCoursesFooter";
import TermInstructors from "./components/TermInstructors";
import TermInstructorsFooter from "./components/TermInstructorsFooter";
import TermLocations from "./components/TermLocations";
import TermLocationsFooter from "./components/TermLocationsFooter";
import TermModalities from "./components/TermModalities";
import TermModalitiesFooter from "./components/TermModalitiesFooter";
import TermReview from "./components/TermReview";
import TermReviewFooter from "./components/TermReviewFooter";
import TermTimes from "./components/TermTimes";
import TermTimesFooter from "./components/TermTimesFooter";
import WhatToReserve from "./components/WhatToReserve";

import NintendoPromo from "@/components/icons/NintendoPromo";
import NintendoPromoDialog from "@/components/NintendoPromoDialog";

import { mapGetters, mapMutations } from "vuex";
import {adminRedirect} from "@/api/admin"
import {
  userIsAdmin,
  getUserId,
  getUserHumanName,
  getAuthKey,
  getAppMode,
  logout,
  reload,
  getBrowser,
  isTestHostname,
} from "@/utils";
import {
  downloadUserData,
  postUserPromoCode,
  storePromoRefCode,
  loadPromoRefCode,
  clearPromoRefCode,
  storeBookmark,
  loadBookmark,
  clearBookmark,
} from "@/api/user";

// import {setTestingReservations} from "@/api/testing";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("App");
// logger.state.isEnabled = true;

export default {
  name: "App",

  components: {
    ATermConfirmation,
    ATermConfirmationFooter,
    ATermLanding,
    ATermLandingFooter,
    Button,
    CustomButton,
    DebugInfo,
    Error,
    Logo,
    DesktopView,
    NintendoPromo,
    NintendoPromoDialog,
    SelectACourse,
    SelectACourseFooter,
    SelectATerm,
    Splash,
    SplashFooter,
    Intro,
    IntroFooter,
    QuickRequest,
    QuickRequestFooter,
    TermConfirm,
    TermConfirmFooter,
    TermCourses,
    TermCoursesFooter,
    TermInstructors,
    TermInstructorsFooter,
    TermLocations,
    TermLocationsFooter,
    TermModalities,
    TermModalitiesFooter,
    TermReview,
    TermReviewFooter,
    TermTimes,
    TermTimesFooter,
    UserRating,
    WhatToReserve,
  },

  props: {
    CustomButtonappBarHeight: {
      type: Number,
      default: 44,
    },
    appBarHeight: {
      type: Number,
      default: 63,
    },
    appFooterHeight: {
      type: Number,
      default: 63,
    },
  },
  data: () => ({
    footerUpdateTime: new Date().getMilliseconds(),
    screenInfo: "",
    menuDialog: false,
    nintendoPromoDialog: false,
    maxViewWidth: 700,
    backdropColor: "#EEEEEE",
    secondaryColor: "#4F4F4F",
    contentWidth: "100%",
    admin: false,
  }),

  mounted() {
    this.onResize();
  },

  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
      debugOverlay: "getDebugOverlay",
      runtimeOS: "getRuntimeOS",
      browserName: "getRuntimeBrowser",
      browserVersion: "getRuntimeBrowserVersion",
      userHumanName: "getUserHumanName",
      appMode: "getAppMode",
      appEnv: "getAppEnv",
      bookmark: "getBookmark",
      config: "getConfig",
    }),
    browserInfo: function() {
      return (
        this.browserName + ";" + this.browserVersion + ";" + this.runtimeOS
      );
    },
  },

  methods: {
    isAndroid() {
      return /Android/i.test(navigator.userAgent);
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    onLogout() {
      logger.debug("onLogout");
      logout();
    },
    isFullscreen() {
      return document.fullscreenElement;
    },
    onFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    onNintendoPromoDialogClose() {
      logger.debug("onNintendoPromoDialogClose");
      this.nintendoPromoDialog = false;
    },
    isPromoActive() {
      return new Date(this.config.nintendoPromoEndDate) > new Date();
    },
    onNintendoPromo() {
      logger.debug("onNintendoPromo");
      this.nintendoPromoDialog = true;
    },
    onMenu() {
      if (this.isPromoActive()) {
        this.onNintendoPromo();
      } else {
        this.menuDialog = true;
      }
    },
    onScrollToTop(event) {
      logger.debug("onScrollToTop", event);
      window.scroll(0, 0);
    },
    onScreenInfo(event) {
      logger.debug("onScreenInfo", event);
      logger.debug(
        "onScreenInfo, virtualKeyboardHeight:",
        event.virtualKeyboardHeight
      );
      this.screenInfo = `4:${event.info}`;
    },
    onNoCourses(event) {
      logger.debug("onNoCourses", event);
      this.footerUpdateTime = new Date().getMilliseconds();
    },
    onAdmin() {
      logger.debug("onAdmin", window.location);
      adminRedirect(getAuthKey())
    },
    readRuntime() {
      const browser = getBrowser();
      logger.debug("readRuntime", browser);
      this.setRuntimeBrowser(browser.name);
      this.setRuntimeBrowserVersion(browser.version);
      this.setRuntimeOS(browser.os);
    },
    onResize() {
      logger.debug("onResize", window.innerHeight);
      if (window.innerWidth > this.maxViewWidth && !this.isMobile()) {
        this.contentWidth = "700px";
      } else {
        this.contentWidth = "100%";
      }
      this.screenInfo = `h:${window.innerHeight}`;
      this.setAppWindowHeight(window.innerHeight);
    },
    ...mapGetters({
      getAllTerms: "getAllTerms",
      getConfig: "getConfig",
      getError: "getError",
    }),
    ...mapMutations({
      selectPage: "setSelectedPage",
      selectTerm: "setSelectedTerm",
      setAppWindowHeight: "setAppWindowHeight",
      setRuntimeOS: "setRuntimeOS",
      setRuntimeBrowser: "setRuntimeBrowser",
      setRuntimeBrowserVersion: "setRuntimeBrowserVersion",
      setStatusMessage: "setStatusMessage",
      setUserHumanName: "setUserHumanName",
      setAppMode: "setAppMode",
      setAppEnv: "setAppEnv",
      setBookmark: "setBookmark",
      setError: "setError",
      setErrorLink: "setErrorLink",
    }),
    handleOnPopstate(event) {
      logger.debug("handleOnPopstate", event);
      history.pushState({}, "EdReserve", "/#appStart");
      if (this.selectedPage == "SelectACourse") {
        this.selectPage("TermCourses");
      }
    },
    catchPromoCode() {
      const refCodeFromUrl = _.get(
        new RegExp(/\/ref\/([0-9A-Z]+)$/).exec(window.location),
        [1]
      );
      if (refCodeFromUrl) {
        storePromoRefCode(refCodeFromUrl);
      }
    },
    catchBookmark() {
      logger.log(`catchBookmark`);
      const mark = _.get(
        new RegExp(/\/bm\/([0-9A-Za-z_]+)$/).exec(window.location),
        [1]
      );
      logger.log(`catchBookmark:${mark}`);
      if (mark) {
        logger.log(`catchBookmark, storing:${mark}`);
        storeBookmark(mark);
      }
    },
    catchExtension() {
      logger.log(
        `catchExtension:${window.location}`,
        new RegExp(/\/start-ext\/.*/).exec(window.location)
      );
      const mark = _.get(new RegExp(/\/start-ext\/.*/).exec(window.location), [
        0,
      ]);
      logger.log(`catchExtension:${mark}`);
      if (mark) {
        logger.log(`catchExtension, storing:${mark}`);
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    logger.debug("created", window.location);
    this.catchPromoCode();
    this.catchBookmark();
    if (this.catchExtension()) {
      this.setAppEnv("extension");
    }
    // this.catchMode();
    history.pushState({}, "EdReserve", "/#appStart");
    window.onpopstate = this.handleOnPopstate;
    const userId = getUserId();
    const userHumanName = getUserHumanName();
    logger.debug(`userId: '${userId}', userHumanName: '${userHumanName}'`);
    this.setUserHumanName(userHumanName);
    this.admin = userIsAdmin();
    if (this.appEnv == "extension" && !userId) {
      logger.log(`extension mode - not authenticated`);
      this.setError(
        "You are not authenticated, click the following link to authenticate in a new tab and then try to reopen extension:"
      );
      this.setErrorLink("/#appStart");
      this.selectPage("Error");
    } else {
      if (!userId) {
        if (!isTestHostname(window.location.hostname)) {
          window.location.replace("/sso/login");
        } else {
          logger.debug("localhost will not redirect");
        }
      } else {
        const refCodeFromStore = loadPromoRefCode();
        if (refCodeFromStore) {
          postUserPromoCode(refCodeFromStore)
            .then((data) => {
              clearPromoRefCode();
              logger.debug("promo ref posted:", data);
            })
            .catch((e) => {
              logger.error("could not post promor ref:", e);
            });
        }
        const bookmark = loadBookmark();
        if (bookmark) {
          this.setBookmark(bookmark);
          logger.log(`cleaning bookmark:${bookmark}`);
          clearBookmark();
        }
        this.setAppMode(getAppMode(bookmark));
      }
      this.readRuntime();
      this.setStatusMessage("loading data...");
      downloadUserData()
        .then((result) => {
          logger.debug("downloadUserData().then:", result);
          Promise.all([
            fetchConfig(),
            this.$store.dispatch("fetchAllTerms"),
            this.$store.dispatch("fetchAllTimes"),
            this.$store.dispatch("fetchAllLocations"),
            this.$store.dispatch("fetchAllModalities"),
            this.$store.dispatch("fetchAllInstructors"),
            this.$store.dispatch("fetchAllUserData"),
          ])
            .then((values) => {
              this.setStatusMessage("please, tap the screen");
              logger.debug("the app got config", values[0]);
              logger.debug("the other promises:", values);
              this.$store.commit("setConfig", values[0]);
              this.$gtag &&
                this.$gtag.event("app_created", {
                  event_category: "app_development",
                });
              let useBookmark = false;
              if (this.bookmark) {
                const terms = this.getAllTerms();
                const term = _.get(
                  terms,
                  _.findIndex(terms, (v) => _.get(v, "id") == this.bookmark)
                );
                if (term) {
                  useBookmark = true;
                  this.selectTerm(term);
                  this.selectPage("TermModalities"); // advisor vs student
                }
              }
              if (!useBookmark) {
                this.selectPage(getStartPage(this.getConfig(), this.appMode)); // advisor vs student
              }
              //
              startVersionCheck(
                _.get(this.getConfig(), "versionCheckInterval"),
                this.getConfig().build,
                (newVersion, oldVersion) => {
                  logger.debug(`version check:${newVersion}, ${oldVersion}`);
                  reload();
                },
                () => {
                  logger.debug("network error callback");
                  this.setError("You are offline");
                  this.selectPage("Error");
                }
              );
            })
            .catch((error) => {
              logger.warn(`failed to load application: ${error}`);
              this.setStatusMessage(`the app failed: ${error}`);
              this.setError(error);
              this.selectPage("Error");
            });
        })
        .catch((e) => {
          logger.warn(`the app failed to load user data: ${e}`);
          this.setStatusMessage(`the app failed to load user data: ${e}`);
          this.setError("The application appears to be offline");
          this.selectPage("Error");
        });
    }
  },
};
</script>
<style>
.v-toolbar__content {
  padding: 0px;
  margin: 0px;
}
</style>
<style scoped>
/* @media (min-width: 700px) {
  .main-view-port {
    width: 700px;
  }
}
@media (max-width: 700px) {
  .main-view-port {
    width: 100%;
  }
} */
.menu-headline {
  color: white;
  background: #4f4f4f;
}
.human-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}
.screen-info {
  color: yellow;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  /* font-weight: lighter; */
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
