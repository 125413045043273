<template>
  <div id="scrollContainer">
    <PageBanner bannerText="Select acceptable delivery methods" />
    <TermCoursesModalities :readOnly="false" />
  </div>
</template>

<script>
// import lodash from "lodash";
import { mapGetters } from "vuex";
import { scrollElementIntoView } from '@/utils'
import PageBanner from "@/components/PageBanner";
import TermCoursesModalities from "@/components/TermCoursesModalities";

// import logdown from "logdown";
// const logger = logdown("TermLocations");
// logger.state.isEnabled = true;

export default {
  name: "TermModalities",
  components: {
    PageBanner,
    TermCoursesModalities,
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
    }),
  },
  methods: {},
  mounted: function() {
    let el = document.getElementById("scrollContainer");
    if (el) {
      // el.scrollIntoView(true);
      scrollElementIntoView(el);
    }
  },
};
</script>
