<template>
  <div :style="'height: ' + height + 'px; overflow: auto;'" @scroll="onScroll">
    <!-- <div>total size:{{ list.length }}, render limit: {{ renderLimit }}</div> -->
    <div v-for="(item, index) in list" :key="index">
      <div v-if="index < renderLimit">
        <slot v-bind:item="item"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import logdown from "logdown";
const logger = logdown("InfiniteScroll");

export default {
  name: "InfiniteScroll",
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
    },
    preloadingChunk: {
      type: Number,
      default: 50,
    },
    scrollTolerance: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
      default: 120,
    },
  },
  data: function() {
    return {
      items: this.list, //['A','B'],
      renderLimit: this.preloadingChunk,
    };
  },
  watch: {
    list: function(oldVal, newVal) {
      logger.debug(
        "the list has changed",
        "limit:",
        this.renderLimit,
        "old length:",
        oldVal.length,
        "new length:",
        newVal.length
      );
      this.renderLimit = this.preloadingChunk;
    },
  },
  methods: {
    //
    onScroll(event) {
      const target = event.target;
      logger.debug(
        "onScroll, hidden height:",
        target.scrollHeight - (target.scrollTop + target.clientHeight)
      );
      if (
        target.scrollHeight - (target.scrollTop + target.clientHeight) <
          this.scrollTolerance &&
        this.renderLimit < this.list.length
      ) {
        this.renderLimit += this.preloadingChunk;
      }
    },
  },
};
</script>
