/**
 * Mocking the Locations
*/

const _locations = [
  { id: "main", title: "Main campus" },
  { id: "east", title: "East campus" },
  { id: "downtown", title: "Downtown campus" },
  { id: "riverton", title: "Riverton campus" },
]

function fetchLocations() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if(_locations){
        resolve(_locations) 
      }else{
        reject('some error')
      }
    }, 0)
  })
}

export {
  fetchLocations,
}