<template>
  <div>
    <!-- :style="'height: ' + appWindowHeight + 'px;'" -->
    <div
      class="splash-frame d-flex align-content-space-around flex-wrap justify-center"
    >
      <div @click="onClick">
        <div class="logo-frame pt-9">
          <Logo class="logo-box" logoColor="brand" :width="234" :lock="false" />
        </div>
        <div class="status-message">
          <!-- <div>{{ statusMessage }}</div> -->
        </div>
        <div class="small-banner my-5">
          <div>A new way to request</div>
          <div>classes for future terms</div>
        </div>
        <div>
          <v-img
            alt="EdReserve Logo"
            class="shrink my-3"
            contain
            :src="customerLogo"
            transition="scale-transition"
            :width="customerLogoWidth"
          >
          </v-img>
        </div>
        <div class="tiny-banner my-1" v-if="(userHumanName || '').length > 0">
          <div class="tiny-banner text">Welcome</div>
          <div class="tiny-banner name">{{ userHumanName }}</div>
        </div>
      </div>
      <div class="copyright">
        Copyright 2020. All rights reserved. ({{ getConfig()["build"] }})
      </div>
    </div>
    <v-container fluid v-if="unlockPasscode">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field autofocus @input="onChange" type="password">
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { setFullScreen } from "@/utils";
// import { toggleFullScreen } from "@/utils";
import { customer } from "@/api/config";
import Logo from "@/components/Logo";
import { mapState, mapMutations, mapGetters } from "vuex";
import logdown from "logdown";
import lodash from "lodash";
const logger = logdown("Splash");
// logger.state.isEnabled = true;

export default {
  name: "Splash",
  components: {
    Logo,
  },
  computed: {
    ...mapState({
      appWindowHeight: (state) => state.appWindowHeight,
    }),
    ...mapGetters({
      statusMessage: "getStatusMessage",
      userHumanName: "getUserHumanName",
    }),
  },

  methods: {
    ...mapGetters({
      getConfig: "getConfig",
      runtimeOS: "getRuntimeOS",
      browserName: "getRuntimeBrowser",
      browserVersion: "getRuntimeBrowserVersion",
    }),
    checkFullScreen() {
      const tryFullScreen = this.getConfig().tryFullScreen;
      if (
        tryFullScreen &&
        (this.runtimeOS() == "iOS" || this.runtimeOS() == "Android")
      ) {
        setFullScreen(
          window,
          this.runtimeOS(),
          this.browserName(),
          this.browserVersion()
        );
        // toggleFullScreen();
      }
    },

    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    copyrightTop: function(height) {
      if (height <= 360) {
        return 0.7;
      }
      if (height <= 568) {
        return 0.85;
      }
      if (height <= 640) {
        return 0.87;
      }
      if (height <= 667) {
        return 0.88;
      }
      if (height <= 731) {
        return 0.89;
      }
      if (height <= 731) {
        return 0.89;
      }
      if (height <= 736) {
        return 0.89;
      }
      return 0.9;
    },
    onClick: function() {
      this.checkFullScreen(this.runtimeOS, window);
      logger.debug("onClick");
      if (!this.getConfig()["lockSplash"]) {
        this.selectPage(this.getConfig().startPage);
      } else {
        const ignoreLock = lodash.get(window, ["localStorage", "ignoreLock"]);
        if (ignoreLock) {
          this.selectPage(this.getConfig().startPage);
        } else {
          this.unlockPasscode = !this.unlockPasscode;
        }
      }
    },
    onChange: function(event) {
      logger.debug(event);
      if (("" + event).endsWith("iaj")) {
        lodash.set(window, ["localStorage", "ignoreLock"], true);
        // this.$vuetify.goTo(0);
        this.selectPage(this.getConfig().startPage);
      }
    },
  },

  data: () => {
    return {
      unlockPasscode: false,
      customerLogo: customer.logo,
      customerLogoWidth: 222,
    };
  },
};
</script>

<style scoped>
.splash-frame {
  height: 85vh;
}
.logo-frame {
  display: flex;
  align-items: center;
  justify-content: center;
}
.status-message {
  padding: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 21px;
  color: #333333;
  display: flex;
  justify-content: center;
}
.text {
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  font-weight: normal;
  color: #828282;
}
.name {
  font-size: 16px;
  color: #333333;
}
.tiny-banner {
  text-align: center;
  cursor: pointer;
}
.small-banner {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #828282;
}
.copyright {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  position: fixed;
  bottom: 75px;
}
</style>
