<template>
  <div>
    <PageBanner
      :bannerText="'Acceptable Locations'"
      subtitle
      v-if="readOnly && locationCount(this.selectedTerm.id)"
      section="locations"
    />
    <div v-if="readOnly">
      <v-container>
        <div v-for="location in locations" :key="location.id">
          <v-row
            v-if="check(location)"
            class="d-flex align-content-space-around flex-wrap"
          >
            <v-col
              cols="2"
              class="d-flex align-content-space-around flex-wrap align-center px-1 py-0 my-0"
            >
            </v-col>
            <v-col
              v-if="readOnly"
              cols="10"
              class="d-flex align-content-space-around flex-wrap align-center px-1 py-0 my-0"
            >
              <v-card
                flat
                class="d-flex align-content-space-around flex-wrap pa-1"
              >
                <div style="color: #282828;" class="text-caption">
                  {{ location.title }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <div v-else>
      <v-container>
        <div v-for="location in locations" :key="location.id">
          <v-row
            class="d-flex align-content-space-around flex-wrap"
            @click="toggleCheck(location)"
            no-gutters
            style="cusror: ponter;"
          >
            <v-col cols="1"></v-col>
            <v-col cols="11">
              <v-checkbox
                color="primary"
                :label="location.title"
                :input-value="check(location)"
                class="location-selector"
                off-icon="$boxBlank"
                on-icon="$boxChecked"
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import PageBanner from "@/components/PageBanner";
import { countItems } from "@/api/reservations";

import logdown from "logdown";
import lodash from "lodash";

const logger = logdown("TermCoursesLocations");
// logger.state.isEnabled = true;

export default {
  name: "TermCoursesLocations",
  components: {
    PageBanner,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      locations: "getAllLocations",
      userLocations: "getUserLocations",
    }),
  },
  methods: {
    ...mapMutations({
      setUserLocation: "setUserLocation",
      unsetUserLocation: "setUserLocation",
    }),
    locationCount(term) {
      return countItems(this.$store.getters.getUserLocations, term.id);
    },

    toggleCheck(location) {
      logger.debug("toggleCheck", location);
      let userLocation = lodash.get(this.userLocations, [
        this.selectedTerm.id,
        location.id,
      ]);
      if (userLocation) {
        logger.debug("unset user location", location.id);
        this.setUserLocation({
          term: this.selectedTerm.id,
          id: location.id,
        });
      } else {
        logger.debug("set user location", location.id);
        this.setUserLocation({
          term: this.selectedTerm.id,
          id: location.id,
          location,
        });
      }
      this.$forceUpdate();
    },
    check(location) {
      let userLocation = lodash.get(this.userLocations, [
        this.selectedTerm.id,
        location.id,
      ]);
      return userLocation;
    },
  },
};
</script>

<style>
.location-selector .v-label {
  font-size: 18px;
  margin-left: 18px;
}
</style>