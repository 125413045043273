<template>
  <v-row align="center">
    <v-col class="text-center" cols="6" >
      <Button look="btnBackShortWG" @click="back()" height="39" width="147" />
    </v-col>
    <v-col class="text-center" cols="6" >
      <Button look="btnConfirmShortWP" @click="confirm()" height="39" width="147" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Button from "@/components/Button";
import _ from 'lodash'
import { postReservations } from "@/api/reservations";
import { getUserId, getAuthKey, computeConfirmation } from "@/utils";
import { uploadUserData } from '@/api/user'


const _devUserStore = "uid-" + getUserId();

export default {
  name: "TermReviewFooter",

  components: { Button },

  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
      userClient :"getUserClient",
    }),
  },

  methods: {
    ...mapGetters({
      getUserConfirmations: "getUserConfirmations",
    }),
    ...mapMutations({
      selectPage: "setSelectedPage",
      setUserConfirmations: "setUserConfirmations",
    }),
    back() {
      // this.$vuetify.goTo(0);
      this.selectPage("TermReview");
      this.$forceUpdate();
    },
    confirm() {
      ///
      let confirmations = _.cloneDeep(this.getUserConfirmations() || {});
      const confirmation = _.get(confirmations, [this.selectedTerm.id]);
      if (!confirmation) {
        const newConfirmation = computeConfirmation();
        _.set(confirmations, [this.selectedTerm.id], newConfirmation);
        this.setUserConfirmations(confirmations);
        uploadUserData(this.userClient, confirmations)
      }
      postReservations(_devUserStore, getAuthKey(), confirmations);
      // this.$vuetify.goTo(0);
      this.selectPage("ATermConfirmation");
      this.$forceUpdate();
    },
  },
  created() {},
};
</script>
