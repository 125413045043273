<template>
  <div class="logo-box">
    <v-img
      alt="EdReserve Logo"
      class="shrink"
      contain
      :src="logoColor == 'white' ? whiteLogo : brandLogo"
      @click="lock && onLogoClick()"
      transition="scale-transition"
      :width="width"
    />
  </div>
</template>

<script>
import { unlockTheDebug } from "../utils";
import { mapMutations } from "vuex";
export default {
  name: "Logo",
  props: {
    logoColor: {
      type: String,
      default: "white",
    },
    width: {
      type: Number,
      default: 120,
    },
    lock: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      whiteLogo: require("../assets/logo-white.svg"),
      brandLogo: require("../assets/logo.svg"),
    };
  },
  methods: {
    ...mapMutations({
      setDebugOverlay: "setDebugOverlay",
    }),
    onLogoClick() {
      console.log("logo click");
      var This = this;
      unlockTheDebug()
        .then((data) => {
          console.log("unlocking: " + data);
          This.setDebugOverlay(true);
        })
        .catch((data) => {
          console.log("keep going: " + data);
        });
    },
  },
};
</script>

<style scoped>
.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
