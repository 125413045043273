<template>
  <div>
    <div v-if="selectedTerm.courseBullets && selectedPage == 'TermConfirm'">
      <PageBanner
        bannerText="You can change or cancel anytime"
        :subtitle="section"
      />
      <v-container>
        <v-row no-gutters class="message-text">
          <v-col cols="12" class="px-4">
            <v-container class="pa-0">
              <v-row
                no-gutters
                class="pa-0"
                v-for="(bullet, index) in selectedTerm.courseBullets"
                :key="index"
              >
                <v-col cols="12" class="px-4">
                  <div :class="' ' + bullet.class">
                    {{ bullet.text }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <PageBanner
      :bannerText="'Important information for ' + selectedTerm.title"
      :subtitle="section"
    />
    <v-container class="pa-3">
      <v-row v-for="(text, index) in selectedTerm.bullets" :key="index">
        <v-col cols="2" class="pl-6 pr-1 py-1"
          ><v-icon class="info-bullet" large>${{ bullet() }}</v-icon></v-col
        >
        <v-col cols="10" class="pl-4 pr-4 py-1">
          <p class="info-text">{{ text }}</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-0" fluid v-if="selectedTerm.finePrintBoldHeader">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card
            color="#C4C4C4"
            class="d-flex align-content-space-around flex-wrap px-5 mx-0"
            tile
            :height="44"
            flat
          >
            <div class="banner-text subtitle-banner">The fine print</div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- fine print bold header -->
    <v-container class="px-4 pt-3 pb-0" v-if="selectedTerm.finePrintBoldHeader">
      <v-row
        no-gutters
        v-for="(text, index) in selectedTerm.finePrintBoldHeader"
        :key="index"
      >
        <v-col cols="12" class="pa-0">
          <p class="fine-print-bold-header">{{ text }}</p>
        </v-col>
      </v-row>
    </v-container>
    <!-- fine print normal header -->
    <v-container class="px-4 pt-3 pb-0" v-if="selectedTerm.finePrintHeader">
      <v-row
        no-gutters
        v-for="(text, index) in selectedTerm.finePrintHeader"
        :key="index"
      >
        <v-col cols="12" class="pa-0">
          <p class="fine-print-header">{{ text }}</p>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="pa-3 fine-print-text"
      v-if="selectedTerm.finePrintBullets"
    >
      <v-row no-gutters>
        <v-col cols="12" class="py-0 px-1">
          <ul
            v-for="(text, index) in selectedTerm.finePrintBullets"
            :key="index"
          >
            <p>{{ text }}</p>
          </ul>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      class="pa-3 fine-print-text"
      v-if="selectedTerm.finePrintFooter"
    >
      <v-row
        no-gutters
        v-for="(text, index) in selectedTerm.finePrintFooter"
        :key="index"
      >
        <v-col cols="12" class="pa-0">
          <p>{{ text }}</p>
        </v-col>
      </v-row>
      <!-- spacing -->
    </v-container>
    <v-container class="pa-6 fine-print-text">
      <v-row>
        <v-col cols="12"> <v-card flat height="50"></v-card></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageBanner from "@/components/PageBanner";
import { seasonBullet } from "@/utils";

export default {
  name: "ATermLanding",
  components: { PageBanner },
  props: {
    section: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      selectedPage: "getSelectedPage",
      TAC: "getTermsAndConditions",
    }),
  },
  methods: {
    bullet() {
      return seasonBullet(this.selectedTerm.season);
    },
  },
};
</script>

<style scoped>
.purple-text {
  color: #9b51e0;
}
.bold-text {
  font-weight: 900;
}
.special-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.normal-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #828282;
  font-weight: 400;
}

.message-text p {
  color: #333333;
}
/* p {
  margin-bottom: 0px;
} */
.fine-print-bold-header {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.fine-print-header {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.fine-print-text p {
  color: #333333;
  font-size: 14px;
}
.info-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
}
.info-bullet {
  width: 34px;
  height: 43px;
}
.page-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;

  /* Gray 2 */

  color: #4f4f4f;
}
.banner-text {
  color: white;
  font-size: 18px;
}
.subtitle-banner {
  color: black;
}
</style>
<style>
.v-label {
  font-size: 14px;
}
</style>
