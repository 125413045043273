<template>
  <div>
    <PageBanner
      :bannerText="'Acceptable Modalities'"
      subtitle
      v-if="readOnly"
      section="modalities"
    />
    <v-container class="py-0 px-2">
      <div v-for="(courseSlot, index) in courseSlots" :key="index">
        <v-row
          v-if="courseSlot['id']"
          :class="
            'd-flex align-content-space-around flex-wrap px-1 pb-5 pt-1' +
              (index + 1 == selectedTerm.reservationLimit ? '' : ' row-border')
          "
          no-gutters
        >
          <v-col
            cols="2"
            class="d-flex align-content-space-around flex-wrap pl-1 pr-1 pr-4 align-center justify-center"
          >
            <div class="slot-badge full">
              <div>{{ courseSlot.slot }}</div>
            </div>
          </v-col>
          <v-col cols="3" class="course-info mx-0 px-0">
            <v-card
              height="46"
              flat
              class="d-flex align-content-space-around flex-wrap align-center"
            >
              <div class="course-id">
                {{ courseSlot["id"] }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="7" class="course-info mx-0 px-0">
            <v-card
              height="46"
              flat
              class="d-flex align-content-space-around flex-wrap align-center px-1"
            >
              <div class="course-title">
                {{ courseSlot["title"] }}
              </div>
            </v-card>
          </v-col>
          <v-container v-if="courseSlot['id']" class="pa-0 ma-0">
            <div v-for="modality in modalities" :key="modality.id">
              <div v-if="isModalityAvailable(courseSlot, modality.id)">
                <v-row
                  class="d-flex align-content-space-around flex-wrap pa-0 ma-0 modality-check-row"
                  @click="toggleCheck(courseSlot, modality.id, index)"
                  no-gutters
                >
                  <v-col cols="2" class="pa-0 ma-0"></v-col>
                  <v-col cols="10" class="pa-0 ma-0">
                    <v-checkbox
                      color="primary"
                      :label="modality.title"
                      :input-value="check(courseSlot, modality.id)"
                      class="modality-selector"
                      off-icon="$boxBlank"
                      on-icon="$boxChecked"
                      dense
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { formatCourseSlots } from "@/utils.js";
import PageBanner from "@/components/PageBanner";
import _ from "lodash";

import logdown from "logdown";
const logger = logdown("TermCoursesModalities");

export default {
  name: "TermCoursesSlots",
  components: {
    PageBanner,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      componentKey: 0,
      courseSlots: [
        // { slot: 1, courseId: "MAT 1A", title: "College Algebra I", modalities:['online'] },
        // { slot: 2, courseId: "ENG 1B", title: "English Composition", modalities: ['inPerson'] },
      ],
      testIndex: 10,
    };
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      modalities: "getAllModalities",
    }),
  },
  methods: {
    ...mapGetters({
      getUserCourses: "getUserCourses",
    }),
    ...mapMutations({
      setUserCourseUnselectedModalities: "setUserCourseUnselectedModalities",
    }),
    isModalityAvailable(course, modalityId) {
      logger.debug(
        `isModalityAvailable, modalityId:${modalityId}, course:`,
        course
      );
      const modalities = _.get(course, ["modalities"]);
      return modalities && modalities.indexOf(modalityId) >= 0;
    },
    toggleCheck(course, modality, slotIndex) {
      logger.debug("toggleCheck", modality, course);
      const unselectedModalities =
        _.clone(_.get(course, ["unselectedModalities"]) || []);
      if (unselectedModalities.indexOf(modality) >= 0) {
        _.remove(unselectedModalities, (v) => v == modality);
      } else {
        unselectedModalities.push(modality);
      }
      _.set(course, ["unselectedModalities"], unselectedModalities);
      logger.debug("toggleCheck, after,", modality, course);
      this.setUserCourseUnselectedModalities({
        term: this.selectedTerm.id,
        slot: slotIndex+1,
        unselectedModalities: unselectedModalities
      })
    },
    check(course, modality) {
      logger.debug("check", modality, course);
      const unselectedModalities =
        _.get(course, ["unselectedModalities"]) || [];
      return !(unselectedModalities.indexOf(modality) >= 0);
    },

    refreshSlots() {
      formatCourseSlots(
        this.courseSlots,
        this.getUserCourses(),
        this.selectedTerm.id,
        this.selectedTerm.reservationLimit
      );
    },
  },
  created() {
    this.refreshSlots();
  },
};
</script>

<style scoped>
.course-id{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  color: #000000;
}
.course-title{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #000000;
}
.course-info {
  color: #282828;
  border-bottom: 1px solid #939393;
  border-radius: 0px;
}
.modality-check-row {
  cursor: pointer;
  height: 40px;
  /* margin-bottom: 10px; */
}
.row-border {
  border-bottom: 1px solid #333333;
}
.slot-badge {
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty {
  background-color: #bdbdbd;
  border-style: solid #bdbdbd;
}
.full {
  background-color: #4f4f4f;
  border-style: solid #4f4f4f;
}
</style>
<style>
.modality-selector .v-label {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333333;
}
</style>
