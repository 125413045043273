/**
 * Mocking the Modalities
*/

import { customer } from '@/api/config'

const _modalities = customer.modalities

function fetchModalities() {
  return new Promise((resolve) => {
    setTimeout(() => {
        resolve(_modalities) 
    }, 10)
  })
}


export {
  fetchModalities,
}