/**
 * Term Catalog
*/
import {nick, name} from './static'

const introText = {
  "title": "What is EdReserve?",
  "headerWhat": ["What it does:"],
  "bulletsWhat": [
    "Request seats for classes you want in Fall 2021 or later",
    "Get first access to seats held for you within your priorty group",
    "Have a voice in the IUP class scheduling process"
  ],
  "headerHow": ["How it works:"],
  "bulletsHow": [
    "Request classes in future terms and tell us when and how you want to take classes.",
    `${nick} uses your requests together with other considerations to create the class schedule.`,
    "Your requests are matched with classes when the schedule is published.",
    `${nick} holds a seat in your matched classes for 24-hours at your registration time, when possible.`
  ]
}


const terms = [
  {
    id: '2021Summer', termId: '202130', season: 'summer', reservationLimit: 2,
    title: "Summer 2021",
    info: "May 17 - Aug 14",
    begins: "February 2021",
    courseBullets: [
      { class: "special-text purple-text bold-text pb-0", text: "Secure your place in line." },
      { class: "special-text purple-text bold-text pb-4", text: "Request without worry." },
      { class: "special-text purple-text pb-2", text: "Here is the deal:" },
      { class: "special-text purple-text pb-3", text: "" },
      { class: "special-text purple-text pb-3", text: "Tell us the courses you want and we’ll hold spots for you to use when you register – if you still want them." },
      { class: "special-text purple-text pb-3", text: "You can change your mind anytime or even decide to take something completely different when you register. That’s cool – you don’t need to use your spot if your plans change." },
      { class: "special-text purple-text pb-3", text: "Letting us know the courses you want helps us schedule the classes you need." },
    ],
    bullets: [
      `Summer 2021 courses cannot be reserved, but your selections help us provide what you need.  We at ${nick} will try to offer classes in a way that works for you.`,
      "Summer 2021 courses shown or selected here are not guaranteed to be offered or available.",
    ],
    finePrintHeader: [
      `${nick} will use your feedback for Summer 2021 to adjust the classes that are offered.  We encourage you to let us know what classes, delivery methods, and times that you want.  This helps us schedule the classes you need.`,
      "Feedback and selections provided for Summer 2021 are not reservation requests.  You will not be pre-registered for these courses (unlike Fall 2021 and Spring 2022).",
      "Summer 2021 courses shown or selected here are not guaranteed to be offered or available.",
      "You may update or change your selections for Summer 2021 at any time until March 1, 2021.  We encourage you to update your selections as your plans change since we regularly refine the class schedule based in part on your selections.",
    ]
  },
  {
    id: '2021Fall', termId: '202210', season: 'fall', reservationLimit: 2,
    title: "Fall 2021",
    info: "Aug 23 - Dec 17",
    begins: "February 2021",
    courseBullets: [
      { class: "special-text purple-text bold-text pb-0", text: "Secure your place in line." },
      { class: "special-text purple-text bold-text pb-4", text: "Request without worry." },
      { class: "special-text purple-text pb-2", text: "Here is the deal:" },
      { class: "special-text purple-text pb-3", text: "" },
      { class: "special-text purple-text pb-3", text: "Tell us the courses you want and we’ll hold spots for you to use when you register – if you still want them." },
      { class: "special-text purple-text pb-3", text: "You can change your mind anytime or even decide to take something completely different when you register. That’s cool – you don’t need to use your spot if your plans change." },
      { class: "special-text purple-text pb-3", text: "Letting us know the courses you want helps us schedule the classes you need." },
    ],
    bullets: [
      "You may request up to 2 courses for Fall 2021.",
      "Rerservations requests are processed in March 2021.",
      "You can change or cancel your reservation request at any time until March 2021.",
      `${name} will do our best to pre-register you for your requested courses.`,
      "You are not guaranteed to be pre-registered for your reservation requests.",
    ],
    finePrintBoldHeader: [
      `${nick} will make its best effort to pre-register you in the courses you reserve.`
    ],
    finePrintHeader: [
      "You may reserve up to 2 courses for Fall 2021.",
      "When processing your reservations, we verify:",
    ],
    finePrintBullets: [
      "A section is offered at a time, location, and delivery method that you indicate is acceptable in EdReserve.",
      "You meet the prerequisite and other eligibility requirements for the course and section at the time of pre-registration. It is your responsibility to check and ensure you meet these requirements ahead of time. ",
      "The course and section has seats available when your pre-registration is processed.",
      "You do not have any holds that prevent registration."
    ],
    finePrintFooter: [
      "The reservation deadline is 11:59pm March 1, 2021. Reservations cannot be changed or canceled after this date. You will need to wait until your assigned registration window to make any changes after this date. "
    ]
  },
  {
    id: '2022Spring', termId: '202220', season: 'spring', reservationLimit: 2,
    title: "Spring 2022",
    info: "Jan 10 - May 6",
    begins: "October 2020",
    courseBullets: [
      { class: "special-text purple-text bold-text pb-0", text: "Secure your place in line." },
      { class: "special-text purple-text bold-text pb-4", text: "Request without worry." },
      { class: "special-text purple-text pb-2", text: "Here is the deal:" },
      { class: "special-text purple-text pb-3", text: "" },
      { class: "special-text purple-text pb-3", text: "Tell us the courses you want and we’ll hold spots for you to use when you register – if you still want them." },
      { class: "special-text purple-text pb-3", text: "You can change your mind anytime or even decide to take something completely different when you register. That’s cool – you don’t need to use your spot if your plans change." },
      { class: "special-text purple-text pb-3", text: "Letting us know the courses you want helps us schedule the classes you need." },
    ],
    bullets: [
      "You may request up to 2 courses for Spring 2022.",
      "Reservation requests are processed in December 2021.",
      "You can change or cancel your reservation request at any time until December 2021.",
      `${name} will do our best to pre-register you for your requested courses.`,
      "You are not guaranteed to be pre-registered for your reservation requests.",
    ],
    finePrintBoldHeader: [
      `${nick} will make its best effort to pre-register you in the courses you reserve.`
    ],
    finePrintHeader: [
      "You may reserve up to 2 courses for Spring 2022.",
      "When processing your reservations, we verify:",
    ],
    finePrintBullets: [
      "A section is offered at a time, location, and delivery method that you indicate is acceptable in EdReserve.",
      "You meet the prerequisite and other eligibility requirements for the course and section at the time of pre-registration. It is your responsibility to check and ensure you meet these requirements ahead of time. ",
      "The course and section has seats available when your pre-registration is processed.",
      "You do not have any holds that prevent registration."
    ],
    finePrintFooter: [
      "The reservation deadline is 11:59pm December 1, 2021. Reservations cannot be changed or canceled after this date. You will need to wait until your assigned registration window to make any changes after this date."
    ]
  },
]

export{
  introText,
  terms,
}