<template>
  <v-row align="center">
    <v-col class="text-center" cols="6">
      <Button look="btnBackShortWG" @click="back()" height="39" width="147" />
    </v-col>
    <v-col class="text-center" cols="6">
      <Button look="btnNextShortWP" @click="next()" height="39" width="147" />
    </v-col>
  </v-row>
</template>

<script>
import { nextTermWizardPage, backTermWizardPage } from "@/api/config";
import { mapGetters, mapMutations } from "vuex";
import Button from '@/components/Button'
// import lodash from "lodash";

// import logdown from "logdown";
// const logger = logdown("TermLocationsFooter");
// logger.state.isEnabled = true;

export default {
  name: "TermLocationsFooter",

  components:{
    Button,
  },

  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
    }),
  },

  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    next() {
      // this.$vuetify.goTo(0);
      this.selectPage(nextTermWizardPage(this.config, 'TermLocations'));
      this.$forceUpdate();
    },
    back() {
      // this.$vuetify.goTo(0);
      this.selectPage(backTermWizardPage(this.config, 'TermLocations'));
      this.$forceUpdate();
    },
  },
  created() {},
};
</script>
