<template>
  <!-- <div class="pa-0 ma-0 d-flex align-center justify-center custom-button" > </div> -->
  <v-container style="width: 246px;" class="pa-0 ma-0">
    <v-row>
      <v-col cols="1" class="pa-0 ma-0"/>
      <v-col cols="2" v-for="index in max" :key="index"
        class="pa-0 ma-0 d-flex align-center justify-center"
        @click="()=>onStar(index)"
      >
        <StarOn v-if="index <= rating" />
        <StarOff v-else />
      </v-col>
      <v-col cols="1" class="pa-0 ma-0"/>
    </v-row>
  </v-container>
</template>

<style scoped>
.custom-button {
  cursor: pointer;
}
.button-text {
  position: fixed;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: white;
  cursor: pointer;
}
</style>

<script>
import StarOn from '@/components/buttons/StarOn'
import StarOff from '@/components/buttons/StarOff'
import logdown from "logdown";

const logger = logdown("RatingStars");

export default {
  components: {StarOn, StarOff},
  name: "RatingStars",
  props: {
    look: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "btn",
    },
    textColor: {
      type: String,
      default: "white",
    },
    max: {
      type: Number,
      default: 5,
    },
    rating: {
      type: Number,
      default: 5,
    }
  },
  data: () => {
    return {};
  },
  methods: {
    onStar(index){
      logger.debug(`on: ${index}`)
      this.$emit("rating-click", {rating:index});
    },
  },
};
</script>
