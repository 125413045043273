<template>
  <v-container class="ma-0 pa-1">
    <v-toolbar>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            @click="menuAction(item.action)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn color="primary" @click="setDebugOverlay(false)" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div>
      <v-card color="rgb(0, 0, 0, 0.5)"
        >reservations:
        <v-card
          color="rgb(0, 0, 0, 0)"
          v-for="term in reservations"
          :key="term.id"
        >
          <v-card-text> {{ term.id }}, {{ term.title }} </v-card-text>
          <v-card-text> </v-card-text>
        </v-card>
      </v-card>
      <div>selected page:{{ selectedPage }}</div>
      <div>term:{{ selectedTerm }}</div>
      <div>slot:{{ selectedSlot }}</div>
      <div>course:{{ selectedCourse }}</div>
      <div>app window height:{{ appWindowHeight }}</div>
      <v-card color="rgb(0, 0, 0, 0.5)">
        <v-card-text> available terms:{{ termsToSelect.length }} </v-card-text>

        <v-card-text> term courses:{{ termCourses.length }} </v-card-text>
      </v-card>
      <div>code info: 0.0.2</div>
      <!-- <div>{{ requestFullscreenInfo() }}</div> -->
      <!-- <div>{{ exitFullscreenInfo() }}</div> -->
      <div>{{ os }}</div>
      <div>{{ browser }}</div>
      <div>{{ browserVersion }}</div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import lodash from "lodash";
import { mapMutations } from "vuex";
// import logdown from "logdown";

export default {
  name: "DebugInfo",

  computed: mapState({
    termsToSelect: (state) => state.allTerms,
    termCourses: (state) => state.termCourses,
    reservations: (state) => state.reservations,
    selectedTerm: (state) => state.selectedTerm,
    selectedPage: (state) => state.selectedPage,
    selectedSlot: (state) => state.selectedSlot,
    selectedCourse: (state) => state.selectedCourse,
    appWindowHeight: (state) => state.appWindowHeight,
    os: (state) => state.runtime.os,
    browser: (state) => state.runtime.browser,
    browserVersion: (state) => state.runtime.browserVersion,
  }),

  methods: {
    menuAction: function(action) {
      switch (action) {
        case "clearUserData":
          this.$store.dispatch("clearUserData");
          break;
        case "lockSplash":
          lodash.unset(window, ["localStorage", "ignoreLock"]);
          break;
      }
    },
    selectCourses: function(resource) {
      this.selectTerm(resource);
      this.selectPage("SelectACourse");
    },
    ...mapMutations({
      selectPage: "setSelectedPage",
      selectTerm: "setSelectedTerm",
      setDebugOverlay: "setDebugOverlay",
    }),
    requestFullscreenInfo: function() {
      const rfs = lodash.get(window.document.body, ["requestFullscreen"]);
      const wkRFS = lodash.get(window.document.body, [
        "webkitRequestFullScreen",
      ]);
      const wkRFs = lodash.get(window.document.body, [
        "webkitRequestFullscreen",
      ]);
      return (
        "rfs=" + rfs + "; " + "wkRFs=" + wkRFs + "; " + "wkRFS=" + wkRFS + "; "
      );
    },
    exitFullscreenInfo: function() {
      const efs = lodash.get(window.document, ["exitFullscreen"]);
      const wkEFS = lodash.get(window.document, ["webkitExitFullScreen"]);
      const wkEFs = lodash.get(window.document, ["webkitExitFullscreen"]);
      return "efs=" + efs + "; " + "wkEFS=" + wkEFS + "; wkEFs=" + wkEFs;
    },
  },
  data: () => ({
    menuItems: [
      {
        title: "clear user data",
        action: "clearUserData",
      },
      {
        title: "lock splash",
        action: "lockSplash",
      },
      {
        title: "capture logs",
        action: "subscribeLogs",
      },
    ],
  }),
  created() {
    this.$store.dispatch("fetchAllTerms");
  },
};
</script>
