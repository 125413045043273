<template>
  <v-row align="center">
    <v-col class="text-center" cols="6">
      <Button look="btnBackShortWG" @click="back()" height="39" width="147" />
    </v-col>
    <v-col
      class="text-center"
      cols="6"
      v-if="courseCount(selectedTerm.id) == 0"
    >
      <Button look="btnNextShortWG" height="39" width="147" />
    </v-col>
    <v-col class="text-center" cols="6" v-else>
      <Button look="btnNextShortWP" @click="next()" height="39" width="147" />
    </v-col>
  </v-row>
</template>

<script>
// import _ from 'lodash'
import { nextTermWizardPage } from "@/api/config";
import { mapGetters, mapMutations } from "vuex";
import { countItems } from "@/api/reservations";
import { uploadUserData } from '@/api/user'
import Button from "@/components/Button";
import { reportEvent } from '@/utils'
import logdown from "logdown";
const logger = logdown("TermCoursesFooter");
// logger.state.isEnabled = true;

export default {
  name: "TermCoursesFooter",

  components: {
    Button,
  },

  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
      userClient :"getUserClient",
    }),
  },

  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    ...mapGetters({
      getUserCourses: "getUserCourses",
    }),
    courseCount(term) {
      logger.debug("courseCount, term:", term);
      logger.debug( "courseCount, userCourses:", this.getUserCourses());
      return countItems(this.getUserCourses(), term);
    },
    back() {
      // this.$vuetify.goTo(0);
      this.$emit('scroll-to-top')
      this.selectPage("SelectATerm");
    },
    next() {
      reportEvent(this, 'next_courses', '---', this.selectedTerm.id)
      uploadUserData(this.userClient)
      // this.$vuetify.goTo(0);
      this.$emit('scroll-to-top')
      this.selectPage(nextTermWizardPage(this.config, "TermCourses"));
      this.$forceUpdate();
    },
  },
};
</script>
