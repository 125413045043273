<template>
  <v-container class="pa-0" v-if="selectedPage == 'ATermLanding'" fluid>
    <v-row align="center" no-gutters class="top-separator">
      <v-col class="text-center" cols="12">
        <v-card
          color="#C4C4C4"
          class="d-flex align-content-space-around flex-wrap px-5 mx-0 justify-center"
          tile
          :height="44"
          flat
        >
          <v-checkbox
            :input-value="TAC"
            @change="onChange"
            color="primary"
            label="I understand and agree"
            class="toc-label-text"
            off-icon="$boxBlankWhite"
            on-icon="$boxCheckedWhite"
          ></v-checkbox>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="text-center" cols="6">
        <Button look="btnBackShortWG" @click="back()" height="39" width="147" />
      </v-col>
      <v-col class="text-center" cols="6" v-if="!TAC">
        <Button look="btnNextShortWG" height="39" width="147" />
      </v-col>
      <v-col class="text-center" cols="6" v-else>
        <Button look="btnNextShortWP" @click="getStarted()" height="39" width="147" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Button from "@/components/Button";
import { mapGetters, mapMutations } from "vuex";
import logdown from "logdown";
const logger = logdown("ATermLandingFooter");

export default {
  name: "ATermLandingFooter",

  components: {
    Button,
  },
  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
      TAC: "getTermsAndConditions",
    }),
  },
  data: function() {
    return {
      // TACCheck: false
    };
  },

  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    back(){
      // this.$vuetify.goTo(0);
      this.selectPage("SelectATerm");
      //reset TAC for the next interaction
      this.$store.commit("setTermsAndConditions", false);
    },
    getStarted() {
      logger.debug("GetStarted!");
      // this.$vuetify.goTo(0);
      this.selectPage("TermCourses");
      //reset TAC for the next interaction
      this.$store.commit("setTermsAndConditions", false);
      this.$forceUpdate();
    },
    onChange(event) {
      logger.debug("onChange", event);
      this.$store.commit("setTermsAndConditions", event);
    },
  },
};
</script>

<style>
/* label. */
.toc-label-text .v-label {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #4f4f4f;
}
.top-separator{
  border-top: solid 1px #5f5f5f;
}
</style>
