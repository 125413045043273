import _ from 'lodash'
import logdown from 'logdown'
import axios from 'axios'
import { apiRoot } from '@/api/config'
import { getAuthKey, getUserStore, } from "@/utils";

import { getReservationStorage, saveReservationStorage } from '@/api/reservations'

const logger = logdown('user')

function uploadUserData(client, confirmations) {
    logger.debug('uploadUserData:', client)
    return getReservationStorage(getUserStore())
        .then(store => {
            let data = _.cloneDeep(store._data)
            if (confirmations) {
                data.confirmations = confirmations
            }
            return axios.post(apiRoot() + '/u', {
                data: data,
                localKey: getAuthKey(),
                client: client || 'init',
            })
        })
        .catch(err => {
            logger.error('uploadUserData, err:', err)
        })
}

function downloadUserData() {
    return new Promise((resolve, reject) => {
        axios.get(`${apiRoot()}/u?localKey=${getAuthKey()}`)
            .then(response => {
                const data = _.get(response, ['data', 0, 'userData'])
                logger.debug('downloadUserData', data)
                if (data) {
                    getReservationStorage(getUserStore()).then(store => {
                        store.setAll(data).then(() => {
                            saveReservationStorage(store).then(() => {
                                resolve('downloaded')
                            })
                        })
                    })
                } else {
                    uploadUserData().then(() => {
                        resolve('init')
                    })
                }
            })
            .catch(error => {
                // Network Errors
                logger.error('downloadUserData', error)
                reject(error)
            })
    })
}

function downloadUserPromoData() {
    return new Promise((resolve, reject) => {
        axios.get(`${apiRoot()}/promo?localKey=${getAuthKey()}`)
            .then(response => {
                const data = response
                logger.debug('downloadUserPromoData', data)
                resolve(data)
            })
            .catch(error => {
                // Network Errors
                logger.error('downloadUserPromoData', error)
                reject(error)
            })
    })
}

function postUserPromoCode(code) {
    return new Promise((resolve, reject) => {
        axios.post(`${apiRoot()}/promo`,{
            localKey: getAuthKey(),
            code: code
        })
            .then(response => {
                const data = response
                logger.debug('postUserPromoCode', data)
                resolve(data)
            })
            .catch(error => {
                // Errors 
                logger.error('postUserPromoCode', error)
                reject(error)
            })
    })
}

const promoKey = 'x-session-promo-code'
function storePromoRefCode(code){
    localStorage.setItem(promoKey, code)
}

function loadPromoRefCode(){
    return localStorage.getItem(promoKey)
}

function clearPromoRefCode(){
    return localStorage.removeItem(promoKey)
}

const bookmarkKey = 'x-session-bookmark'
function storeBookmark(code){
    localStorage.setItem(bookmarkKey, code)
}

function loadBookmark(){
    return localStorage.getItem(bookmarkKey)
}

function clearBookmark(){
    return localStorage.removeItem(bookmarkKey)
}

export {
    uploadUserData,
    downloadUserData,
    downloadUserPromoData,
    postUserPromoCode,
    storePromoRefCode,
    loadPromoRefCode,
    clearPromoRefCode,
    storeBookmark,
    loadBookmark,
    clearBookmark,
}