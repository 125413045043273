var data = [
{"id":"ACCT201.","title":"Accounting Princ I"},
{"id":"ACCT303.","title":"Intermed Accounting I"},
{"id":"ACCT311.","title":"Intr to Fed Income Taxation"},
{"id":"ACCT401.","title":"Advanced Accounting"},
{"id":"ACCT601.","title":"Acct-Decision Making & Cntrl"},
{"id":"ACCT605.","title":"Seminar in Auditing"},
{"id":"ADV101.","title":"Intro to Advertising"},
{"id":"AFRC111.","title":"Intro Africana Studies"},
{"id":"ANTH111.","title":"Intro to Cultural Anthropology"},
{"id":"ART201.","title":"Intro to Visual Arts"},
{"id":"ARTH221.","title":"Art History I"},
{"id":"ARTP221.","title":"Intro Digital Photo Imaging"},
{"id":"BAN601.","title":"Applied Business Analytics"},
{"id":"BCOM231.","title":"Business Communication"},
{"id":"BCOM401.","title":"Career Plan&Prof Devel"},
{"id":"BIOL121.","title":"Human Anat&Phys I"},
{"id":"BIOL122.","title":"Human Anat&Phys II"},
{"id":"BIOL151.","title":"Botany"},
{"id":"BIOL215.","title":"Ecology"},
{"id":"BIOL334.","title":"Cell Biology"},
{"id":"BLAW618.","title":"Legal & Social Envrmnt-Bus"},
{"id":"CHEM107.","title":"Elements in Everyday Chem"},
{"id":"CHEM141.","title":"Principles-Chemistry"},
{"id":"CHEM143.","title":"Survey Organic & Biochemistry"},
{"id":"CHEM261.","title":"General Chemistry I"},
{"id":"CHEM321.","title":"Quant Analysis"},
{"id":"CHEM353.","title":"Organic Chemistry I"},
{"id":"CHEM490.","title":"Undrgrad Tch Exp-Chemstr"},
{"id":"CHEM499.","title":"Intro Research-Chem"},
{"id":"CIS141.","title":"Computer Skills/non-Bus majrs"},
{"id":"CIS151.","title":"Computer Appl. in Bus."},
{"id":"CIS201.","title":"Business Processes and IS"},
{"id":"CIS375.","title":"System Analysis&Design"},
{"id":"CMST101.","title":"Intro to Public Speaking"},
{"id":"CMST107.","title":"Intro Interpersonal Comm"},
{"id":"CMST317.","title":"Intercultural Communication"},
{"id":"CMST321.","title":"Comm & Workplace Relationships"},
{"id":"COMM494.","title":"Mass Communicatn Law"},
{"id":"COMM614.","title":"Problematic Workplace Comm"},
{"id":"CRIM244.","title":"Police & Society"},
{"id":"CRIM301.","title":"Intro-Criminal Justice Admin"},
{"id":"CRIM335.","title":"Juvenile Delinquency"},
{"id":"CRIM475.","title":"Criminal Justice Capstone"},
{"id":"CRIM499.","title":"Internship-Criminal Justice"},
{"id":"CS299.","title":"ST-Intro to Python"},
{"id":"DMS315.","title":"Abdominal Procedures"},
{"id":"DMS316.","title":"Sonographic Lab Prac I"},
{"id":"DMS347.","title":"Advanced Clinical Practice"},
{"id":"DVT398.","title":"Intr Sonogrphc Vascular Lab"},
{"id":"DVT401.","title":"Intro to Vasc Anatomy & Proced"},
{"id":"ECE241.","title":"Digital Logic"},
{"id":"ECE347.","title":"Microcomputer Engineering"},
{"id":"ECHO398.","title":"Intro Echocardiography Lab"},
{"id":"ECHO401.","title":"Introduction Echocardiography"},
{"id":"ECON208.","title":"Microeconomics"},
{"id":"ECON209.","title":"Macroeconomics"},
{"id":"ECON241.","title":"Global Econ Issues"},
{"id":"ECON265.","title":"Elem Statistics"},
{"id":"ECON601.","title":"Managerial Economics"},
{"id":"EDLE708.","title":"Advanced Research Design/Mthds"},
{"id":"EDLE727.","title":"Special Education Policy/Pract"},
{"id":"EDLE756.","title":"School Personnel Management"},
{"id":"EDLE760.","title":"Internship Sch District Adm"},
{"id":"EDLE763.","title":"Community-Based Curric & Instr"},
{"id":"EDLE770.","title":"Internship in Ped Leadership"},
{"id":"EDUC203.","title":"Intro to Children's Literature"},
{"id":"EDUC206.","title":"Intro to Exceptionalities"},
{"id":"EDUC340.","title":"ST: Aim4Excellence 1-3"},
{"id":"EDUC340.","title":"ST: Aim4Excellence 4-6"},
{"id":"EDUC373.","title":"Tching Science Elem School"},
{"id":"EDUC437.","title":"Classrm Mngt Data-Based Dec"},
{"id":"EDUC490.","title":"ST-Aim4Excellence 7-9"},
{"id":"EDUC512.","title":"Assessing Stdnts w/Spec Needs"},
{"id":"EDUC601.","title":"Research in Education"},
{"id":"EDUC680.","title":"Internship for School Leaders"},
{"id":"EDUC685.","title":"Curr & Learning Educ Leadrs"},
{"id":"ENG101.","title":"Rhet&Comp I:Literacy/Self"},
{"id":"ENG201.","title":"Rhet&Comp II:Literacy/World"},
{"id":"ENG301.","title":"Advanced Composition"},
{"id":"ENGR299.","title":"Co-op Experience"},
{"id":"ENGR355.","title":"Strength of Materials"},
{"id":"ENGR390.","title":"Special Design Projects"},
{"id":"ENGR482.","title":"Engineering Org & Mngt"},
{"id":"ENGR499.","title":"Independent Study/Research"},
{"id":"EXSC394.","title":"Measurement & Evaluation"},
{"id":"EXSC478.","title":"Essentials Strgth Trning&Cond"},
{"id":"EXSC496.","title":"Practicum in Exercise Science"},
{"id":"EXSC497.","title":"Practicum Strength & Condition"},
{"id":"FIN101.","title":"Money Skills"},
{"id":"FIN201.","title":"Fundamentals of Finance"},
{"id":"FIN305.","title":"Business Finance"},
{"id":"FIN361.","title":"Financial Institutions"},
{"id":"GENS399.","title":"Field Experience"},
{"id":"GEOG112.","title":"Earth System Science"},
{"id":"GEOG123.","title":"Phys Geog/Landscp Analysis US"},
{"id":"GEOG330.","title":"World Geography"},
{"id":"GEOL121.","title":"Geology of Gemstones"},
{"id":"GEOL161.","title":"Introduction to Geology"},
{"id":"GERO215.","title":"Hlth Care Aspects Gerontology"},
{"id":"GNDR111.","title":"Intro to Gender Studies"},
{"id":"GNDR222.","title":"The Body in Art and Culture"},
{"id":"HA407.","title":"Issues Hlth Care Ldrship"},
{"id":"HI301.","title":"Health Informatics"},
{"id":"HI410.","title":"Internship in HIIM"},
{"id":"HIST101.","title":"The United States to 1877"},
{"id":"HIST111.","title":"World Civ I"},
{"id":"HIST464.","title":"Social History of Murder"},
{"id":"HIST490.","title":"Intern/Spec St-History"},
{"id":"HP115.","title":"Medical Terminology"},
{"id":"HP211.","title":"Health Care System"},
{"id":"HP225.","title":"Pharmacology and Therapeutics"},
{"id":"HP302.","title":"Biostatistics"},
{"id":"HP475.","title":"Professionalism in Health Care"},
{"id":"HP480.","title":"Internship in Hlth Professions"},
{"id":"HP490.","title":"ST:Health Care Leadership"},
{"id":"HP498.","title":"Curr Cncpts-Hlth Prof"},
{"id":"HUM211.","title":"World Humanities I"},
{"id":"HUM212.","title":"World Humanities II"},
{"id":"IEP092.","title":"IEP-Writing"},
{"id":"IEP093.","title":"IEP-Acad Skills with Grammar"},
{"id":"IEP094.","title":"IEP-Reading"},
{"id":"IEP095.","title":"IEP-Listening and Speaking"},
{"id":"IM611.","title":"Princ & Pract-Project Mngt"},
{"id":"IME315.","title":"Process Improvement"},
{"id":"IME431.","title":"Process Integration w/SAP ERP"},
{"id":"IPH356.","title":"Ethics & Hlth Care Plural Soc"},
{"id":"IPH401.","title":"Intprfssl Persp on Global Hlth"},
{"id":"KIN192.","title":"Concepts of Wellness & Fitness"},
{"id":"KIN281.","title":"Personal Health Sci"},
{"id":"KIN284.","title":"Stress Management"},
{"id":"KIN393.","title":"Practicum in Kinesiology I"},
{"id":"KIN493.","title":"Practicum in Kinesiology II"},
{"id":"LBST680.","title":"ST: Social History of Murder"},
{"id":"LBST690.","title":"Independent Work-LBST"},
{"id":"MATH081.","title":"Foundations Quant Reasoning"},
{"id":"MATH085.","title":"Foundations for College Alg"},
{"id":"MATH103.","title":"Math Elementary Teachers I"},
{"id":"MATH107.","title":"Fundmntls Math for Health Prof"},
{"id":"MATH111.","title":"College Algebra"},
{"id":"MATH114.","title":"Quantitative Reasoning"},
{"id":"MATH118.","title":"Comprehensive Pre-calculus"},
{"id":"MATH203.","title":"Math Elementary Teachers II"},
{"id":"MATH215.","title":"Survey of Calculus"},
{"id":"MATH230.","title":"Calculus I"},
{"id":"MATH335.","title":"Calculus III"},
{"id":"MATH366.","title":"Differential Equations"},
{"id":"MBA604.","title":"Lean Six Sigma"},
{"id":"ME225.","title":"Thermodynamics"},
{"id":"MHA622.","title":"Biostatistics"},
{"id":"MHA625.","title":"Mktg & Comp Strat-Hlth Care"},
{"id":"MHA635.","title":"Sem In Health Administration"},
{"id":"MHA637.","title":"Leadership in Health Care"},
{"id":"MHA643.","title":"Health Care Finance"},
{"id":"MHA652.","title":"Capstone II: Implementation"},
{"id":"MKTG201.","title":"Intro to Marketing"},
{"id":"MKTG305.","title":"Principles Marketing"},
{"id":"MKTG332.","title":"Consumer Behavior"},
{"id":"MKTG344.","title":"Personal Selling"},
{"id":"MKTG355.","title":"Interactive Marketing"},
{"id":"MKTG601.","title":"Marketing Strategies"},
{"id":"MNGT305.","title":"Principles of Management"},
{"id":"MNGT315.","title":"Project Managment I"},
{"id":"MNGT452.","title":"Policy Formulation Adm"},
{"id":"MNGT611.","title":"Leadership Skills & Innovation"},
{"id":"NURS327.","title":"Transitions to Adv Nursing"},
{"id":"NURS331.","title":"Care Coordination in Prof Nurs"},
{"id":"NURS337.","title":"Population Health & Prof NURS"},
{"id":"NURS488.","title":"Leadership in Care Delivery"},
{"id":"NURS492.","title":"Clinical Synthesis"},
{"id":"NURS618.","title":"Adv Health Assessmnt"},
{"id":"NURS647.","title":"Adv Assmnt & Intrvtn Practicum"},
{"id":"NURS656.","title":"Family Nurse Pract Practicum"},
{"id":"NURS664.","title":"Psych Mental Hlth Care-Fam III"},
{"id":"NURS673.","title":"Evaluation Strat for Nur Edu"},
{"id":"NURS676.","title":"Mgt Acutely Ill Adult-Gero III"},
{"id":"NURS678.","title":"Nurse Educ Role Integration"},
{"id":"NURS686.","title":"Primary Care Adults/Elders III"},
{"id":"NURS715.","title":"Analyticl Meths Popul Base Cre"},
{"id":"NURS724.","title":"Hlth Care Plcy Strategic Plan"},
{"id":"NURS735.","title":"Applications Population Health"},
{"id":"NURS737.","title":"Hlth Policy APN"},
{"id":"NURS741.","title":"Appl Assessment/Differentials"},
{"id":"NUTR376.","title":"Princpl/App in Nutrition"},
{"id":"NUTR378.","title":"Nutr-Fitness & Sport"},
{"id":"NUTR420.","title":"Practicum Food,Nutr,Wellness"},
{"id":"NUTR496.","title":"Ldrship/Prof Iss-Food &Nutr"},
{"id":"OT637.","title":"Occup Intervn/Evid Based Pract"},
{"id":"OT651.","title":"Prof Trends/Emerging Practice"},
{"id":"OT696.","title":"Professional Fieldwork I"},
{"id":"OT697.","title":"Professional Fieldwork II"},
{"id":"OTA298.","title":"Practicum Seminar B"},
{"id":"OTA344.","title":"Occup Performance Areas II"},
{"id":"OTA345.","title":"Occupatnl Performnc Pediatrics"},
{"id":"OTA372.","title":"Mngt for Occup Therapy Asst"},
{"id":"PA606.","title":"Public/Nonprofit Personnel Adm"},
{"id":"PA689.","title":"Ind Study In Public Admin"},
{"id":"PA695.","title":"Internship in Public Agency"},
{"id":"PA699.","title":"Thesis in Public Admin"},
{"id":"PET402.","title":"Controv Issues in Health Educ"},
{"id":"PH284.","title":"Public Health"},
{"id":"PH483.","title":"Hlth Around Us:Cncpts Env Hlth"},
{"id":"PH485.","title":"Epidemiology"},
{"id":"PHIL201.","title":"Intro to Ethics"},
{"id":"PHYS101.","title":"Intro Physical Sci"},
{"id":"PHYS175.","title":"General Physics I"},
{"id":"PHYS175L","title":"General Physics I Lab"},
{"id":"PHYS205.","title":"Intermed Physics I"},
{"id":"PHYS205L","title":"Intermediate Physics I Lab"},
{"id":"POLS102.","title":"Intr American Polit"},
{"id":"POLS351.","title":"Leadership Bureaucratic Orgs"},
{"id":"POLS445.","title":"Ancient Political Theory"},
{"id":"PRFS301.","title":"Ethics of Global Engagement"},
{"id":"PRFS401.","title":"Teams/Diversity/Leadership"},
{"id":"PRFS490.","title":"Professional Studies Intrnship"},
{"id":"PRFS498.","title":"Personal & Professional Dev"},
{"id":"PRL101.","title":"Intro to Public Relations"},
{"id":"PSY201.","title":"Intro to Psychology"},
{"id":"PSY202.","title":"Orient to Major in Psy"},
{"id":"PSY261.","title":"Lifespan Develop Psy"},
{"id":"PSY273.","title":"Social Psychology"},
{"id":"PSY322.","title":"Abnormal Psychology"},
{"id":"PSY362.","title":"Clinical Psychology"},
{"id":"PSY377.","title":"Sport Psychology"},
{"id":"PSY400.","title":"ST-Wrongful Conviction"},
{"id":"PSY425.","title":"Cognitive Psychology"},
{"id":"PSY462.","title":"Psychol of Personality"},
{"id":"PSY497.","title":"Capstone Psy:Hist and Syst"},
{"id":"PSY498.","title":"Internship"},
{"id":"RADT196.","title":"Orient Radiologic/Imag Sci"},
{"id":"RADT312.","title":"Radiographic Exposure"},
{"id":"RADT313.","title":"Radiographic Procedures II"},
{"id":"RADT333.","title":"Clinical Practicum I"},
{"id":"RADT411.","title":"CT/MRI Physics & Instr"},
{"id":"RADT415.","title":"CT/MRI Procedures II"},
{"id":"RADT435.","title":"Clinical Practicum IV"},
{"id":"RADT486.","title":"Clinical IX-CT/MRI"},
{"id":"REST451.","title":"Mgt and Ldrshp in Resp Therapy"},
{"id":"REST454.","title":"Advanced Critical Care"},
{"id":"REST456.","title":"Prof Issues in Resp Ther"},
{"id":"REST460.","title":"Independ Study Resp Therapy"},
{"id":"REST499.","title":"Special Topics Resp Therapy"},
{"id":"SOC121.","title":"Principles Sociology"},
{"id":"SOCW221.","title":"Intro to Social Work"},
{"id":"SOCW241.","title":"Soc Work Intervention"},
{"id":"SOCW400.","title":"ST-Understanding Adoption"},
{"id":"SOCW500.","title":"ST:Death & Dying"},
{"id":"SOCW500.","title":"ST:Music of the Oppressed"},
{"id":"SOCW500.","title":"ST:Social Work in Community Co"},
{"id":"SOCW500.","title":"ST:Therapeutic Mindflnss SW Pr"},
{"id":"SOCW511.","title":"Grad Fld Placemnt I"},
{"id":"SOCW512.","title":"Integr Fld Placemt Sem"},
{"id":"SOCW610.","title":"Human Diversity in SW Practice"},
{"id":"SOCW611.","title":"Grad Fld Placemnt II"},
{"id":"SOCW612.","title":"Grad Fld Seminar II"},
{"id":"SOCW625.","title":"Socw Pract-Child & Adol"},
{"id":"SOCW634.","title":"SW Pract w/Addictions & FamSys"},
{"id":"SOCW674.","title":"Psychopharmacology"},
{"id":"SPTM223.","title":"Princ of Recreation"},
{"id":"SPTM226.","title":"Princ & Probs of Coaching"},
{"id":"SPTM305.","title":"Practicum in Sport Management"},
{"id":"SPTM332.","title":"Sport Facility Management"},
{"id":"SPTM453.","title":"Diversity in Sport"},
{"id":"SPTM498.","title":"Internship in Sport Management"},
{"id":"STAT241.","title":"Principles of Statistics"},
{"id":"TECH299.","title":"Co-op Experience"},
{"id":"TECH439.","title":"Spec Problems-Indust Suprvsn"},
{"id":"THTR101.","title":"Intro to Theatre"},
{"id":"WLC155.","title":"Cultural Awareness"},
{"id":"WLC423.","title":"TESOL Certificate"},
{"id":"WLC523.","title":"Tching Engl Speakers Othr Lang"},
{"id":"ACCT202.","title":"Accounting Princ II"},
{"id":"ACCT304.","title":"Intermed Accounting II"},
{"id":"ACCT416.","title":"Advanced Cost Acct"},
{"id":"ACCT499.","title":"Accounting Internship"},
{"id":"ACCT628.","title":"Acct Regulation & Compliance"},
{"id":"ANTH131.","title":"Intro to Physical Anthropology"},
{"id":"BAN602.","title":"Data-Driven Decision Making"},
{"id":"BAN603.","title":"Info Visualization & Dshbrding"},
{"id":"BIOL152.","title":"Zoology"},
{"id":"BIOL371.","title":"Tropical Biology"},
{"id":"BLAW263.","title":"Legal Env of Business"},
{"id":"BUAD499.","title":"Professional Practice"},
{"id":"CHEM108.","title":"Chemistry for Educators"},
{"id":"CHEM262.","title":"General Chemistry II"},
{"id":"CHEM354.","title":"Organic Chemistry II"},
{"id":"CIS301.","title":"Enterprise Processes & IS"},
{"id":"CIS367.","title":"Data Communications"},
{"id":"CIS601.","title":"Information Sys & Technology"},
{"id":"CMST314.","title":"Organizational Communication"},
{"id":"CMST318.","title":"Conflict Resolution"},
{"id":"COMM495.","title":"Mass Comm Research"},
{"id":"COMM634.","title":"Social Media, Culture, and Soc"},
{"id":"CRIM234.","title":"Introduction to Corrections"},
{"id":"CRIM315.","title":"Crime Control Strategies"},
{"id":"CRIM370.","title":"Sem:Hate Groups, Hate Crimes"},
{"id":"CS258.","title":"Intro to Object-Oriented Progr"},
{"id":"DSCI351.","title":"Intro to Operatn Resrch"},
{"id":"EDUC221.","title":"Diversity and Equity-Education"},
{"id":"EDUC388.","title":"Teach Soc Studies-Elem"},
{"id":"EDUC408.","title":"Tching Read Stds w/Spec Needs"},
{"id":"EDUC511.","title":"Found & Legal-Exceptnl Needs"},
{"id":"ENG227.","title":"Literature and Popular Culture"},
{"id":"ENGR291.","title":"Exper Design & Tech Writing"},
{"id":"ENGR305.","title":"Engineering Statistics"},
{"id":"ENGR335.","title":"Engineering Economics"},
{"id":"EXSC376.","title":"Behavioral Strategies of Exerc"},
{"id":"EXSC383.","title":"Kinesiology"},
{"id":"EXSC488.","title":"Prgm Dsgn Diverse Populations"},
{"id":"FIN461.","title":"Financial Management"},
{"id":"FIN601.","title":"Financial Management & Markets"},
{"id":"GENS151.","title":"Academic Reading Strategies"},
{"id":"GENS199.","title":"EAB: Student Success"},
{"id":"GEOL390.","title":"Geological Field Excursion"},
{"id":"GERO312.","title":"Living Well Through Lifespan"},
{"id":"HIST102.","title":"The United States since 1877"},
{"id":"HIST112.","title":"World Civ II"},
{"id":"HIST316.","title":"The Vietnam War"},
{"id":"HIST598.","title":"Sem-The Vietnam War"},
{"id":"HP236.","title":"Eastrn Med & Alt/Comp Hlth"},
{"id":"HP377.","title":"Foundations for Hlth Promotion"},
{"id":"MATH115.","title":"Pre-Calculus Math"},
{"id":"MATH235.","title":"Calculus II"},
{"id":"MATH604.","title":"Fund Concept-Geometry"},
{"id":"MBA602.","title":"Risk Management"},
{"id":"MHA624.","title":"Economic Policies-Hlth Care"},
{"id":"MHA633.","title":"Human Res& Labor Rel Mngt"},
{"id":"MHA642.","title":"Health Informatics"},
{"id":"MKTG313.","title":"Marketing of Services"},
{"id":"MKTG342.","title":"Bus & Industrial Marketing"},
{"id":"MKTG471.","title":"Internationl Marketing"},
{"id":"MNGT201.","title":"Survey of Management"},
{"id":"MNGT341.","title":"Human Resource Mngt"},
{"id":"MNGT601.","title":"Managing Human Capital"},
{"id":"MNGT681.","title":"Strategic & Internatl Mngt"},
{"id":"NURS307.","title":"Health Assessment for RN's"},
{"id":"NURS333.","title":"Nursing Informatics"},
{"id":"NURS367.","title":"Application Evidnce Bsed Pract"},
{"id":"NURS487.","title":"Ldrshp in Nrsg & Hlthcare Orgs"},
{"id":"NURS498.","title":"Internship-Prof Nursing"},
{"id":"PHYS176.","title":"General Physics II"},
{"id":"PHYS176L","title":"General Physics II Lab"},
{"id":"POLS104.","title":"Intro to Public Administration"},
{"id":"POLS363.","title":"Public Organizational Behavior"},
{"id":"PRL362.","title":"Writing Public-Relatns"},
{"id":"PRL365.","title":"Public Relatn Administrn"},
{"id":"PSY332.","title":"Forensic Psychology"},
{"id":"REST452.","title":"Intro to Research Resp Therapy"},
{"id":"REST453.","title":"Resp Therapy Disease Mgt"},
{"id":"REST455.","title":"Pul Rehab and Geriatrics"},
{"id":"SOC261.","title":"Marriage & Family"},
{"id":"SOCW222.","title":"Social Welfare"},
{"id":"SOCW239.","title":"Hum Behav Soc Environ"},
{"id":"SOCW664.","title":"Crisis Intervention"},
{"id":"SOCW672.","title":"Legal,Ethical,Prof Stds Addict"},
{"id":"SPTM491.","title":"Managing Sport Organizations"},
{"id":"SPTM492.","title":"Contemp Iss-Sport & Exercise"},
{"id":"SPTM688.","title":"Strategic Sport Communication"},
]
module.exports={data}