<template>
  <div>
    <PageBanner
      :bannerText="'Acceptable Instructors'"
      subtitle
      v-if="readOnly"
      section="instructors"
    />
    <v-container class="py-0">
      <div v-for="(courseSlot, index) in courseSlots" :key="index">
        <v-row
          v-if="courseSlot['id']"
          :class="'d-flex align-content-space-around flex-wrap px-2' +
                      (index + 1 == selectedTerm.reservationLimit
              ? ''
              : ' row-border')

          "
          no-gutters
        >
          <v-col
            cols="2"
            class=" d-flex align-content-space-around flex-wrap pl-1 pr-4 align-center justify-center"
          >
            <div class="slot-badge full">
              <div>{{ courseSlot.slot }}</div>
            </div>
          </v-col>
          <v-col cols="4" class="course-info mx-0 px-0">
            <v-card
              height="46"
              flat
              class="d-flex align-content-space-around flex-wrap align-center"
            >
              <div>
                {{ courseSlot["id"] }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="6" class="course-info mx-0 px-0">
            <v-card
              height="46"
              flat
              class="d-flex align-content-space-around flex-wrap align-center px-1"
            >
              <div class="text-caption">
                {{ courseSlot["title"] }}
              </div>
            </v-card>
          </v-col>
          <v-container v-if="courseSlot['id']" class="pa-0 ma-0">
            <div v-for="instructor in instructors" :key="instructor.id">
              <v-row
                class="d-flex align-content-space-around flex-wrap pa-0 ma-0 instructor-check-row"
                @click="toggleCheck(instructor, courseSlot)"
                no-gutters
              >
                <v-col cols="2" class="pa-0 ma-0"></v-col>
                <v-col cols="10" class="pa-0 ma-0">
                  <v-checkbox
                    color="primary"
                    :label="instructor.title"
                    :input-value="check(instructor)"
                    class="instructor-selector"
                    off-icon="$boxBlank"
                    on-icon="$boxChecked"
                    dense
                  ></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatCourseSlots } from "@/utils.js";
import PageBanner from "@/components/PageBanner";
// import lodash from "lodash";

import logdown from "logdown";
const logger = logdown("TermCoursesInstructors");

export default {
  name: "TermCoursesSlots",
  components: {
    PageBanner,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      courseSlotForDeletion: undefined,
      componentKey: 0,
      slots: [],
      courseSlots: [
        // { slot: 1, courseId: "MAT 1A", title: "College Algebra I" },
        // { slot: 2, courseId: "ENG 1B", title: "English Composition" },
      ],
      testIndex: 10,
    };
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      instructors: "getAllInstructors",
    }),
  },
  methods: {
    ...mapGetters({
      getUserCourses: "getUserCourses",
    }),
    toggleCheck(instructor, courseSlot) {
      logger.debug("toggleCheck", instructor, courseSlot);
    },
    check(instructor) {
      logger.debug("check", instructor);
    },

    refreshSlots() {
      formatCourseSlots(
        this.courseSlots,
        this.getUserCourses(),
        this.selectedTerm.id,
        this.selectedTerm.reservationLimit
      );
    },
  },
  created() {
    this.refreshSlots();
  },
};
</script>

<style scoped>
.course-info{
  color: #282828;
  border-bottom: 1px solid #939393;
  border-radius: 0px;
}
.instructor-check-row {
  cursor: pointer;
  height: 40px;
}
.row-border {
  border-bottom: 1px solid #333333;
}
.slot-badge {
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty {
  background-color: #bdbdbd;
  border-style: solid #bdbdbd;
}
.full {
  background-color: #4f4f4f;
  border-style: solid #4f4f4f;
}
</style>
