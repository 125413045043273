<template>
  <v-row align="center">
    <v-col class="text-center" cols="12">
      <Button look="btnFinishWP" @click="finish()" height="39" width="244" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Button from "@/components/Button";
import { postFeedback } from "@/api/feedback";
import logdown from "logdown";
const logger = logdown("ATermConfirmationFooter");

export default {
  name: "ATermConfirmationFooter",

  components: { Button },

  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
    }),
  },

  methods: {
    ...mapGetters({
      getUserConfirmations: "getUserConfirmations",
      getUserFeedback: "getUserFeedback",
      getUserRating: "getUserRating",
    }),
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    finish() {
      // this.$vuetify.goTo(0);
      this.selectPage("SelectATerm");
      this.$forceUpdate();
      const feedback = this.getUserFeedback()
      const rating = this.getUserRating()
      postFeedback({
        text: feedback,
        rating: rating,
        mode: "finish",
      })
        .then(() => {
          logger.debug(`postFeedback, finish, ok ${rating}, ${feedback}`);
        })
        .catch((err) => logger.error(err));
    },
  },
};
</script>
