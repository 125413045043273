<template>
  <div id="scrollContainer">
    <PageBanner bannerText="Set acceptable times" />
    <TermCoursesTimes :readOnly="false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { scrollElementIntoView } from '@/utils'
import PageBanner from "@/components/PageBanner";
import TermCoursesTimes from "@/components/TermCoursesTimes";

import logdown from "logdown";
const logger = logdown("TermTimes");
logger.state.isEnabled = true;

export default {
  name: "TermTimes",
  components: {
    PageBanner,
    TermCoursesTimes,
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
    }),
  },
  methods: {},
  mounted: function() {
    logger.debug("mounted");
    let el = document.getElementById("scrollContainer");
    if (el) {
      // el.scrollIntoView(true);
      scrollElementIntoView(el)
    }
  },
};
</script>
