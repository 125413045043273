<template>
  <div>
    <PageBanner bannerText="Select acceptable delivery methods" />
    <TermCoursesInstructors :readOnly="false"/>
  </div>
</template>

<script>
// import lodash from "lodash";
import { mapGetters } from "vuex";
import PageBanner from "@/components/PageBanner";
import TermCoursesInstructors from "@/components/TermCoursesInstructors";

// import logdown from "logdown";
// const logger = logdown("TermLocations");
// logger.state.isEnabled = true;

export default {
  name: "TermInstructors",
  components: {
    PageBanner,
    TermCoursesInstructors,
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
    }),
  },
  methods: {
  },
};
</script>
