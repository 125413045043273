<template>
  <div :style="'height: 100%; width: 100%; display: flex; flex-flow: column;'">
    <div
      v-if="barRole == 'top-bar'"
      :style="
        'display: flex; width: 100%; height: 100%;' +
          ' justify-content: center; background: ' +
          backdropColor +
          ';'
      "
    >
      <div
        :style="
          'width: ' +
            contentWidth +
            '; background: ' +
            backgroundColor +
            '; margin: 0; padding: 0; height: 100%; ' +
            'display: flex; flex-flow: column; justify-content: center;'
        "
        @goto="onGoto"
      >
        <slot></slot>
      </div>
    </div>
    <div
      v-if="barRole == ''"
      :style="
        'display: flex; width: 100%; justify-content: center; background: ' +
          backdropColor +
          ';'
      "
    >
      <div
        :style="
          'width: ' + contentWidth + '; background: ' + backgroundColor + ';'
        "
      >
        <div id="parent-box" 
            :style="`width: ${contentWidth}; 
            height: ${appWindowHeight - topBarHeight - footBarHeight}px;
            overflow-y: ${!scrollY?'auto':scrollY}`"
        >
          <div id="slot-box">
            <div id="slot-content" @goto="onGoto">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content appBar -->
    <div
      v-if="barRole == 'foot-bar'"
      :style="
        'display: flex; width: 100%; justify-content: center; background: ' +
          backdropColor +
          ';'
      "
    >
      <div
        :style="
          'width: ' + contentWidth + '; background: ' + backgroundColor + ';'
        "
        @goto="onGoto"
      >
        <slot></slot>
      </div>
    </div>
    <div
      :style="
        (debugStyle ? debugBorder : '') +
          ' flex: 1 1 auto; background: ' +
          backdropColor +
          ';' +
          ' flex-flow: row; display: flex; justify-content: center; '
      "
    >
      <div
        :style="
          'background: ' +
            backgroundColor +
            '; width:' +
            contentWidth +
            '; height 44px;'
        "
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import logdown from "logdown";
const logger = logdown("DesktopView");

export default {
  name: "DesktopView",
  props: {
    appBar: {
      type: Boolean,
      default: false,
    },
    barRole: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    backdropColor: {
      type: String,
      default: "",
    },
    contentWidth: {
      type: String,
      default: "100%",
    },
    topBarHeight:{
      type: Number,
      defaut: 0,
    },
    footBarHeight:{
      type: Number,
      defaut: 0,
    },
    scrollY: {
      type: String,
      defaut: 'auto',
    },
  },
  computed:{
    ...mapGetters({
      appWindowHeight: 'getAppWindowHeight',
    })
  },
  data: () => ({
    debugStyle: false,
    debugBorder: "border: solid 1px green; background: yellow;",
  }),
  methods: {
    onGoto(){
      logger.debug('goto')
    },
  },
};
</script>

<style scoped>
div#parent-box::-webkit-scrollbar {
    display: none;
}
#parent-box {
  /* border: solid 5px navy; */
  position: absolute;
  /* width: 700px; */
  height: 100%;
  /* overflow-y: hidden; */
  overflow-x: hidden;
}
#slot-box {
  /* border: solid 5px salmon; */
  /* height: inherit; */
  display: flex;
  flex-flow: column;
}
/* #slot-content { 
  border: solid 5px olivedrab;
  height: 600px;
}  */
</style>
