// import _ from 'lodash'
import logdown from 'logdown'
import axios from 'axios'
import { apiRoot } from '@/api/config'
import { getAuthKey, } from "@/utils";

const logger = logdown('email')

function sendEmail(email, message) {
    logger.log(`sendEmail, ${email}`, message)
    return new Promise((resolve, reject) => {
        axios.post(`${apiRoot()}/email`, {
            localKey: getAuthKey(),
            email: email,
            message: message,
        })
            .then(response => {
                const data = response
                logger.debug('sendEmail', data)
                resolve(data)
            })
            .catch(error => {
                // Errors 
                logger.error('sendEmail', error)
                reject(error)
            })
    })
}

export {
    sendEmail,
}