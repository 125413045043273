<template>
  <div id="scrollContainer">
    <PageBanner v-if="!courseSelectionMode" :bannerText="'Quick Request'" />
    <TermSelector
      v-if="!courseSelectionMode && isEditMode()"
      :terms="getAllTerms()"
      :selectedTerm="term"
      :userCourses="userCourses"
      @quick-term-select="onTermSelected"
      :key="term.id"
    />
    <QuickRequestInfo
      v-if="!courseSelectionMode && !isEditMode()"
      :term="term"
      :studentId="studentId"
      :confirmation="getTermConfirmation(term)"
      :url="getQRUrl()"
    />
    <StudentIdSelector
      v-if="!courseSelectionMode && isEditMode()"
      :studentId="studentId"
      @student-id-update="onStudentIdUpdate"
      :key="studentId"
    />
    <CoursesSlots
      v-if="!courseSelectionMode"
      :selectedTerm="term"
      :userCourses="userCourses"
      @select-course="onSelectCourse"
      @course-deleted="onCourseDeleted"
      @edit-click="onStartEdit"
      :readOnly="!isEditMode()"
      :userInfo="false"
      :banner="'Requests'"
      :key="slotsRefreshHint"
    />
    <CourseSelector
      v-if="courseSelectionMode"
      :selectedTerm="term"
      :selectedSlot="slot"
      @add-course="onAddCourse"
    />
    <v-container v-if="courseCount(term) > 0 && isEditMode()">
      <v-row align="center">
        <v-col class="text-center" cols="12">
          <CustomButton
            look="longPrimary"
            text="Confirm"
            @click="confirm(term)"
            height="39"
            width="147"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-0" v-if="donePanel">
      <v-row class="email-row small-text">
        <v-col class="px-4 pt-3 pb-1">
          Email confirmation
        </v-col>
      </v-row>
      <v-row class="email-row small-text" align="center">
        <v-col cols="9" class="pl-4 pb-3 pt-1 pr-1">
          <div>
            <v-text-field
              color="#4F4F4F"
              background-color="white"
              class="email-text pa-0"
              dense
              hide-details
              outlined
              v-model="email"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="3" class="pl-1 pr-4 pb-3 pt-1">
          <CustomButton
            look="smallPrimary"
            text="Send"
            size="50"
            @click="onEmailSend"
          />
        </v-col>
      </v-row>
      <v-row class="done-row" align="center">
        <v-col class="text-center" cols="12">
          <CustomButton
            look="longPrimary"
            text="Done"
            @click="done(term)"
            height="39"
            width="147"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="emailSent"
      max-width="500"
      persistent
      style="overflow-x: hidden;"
    >
      <v-card class="pa-0" style="overflow-x: hidden;">
        <v-card-title class="menu-headline pa-0">
          <v-container class="pa-1">
            <v-row no-gutters>
              <v-col cols="6" class="d-flex justify-start pl-1">
                <!-- <v-icon color="white" class="px-2">mdi-account-outline</v-icon>  -->
                Email sent
              </v-col>
              <v-col cols="4"> </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <v-icon color="white" class="px-2" @click="emailSent = false"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <!-- student mode -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" align="center">
                <div class="message-text">Confirmation sent to:</div>
              </v-col>
            </v-row>
            <v-row align="center" no-gutters>
              <v-col cols="12" align="center">
                <div class="primary-color message-text">{{ email }}</div>
              </v-col>
            </v-row>
          </v-container>
          <p>
            <CustomButton
              class="py-2 my-2"
              look="smallPrimary"
              text="OK"
              size="50"
              @click="emailSent = false"
            />
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.message-text {
  font-size: 18px;
}
.primary-color {
  color: #9b51e0;
}
.menu-headline {
  color: white;
  background: #4f4f4f;
}
.email-row {
  background: #828282;
}
.done-row {
  background: #4f4f4f;
}
.small-text {
  font-size: 10px;
  color: white;
}
.email-text {
  font: 12px;
  color: white;
}
</style>
<script>
import {
  mapGetters,
  // mapMutations, mapState
} from "vuex";
import { scrollElementIntoView, computeConfirmation, getUserId } from "@/utils";
// import { skipPage } from "@/api/config";
import PageBanner from "@/components/PageBanner";
import QuickRequestInfo from "@/components/QuickRequestInfo";
import TermSelector from "@/components/TermSelector";
import StudentIdSelector from "@/components/StudentIdSelector";
import CoursesSlots from "@/components/CoursesSlots";
import CourseSelector from "@/components/CourseSelector";
import CustomButton from "@/components/CustomButton";
// import Button from "@/components/Button";
import { countItems } from "@/api/reservations";
import { sendEmail } from "@/api/email";
import { updateStudent } from "@/api/advisor";
import logdown from "logdown";
import _ from "lodash";
const logger = logdown("QuickRequest");

export default {
  name: "QuickRequest",
  components: {
    PageBanner,
    QuickRequestInfo,
    CoursesSlots,
    CourseSelector,
    TermSelector,
    StudentIdSelector,
    CustomButton,
    // Button,
  },
  data: function() {
    return {
      studentId: undefined,
      term: this.getDefaultTerm(),
      userCourses: {},
      userConfirmations: {},
      userModified: {},
      courseSelectionMode: false,
      slot: undefined,
      slotsRefreshHint: "",
      email: "",
      donePanel: false,
      emailSent: false,
    };
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
    }),
  },
  methods: {
    getQRUrl() {
      const url = `${window.location.origin}/bm/${_.get(this.term, "id")}`;
      logger.log(`getQRUrl: ${url}`);
      return url;
    },
    getDefaultTerm() {
      const terms = this.getAllTerms();
      const defaultTermId = _.get(this.config, "quickRequestStartTerm");
      logger.log(`defaultTermId:${defaultTermId}`, this.config);
      return _.get(
        terms,
        _.findIndex(terms, (v) => _.get(v, "id") == "2021Fall")
      );
    },
    getTermConfirmation(term) {
      return _.get(this.userConfirmations, _.get(term, "id"));
    },
    ...mapGetters({
      getAllTerms: "getAllTerms",
      getUserCourses: "getUserCourses",
    }),
    refreshHint() {
      this.slotsRefreshHint = `${new Date().getTime()}`;
    },
    onTermSelected({ term }) {
      logger.log(`onTermSelected, ${_.get(term, "id")}`);
      this.term = term;
      this.refreshHint();
    },
    onStudentIdUpdate({ id }) {
      logger.log(`onStudentIdUpdate: ${id}`);
      this.studentId = id;
    },
    onSelectCourse({ slot }) {
      logger.log(`onSelectCourse`, slot);
      this.slot = slot;
      this.courseSelectionMode = true;
    },
    onAddCourse({ course, term, slot }) {
      logger.log(`onAddCourse`);
      _.set(this.userCourses, [term.id, slot], course);
      this.courseSelectionMode = false;
      logger.log(`onAddCourse, userCourses:`, this.userCourses);
    },
    onCourseDeleted({ term, slot }) {
      logger.log(`onCourseDeleted`, term, slot);
      _.unset(this.userCourses, [term.id, slot]);
      logger.log(`onCourseDeleted`, this.userCourses);
    },
    onStartEdit({ section }) {
      logger.log(`onStartEdit:${section}`);
      _.set(this.userModified, _.get(this.term, "id"), true);
      this.refreshHint();
    },
    onEmailSend() {
      // data prep.
      const termId = _.get(this.term, "id");
      const termName = _.get(this.term, "title");
      const course1 = _.get(this.userCourses, [termId, 1, "id"]);
      const courseName1 = _.get(this.userCourses, [termId, 1, "title"]);
      const course2 = _.get(this.userCourses, [termId, 2, "id"]);
      const courseName2 = _.get(this.userCourses, [termId, 2, "title"]);
      const confirmation = _.get(this.userConfirmations, [termId]);
      sendEmail(this.email, {
        termLink: `https://${this.config.appDomain}.edreserve.app/bm/${termId}`,
        userCourseName2: courseName2,
        userCourse2: course2,
        userCourseName1: courseName1,
        userCourse1: course1,
        userConfirmation: confirmation,
        userTerm: termName,
      })
        .then((data) => {
          logger.log(`email sent,`, data);
        })
        .catch((e) => {
          logger.log(`email failed,`, e);
        });
      this.emailSent = true;
    },
    courseCount(term) {
      logger.log(`courseCount, term.id: ${term.id}`);
      return countItems(this.userCourses, term.id);
    },
    isConfirmed(term) {
      return _.size(this.getTermConfirmation(term)) > 0;
    },
    isEditMode() {
      let mode;
      if (!this.isConfirmed(this.term)) {
        mode = true;
      } else {
        mode = _.get(this.userModified, [_.get(this.term, "id")]);
      }
      logger.log(`isEditMode: ${mode}`);
      return mode;
    },
    confirm(term) {
      if (!this.isConfirmed(term)) {
        let confirmation = computeConfirmation();
        _.set(this.userConfirmations, _.get(term, "id"), confirmation);
      }
      //TODO: submit to storage
      // this.studentId, this.term, this.userConfirmations, this.userCourses
      const termId = _.get(this.term, "id");
      updateStudent({
        studentId: this.studentId,
        termId,
        userConfirmation: _.get(this.userConfirmations, termId),
        userCourses: _.get(this.userCourses, termId),
      });
      this.$gtag &&
        this.$gtag.event("advice", {
          event_category: "user_interaction",
          data_student_id: this.studentId,
          data_term_id: termId,
          data_advisor_id: getUserId()
        });

      _.set(this.userModified, _.get(term, "id"), false);
      logger.log(`confirm,`, this.userModified, this.userConfirmations);
      this.donePanel = true;
      this.refreshHint();
    },
    done() {
      this.userConfirmations = {};
      this.userCourses = {};
      this.studentId = undefined;
      this.refreshHint();
      this.donePanel = false;
      this.email = undefined;
    },
  },
  mounted: function() {
    let el = document.getElementById("scrollContainer");
    logger.log(`mounted, el:${el}`);
    if (el) {
      // el.scrollIntoView(true);
      scrollElementIntoView(el);
    }
  },
  created: function() {
    //init defaults
  },
};
</script>
