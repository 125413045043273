<template>
  <v-row align="center">
    <v-col class="text-center" cols="12">
      <Button look="btnBackShortWG" @click="back()" height="39" width="147" />
    </v-col>
  </v-row>
</template>

<script>
// import _ from 'lodash'
import { mapGetters, mapMutations } from "vuex";
import Button from "@/components/Button";

import logdown from "logdown";
const logger = logdown("SelectACourseFooter");
// logger.state.isEnabled = true;

export default {
  name: "SelectACourseFooter",

  components: {
    Button,
  },

  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
    }),
  },

  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    back() {
      logger.debug('back')
      // this.$vuetify.goTo(0);
      this.$emit('scroll-to-top')
      this.selectPage("TermCourses");
    },
  },
};
</script>
