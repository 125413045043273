<template>
  <div fill>
    <v-snackbar v-model="snackbar" app centered>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="pa-0 ma-0">
      <div v-for="term in terms" :key="term.id">
        <v-row
          no-gutters
          v-if="mode == 'select' || (mode == 'display' && display(term.id))"
        >
          <v-col cols="12" class="tile-border">
            <v-card
              flat
              class="mx-auto align-center justify-center d-flex"
              height="75"
              @click="selectTerm(term)"
            >
              <v-container class="pa-0">
                <v-row align="center">
                  <v-col cols="3">
                    <v-img
                      :src="
                        require('../assets/term-icon-' +
                          term.season +
                          (term.reservationLimit > 0 ? '' : '-inactive') +
                          '.svg')
                      "
                      contain
                      height="47"
                      style="margin-left:auto;margin-right:auto;"
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="6">
                    <div
                      :class="
                        'term-title ' +
                          (term.reservationLimit > 0
                            ? 'primary--text py-0'
                            : 'secondary--text py-0')
                      "
                    >
                      {{ term.title }}
                    </div>
                    <div
                      :class="
                        'term-info ' +
                          (term.reservationLimit > 0
                            ? 'primary--text py-0'
                            : 'secondary--text py-0')
                      "
                    >
                      {{ term.info }}
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <div class="term-toggle" v-if="mode == 'display'">
                      <div>change</div>
                    </div>
                    <div
                      class="term-badge"
                      v-if="
                        courseCount(term) > 0 &&
                          confirmed(term) &&
                          mode != 'display'
                      "
                    >
                      <div>
                        {{ courseCount(term) }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
// import PageBanner from "@/components/PageBanner";
// import { countItems } from "@/api/reservations";
import logdown from "logdown";
import _ from "lodash";

const logger = logdown("TermSelector");

export default {
  name: "TermSelector",

  components: {
    // PageBanner,
  },

  props: {
    terms: {
      type: Array,
      default: function() {
        return [];
      },
    },
    selectedTerm: {
      type: Object,
      default: function() {
        return undefined;
      },
    },
  },

  data: function() {
    return {
      snackbar: false,
      snackbarText: "Not available",
      mode: "display", // 'display' or 'select'
      term: this.selectedTerm,
    };
  },

  computed: mapState({
    termsToSelect: (state) => state.allTerms,
  }),

  methods: {
    ...mapGetters({}),
    courseCount(term) {
      logger.log(`courseCount, term.id: ${term.id}`);
      return 0; //countItems(this.$store.getters.getUserCourses, term.id);
    },
    confirmed(term) {
      logger.log(`confirmed, term: ${term}`);
      // logger.log(this.userConfirmations, term)
      // const confirmation = _.get(this.userConfirmations, _.get(term, "id"));
      return false; //_.size(confirmation) > 0;
    },
    ...mapMutations({}),
    display(termId) {
      logger.log(`display, termId:${termId}, selected: ${this.term}`);
      return termId == _.get(this.term, "id");
    },
    selectTerm(term) {
      if (this.mode == "select") {
        if (term.reservationLimit > 0) {
          // this.selectTerm(term);
          // TODO: consider to generate event
          this.$emit("quick-term-select", { term: term });
          this.mode = "display";
        } else {
          this.snackbar = true;
          this.snackbarText =
            "The " + term.title + " term is not available for reservation";
        }
      } else {
        logger.log("selecting...");
        this.mode = "select";
      }
      // this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.active-title {
  color: blue;
}
.tile-border {
  border-bottom: 1px solid #939393;
}
.term-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: red;
  /* color: #9B51E0; */
}
.term-info {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Purple 1 */
}

.term-toggle {
  border-radius: 3px;
  border: none; /*solid 1px;*/
  background-color: #ffffff;
  color: #9b51e0;
  font-size: 16px;
  height: 22px;
  width: 42px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 20px;
  /* padding: 4px 6px; */
  pointer-events: auto;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.term-badge {
  border-radius: 3px;
  border: solid 1px;
  background-color: #9b51e0;
  color: #ffffff;
  font-size: 12px;
  height: 22px;
  width: 22px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 20px;
  /* padding: 4px 6px; */
  pointer-events: auto;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
