var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:('height: 100%; width: 100%; display: flex; flex-flow: column;')},[(_vm.barRole == 'top-bar')?_c('div',{style:('display: flex; width: 100%; height: 100%;' +
        ' justify-content: center; background: ' +
        _vm.backdropColor +
        ';')},[_c('div',{style:('width: ' +
          _vm.contentWidth +
          '; background: ' +
          _vm.backgroundColor +
          '; margin: 0; padding: 0; height: 100%; ' +
          'display: flex; flex-flow: column; justify-content: center;'),on:{"goto":_vm.onGoto}},[_vm._t("default")],2)]):_vm._e(),(_vm.barRole == '')?_c('div',{style:('display: flex; width: 100%; justify-content: center; background: ' +
        _vm.backdropColor +
        ';')},[_c('div',{style:('width: ' + _vm.contentWidth + '; background: ' + _vm.backgroundColor + ';')},[_c('div',{style:(("width: " + _vm.contentWidth + "; \n          height: " + (_vm.appWindowHeight - _vm.topBarHeight - _vm.footBarHeight) + "px;\n          overflow-y: " + (!_vm.scrollY?'auto':_vm.scrollY))),attrs:{"id":"parent-box"}},[_c('div',{attrs:{"id":"slot-box"}},[_c('div',{attrs:{"id":"slot-content"},on:{"goto":_vm.onGoto}},[_vm._t("default")],2)])])])]):_vm._e(),(_vm.barRole == 'foot-bar')?_c('div',{style:('display: flex; width: 100%; justify-content: center; background: ' +
        _vm.backdropColor +
        ';')},[_c('div',{style:('width: ' + _vm.contentWidth + '; background: ' + _vm.backgroundColor + ';'),on:{"goto":_vm.onGoto}},[_vm._t("default")],2)]):_vm._e(),_c('div',{style:((_vm.debugStyle ? _vm.debugBorder : '') +
        ' flex: 1 1 auto; background: ' +
        _vm.backdropColor +
        ';' +
        ' flex-flow: row; display: flex; justify-content: center; ')},[_c('div',{style:('background: ' +
          _vm.backgroundColor +
          '; width:' +
          _vm.contentWidth +
          '; height 44px;')})])])}
var staticRenderFns = []

export { render, staticRenderFns }