/**
 * Mocking the Times related API
*/

import lodash from 'lodash'
import logdown from 'logdown'
const logger = logdown("times");


const _weekDays = {
  aMN: { name: 'Monday', activeDay: true},
  bTU: { name: 'Tuesday', activeDay: true},
  cWE: { name: 'Wednesday', activeDay: true},
  dTH: { name: 'Thursday', activeDay: true },
  eFR: { name: 'Friday', activeDay: true },
  fSA: { name: 'Saturday', activeDay: true },
  gSU: { name: 'Sunday', activeDay: true },
}

const _dayTimes = {
  '-0000-0100': { activeTime: false },
  '-0100-0200': { activeTime: false },
  '-0200-0300': { activeTime: false },
  '-0300-0400': { activeTime: true },
  '-0400-0500': { activeTime: true },
  '-0500-0600': { activeTime: true },
  '-0600-0700': { activeTime: true },
  '-0700-0800': { activeTime: true },
  '-0800-0900': { activeTime: true },
  '-0900-1000': { activeTime: true },
  '-1000-1100': { activeTime: true },
  '-1100-1200': { activeTime: true },
  '-1200-1300': { activeTime: true },
  '-1300-1400': { activeTime: true },
  '-1400-1500': { activeTime: true },
  '-1500-1600': { activeTime: true },
  '-1600-1700': { activeTime: true },
  '-1700-1800': { activeTime: true },
  '-1800-1900': { activeTime: true },
  '-1900-2000': { activeTime: true },
  '-2000-2100': { activeTime: false },
  '-2100-2200': { activeTime: false },
  '-2200-2300': { activeTime: false },
  '-2300-0000': { activeTime: false },
}

function genTimes() {
  let times = []
  for (let _day in _weekDays) {
    for (let _time in _dayTimes){
      let time = {}
        lodash.assign(time, _weekDays[_day])
        lodash.assign(time, _dayTimes[_time])
        time.id = _day + _time
        times.push(time)
        logger.debug('time:', time)
    }
  }
  logger.debug('times.length: ' + times.length)
  return times;
}

const _times = genTimes();

function fetchTimes() {
  return new Promise((resolve) => {
    setTimeout(() => {
        resolve(_times) 
    }, 0)
  })
}

export {
  fetchTimes,
}