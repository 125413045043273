const nick = "USI"
const name = "University of Southern Indiana"
const domain = 'usi.edu'
const appDomain = 'usi'

const modalities = [
    { id: "In person", title: "In person" },
    { id: "Hybrid", title: "Hybrid (synchronous)" },
    { id: "Online", title: "Online (asynchronous)" },
  ]

export {
    nick,
    name,
    domain,
    modalities,
    appDomain,
}