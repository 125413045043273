<template>
  <div
    @click.passive="onClick"
    class="custom-button d-flex align-center justify-center pa-0 ma-0"
  >
    <LongPrimaryBtn v-if="look == 'longPrimary'" />
    <LongSecondaryBtn v-if="look == 'longSecondary'" />
    <ShortSecondaryBtn v-if="look == 'shortSecondary'" />
    <SmallPrimaryBtn v-if="look == 'smallPrimary'" />
    <div class="button-content">
      <div :style="`color: ${textColor};`">
        <v-icon
          :color="textColor"
          :size="iconSize"
          class="py-0 px-1 ma-0"
          :style="`width: ${iconSize}px;`"
          >{{ leftIcon }}</v-icon
        >
      </div>
      <div>
        <div
          class="button-text-container"
          :style="`color: ${textColor}; width: ${textWidth};`"
        >
          <div :class="`${(look!='smallPrimary pt-2') ? 'button-text' : 'small-button-text'}`">
            {{ text }}
          </div>
        </div>
      </div>
      <div :style="`color: ${textColor};`">
        <v-icon
          :color="textColor"
          :size="iconSize"
          class="py-0 px-1 ma-0"
          :style="`width: ${iconSize}px;`"
          >{{ rightIcon }}</v-icon
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-button {
  cursor: pointer;
}
.button-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-button-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.button-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}
.button-content {
  width: inherit;
  position: absolute;
  color: white;
  cursor: pointer;
  display: flex;
  flex-flow: row;
}
</style>

<script>
import LongPrimaryBtn from "@/components/buttons/LongPrimaryBtn";
import LongSecondaryBtn from "@/components/buttons/LongSecondaryBtn";
import ShortSecondaryBtn from "@/components/buttons/ShortSecondaryBtn";
import SmallPrimaryBtn from "@/components/buttons/SmallPrimaryBtn";
import logdown from "logdown";

const logger = logdown("CustomButton");

export default {
  components: { LongPrimaryBtn, LongSecondaryBtn, ShortSecondaryBtn, SmallPrimaryBtn },
  name: "CustomButton",
  props: {
    look: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "btn",
    },
    textColor: {
      type: String,
      default: "white",
    },
    iconSize: {
      type: String,
      default: "22",
    },
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
  },
  computed: {
    textWidth: function() {
      return /^long.*/gi.test(this.look) || Number.parseInt(this.size) > 160
        ? this.longWidth
        : this.shortWidth;
    },
  },
  data: () => {
    return {
      longWidth: "170px",
      shortWidth: "80px",
    };
  },
  methods: {
    onClick(event) {
      logger.debug("onClick", event);
      this.$emit("click", event);
    },
  },
};
</script>
