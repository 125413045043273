<template>
  <div id="scrollContainer">
    <PageBanner :bannerText="'Confirm your reservation request'" />
    <TermCoursesSlots
      :readOnly="true"
      :userInfo="false"
      :banner="'Reservation requests for ' + selectedTerm.title"
    />
    <ATermLanding :section="true" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { scrollElementIntoView } from '@/utils'
import { skipPage } from "@/api/config";
import PageBanner from "@/components/PageBanner";
import TermCoursesSlots from "@/components/TermCoursesSlots";
import ATermLanding from "@/components/ATermLanding";

// import logdown from "logdown";
// const logger = logdown("TermReview");
// logger.state.isEnabled = true;

export default {
  name: "TermReview",
  components: {
    PageBanner,
    TermCoursesSlots,
    ATermLanding,
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
    }),
  },
  methods: {
    available: function(page) {
      return !skipPage(this.config, page);
    },
  },
  mounted: function() {
    let el = document.getElementById("scrollContainer");
    if (el) {
      // el.scrollIntoView(true);
      scrollElementIntoView(el)
    }
  },
};
</script>
